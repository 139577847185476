import React from "react";
import styles from "./DashboardConfigSet.less";
import {Form,Input,Radio} from "antd";
import CommonFormItems from "../CommonFormItems";
import ColorPicker from "../formDiyItem/ColorPicker";

const formItemLayout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 19,
    },
    size: "small"
};

export default class BoxDescSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formUpdate: 0
        }
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    //延迟触发，避免太频繁
    delayChangeAction(action){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            action?.();
        },300)
    }

    render(){
        let {formUpdate} = this.state;
        return <div className={styles.dashboardSetBox}>
            <Form
                key={"descSet_" + formUpdate}
                name={"descSet"}
                {...formItemLayout}
                requiredMark={false}
                initialValues={this.props.descConfig}
                onValuesChange={(changedValues,allValues)=>{
                    this.delayChangeAction(()=>{
                        //通知外部改变
                        this.props.onChange(allValues);
                    })
                }}
            >
                <Form.Item
                    label={"显示"}
                    name={["showType"]}
                >
                    <Radio.Group>
                        <Radio value={"always"}>总是显示</Radio>
                        <Radio value={"hover"}>鼠标经过容器</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={"方位"}
                    name={["position"]}
                >
                    <Radio.Group>
                        <Radio value={"topLeft"}>左上</Radio>
                        <Radio value={"topRight"}>右上</Radio>
                        <Radio value={"bottomLeft"}>左下</Radio>
                        <Radio value={"bottomRight"}>右下</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={"间距"}
                >
                    {CommonFormItems.distanceSet(["marginSet","value"],["marginSet","unit"],["px","rem"])}
                </Form.Item>
                <Form.Item
                    label={"大小"}
                >
                    {CommonFormItems.distanceSet(["sizeSet","value"],["sizeSet","unit"],["px","rem"])}
                </Form.Item>
                <Form.Item
                    label={"颜色"}
                    name={["color"]}
                >
                    <ColorPicker/>
                </Form.Item>
                <Form.Item
                    label={"文案"}
                    name={["desc"]}
                    // trigger={"onBlur"} bug:无法触发onValuesChange
                >
                    <Input.TextArea
                        autoSize={{minRows: 6, maxRows: 10}}
                    />
                </Form.Item>
            </Form>
        </div>
    }
}