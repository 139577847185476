import HttpTool from "../../tool/HttpTool";
class MetaDataGet {
  constructor() {

  }

  getMeteData(succCB, errCB) {
    if (window.sourceTreeData) {
      //已经存在数据
      this.getDataFromWindow(120, succCB, errCB, 'tree');
    } else {
      //初始化，并向服务器请求
      window.sourceTreeData = {
        treeData: null,
        treeDataNameFlat: null,
        update: 0,
      };
      this.getAllDataTree(succCB, errCB, 'tree');
    }
  }

  getMeteNameFlatData(succCB, errCB) {
    if (window.sourceTreeData) {
      //已经存在数据
      this.getDataFromWindow(120, succCB, errCB, 'flat');
    } else {
      //初始化，并向服务器请求
      window.sourceTreeData = {
        treeData: null,
        treeDataNameFlat: null,
        update: 0,
      };
      this.getAllDataTree(succCB, errCB, 'flat');
    }
  }

  //获得 _id 与 值的对应集合 (_id是唯一的，变成一级结构以后方便查找数据)
  getKeyMatchTitleCon(meteData) {
    let result = {};

    let action = (item) => {
      result[item['_id']] = item;
      if (item.children) {
        item.children.forEach((child) => {
          action(child);
        });
      }
    };

    action(meteData);

    return result;
  }

  //获得 名称与 _id的对应集合  (名称可能会重复,_id会被覆盖)
  getTitleMatchKeyCon(meteData) {
    let result = {};

    let action = (item) => {
      result[item['title']] = item['_id'];
      if (item.children) {
        item.children.forEach((child) => {
          action(child);
        });
      }
    };

    action(meteData);

    return result;
  }

  /**
   * 向全局查找元数据集合
   * @param times
   */
  getDataFromWindow(times, succCB, errCB, type) {
    if (!times) {
      errCB && errCB('超时未获取到数据');
    } else {
      if (window.sourceTreeData && window.sourceTreeData.treeData) {
        if (type === 'flat') {
          succCB && succCB(window.sourceTreeData.treeDataNameFlat);
        } else {
          succCB && succCB(window.sourceTreeData.treeData);
        }
      } else {
        //已经初始化过了，但是还没有数据结果，所以只要等待数据返回就行了
        setTimeout(() => {
          this.getDataFromWindow(--times, succCB, errCB, type);
        }, 500);
      }
    }
  }

  //获取完整的元数据（强刷）
  getAllDataTree(succCB, errCB, type) {
    let path = '';
    let param = {};
    let successCB = (code, msg, obj, option) => {
      console.log(obj);
      let update =
        window.sourceTreeData && window.sourceTreeData.update
          ? window.sourceTreeData.update + 1
          : 1;
      window.sourceTreeData = {
        update: update,
        treeData: obj,
        treeDataNameFlat: this.getNameFlat(_.cloneDeep(obj)),
      };
      if (type === 'flat') {
        succCB && succCB(window.sourceTreeData.treeDataNameFlat);
      } else {
        succCB && succCB(window.sourceTreeData.treeData);
      }
    };
    let failureCB = (code, msg, obj, option) => {
      console.error(msg);
      errCB && errCB(msg);
    };

    //后台无法获取元数据，直接设置空置
    successCB(200,"模拟数据",[],{})

    // HttpTool.post(path, successCB, failureCB,param);
  }

  //递归得到每个键与元数据的一层对象结构
  getNameFlat(list) {
    let obj = {};

    let loopGet = (list) => {
      list?.forEach((item) => {
        if (item.children) {
          loopGet(item.children);
        }
        delete item.children;
        delete item.developer;
        if(item.mdId){
          obj[item.mdId] = item;
        }else{
          obj[item._id] = item;
        }
      });
    };

    loopGet(list);

    return obj;
  }
}

export default MetaDataGet;
