import React from "react";
import {Input,Space,Row,Col,Button,message} from "antd";
import {MinusCircleOutlined,PlusCircleOutlined,ArrowRightOutlined,ArrowDownOutlined} from "@ant-design/icons";
import ColorPicker from "./ColorPicker";
import styles from "./ColorPicker.less"

export default class ColorArray extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            colorArray: this.props.value || [],
            listChange: 0,
        }
    }

    render(){
        let {colorArray,listChange} = this.state;
        return <Space
            size={5}
            style={{width: "100%"}}
            wrap={true}
        >
            {colorArray?.map((eachColor,index)=>{
                if(typeof eachColor === "string" || !eachColor){
                    return <div
                        key={"ec_" + listChange + index}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: 4
                        }}
                    >
                        <MinusCircleOutlined
                            style={{color: "red",fontSize: "14px"}}
                            onClick={()=>{
                                this.removeColor(index)
                            }}
                        />
                        <ColorPicker
                            value={eachColor}
                            hideOpacity={!this.props.hasOpacity}
                            hideRecommendBtn={true}
                            onChange={(colorStr)=>{
                                this.changeColor(colorStr,index)
                            }}
                        />
                    </div>
                }else{
                    return <div
                        key={"ec_" + listChange + index}
                        style={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: 4
                        }}
                    >
                        <MinusCircleOutlined
                            style={{color: "red",fontSize: "14px"}}
                            onClick={()=>{
                                this.removeColor(index)
                            }}
                        />
                        <ColorPicker
                            hideClearBtn={true}
                            value={eachColor.colorStops[0]}
                            hideOpacity={!this.props.hasOpacity}
                            hideRecommendBtn={true}
                            onChange={(colorStr)=>{
                                eachColor.colorStops[0] = colorStr;
                                this.changeColor(eachColor,index)
                            }}
                        />
                        +
                        <ColorPicker
                            hideClearBtn={true}
                            value={eachColor.colorStops[1]}
                            hideOpacity={!this.props.hasOpacity}
                            hideRecommendBtn={true}

                            onChange={(colorStr)=>{
                                eachColor.colorStops[1] = colorStr;
                                this.changeColor(eachColor,index)
                            }}
                        />
                        =
                        <div
                            className={styles.colorShowBox}
                            style={{
                                background: `linear-gradient(${eachColor.direction==="horizontal"?"90deg":"180deg"}, ${eachColor.colorStops[0]} 0%, ${eachColor.colorStops[1]} 100%)`,
                            }}
                        ></div>
                        {
                            eachColor.direction==="horizontal"
                                ?<ArrowRightOutlined
                                    style={{color: "#843cff",fontSize: "14px"}}
                                    onClick={()=>{
                                        eachColor.direction = "vertical";
                                        this.changeColor(eachColor,index)
                                    }}
                                />
                                :<ArrowDownOutlined
                                    style={{color: "#843cff",fontSize: "14px"}}
                                    onClick={()=>{
                                        eachColor.direction = "horizontal";
                                        this.changeColor(eachColor,index)
                                    }}
                                />
                        }
                    </div>
                }
            })}
            <Button
                type={"link"}
                onClick={()=>{
                    this.addColor();
                }}
            >
                <span style={{color: "limegreen"}}>
                    <PlusCircleOutlined />
                    &nbsp;&nbsp;
                    新增颜色</span>
            </Button>
            {
                this.props.hasGradient
                    ?   <Button
                        type={"link"}
                        onClick={()=>{
                            this.addGradient();
                        }}
                    >
                <span style={{color: "limegreen"}}>
                    <PlusCircleOutlined />
                    &nbsp;&nbsp;
                    新增渐变组</span>
                    </Button>
                    :null
            }
        </Space>
    }

    changeColor(color,index){
        this.state.colorArray && this.state.colorArray[index] && (this.state.colorArray[index] = color);

        this.setState({},()=>{
            this.props.onChange?.(this.state.colorArray)
        })
    }

    removeColor(index){
        _.remove(this.state.colorArray,(ti, n) => {
            return n === index
        })
        this.setState({
            listChange: this.state.listChange + 1
        },()=>{
            this.props.onChange?.(this.state.colorArray)
        })
    }

    addColor(){
        if(!Array.isArray(this.state.colorArray)){
            this.state.colorArray = [];
        }

        if(this.props.max && this.state.colorArray.length>=this.props.max){
            message.warning("最多添加" + this.props.max + "个颜色");
            return;
        }
        this.state.colorArray.push("#000000")
        this.setState({
            listChange: this.state.listChange + 1
        },()=>{
            this.props.onChange?.(this.state.colorArray)
        })
    }

    addGradient(){
        if(!Array.isArray(this.state.colorArray)){
            this.state.colorArray = [];
        }

        if(this.props.max && this.state.colorArray.length>=this.props.max){
            message.warning("最多添加" + this.props.max + "个颜色");
            return;
        }
        this.state.colorArray.push({
            type: 'linear',
            direction: "horizontal",//vertical
            colorStops: ["#000000","#ffffff"]
        })
        this.setState({
            listChange: this.state.listChange + 1
        },()=>{
            this.props.onChange?.(this.state.colorArray)
        })
    }
}