import React, {Component} from 'react';
import styles from "./SimpleMsgDiv.less";
import CommonViewDiv from "../../../CommonViewDiv";
import NumberAnimation from "./NumberAnimation"

export default class SimpleMsgDiv extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            msgStr: ""
        }
    }

    showValue(value){
        this.setState({
            msgStr: value
        })
    }

    render(){
        let msgStr = this.state.msgStr;
        let err;
        if(typeof msgStr === "object"){
            err = "[格式错误]"
        }

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};

        if(!isNaN(Number(msgStr)) && contentParams.tranBigNum){
            //是数字,且开启了处理
            msgStr = this.tranNumber(msgStr,contentParams.bigNumPoint)
        }

        return <div
            className={styles.outerBox}
            style={{
                alignItems: `${contentParams.alignItems}`
            }}
        >
            {
                contentParams.beforeType === "text"
                    ?CommonViewDiv.getText(contentParams.beforeTextSet,contentParams.beforeTextSet?.text)
                    :null
            }
            {
                contentParams.beforeType === "icon"
                    ?CommonViewDiv.getIcon(contentParams.beforeIconSet)
                    :null
            }
            <div className={styles.centerMsg}>
                <div
                    style={{
                        marginLeft: window.changeRemNum(contentParams.marginLeft),
                        marginRight: window.changeRemNum(contentParams.marginRight),
                    }}
                >
                    {
                        err
                            ?<span style={{color:"red"}}>格式错误</span>
                            :contentParams.numberDiffShow
                                ?(this.getHasValueText(msgStr,contentParams.centerTextSet?.text))?.toString().split("").map((numberItem,index)=>{
                                return <NumberAnimation
                                    key={index}
                                    value={numberItem}
                                    styleSet={contentParams.centerTextSet}
                                    animateTime={contentParams.animateTime || 0.5}
                                />
                            })
                            :CommonViewDiv.getText(contentParams.centerTextSet,this.getHasValueText(msgStr,contentParams.centerTextSet?.text))
                    }
                </div>
            </div>
            {
                contentParams.afterType === "text"
                    ?CommonViewDiv.getText(contentParams.afterTextSet,contentParams.afterTextSet?.text)
                    :null
            }
            {
                contentParams.afterType === "icon"
                    ?CommonViewDiv.getIcon(contentParams.afterIconSet)
                    :null
            }

        </div>
    }

    getHasValueText(a,b){
        if(a === null || a === undefined){
            return b
        }else{
            return a
        }
    }

    // num接收的数字,point保留数字的第几位
    tranNumber(num, point=0){
        // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
        let numStr = num.toString().split('.')[0]
        if(numStr.length<6) { // 判断数字有多长,如果小于6,,表示10万以内的数字,让其直接显示
            // console.log(numStr);
            return numStr;
        }else if(numStr.length>=6 && numStr.length<=8){ // 如果数字大于6位,小于8位,让其数字后面加单位万
            let decimal = numStr.substring(numStr.length-4, numStr.length-4+point)
            // console.log(decimal);
            // 由千位,百位组成的一个数字
            return parseFloat(parseInt(num / 10000)+'.'+decimal)+'万'
        }else if(numStr.length >8){ // 如果数字大于8位,让其数字后面加单位亿
            let decimal = numStr.substring(numStr.length-8, numStr.length-8+point);
            // console.log(decimal);
            return parseFloat(parseInt(num/100000000)+'.'+decimal)+'亿'
        }
    }
}