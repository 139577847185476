import React from "react";
import {Space,Button,Popover,Checkbox} from "antd";
import SVGForDivBorder from "../contentView/SVGForDivBorder";
import styles from "./DashboardConfigSet.less"

export default class SVGBorderTypeSel extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            selType: this.props.value || null,
            visible: false
        }

        this.enterTime = Date.now();
    }

    render(){
        let {selType,visible} = this.state;

        return <Space>
            {
                selType
                    ?SVGForDivBorder.getSVGName(selType)
                    :"未选择"
            }
            <Popover
                // trigger="click"
                title="选择一个边框类型"
                visible={visible}
                content={this.getBorderTypeShow()}
                color={"#1f2333"}
                arrowPointAtCenter={true}
            >
                <Button
                    type={"link"}
                    onClick={()=>{
                        this.setState({
                            visible: !visible
                        })
                    }}
                >
                    选择
                </Button>
            </Popover>
        </Space>
    }

    getBorderTypeShow(){
        let {selType} = this.state;
        let allSvgObj = SVGForDivBorder.getAllTypes();
        return <div className={styles.svgContainer}
            onMouseLeave={()=>{
                if(Date.now() - this.enterTime < 150){
                    return
                }
                this.setState({
                    visible: false
                })
            }}
            onMouseEnter={()=>{
                this.enterTime = Date.now();
            }}
        >
            {
                Object.keys(allSvgObj).map((key)=>{
                    return <div
                        key={key}
                        className={styles.svgShowBox}
                        onClick={()=>{
                            this.setState({
                                selType: key
                            },()=>{
                                this.props.onChange?.(key)
                            })
                        }}
                    >
                        <div
                            className={styles.svgContent}
                            style={{
                                color: this.props.color || "#fff"
                            }}
                        >
                            {allSvgObj[key]}
                        </div>
                        <Space>
                            <Checkbox
                                checked={selType === key}
                            />
                            {SVGForDivBorder.getSVGName(key)}
                        </Space>
                    </div>
                })
            }
        </div>
    }
}