import React from "react";
import styles from "./ChartOptionSetView.less";
import {Select,message,Space,Button,Modal,Flex,Form,Tooltip} from "antd";
import HttpTool from "../../../../../tool/HttpTool";
import {ProForm,ProFormText} from "@ant-design/pro-components"
import DataDefinitionFile from "../../dataDisposition/DataDefinitionFile";
import TimeHelp from '../../../../../tool/TimeHelp';
import LocalStorage from '../../../../../tool/LocalStorage';
import {BgColorsOutlined,FormatPainterOutlined} from "@ant-design/icons";
import {UsageToCodeMap} from "./UsageDictionary";
import APILXD from '../../../../../http/APILXD';

const ContentParamsSetSaveKey = "ContentParamsSetSaveKey";
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default class ChartOptionSetView extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      categoryId: this.props.defaultCategoryId || null,
      dataConfig: this.props.defaultDataConfig || {},
      categoryList: [],
      formUpdate: 0
    }

    console.log("this.props",this.props)
    this.businessType = UsageToCodeMap[props.usage] || 2
  }

  componentDidMount(){
    this.getAllCategory()
  }

  componentWillUnmount(){
    if(this.delayMark){
      clearTimeout(this.delayMark);
      this.delayMark = null;
    }
  }

  getAllCategory(){
    HttpTool.post(APILXD.getBackStageCategory,(code,msg,json)=>{
      this.setState({
        categoryList: json.filter((a)=>{
          return a._id && a._id !== "0"
        })
      })
    },(code,msg)=>{
      message.error(msg)
    },{
      businessType: this.businessType
    })
  }

  addCategory(){
    Modal.confirm({
      title: "新增类别",
      icon: null,
      content: (
        <ProForm
          layout='horizontal'
          wrapperCol={{span: 19}}
          submitter={{
            render: (props) => {
              return (
                <Flex style={{width: '100%'}} justify='flex-end'>
                  <Space size={16}>
                    <Button
                      type="default"
                      onClick={() => {
                        Modal.destroyAll()
                      }}
                    >
                      取消
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        props.form?.submit();
                      }}
                    >
                      保存
                    </Button>
                  </Space>
                </Flex>

              );
            },
          }}
          onFinish={async (values) => {
            console.log('values', values)

            HttpTool.post(APILXD.editOrAddModuleCategory,(code,msg,json)=>{
              Modal.destroyAll()
              message.success(msg)
              this.getAllCategory()

              if(json?._id){
                this.setState({
                  categoryId: json._id
                },()=>{
                  this.props.onCategoryChange?.(json._id)
                })
              }

            },(code,msg)=>{
              message.error(msg)
            },{
              name: values.name,
              businessType: this.businessType,
            })
          }}
        >
          <div style={{padding: '16px 0 64px 0',}}>
            <ProFormText
              name="name"
              label="类别名称"
              placeholder="请输入类别名称名称,1-10字符"
              required
              fieldProps={{
                maxLength: 10,
                showCount: true
              }}
              rules={[{required: true}]}
            />
          </div>
        </ProForm>
      ),
      footer: null
    })
  }

  render(){
    let {categoryId,categoryList,dataConfig} = this.state;

    return <div
      className={styles.outerBox}
    >
      <div className={styles.header}>
        <div style={{fontSize: "16px"}}>属性配置</div>
        <Space>
          <Select
            placeholder={"给组件划分类别"}
            style={{width: "150px"}}
            allowClear={true}
            value={categoryId}
            onChange={(v)=>{
              this.setState({
                categoryId: v
              },()=>{
                this.props.onCategoryChange?.(v)
              })
            }}
            showSearch={true}
            filterOption={(input, option) => {
              return (
                option.props.label
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
            options={categoryList?.map((eachCategory)=>{
              return {
                label: eachCategory.name,
                value: eachCategory._id
              }
            })}
          />
          <Button
            type={"link"}
            onClick={()=>{
              this.addCategory()
            }}
          >
            新增类别
          </Button>
        </Space>
      </div>
      <div className={styles.body}>
        {this.getChartOptionSetView(dataConfig)}
      </div>
    </div>
  }

  //延迟触发，避免太频繁
  delayChangeAction(action){
    if(this.delayMark){
      clearTimeout(this.delayMark);
    }
    this.delayMark = setTimeout(()=>{
      action?.();
    },300)
  }

  //不同类型的视图，有不同的配置项
  getChartOptionSetView(dataConfig){
    if(!dataConfig?.contentType){
      return null
    }

    let itemArr = DataDefinitionFile.getViewSetItems(dataConfig.contentType,{
      bindData: this.props.bindData || null,
    });
    let initialValues = dataConfig.contentParams;

    let onlyStyleUpdate = true;

    return <Form
      key={"form_" + dataConfig.contentType + this.state.formUpdate}
      ref={ref=>this.formForViewChartOption = ref}
      initialValues={initialValues}
      requiredMark={false}
      size={"small"}
      {...formItemLayout}
      onFieldsChange={(changedFields, allFields)=>{
        // console.log("changedFields",changedFields)
        this.delayChangeAction(()=>{
          dataConfig.contentParams = this.formForViewChartOption.getFieldsValue();
          this.setState({},()=>{
            if(changedFields[0]?.name?.indexOf("clickActionSet") !== -1){
              this.saveAll(false)
            }else{
              this.saveAll(onlyStyleUpdate)
            }
          })
        })
      }}
    >
      {
        itemArr
          ?this.getStylesCopyBtns(dataConfig.contentType,()=>{
            return this.formForViewChartOption.getFieldsValue();
          },(newViewParams)=>{
            dataConfig.contentParams = newViewParams;
            this.setState({
              formUpdate: this.state.formUpdate + 1
            },()=>{
              //整个参数替换了，直接强刷
              this.saveAll(false)
            })
          })
          :null
      }
      {itemArr}
    </Form>
  }


  saveAll(onlyStyleUpdate=false){
    this.props.onContentParamsChange?.(this.state.dataConfig,onlyStyleUpdate);
  }

  getStylesCopyBtns(formName,getCurrentParams,setNewParams){
    return <div
      className={styles.copyItemsBtnBox}
    >
      <Space>
        <Tooltip
          title={"收集配置"}
        >
          <Button
            icon={<FormatPainterOutlined />}
            type={"primary"}
            ghost={true}
            size={"small"}
            onClick={(e)=>{
              LocalStorage.setWithoutId(ContentParamsSetSaveKey,{
                formName: formName,
                time: Date.now(),
                styleObj: getCurrentParams()
              })
              message.success(`已收集到配置`);
            }}
          >

          </Button>
        </Tooltip>
        <Tooltip
          title={"粘贴配置"}
        >
          <Button
            icon={<BgColorsOutlined />}
            type={"primary"}
            ghost={true}
            size={"small"}
            onClick={()=>{
              let obj = LocalStorage.getWithoutId(ContentParamsSetSaveKey);

              if(!obj){
                message.warning("缓存中无收集到的配置");
                return;
              }

              if(obj.formName !== formName){
                message.warning(`收集的配置 与 该栏目不匹配`);
                return;
              }


              Modal.confirm({
                title: "提示",
                content: <div>
                  <div>是否将参数配置替换成【收集】到的配置？</div>
                  <div>收集时间：{TimeHelp.getYMDHMS(obj.time,true)}</div>
                </div>,
                onOk: ()=>{
                  setNewParams?.(obj.styleObj)
                }
              })
            }}
          >

          </Button>
        </Tooltip>
      </Space>
    </div>
  }
}