import React, {Component} from 'react';
import ReactDom from 'react-dom';
import less from './CountView.less';
import TimeHelp from '../../../../tool/TimeHelp.js';
import {DatePicker, message, Spin, Row, Col, InputNumber} from 'antd';
import APILXD from "../../../../http/APILXD";
import moment from "moment"
import HttpTool from "../../../../tool/HttpTool";


//按需加载 Echarts的组件
//引入 Echarts 主模块
// let echarts = require('echarts/lib/echarts');
// // //线型图
// require('echarts/lib/chart/line');
// // //提示框
// require('echarts/lib/component/tooltip');
// // //图例
// require('echarts/lib/component/legend');
// // //标题
// require('echarts/lib/component/title');


class CountDayDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            dateStr: null,
            step: 10,
            loading: false,
        };

        //基础配置
        this.option = {
            tooltip: {
                trigger: 'axis',
                formatter: (params, ticket) => {
                    // console.log(params);
                    let str = "<div>心跳时间：</div>";
                    if (params[0].data.time.length > 0) {
                        params[0].data.time.forEach((obj) => {
                            let statusStr = obj.status == 2 ? 'TV中' : '可工作';
                            str += "<div>" + TimeHelp.getHM(obj.addtime) + "   (" + statusStr + ")</div>";
                        });
                        return str;
                    }
                    return "无心跳 (离线)";
                },
            },
            calculable: true,
            // title: {
            //     text: '请选择日期查看当天在线情况',
            //     x: 'center'
            // },
            yAxis:
                {
                    type: 'value',
                    name: '状态(0:离线,1:TV中,2:可工作)',
                    splitNumber: 4,
                    minInterval: 1,
                    min: 0,
                    max: 3,
                },
            xAxis:
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        // rotate: 45,
                    },
                    data: []
                },
            series: [
                {
                    name: '在线状态',
                    type: 'line',
                    itemStyle: {
                        normal: {
                            color: '#2f86fc',
                        }
                    },
                    data: []
                },
            ]
        };
    }

    componentDidMount() {
        //初始化 echarts 实例
        setTimeout(() => {
            let chartDayDom = ReactDom.findDOMNode(this.chartDay);
            this.myDayChart = echarts.init(chartDayDom);
            //绘制
            this.paintChart();
        }, 0);
    }

    loadDayData(dateString) {
        if (!this.props.id || !dateString) {
            return;
        }
        let params = {
            deviceId: this.props.id,
            time: moment(dateString).valueOf(),
        };
        let successCB = (code, msg, json, option) => {
            this.setState({
                data: json,
                dateStr: dateString,
                loading: false,
            }, () => {
                this.paintChart()
            })
        };
        let failureCB = (code, msg) => {
            this.setState({
                loading: false,
            }, () => {
                message.error(msg);
            })
        };

        this.setState({
            loading: true
        }, () => {
            HttpTool.post(APILXD.getDeviceDayHeartbeat, successCB, failureCB, params);
        })
    }

    render() {
        return (
            <div className={less.mainBox}>
                <Spin spinning={this.state.loading} size={'large'}>
                    <Row>
                        <Col offset={1}>
                            <div className={less.dateSel02}>
                                选择日期查看心跳情况：<DatePicker
                                showToday={false}
                                format={"YYYY-MM-DD"}
                                disabledDate={((currentDate) => {
                                    return (currentDate && currentDate.valueOf() >= (Date.now())) || (currentDate && currentDate.valueOf() <= (Date.now() - 86400000 * 30))
                                })}
                                onChange={(date, dateString) => {
                                    this.loadDayData(dateString);
                                }}
                            />
                                &nbsp;&nbsp;&nbsp;&nbsp;时间精度：<InputNumber
                                value={this.state.step}
                                min={1}
                                max={60}
                                precision={0}
                                step={5}
                                onChange={(val) => {
                                    this.setState({
                                        step: val,
                                    }, () => {
                                        this.paintChart();
                                    })
                                }}
                            />
                                &nbsp;分钟
                            </div>
                        </Col>
                    </Row>
                    <div
                        ref={(ref) => {
                            this.chartDay = ref;
                        }}
                        className={less.countBox02}
                    >
                    </div>
                </Spin>
            </div>
        );
    }

    //配置数据
    getOptionConfig() {
        let step = this.state.step;

        let option = _.merge({}, this.option);
        let {data, dateStr} = this.state;
        //时区
        let dateStamp = (new Date(dateStr)).valueOf() - 28800000;

        // option.title.text = dateStr ? (dateStr + ' 在线情况统计') : '请选择日期查看当天在线情况';
        option.xAxis.data = [];
        option.series[0].data = [];

        if (!data || !data.length || data.length <= 0) {

        } else {
            let returnData = [];
            for (let obj of data) {
                let timeName = TimeHelp.getHM(obj.addtime);
                returnData[timeName] = obj;
            }

            let allTime = [];
            let all = 86400000 / 60000 / step;  //一共多少个刻度
            for (let i = 0; i < all; i++) {
                let timeName = TimeHelp.getHM(dateStamp + i * 60000 * step);
                // allTime.push({
                //     title: timeName,
                //     value: returnData[timeName] || 0,
                // });

                // let value = returnData[timeName] || 0;
                // option.xAxis.data.push(timeName);
                // option.series[0].data.push(value);

                let val = {value: 0, time: []};
                for (let j = 0; j < step; j++) {
                    let timeName02 = TimeHelp.getHM(dateStamp + i * 60000 * step + 60000 * j);
                    if (returnData[timeName02]) {
                        val.value = returnData[timeName02].status == 2?1:2;
                        val.time.push(returnData[timeName02]);
                    }
                }

                option.xAxis.data.push(timeName);
                option.series[0].data.push(val);
            }
        }

        return option;
    }


    //绘制方法
    paintChart() {
        this.option = this.getOptionConfig();

        this.myDayChart.setOption(this.option);
    }
}

module.exports = CountDayDetail;