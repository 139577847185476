import React from "react";
import {
    Form,
    Divider,
    Input,
    Select,
    Slider,
    Radio,
    Switch,
    Collapse,
    InputNumber
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

const Item = Form.Item;

/**
 * 柱状图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            {EchartComItem.getDatasetItems(extendData)}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="barSpecial"
            header="柱状图专用"
        >
            <Item
                label={"显示柱条的底色"}
                name={["barSpecial","showBackground"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"底色颜色"}
                name={["barSpecial","backgroundStyle","color"]}
                extra={"不设置，则自动继承线条颜色"}
                rules={[{required: false}]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"不同系列柱间距"}
                extra={"为负数时，将发生重叠"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["barSpecial","_barGap"],{
                    min: -100,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"数值最小值配置"}
                name={["barSpecial","_minSet","type"]}
            >
                <Radio.Group
                >
                    <Radio value="none">不设定</Radio>
                    <Radio value="set">手动</Radio>
                    <Radio value="auto">自动</Radio>
                </Radio.Group>
            </Item>
            <Item
                noStyle={true}
                shouldUpdate={(prevValues, currentValues) => prevValues.barSpecial._minSet?.type !== currentValues.barSpecial._minSet?.type}
            >
                {({getFieldValue})=>{
                    let minSetType = getFieldValue(["barSpecial","_minSet","type"])

                    return <>
                        <Item
                            label={"设定最小值"}
                            name={["barSpecial","_minSet","setNum"]}
                            hidden={minSetType !== "set"}
                        >
                            <InputNumber/>
                        </Item>
                        <Item
                            label={"设定最小比例"}
                            hidden={minSetType !== "auto"}
                        >
                            {CommonFormItems.getSliderAndInputItemGroup(["barSpecial","_minSet","autoNum"],{
                                min: 0,
                                max: 100,
                                step: 1,
                                addonAfter: "%"
                            })}
                        </Item>
                        <Item
                            label={"设定步长"}
                            hidden={minSetType !== "auto"}
                            name={["barSpecial","_minSet","autoStep"]}
                        >
                            <InputNumber
                                min={0.01}
                                step={1}
                                precision={2}
                            />
                        </Item>
                    </>
                }}
            </Item>
            <Item
                label={"时间轴模式"}
                name={["barSpecial", "_timeAxis"]}
                valuePropName={"checked"}
                extra={"时间轴模式对维度数据有特殊的格式要求，详见数据结构示例。"}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="usual"
            header="布局"
        >
            {EchartComItem.getUsualItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="top">上</Select.Option>
                    <Select.Option value="bottom">下</Select.Option>
                    <Select.Option value="left">左</Select.Option>
                    <Select.Option value="right">右</Select.Option>
                    <Select.Option value="inside">内嵌</Select.Option>
                    <Select.Option value="insideTop">顶部内嵌</Select.Option>
                    <Select.Option value="insideBottom">底部内嵌</Select.Option>
                    <Select.Option value="insideLeft">左侧内嵌</Select.Option>
                    <Select.Option value="insideRight">右侧内嵌</Select.Option>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems({
                extendItems: <>
                    <Item
                        label={"显示百分比"}
                        valuePropName={"checked"}
                        name={["tooltip","_showPercentage"]}
                    >
                        <Switch
                            size={"small"}
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"legend"}
            header={"图例"}
        >
            {EchartComItem.getLegendItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="xAxis"
            header="X 轴配置"
        >
            {EchartComItem.getAxisItems("xAxis")}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="yAxis"
            header="Y 轴配置"
        >
            {EchartComItem.getAxisItems("yAxis")}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="stackSet"
            header="数据堆叠"
        >
            {EchartComItem.getStackSetItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataZoom"
            header="数据缩放"
        >
            <Item
                label={"X轴缩放"}
                name={["dataZoom","useX"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Item
                label={"Y轴缩放"}
                name={["dataZoom","useY"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Divider
                orientation="right"
            >
                <Item
                    style={{width: "150px"}}
                    labelCol={{span: 14}}
                    label={"更多配置"}
                    name={["dataZoom", "seeMore"]}
                    valuePropName={"checked"}
                >
                    <Switch
                        size={"small"}
                        checkedChildren={"已展开"}
                        unCheckedChildren={"已收起"}
                    />
                </Item>
            </Divider>
            <Item
                noStyle={true}
                dependencies={["dataZoom", "seeMore"]}
            >
                {({getFieldValue}) => {
                    let seeMore = getFieldValue(["dataZoom", "seeMore"]);
                    let showXSet = getFieldValue(["dataZoom", "useX"]);
                    let xMode = getFieldValue(["dataZoom", "_xMoreSet","mode"]);

                    let showYSet = getFieldValue(["dataZoom", "useY"]);
                    let yMode = getFieldValue(["dataZoom", "_yMoreSet","mode"]);


                    return <Item
                        noStyle={true}
                        hidden={!seeMore}
                    >
                        <Item
                            label={"无延迟缩放"}
                            name={["dataZoom","_instantly"]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"启用"}
                                unCheckedChildren={"禁用"}
                            />
                        </Item>
                        <Item
                            label={"X轴缩放初始化"}
                            hidden={!showXSet}
                            name={["dataZoom", "_xMoreSet","mode"]}
                        >
                            <Select>
                                <Select.Option value={"none"}>不配置</Select.Option>
                                <Select.Option value={"percent"}>通过百分比配置</Select.Option>
                                <Select.Option value={"value"}>通过数据项序号配置</Select.Option>
                            </Select>
                        </Item>
                        <Item
                            hidden={!showXSet || xMode !== "percent"}
                            noStyle={true}
                        >
                            <Item
                                label={"X轴起点百分比"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataZoom", "_xMoreSet", "start"],{
                                    min: 0,
                                    max: 100,
                                    step: 0.1,
                                    precision: 1,
                                    addonAfter: "%"
                                })}
                            </Item>
                            <Item
                                label={"X轴终点百分比"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataZoom", "_xMoreSet", "end"],{
                                    min: 0,
                                    max: 100,
                                    step: 0.1,
                                    precision: 1,
                                    addonAfter: "%"
                                })}
                            </Item>
                        </Item>
                        <Item
                            hidden={!showXSet || xMode !== "value"}
                            noStyle={true}
                        >
                            <Item
                                label={"X轴起点序号"}
                                name={["dataZoom", "_xMoreSet", "startValue"]}
                            >
                                <InputNumber
                                    min={1}
                                    step={1}
                                />
                            </Item>
                            <Item
                                label={"X轴终点序号"}
                                name={["dataZoom", "_xMoreSet", "endValue"]}
                            >
                                <InputNumber
                                    min={1}
                                    step={1}
                                />
                            </Item>
                            <Item
                                label={"从后往前计算"}
                                name={["dataZoom", "_xMoreSet", "reverse"]}
                                valuePropName={"checked"}
                            >
                                <Switch
                                    size={"small"}
                                    checkedChildren={"是"}
                                    unCheckedChildren={"否"}
                                />
                            </Item>
                        </Item>
                        <Item
                            label={"y轴缩放初始化"}
                            hidden={!showYSet}
                            name={["dataZoom", "_yMoreSet","mode"]}
                        >
                            <Select>
                                <Select.Option value={"none"}>不配置</Select.Option>
                                <Select.Option value={"percent"}>通过百分比配置</Select.Option>
                                <Select.Option value={"value"}>通过数据项序号配置</Select.Option>
                            </Select>
                        </Item>
                        <Item
                            hidden={!showYSet || yMode !== "percent"}
                            noStyle={true}
                        >
                            <Item
                                label={"Y轴起点百分比"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataZoom", "_yMoreSet", "start"],{
                                    min: 0,
                                    max: 100,
                                    step: 0.1,
                                    precision: 1,
                                    addonAfter: "%"
                                })}
                            </Item>
                            <Item
                                label={"Y轴终点百分比"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataZoom", "_yMoreSet", "end"],{
                                    min: 0,
                                    max: 100,
                                    step: 0.1,
                                    precision: 1,
                                    addonAfter: "%"
                                })}
                            </Item>
                        </Item>
                        <Item
                            hidden={!showYSet || yMode !== "value"}
                            noStyle={true}
                        >
                            <Item
                                label={"Y轴起点序号"}
                                name={["dataZoom", "_yMoreSet", "startValue"]}
                            >
                                <InputNumber
                                    min={1}
                                    step={1}
                                />
                            </Item>
                            <Item
                                label={"Y轴终点序号"}
                                name={["dataZoom", "_yMoreSet", "endValue"]}
                            >
                                <InputNumber
                                    min={1}
                                    step={1}
                                />
                            </Item>
                            <Item
                                label={"从后往前计算"}
                                name={["dataZoom", "_yMoreSet", "reverse"]}
                                valuePropName={"checked"}
                            >
                                <Switch
                                    size={"small"}
                                    checkedChildren={"是"}
                                    unCheckedChildren={"否"}
                                />
                            </Item>
                        </Item>
                    </Item>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
          forceRender={true}
          key="chatItemClick"
          header="点击图形取值"
        >
            {EchartComItem.getChatItemClickItems(extendData)}
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: null,
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        //布局
        usual: {
            _categoryWhere: "x",
            grid: {
                top: "10px",
                bottom: "10px",
                left: "10px",
                right: "10px",
                containLabel: true,
            }
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "axis",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            },
            _showPercentage: false
        },
        //图例
        legend: {
            seeMore: false,
            show: false,
            type: "plain",  //plain  scroll
            icon: "roundRect",
            _position: "top",
            width: "auto",
            height: "auto",
            orient: "horizontal",
            align: "auto",
            itemGap: 10,
        },
        xAxis: {
            seeMore: false,
            show: true,
            showSplitLine: false,
            position: "bottom",
            name: null,
            nameLocation: "end",
            nameGap: 15,
            axisLabel: {
                rotate: 0
            }
        },
        yAxis: {
            seeMore: false,
            show: true,
            showSplitLine: false,
            position: "left",
            name: null,
            nameLocation: "end",
            nameGap: 15,
            axisLabel: {
                rotate: 0
            }
        },

        //柱状图特殊
        barSpecial:{
            showBackground: false,
            backgroundStyle: {
                color: "#b4b4b403"
            },
            _barGap: 30,
            _minSet: {
                type: "none",
                autoNum: 10,
                setNum: 0,
                autoStep: 1,
            },
            _timeAxis: false
        },
        //数据堆叠
        stackSet: {
            seeMore: false,
            show: false,
            stack: "total",
            stackStrategy: "samesign",
        },
        //标签
        label: {
            _setType: "normal",
            show: false,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "top",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //数据缩放
        dataZoom: {
            useX: false,
            useY: false,
            _instantly: true,
            seeMore: false,
            _xMoreSet: {
                mode: "none",
                start: 0,
                end: 100,
                startValue: 1,
                endValue: 10,
                reverse: false
            },
            _yMoreSet: {
                mode: "none",
                start: 0,
                end: 100,
                startValue: 1,
                endValue: 10,
                reverse: false
            }
        },
        //点击事件配置
        clickActionSet: {
            use: false,
            toWhere: [],
            valueType: "name"
        }
    }
}

function getDemoCode(){
    return `
    1.单系列柱状图，即只存在2个维度，如：季度，销售总量
        1.1 返回数组中每个数据项中存在维度名，如：
            [
                {
                    季度:"第一季度",
                    销售总量:2600,
                },
                {
                    季度:"第二季度",
                    销售总量:3400,
                },
                 {
                    季度:"第三季度",
                    销售总量:5600,
                },
            ]
       1.2 返回一个对象，里面根据维度名称组装数组数据，各数组数据按顺序一一对应
            {
                季度:["第一季度","第二季度","第三季度"],
                销售总量:[2600,3400,5600]
            }
    
   2.多系列柱状图，存在更多维度，如：季度，销售总量，预约总量，营业额
        2.1 返回数组中每个数据项中存在维度名，如：
               [
                {
                    季度:"第一季度",
                    销售总量:2600,
                    预约总量:2900,
                    营业额:35000,
                },
                ...
            ]
        2.2 返回一个对象，里面根据维度名称组装数组数据，各数组数据按顺序一一对应
            {
                季度:["第一季度","第二季度","第三季度"],
                销售总量:[2600,3400,5600],
                预约总量:[2900,2500,6000],
                营业额:[35000,45000,80000]
            }
            
    3.时间轴模式
    3.1 将代表维度轴的数据值，传时间格式，如：
           [
            {
                日期: 1674898526928,
                销售总量:160,
                预约总量:190,
                营业额:1500,
            },
            ...
        ]
    3.2 时间轴模式也支持返回一个对象，里面根据维度名称组装数组数据，各数组数据按顺序一一对应
        {
            日期:[1674898526928,1674984961643,1675071371834],
            销售总量:[160,140,360],
            预约总量:[190,150,200],
            营业额:[1500,2500,3000]
        }
      3.3 时间格式 不单指 时间戳，也可以传 符合规定的时间字符串，所有格式如下：
           3.3.1  一个时间戳，如 1674898526928，表示 UTC 时间
           3.3.2  部分年月日时间: '2012-03', '2012-03-01', '2012-03-01 05', '2012-03-01 05:06'
           3.3.3  使用 'T' 或 空格 分割: '2012-03-01T12:22:33.123', '2012-03-01 12:22:33.123'
           3.3.4  时区设定: '2012-03-01T12:22:33Z', '2012-03-01T12:22:33+8000', '2012-03-01T12:22:33-05:00'
           3.3.5  其他的时间字符串  '2012', '2012-3-1', '2012/3/1', '2012/03/01', '2009/6/12 2:00', '2009/6/12 2:05:08', '2009/6/12 2:05:08.123'
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}