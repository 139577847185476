import React from "react";
import {Space,Tabs,Popover,Button} from "antd";
import styles from "./ColorPicker.less";
import {SketchPicker} from "react-color";
import LocalStorage from '../../../../tool/LocalStorage';

const {TabPane} = Tabs;
const TempSaveColorSelKey = "colorSelSTKey";
const RecomdColorArr = [
    {
        title: "状态色-成功",
        arr: ["#00B42A","#23C343","#009A29","#7BE188","#AFF0B5","#E8FFEA"]
    },
    {
        title: "状态色-提示",
        arr: ["#FF7D00","#FF9A2E","#D25F00","#FFCF8B","#FFF7E8","#FFF7E8"]
    },
    {
        title: "状态色-错误",
        arr: ["#F53F3F","#F76560","#CB2634","#FBACA3","#FDCDC5","#FFECE8"]
    },
    {
        title: "状态色-信息",
        arr: ["#165dff","#4080ff","#0e42d2","#6aa1ff","#94bfff","#bedaff","#e8f3ff"]
    },
    {
        title: "文本推荐色",
        arr: ["#4e5969","#86909c","#c9cdd4","#ffffff"]
    },
    {
        title: "图表推荐色",
        arr: ["#1890ff","#1ee7e7","#2f54eb","#bae7ff","#00d68a","#38bbcf","#5ac3ff","#564af1"]
    },

    {
        title: "线条推荐色",
        arr: ["#86909C","#C9CDD4","#E5E6EB","#F2F3F5"]
    },
    {
        title: "填充推荐色",
        arr: ["#4E5969","#C9CDD4","#E5E6EB","#F2F3F5","#F7F8FA","#ffffff"]
    },
    {
        title: "渐变色-1",
        arr: ["#317EEA","#30D1EE"]
    },
    {
        title: "渐变色-2",
        arr: ["#30DCE8","#42F4B7"]
    },
    {
        title: "渐变色-3",
        arr: ["#FC915A","#FBDD76"]
    },
]

export default class ColorPicker extends React.Component{
    constructor(props) {
        super(props);

        let {value} = this.props;

        let {color,opacity} = this.getColorAndOpacity(value);

        this.state = {
            color: color,   //字符串
            opacity: opacity,   // 0 - 100

            //第三方组件需要的格式
            sketchPickerRGB: this.getRGBAFromColorStr(color,opacity),

            visible: false,
            tabKey: "select",
        }

        this.openId = Math.random();

        this.enterTime = Date.now();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.value !== this.props.value){

            let {color,opacity} = this.getColorAndOpacity(nextProps.value);

            this.setState({
                color: color,   //字符串
                opacity: opacity,   // 0 - 100

                //第三方组件需要的格式
                sketchPickerRGB: this.getRGBAFromColorStr(color,opacity),
            })
        }
    }

    componentDidMount(){
        window.inputHelp.getEventEmitter().on("openColorSel", (openId) => {
           if(openId !== this.openId){
               this.setState({
                   visible: false
               })
           }
        })
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }


    getColorAndOpacity(value){
        let color;
        let opacity;

        if(!value){
            color = undefined;
            opacity = 100;
        }else if(!/^#[\da-zA-Z]{6,8}$/.test(value)){
            color = "#000000";
            opacity = 100;
        }else{
            color = value.slice(0,7);
            let opacityStr = value.slice(7,9);
            if(!opacityStr){
                //一般颜色后面不填写透明度的，按照 100% 来处理
                opacity = 100;
            }else{
                opacity = window.getOpacityNum(opacityStr);
            }
        }

        return {color,opacity}
    }

    //延迟触发，避免太频繁
    delayChange(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            let {color,opacity} = this.state;
            let resultColor;

            if(!color){
                resultColor = null;
            }else if(color.length<7){
                resultColor = color;
            }else{
                //只有前面的颜色正确，才加透明度
                if(this.props.hideOpacity){
                    resultColor = color
                }else{
                    resultColor = color + window.getOpacityStr(opacity)
                }
            }
            this.props.onChange?.(resultColor);
        },300)
    }

    getRGBAFromColorStr(colorStr,opacity){
        if(!colorStr){
            return undefined
        }

        return {
            r: eval("0x"+colorStr.slice(1,3)),
            g: eval("0x"+colorStr.slice(3,5)),
            b: eval("0x"+colorStr.slice(5,7)),
            a: this.props.hideOpacity?1:(opacity / 100)
        }
    }

    render(){
        let {visible,sketchPickerRGB} = this.state;
        return <Popover
            zIndex={9999}
            visible={visible}
            color={"#fff"}
            arrowPointAtCenter={true}
            content={this.getTypeShow()}
        >
            <div
                className={styles.spaceBox}
            >
                <div
                    className={styles.colorShowBox}
                     style={sketchPickerRGB?{
                         backgroundColor: `rgba(${sketchPickerRGB.r},${sketchPickerRGB.g},${sketchPickerRGB.b},${sketchPickerRGB.a})`
                     }:{
                         borderStyle: "dashed"
                     }}
                     onClick={()=>{
                         if(visible){
                             this.setState({
                                 visible: false,
                             })
                         }else{
                             window.inputHelp.getEventEmitter().emit("openColorSel",this.openId);
                             this.setState({
                                 visible: true,
                                 tabKey: "select"
                             })
                         }

                     }}
                >
                    {
                        sketchPickerRGB
                            ?null
                            :"无"
                    }
                </div>
                {
                    this.props.hideRecommendBtn
                        ?null
                        :<span
                            className={styles.colorSetBtn}
                            onClick={()=>{
                                if(visible){
                                    this.setState({
                                        visible: false,
                                    })
                                }else{
                                    window.inputHelp.getEventEmitter().emit("openColorSel",this.openId);
                                    this.setState({
                                        visible: true,
                                        tabKey: "recommend"
                                    })
                                }
                            }}
                        ></span>
                }
            </div>
        </Popover>
    }

    getTypeShow(){
        let {tabKey,sketchPickerRGB} = this.state;

        let colorHistory = LocalStorage.get(TempSaveColorSelKey) || [];

        return <div
            className={styles.tabContainer}
            onMouseLeave={()=>{
                if(Date.now() - this.enterTime < 150){
                    return
                }
                this.setState({
                    visible: false,
                },()=>{
                    this.saveColor();
                })
            }}
            onMouseEnter={()=>{
                this.enterTime = Date.now();
            }}
        >
            <Tabs
                activeKey={tabKey}
                tabBarGutter={30}
                centered={true}
                tabBarExtraContent={<Space
                    size={0}
                >
                    {
                        this.props.hideClearBtn
                            ?null
                            :<Button
                                type={"link"}
                                onClick={()=>{
                                    this.setState({
                                        visible: false,
                                        sketchPickerRGB: undefined,
                                        color: undefined
                                    },()=>{
                                        this.delayChange()
                                    })
                                }}
                            >
                                <span style={{color: "darkorange"}}>清除</span>
                            </Button>
                    }
                    {/*<Button*/}
                    {/*    type={"link"}*/}
                    {/*    onClick={()=>{*/}
                    {/*        this.setState({*/}
                    {/*            visible: false,*/}
                    {/*        },()=>{*/}
                    {/*            this.saveColor();*/}
                    {/*        })*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <span style={{color: "red"}}>关闭</span>*/}
                    {/*</Button>*/}
                </Space>}
                onTabClick={(key) => {
                    this.setState({
                        tabKey: key,
                    })
                }}
            >
                <TabPane
                    tab={<span
                        style={tabKey === "select"?{}:{
                            color: "#333"
                        }}
                    >
                        选择
                    </span>}
                    key="select"
                >
                    <SketchPicker
                        className={styles.sketchBox}
                        disableAlpha={!!this.props.hideOpacity}
                        color={sketchPickerRGB}
                        width={250}
                        presetColors={colorHistory}
                        // onChangeComplete={(color)=>{
                        //     console.log("确认",color);
                        //     this.setState({
                        //         sketchPickerRGB: color.rgb
                        //     })
                        // }}
                        onChange={(color,event)=>{
                            // console.log("修改",color);
                            this.setState({
                                sketchPickerRGB: color.rgb,
                                color: color.hex,
                                opacity: Math.round(color.rgb.a * 100),
                            },()=>{
                                this.delayChange()
                            })
                        }}
                    />
                </TabPane>
                <TabPane
                    tab={<span
                        style={tabKey === "recommend"?{}:{
                            color: "#333"
                        }}
                    >
                        推荐
                    </span>}
                    key="recommend"
                >
                    <div
                        className={styles.reContainer}
                    >
                        {
                            RecomdColorArr.map((eachItem,index)=>{
                                return <div
                                    key={"r"+ index}
                                >
                                    <div
                                        className={styles.reTitle}
                                    >{eachItem.title}</div>
                                    <div className={styles.reContent}>
                                        {
                                            eachItem.arr.map((colorStr,index)=>{
                                                return <div
                                                    key={"c_" + index}
                                                    className={styles.reColorItem}
                                                    style={{
                                                        backgroundColor: `${colorStr}`
                                                    }}
                                                    onClick={()=>{
                                                        this.setState({
                                                            color: colorStr,
                                                            opacity: 100,
                                                            sketchPickerRGB: this.getRGBAFromColorStr(colorStr,100)
                                                        },()=>{
                                                            this.delayChange()
                                                        })
                                                    }}
                                                >
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </TabPane>
            </Tabs>
        </div>
    }

    saveColor(){
        let colorHistory = LocalStorage.get(TempSaveColorSelKey) || [];
        let {color} = this.state

        //记录最新保存的
        if(color){
            _.remove(colorHistory,(ti,n)=>{
                return ti === color
            })

            colorHistory.unshift(color);
        }

        colorHistory = colorHistory.slice(0,30);

        LocalStorage.set(TempSaveColorSelKey,colorHistory)
    }
}