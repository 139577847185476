import React, {Component} from 'react';
import styles from "./ShowVideo.less";

export default class ShowVideo extends React.Component {
    constructor(props) {
        super(props);

    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let  stylesObj = {
            opacity: contentParams.opacity / 100,
            filter: `brightness(${contentParams.brightness / 100}) blur(${contentParams.blur || 0}px)`,
            borderRadius: window.changeRemNum(contentParams.radiusSet),
        }

        return <div
            className={styles.vdoBox}
            style={stylesObj}
        >
            <video
                src={contentParams.upload_videoUrl}
                crossOrigin={"anonymous"}
                autoPlay={true}
                muted={true}
                loop={true}
                className={styles.videoBgStyle}
                style={{
                    objectFit: `${contentParams.objectFit}`,
                    transformOrigin: `${contentParams.scaleOption}`,
                    transform: `scale(${contentParams.scale}) translate(${contentParams.absoluteSet.translateX}${contentParams.absoluteSet.translateXUnit}, ${contentParams.absoluteSet.translateY}${contentParams.absoluteSet.translateYUnit})`,
                }}
            ></video>
        </div>
    }
}