//这个function是在服务器不支持权限配置的时候，为了前端结构不做大的修改，用于作为默认权限。
let functions = [
    {
        title: '查看',
        name: 'Query',
        permit: true,
        key: 'TestQuery',
        url: null,
    },
    {
        title: '新增',
        name: 'New',
        permit: true,
        key: 'TestNew',
        url: null,
    },
    {
        title: '编辑',
        key: 'TestEdit',
        permit: true,
        name: 'Edit',
        url: null,
    },
    {
        title: '删除',
        name: 'Delete',
        permit: true,
        key: 'TestDelete',
        url: null,
    }
];

//服务端有权限配置，填{}，代码执行时会被替换。否则把上面的functions填入作为默认权限
//同时leftMenu/Index.js里  componentDidMount中menuData数据不再需要checkPower

//左侧导航栏配置 type:1:父栏(用于展开) 2:子栏(可跳转，需配置url)
module.exports = [
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceStatics',
        index: 1,
        name: '设备统计',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceStaticsChart',
        index: 1,
        name: '设备统计图表',
        parentId: 'DeviceStatics',
        type: 2,
        url: 'DeviceStaticsChart',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceStaticsList',
        index: 1,
        name: '设备统计列表',
        parentId: 'DeviceStatics',
        type: 2,
        url: 'DeviceStaticsList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceManage',
        index: 3,
        name: '设备管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceList',
        index: 1,
        name: '设备列表',
        parentId: 'DeviceManage',
        type: 2,
        url: 'DeviceList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'DeviceShareRules',
        index: 2,
        name: '设备分配规则',
        parentId: 'DeviceManage',
        type: 2,
        url: 'DeviceShareRules',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'SeparationList',
        index: 3,
        name: '分身列表',
        parentId: 'DeviceManage',
        type: 2,
        url: 'SeparationList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'ShardDelDeviceConfig',
        index: 4,
        name: '删除分身设备白名单',
        parentId: 'DeviceManage',
        type: 2,
        url: 'ShardDelDeviceConfig',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'PackageManage',
        index: 5,
        name: '安装包管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'PackageAndroid',
        index: 1,
        name: 'Android应用程序',
        parentId: 'PackageManage',
        type: 2,
        url: 'PackageAndroid',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'IPFSWhiteList',
        index: 2,
        name: 'ipfs设备白名单',
        parentId: 'PackageManage',
        type: 2,
        url: 'IPFSWhiteList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'UpdateTest',
        index: 4,
        name: '应用升级测试',
        parentId: 'PackageManage',
        type: 2,
        url: 'UpdateTest',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'CompanyManage',
        index: 10,
        name: '公司管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'CompanyList',
        index: 1,
        name: '公司列表',
        parentId: 'CompanyManage',
        type: 2,
        url: 'CompanyList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'UserManage',
        index: 20,
        name: '用户管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'UserList',
        index: 1,
        name: '用户列表',
        parentId: 'UserManage',
        type: 2,
        url: 'UserList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'LogManage',
        index:150,
        name:'日志管理',
        parentId: null,
        type:1,
        url:null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'SystemLogList',
        index:2,
        name:'系统错误日志',
        parentId:'LogManage',
        type:2,
        url:'SystemLogList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'DashboardThemeManage',
        index:170,
        name:'分析配置',
        parentId: null,
        type:1,
        url:null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'DashboardThemeList',
        index:2,
        name:'仪表板主题管理',
        parentId:'DashboardThemeManage',
        type:2,
        url:'DashboardThemeList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'DashboardModelList',
        index:5,
        name:'仪表板模板管理',
        parentId:'DashboardThemeManage',
        type:2,
        url:'DashboardModelList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'CustomComponentsList',
        index:10,
        name:'自定义组件管理',
        parentId:'DashboardThemeManage',
        type:2,
        url:'CustomComponentsList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'BuiltInQueryManage',
        index:175,
        name:'内置查询接口配置',
        parentId: null,
        type:1,
        url:null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id:'BuiltInQueryList',
        index:2,
        name:'接口列表',
        parentId:'BuiltInQueryManage',
        type:2,
        url:'BuiltInQueryList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'SystemManage',
        index: 250,
        name: '系统配置',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'SystemSet',
        index: 1,
        name: '全局配置',
        parentId: 'SystemManage',
        type: 2,
        url: 'SystemSet',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'AccountManage2',
        index: 350,
        name: '后台账号',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'AccountList',
        index: 1,
        name: '账号列表',
        parentId: 'AccountManage2',
        type: 2,
        url: 'AccountList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'ESIMManage',
        index: 400,
        name: 'ESIM管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'ESIMList',
        index: 1,
        name: 'ESIM列表',
        parentId: 'ESIMManage',
        type: 2,
        url: 'ESIMList',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'RomManage',
        index: 430,
        name: '终端管理',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'RomRuleManage',
        index: 1,
        name: '终端管理',
        parentId: 'RomManage',
        type: 2,
        url: 'RomRuleManage',
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'RecycleManage',
        index: 450,
        name: '回收站',
        parentId: null,
        type: 1,
        url: null,
    },
    {
        functions: {},
        icon: require('../../images/tabImg/icon_nav.png'),
        icon_active: require('../../images/tabImg/icon_nav_active.png'),
        id: 'ESIMRecycleList',
        index: 1,
        name: 'ESIM回收列表',
        parentId: 'RecycleManage',
        type: 2,
        url: 'ESIMRecycleList',
    },
];
