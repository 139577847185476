import React from "react";
import {
    Form,
    Divider,
    Radio,
    InputNumber,
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import UploadFile from "../../../../../../components/upLoad/UploadFile"

const Item = Form.Item;

function getSetItems(){
    return <>
        <Divider orientation="left">
            视频参数设置
        </Divider>
        <Item
            label={"视频圆角"}
        >
            {CommonFormItems.distanceSet(["radiusSet", "num"],["radiusSet", "unit"],["px","rem","%"])}
        </Item>
        <Item
            label={"不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("opacity", {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"亮度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("brightness", {
                min: 0,
                max: 200
            })}
        </Item>
        <Item
            label={"模糊效果"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("blur", {
                min: 0,
                max: 20
            })}
        </Item>
        <Form.Item
            noStyle={true}
            shouldUpdate
        >
            {({getFieldValue}) => {
                let defaultVioUrl = getFieldValue(["upload_videoUrl"]);
                return <Form.Item
                    name={["upload_videoUrl"]}
                    label={"上传视频"}
                    rules={[{required: true}]}
                    normalize={(value, prevValue, prevValues) => {
                        value = value?.[0]?.url || null
                        return value
                    }}
                >
                    <UploadFile
                        toCN={true}
                        type={2}
                        accept={"video/*"}
                        size={1}
                        bigLabel={true}
                        data={{
                            option: {
                                size: 1,
                                defaultValue: defaultVioUrl ? [{
                                    url: defaultVioUrl
                                }] : null
                            }
                        }}
                    />
                </Form.Item>
            }}
        </Form.Item>
        <Form.Item
            name={["objectFit"]}
            label={"填充方式"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="cover">剪裁铺满</Radio>
                <Radio value="contain">包含</Radio>
                <Radio value="fill">拉伸填充</Radio>
            </Radio.Group>
        </Form.Item>
        <Form.Item
            name={["scale"]}
            label={"缩放比例"}
            rules={[{required: true}]}
        >
            <InputNumber
                min={0.01}
                max={100}
                step={1}
                precision={2}
                addonAfter={<span>倍</span>}
            />
        </Form.Item>
        <Form.Item
            name={["scaleOption"]}
            label={"缩放中心"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="center">视频中心</Radio>
                <Radio value="top left">视频左上角</Radio>
                <Radio value="top right">视频右上角</Radio>
                <Radio value="bottom left">视频左下角</Radio>
                <Radio value="bottom right">视频右下角</Radio>
            </Radio.Group>
        </Form.Item>
        <Item
            label={"视频设置偏移"}
        >
            <Item
                label={"x轴偏移"}
            >
                {CommonFormItems.distanceSet(["absoluteSet", "translateX"], ["absoluteSet", "translateXUnit"])}
            </Item>
            <Item
                label={"y轴偏移"}
            >
                {CommonFormItems.distanceSet(["absoluteSet", "translateY"], ["absoluteSet", "translateYUnit"])}
            </Item>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        upload_videoUrl: null,
        objectFit:  "cover", //cover contain fill
        scale: 1,
        scaleOption: "center",  //"center"  "top left"  "bottom left"  "top right"  "bottom right"
        absoluteSet: {
            translateX: 0,
            translateXUnit: "%",
            translateY: 0,
            translateYUnit: "%",
        },
        radiusSet: {
            num: 0,
            unit: "px" //px  %
        }, //圆角
        opacity: 100,   //不透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
    }
}
export default {
    getSetItems,
    getDefaultValues,
}