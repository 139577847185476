import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "./WordCloud2d.less";
import {message} from "antd";
import _ from "lodash";

export default class WordCloud2d extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }

        this.remAndPxRate = document.body.clientWidth/10/150;
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    getPxAndRemChange(pxNum){
        return Math.round(pxNum * this.remAndPxRate)
    }

    // //加载图形
    // loadShapeImage(url){
    //     if(!url){
    //         return;
    //     }
    //
    //     axios({
    //         method: 'get',
    //         url: url + "?x-oss-process=image/resize,p_50",
    //         headers: {
    //             "Content-Type": "text/plain",
    //         },
    //         responseType: "arraybuffer"
    //     }).then(({status, data}) => {
    //         if (status === 200) {
    //             this.shapeImageBase64 = "data:image/png;base64," + this.arrayBufferToBase64(data);
    //
    //             if(this.waitRenderData){
    //                 this.showValue(this.waitRenderData);
    //             }
    //         }
    //     }).catch((err) => {
    //         // console.error(err);
    //         let str = err.toString();
    //
    //         if(str.indexOf("failed with status code 404") !== -1){
    //             str = "无缩略图";
    //         }else if(str.indexOf("Network Error") !== -1){
    //             str = "网络错误";
    //         }
    //         message.error(str);
    //     });
    // }

    // arrayBufferToBase64(buffer) {
    //     //buffer数组长度超过10万的时候，可能会报错 栈溢出（必要的时候，可以切割数组分开来调用fromCharCode再组装结果）
    //     const str = String.fromCharCode(...new Uint8Array(buffer));
    //     return window.btoa(str);
    // }

    //获取默认的
    getChartOption() {
        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {}

        if(this.lastOption){
            return this.lastOption;
        }

        let option =  {
            tooltip:
                {},
            backgroundColor: "transparent",
            series: {
                type: 'wordCloud',
                gridSize: contentParams.gridSize?this.getPxAndRemChange(contentParams.gridSize) : 2,
                sizeRange: contentParams.sizeRange?[
                    this.getPxAndRemChange(contentParams.sizeRange[0]),
                    this.getPxAndRemChange(contentParams.sizeRange[1])
                ] : [12, 30],
                rotationRange: contentParams.rotationRange
                    ? [
                        this.getPxAndRemChange(contentParams.rotationRange[0]),
                        this.getPxAndRemChange(contentParams.rotationRange[1])
                    ]
                    :[-90, 90],
                shape: contentParams.shape || 'circle',
                width: "100%",
                height: "100%",
                top: "center",
                left: "center",
                right: null,
                bottom: null,
                drawOutOfBound: contentParams.shape || false,
                textStyle:
                    {
                        color: function()
                        {
                            return 'rgb(' + [
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255),
                                Math.round(Math.random() * 255)
                            ].join(',') + ')';
                        }
                    },
                data: [],
            }
        }

        this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {}

        let option = this.getChartOption();


        //给option 填充数据
        let items = [];
        if(Array.isArray(data)){
            data.forEach((eachWordObj,index)=>{
                let item;
                if(typeof eachWordObj === "string"){
                    item = {
                        name: eachWordObj,
                        value: Math.ceil(Math.random() * 1000)
                    };

                }else{
                    item = {
                        name: eachWordObj[contentParams.labelField] || "词汇匹配错误",
                        value: contentParams.valueField?eachWordObj[contentParams.valueField]:Math.ceil(Math.random() * 1000),
                    };
                    if(contentParams.colorField && eachWordObj[contentParams.colorField]){
                        item.textStyle = {
                            color: eachWordObj[contentParams.colorField]
                        }
                    }
                }

                if(contentParams.colorArray?.length && !item.textStyle){
                    let color;
                    if(contentParams.colorSelType === "random"){
                        // 颜色随机
                        color = contentParams.colorArray[parseInt(Math.random()*(contentParams.colorArray.length))]
                    }else{
                        color = contentParams.colorArray[index%contentParams.colorArray.length]
                    }
                    item.textStyle = {
                        color: color
                    }
                }
                items.push(item)
            })
        }else{
            this.setState({
                error: "格式不正确:需要数组结构"
            })
            return;
        }

        option.series.data = items;

        //自定义转换数据和配置项
        if(this.props.contentMsg.dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${this.props.contentMsg.dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        if(contentParams.shape === "diyShape" && contentParams.diyShape_imageUrl){
            //自定义图形
            let maskImage = new Image();
            maskImage.src = contentParams.diyShape_imageUrl + "?x-oss-process=image/resize,w_300";
            maskImage.crossOrigin = "Anonymous";
            maskImage.onload=()=>{
                option.series.maskImage = maskImage

                if (this.myChart) {
                    this.myChart.clear();
                    this.myChart.resize();
                    this.myChart.setOption(option);
                }
            }
        }else{
            //直接渲染
            if (this.myChart) {
                this.myChart.clear();
                this.myChart.resize();
                this.myChart.setOption(option);
            }
        }
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}