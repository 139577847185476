import React from "react";
import {Space} from "antd";
import CommonViewDiv from "../../../CommonViewDiv";

export default class UrlParamShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        return <Space>
            {
                contentParams.beforeTextSet?.text
                    ?<div>{CommonViewDiv.getText(contentParams.beforeTextSet, contentParams.beforeTextSet?.text)}</div>
                    :null
            }
            <div>{CommonViewDiv.getText(contentParams.fieldTextSet, parameterValue)}</div>
        </Space>
    }
}