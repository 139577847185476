//keyArray里定义所有会用到的权限类型，treeData里的name值必须从这里选，否则匹配不到
export default {
    keyArray:[
        {
            key: "Query",
            desc: "查询权限，比如查询列表，查询详情",
        },
        {
            key: "New",
            desc: "新增权限，比如列表右上角的新增按钮",
        },
        {
            key: "Edit",
            desc: "编辑权限，比如列表操作栏的编辑，禁用等",
        },
        {
            key: "Delete",
            desc: "删除权限，比如列表操作栏的删除",
        },
        {
            key: "Developer",
            desc: "开发者权限，（属于自定义）",
        },
        {
            key: "Special",
            desc: "特殊权限，（属于自定义）",
        },
    ],
    treeData:[
        {
            title: '通用权限',
            key: 'Others',
            hide: true, //这个权限不需要选择，设置为隐藏(比如查询个人信息，修改密码等)
            children: [
                {
                    title: '查询',
                    name: 'Query',
                    permit: true,
                    key: 'OthersQuery',
                    url: '/admin/admin/getMyinfo,/admin/admin/modfiyPasswd,/admin/admin/logoutAdmin,/admin/space/getSpaceList,/platform/admin/device/getDeviceRomIdList,/admin/company/select,/admin/common/getCountryList,/admin/deviceHeartbeat/getHeartBeatRomIds,/admin/globalConfig/getAllGlobalConfigs,/admin/space/getAllSpaces,/admin/common/getProvinceList,/admin/common/getCityList,/admin/common/getCountry,/platform/admin/terminal/classificationSearch'
                },
                {
                    title: '编辑',
                    name: 'Edit',
                    permit: true,
                    key: 'OthersEdit',
                    url: '/admin/common/getUploadToken,/common/upload'
                },
            ]
        },
        {
            title: '设备统计',
            key: 'DeviceStatics',
            children: [
                {
                    title: '设备统计相关权限',
                    name: 'Query',
                    permit: true,
                    key: 'DeviceStaticsQuery',
                    url: '/platform/admin/device/getDeviceList,/admin/deviceHeartbeat/getDeviceTotal,/admin/deviceHeartbeat/getDeviceDayLoss,/admin/deviceHeartbeat/getLatestThirtyAddDevice,/admin/deviceHeartbeat/getOfflineThirtyDevice,/admin/deviceHeartbeat/getOnOfflineDeviceRate,/admin/deviceHeartbeat/getDeviceLocation,/admin/deviceHeartbeat/getHeartBeatRomIds,/admin/deviceHeartbeat/getDeviceMonthStandard,/admin/deviceHeartbeat/getOnOfflineAllDeviceRate,/admin/deviceHeartbeat/getLatestAllAddDevice,/admin/deviceHeartbeat/getDeviceDayHeartbeat,/admin/deviceHeartbeat/getDeviceDayOnline,/admin/deviceHeartbeat/getDevice,/admin/deviceHeartbeat/getHeartbeatDeviceList,/admin/deviceHeartbeat/exportHeartbeatDeviceList,/admin/deviceHeartbeat/getCountryDevice,/admin/deviceHeartbeat/getDeviceFreeSize'
                },
            ]
        },
        {
            title: '设备管理',
            key: 'DeviceManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'DeviceManageQuery',
                    url: '/platform/admin/device/getDeviceList,/admin/device/addDeviceList,/platform/admin/device/getDeviceIds,/admin/conf/getShardConfList,/admin/conf/getDeviceDynamicAssignConf,/admin/shardDelDevice/getDeviceList,/admin/conf/getBoxShard'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'DeviceManageNew',
                    url: '/admin/shardDelDevice/addDevice'
                },
                {
                    title: '操作',
                    name: 'Edit',
                    permit: true,
                    key: 'DeviceManageEdit',
                    url: '/platform/admin/device/assignDeviceToCompany,/admin/conf/setDeviceDynamicAssignConf,/admin/conf/editShardConf,/admin/conf/addShardConf'
                },
                {
                    title: '删除',
                    name: 'Delete',
                    permit: true,
                    key: 'DeviceManageDelete',
                    url: '/admin/shardDelDevice/delDevice'
                }
            ]
        },
        {
            title: '安装包管理',
            key: 'PackageManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'PackageManageQuery',
                    url: '/admin/app/getAppList,/admin/app/getApps,/admin/appVersion/getAppVersionList,/admin/appIpfs/getDeviceList'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'PackageManageNew',
                    url: '/admin/app/addApp,/admin/appVersion/addAppVersion,/admin/appIpfs/addDevice'
                },
                {
                    title: '编辑',
                    name: 'Edit',
                    permit: true,
                    key: 'PackageManageEdit',
                    url: '/admin/app/editApp,/admin/appVersion/editAppVersion,/admin/appVersion/setAppVersionStatus'
                },
                {
                    title: '删除',
                    name: 'Delete',
                    permit: true,
                    key: 'PackageManageDelete',
                    url: '/admin/app/delApp,/admin/appVersion/delAppVersion,/admin/appIpfs/delDevice'
                },
            ]
        },
        {
            title: '公司管理',
            key: 'CompanyManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'CompanyManageQuery',
                    url: '/admin/company/index,/admin/company/select'
                },
                {
                    title: '创建公司',
                    name: 'New',
                    permit: true,
                    key: 'CompanyManageNew',
                    url: '/admin/company/create'
                },
                {
                    title: '编辑公司',
                    name: 'Edit',
                    permit: true,
                    key: 'CompanyManageEdit',
                    url: '/admin/company/update'
                },
                {
                    title: '删除公司',
                    name: 'Delete',
                    permit: true,
                    key: 'CompanyManageDelete',
                    url: '/admin/company/delete'
                }
            ]
        },
        {
            title: '用户管理',
            key: 'UserManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'UserManageQuery',
                    url: '/admin/user/index'
                },
                {
                    title: '创建用户',
                    name: 'New',
                    permit: true,
                    key: 'UserManageNew',
                    url: '/admin/user/create'
                },
                {
                    title: '编辑用户',
                    name: 'Edit',
                    permit: true,
                    key: 'UserManageEdit',
                    url: '/admin/user/update'
                },
                {
                    title: '删除用户',
                    name: 'Delete',
                    permit: true,
                    key: 'UserManageDelete',
                    url: '/admin/user/delete'
                }
            ]
        },
        {
            title: '日志管理',
            key: 'LogManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'LogManageQuery',
                    url: '/admin/syslog/getSyslogList,'
                },
                {
                    title: '清除',
                    name: 'Delete',
                    permit: true,
                    key: 'LogManageDelete',
                    url: '/admin/syslog/delSyslogs'
                },
            ]
        },
        {
            title: '分析配置管理',
            key: 'DashboardThemeManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'DashboardThemeManageQuery',
                    url: '/platform/admin/chartTheme/getChartThemeList,/platform/admin/chartTheme/getChartTheme,/platform/admin/dashBoardModel/updateDashboardModelImg,/platform/admin/dashBoardModel/getDashboardModelDetail,/platform/admin/dashBoardModel/getDashboardModelHistoryList,/platform/admin/dashBoardModel/getDashboardHistory,/platform/admin/dashBoardModel/getDashboardModelList,/platform/admin/module/getModuleCategoryAndModuleList,/platform/admin/module/executeDbQuery,/platform/admin/module/getCustomModuleDetail,/platform/admin/module/getCustomModuleList,/platform/admin/module/getBackStageCategory,/platform/admin/chartTheme/getChartThemeListAll'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'DashboardThemeManageNew',
                    url: '/platform/admin/chartTheme/addChartTheme,/platform/admin/dashBoardModel/addDashBoardModel,/platform/admin/dashBoardModel/copyDashboard,/platform/admin/module/editOrAddCustomModule,/platform/admin/module/editOrAddModuleCategory'
                },
                {
                    title: '编辑',
                    name: 'Edit',
                    permit: true,
                    key: 'DashboardThemeManageEdit',
                    url: '/platform/admin/chartTheme/editChartTheme,/platform/admin/chartTheme/setChartThemeStatus,/platform/admin/chartTheme/setChartThemeSort,/platform/admin/dashBoardModel/setDashboardModelSort,/platform/admin/dashBoardModel/editDashboardModel,/platform/admin/dashBoardModel/updateDashboardModel,/platform/admin/module/editOrAddCustomModule,/platform/admin/module/editOrAddModuleCategory'
                },
                {
                    title: '清除',
                    name: 'Delete',
                    permit: true,
                    key: 'DashboardThemeManageDelete',
                    url: '/platform/admin/chartTheme/delChartTheme,/platform/admin/dashBoardModel/delDashboardModel,/platform/admin/module/delCustomModule,/platform/admin/module/delModuleCategory'
                },
            ]
        },
        {
            title: '内置查询配置',
            key: 'BuiltInQueryManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'BuiltInQueryManageQuery',
                    url: '/admin/chartBuiltInQuery/getBuiltInQueryList,/admin/chartBuiltInQuery/getBuiltInQuery'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'BuiltInQueryManageNew',
                    url: '/admin/chartBuiltInQuery/addBuiltInQuery'
                },
                {
                    title: '编辑',
                    name: 'Edit',
                    permit: true,
                    key: 'BuiltInQueryManageEdit',
                    url: '/admin/chartBuiltInQuery/editBuiltInQuery,/admin/chartBuiltInQuery/setBuiltInQuerySort'
                },
                {
                    title: '清除',
                    name: 'Delete',
                    permit: true,
                    key: 'BuiltInQueryManageDelete',
                    url: '/admin/chartBuiltInQuery/delBuiltInQuery'
                },
            ]
        },
        {
            title: '系统全局配置',
            key: 'SystemManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'SystemManageQuery',
                    url: '/admin/globalConfig/getAllGlobalConfigs,/admin/plan/getPlanDistribLimit'
                },
                {
                    title: '修改',
                    name: 'Edit',
                    permit: true,
                    key: 'SystemManageEdit',
                    url: '/admin/globalConfig/setGlobalConfigs,/admin/plan/setPlanDistribLimit'
                },
            ]
        },
        {
            title: '后台账号管理',
            key: 'AccountManage2',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'AccountManage2Query',
                    url: '/admin/admin/getAdminList,/admin/admin/getAdminDetail'
                },
                {
                    title: '创建后台管理账号',
                    name: 'New',
                    permit: true,
                    key: 'AccountManage2New',
                    url: '/admin/admin/addAdmin'
                },
                {
                    title: '编辑后台账号',
                    key: 'AccountManage2Edit',
                    permit: true,
                    name: 'Edit',
                    url: '/admin/admin/editAdmin,/admin/admin/enableAdmin,/admin/admin/authRomIds'
                },
                {
                    title: '删除后台账号',
                    name: 'Delete',
                    permit: true,
                    key: 'AccountManage2Delete',
                    url: '/admin/admin/delAdmin'
                }
            ]
        },
        {
            title: 'ESIM管理',
            key: 'ESIMManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'ESIMManageQuery',
                    url: '/platform/admin/getMessageList,/platform/admin/getESIMList,/platform/admin/getPlatformList,/platform/admin/deviceStatistics'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'ESIMManageNew',
                    url: '/platform/admin/insert'
                },
                {
                    title: '编辑',
                    key: 'ESIMManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/platform/admin/update,/platform/admin/sendTestMessage,/platform/admin/preBind'
                },
                {
                    title: '回收',
                    name: 'Delete',
                    permit: true,
                    key: 'ESIMManageDelete',
                    url: '/platform/admin/recycleEsim'
                }
            ]
        },
        {
            title: '终端管理',
            key: 'RomManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'RomManageQuery',
                    url: '/platform/admin/terminal/getClassificationList,/platform/admin/terminal/getRuleList,/platform/admin/terminal/ruleRomList,/platform/admin/terminal/renewal'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'RomManageNew',
                    url: '/platform/admin/terminal/insertClassification,/platform/admin/terminal/insertRule'
                },
                {
                    title: '编辑',
                    key: 'RomManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/platform/admin/terminal/updateClassification,/platform/admin/terminal/updateRule'
                },
                {
                    title: '删除',
                    name: 'Delete',
                    permit: true,
                    key: 'RomManageDelete',
                    url: '/platform/admin/terminal/deleteClassification,/platform/admin/terminal/deleteRule'
                }
            ]
        },
        {
            title: '回收站',
            key: 'RecycleManage',
            children: [
                {
                    title: '查看',
                    name: 'Query',
                    permit: true,
                    key: 'RecycleManageQuery',
                    url: '/platform/admin/getRecycleEsimList'
                },
                {
                    title: '新增',
                    name: 'New',
                    permit: true,
                    key: 'RecycleManageNew',
                    url: ''
                },
                {
                    title: '编辑、还原',
                    key: 'RecycleManageEdit',
                    permit: true,
                    name: 'Edit',
                    url: '/platform/admin/restoreESim'
                },
                {
                    title: '删除',
                    name: 'Delete',
                    permit: true,
                    key: 'RecycleManageDelete',
                    url: ''
                }
            ]
        },
    ]
};
