import React from "react";
import APILXD from "../../../../http/APILXD";
// import * as echarts from "echarts";
import TimeHelp from "../../../../tool/TimeHelp";
import {message} from "antd";
import styles from "../DeviceStaticsChart.less";
import HttpTool from "../../../../tool/HttpTool";

export default class HisAddView extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            boxMsg: null
        }
        this.option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            legend: {
                data: ['离线数量', '在线数量'],
                left: "center"
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "0px"
            },
            xAxis: {
                name: "日期",
                type: 'category',
                axisLabel: {
                    rotate: -45,
                },
                nameTextStyle: {
                    fontSize: 0
                },
                data: []
            },
            yAxis: {
                name: "数量（台）",
                type: 'value',
                minInterval: 1,
            },
            series: [
                {
                    name: '离线数量',
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    color: "darkorange",
                    data: []
                },
                {
                    name: '在线数量',
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    color: "dodgerblue",
                    data: []
                },
            ]
        }
    }

    componentDidMount(){
        setTimeout(()=>{
            this.boxChart = echarts.init(this.boxDom);
            this.getOnOfflineAllDeviceRate()
        },0)
    }

    getOnOfflineAllDeviceRate(){
        this.setState({
            loading: true
        },()=>{
            HttpTool.post(APILXD.getOnOfflineAllDeviceRate,(code,msg,json)=>{
                this.setState({
                    loading: false
                },()=>{
                    this.renderBoxChart(json)
                })
            },(code,msg)=>{
                message.error(msg);
                this.setState({
                    loading: false
                })
            },{
                romIds: this.props.selRomIds.length?this.props.selRomIds:null,
                runStatus: this.props.selType,
            })
        })
    }


    renderBoxChart(data){
        let option = _.cloneDeep(this.option);

        let xData = [];
        let vData = [];
        let vData2 = [];

        let totalOffline = 0;
        let totalOnline = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.offline,
            });
            vData2.push({
                value: item.online,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"MM-dd"));
            totalOffline+=item.offline;
            totalOnline+=item.online;
        })

        option.series[0].data = vData;
        option.series[0].name = this.props.selType === 1?"离线数量":"不可工作数量";
        option.series[1].data = vData2;
        option.series[1].name = this.props.selType === 1?"在线数量":"可工作数量";
        option.xAxis.data = xData;
        option.legend.data = this.props.selType === 1?["离线数量","在线数量"]:["不可工作数量","可工作数量"];

        this.setState({
            boxMsg: {
                offlineAverage: (totalOffline * 100/(totalOffline + totalOnline)).toFixed(2),
                onlineAverage: (totalOnline * 100/(totalOffline + totalOnline)).toFixed(2),
            }
        })
        this.boxChart && this.boxChart.setOption(option)
    }

    render(){
        let {boxMsg} = this.state;
        let {selType} = this.props;
        return <div
            ref={ref=>this.boxDom = ref}
            className={styles.chartContainer}
        >
            {
                boxMsg!==null
                    ?<div className={styles.boxFiveExtra}>
                        <div
                            style={{color: "dodgerblue"}}
                        >
                            {selType === 1?"平均在线率":"平均可工作率"}
                            ：{boxMsg.onlineAverage} %</div>
                        <div
                            style={{color: "darkorange"}}
                        >
                            {selType === 1?"平均离线率":"平均无法工作率"}
                            ：{boxMsg.offlineAverage} %</div>
                    </div>
                    :null
            }
        </div>
    }
}