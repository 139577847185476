import React, {Component} from 'react';
import styles from "./ShowArrow.less";
import {RightOutlined,CaretRightOutlined,RightCircleOutlined,DoubleRightOutlined,VerticalLeftOutlined,StepForwardOutlined,FastForwardOutlined,SwapRightOutlined,ArrowRightOutlined,RightSquareOutlined} from "@ant-design/icons";

export default class ShowArrow extends React.Component {
    constructor(props) {
        super(props);

    }


    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let View;
        switch (contentParams.type){
            case "ArrowRightOutlined": View = ArrowRightOutlined;break;
            case "RightOutlined": View = RightOutlined;break;
            case "CaretRightOutlined": View = CaretRightOutlined;break;
            case "RightCircleOutlined": View = RightCircleOutlined;break;
            case "DoubleRightOutlined": View = DoubleRightOutlined;break;
            case "VerticalLeftOutlined": View = VerticalLeftOutlined;break;
            case "StepForwardOutlined": View = StepForwardOutlined;break;
            case "FastForwardOutlined": View = FastForwardOutlined;break;
            case "SwapRightOutlined": View = SwapRightOutlined;break;
            case "RightSquareOutlined": View = RightSquareOutlined;break;
        }

        let rotateDeg = 0;
        switch (contentParams.direction){
            case "left": rotateDeg = 180;break;
            case "right": rotateDeg = 0;break;
            case "top": rotateDeg = 270;break;
            case "bottom": rotateDeg = 90;break;
        }

        if(!View){
            return <div
            >
                未知形状
            </div>
        }

        let animateStyles;
        switch (contentParams.animation){
            case "none": animateStyles = styles.noAnimate;break;
            case "shine": animateStyles = styles.shineAnimate;break;
            case "shineMore": animateStyles = styles.shineAnimateMore;break;
            case "move": animateStyles = styles.moveAnimate;break;
            case "moveLine": animateStyles = styles.moveAnimateLine;break;
        }

        return <div
            style={{
                transform: `rotate(${rotateDeg}deg)`
            }}
        >
            <div
                className={styles.container}
            >
                <View
                    className={animateStyles}
                    style={{
                        position: "relative",
                        color: contentParams.color,
                        fontSize: window.changeRemNum(contentParams.size),
                    }}
                />
            </div>
        </div>
    }
}