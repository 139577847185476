import React from 'react';
import less from './RomRuleManage.less';
import RomRuleList from "./RomRuleList";
import RuleDetail from "./RuleDetail"
import {Empty} from "antd";

class RomRuleManage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      record: null
    }
  }

  render(){
    let {record} = this.state;

    return <div className={less.outer}>
      <div className={less.halfBox}>
        <RomRuleList
          {...this.props}
          manageWords={(data)=>{
            this.setState({
              record: data
            })
          }}
          onDeleteGroup={(_id)=>{
            if(record._id === _id){
              this.setState({
                record: null
              })
            }
          }}
        />
      </div>
      <div className={less.halfBox}>
        {
          record
            ?<RuleDetail
              key={record._id}
              record={record}
              {...this.props}
            />
            :<Empty
              description={"请从左侧选择需要管理的类型"}
              style={{marginTop: "100px"}}
            />
        }

      </div>
    </div>
  }
}

module.exports = RomRuleManage;