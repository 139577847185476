import React from "react";
import {
    Form,
    Radio,
    Input,
    Select,
    Tag
} from "antd";
import SelectOptionMake from "./SelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"选择框大小"}
            name={["selectOption","size"]}
        >
           <Radio.Group>
               <Radio value={"small"}>小</Radio>
               <Radio value={"middle"}>中</Radio>
               <Radio value={"large"}>大</Radio>
           </Radio.Group>
        </Item>
        <Item
            label={"提示语"}
            name={["selectOption","placeholder"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"标签"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"普通选项"}
            name={["list"]}
        >
            <Select
                mode={"tags"}
                allowClear={true}
                placeholder={"请填写定义下拉选项"}
                tagRender={(props) => {
                    return <Tag
                        color={"cyan"}
                        closable={props.closable}
                        style={{marginRight: 3}}
                        onClose={props.onClose}
                    >
                        {props.label}
                    </Tag>
                }}
                maxTagTextLength={30}
            ></Select>
        </Item>
        <Item
            label={"映射选项"}
            name={["listSpecial"]}
        >
            <SelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: null,
        selectOption: {
            size: "middle",
            placeholder: "请选择内容",
        },
        list: [],
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}