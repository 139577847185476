import React from "react";
import {
    Form,
    Switch,
    Select
} from "antd";
import ColorPicker from "../../../formDiyItem/ColorPicker";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(extendData= {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Item
            label={"表头背景色"}
            name={["headBackgroundColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表头文字"}
            name={["headFontColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表格背景色"}
            name={["bodyBackgroundColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表格文字"}
            name={["bodyFontColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"边框颜色"}
            name={["borderColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"单页时隐藏分页器"}
            name={["hideOnSinglePage"]}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"隐藏底部工具条"}
            name={["hideFooter"]}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            valuePropName={"checked"}
            extra={"此配置修改后需要手动刷新视图矫正排版"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
          label={"每列宽度自适应"}
          name={["autoTableLayout"]}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          valuePropName={"checked"}
        >
            <Switch
              checkedChildren={"是"}
              unCheckedChildren={"否"}
            />
        </Item>
        <Item
          label={"指定图片列"}
          name={["imgColArr"]}
          extra={"图片列地址将展示为缩略图"}
        >
            <Select
              placeholder={"请选择或填写"}
              style={{width: "100%"}}
              allowClear={true}
              mode={"tags"}
              options={opts}
            >

            </Select>
        </Item>
        <Item
          label={"缩略图大小"}
          name={["imgSize"]}
        >
            {CommonFormItems.getSliderAndInputItemGroup("imgSize",{
                min: 5,
                max: 250,
                step: 1,
                addonAfter: "px"
            })}
        </Item>
    </>
}

function getDefaultValues(){
    return {
        headBackgroundColor: "#373C5188",
        headFontColor: "#ffffff",
        bodyBackgroundColor: "#00000000",
        bodyFontColor: "#ffffff",

        borderColor: "#373c51",

        hideOnSinglePage: true,
        hideFooter: false,
        imgColArr: [],
        imgSize: 60,
        autoTableLayout: false,
    }
}

function getDemoCode(){
    return `遵循元数据查询规则所得数据结构。`
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}