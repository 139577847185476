import React from "react";
import {
  PictureOutlined,
} from "@ant-design/icons";

import Demo001Set from "./demo001/Demo001Set";
import Demo001 from "./demo001/Demo001";

//系统组件内容的注册
const SystemComponentContentArray = [
  {
    groupName: "分类A",  //分组名称
    children: [
      {
        title: '示例系统组件001',   //名称
        type: "system_demo001",   //匹配类型
        icon: <PictureOutlined />,  //小图标
        cover: require("./demo001/cover.png"),  //封面图
        renderFile: Demo001, //渲染视图文件
        setFile: Demo001Set,  //配置文件
        usageArray: ["company","space","unit","device"],  //可以在哪些板块使用
      },
    ]
  },
]

export {SystemComponentContentArray};