import React from "react";
import {
    Form,
    Divider,
    Radio,
    Input,
    Switch,
    InputNumber
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(){
    return <>
        <Divider orientation="left">
            前缀设置
        </Divider>
        <Item
            label={"前缀"}
            name={"beforeType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">文字</Radio>
                <Radio value="icon">图标</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.beforeType !== currentValues.beforeType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("beforeType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("beforeTextSet");break;
                    case "icon": items = CommonFormItems.getIconSet("beforeIconSet",getFieldValue);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            后缀设置
        </Divider>
        <Item
            label={"后缀"}
            name={"afterType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">文字</Radio>
                <Radio value="icon">图标</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.afterType !== currentValues.afterType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("afterType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("afterTextSet");break;
                    case "icon": items = CommonFormItems.getIconSet("afterIconSet",getFieldValue);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            中间信息设置
        </Divider>
        <Item
            label={"文案"}
            name={["centerTextSet","text"]}
            extra={"如果绑定了数据集，则无需填写此内容"}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        {CommonFormItems.getFontSet("centerTextSet",true)}
        <Item
            label={"左间隙"}
        >
            {CommonFormItems.distanceSet(["marginLeft", "num"], ["marginLeft", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"右间隙"}
        >
            {CommonFormItems.distanceSet(["marginRight", "num"], ["marginRight", "unit"], ["px", "rem"])}
        </Item>
        <Divider orientation="left">
            其它设置
        </Divider>
        <Item
            label={"对齐方式"}
            name={"alignItems"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="flex-start">顶部对齐</Radio>
                <Radio value="center">居中对齐</Radio>
                <Radio value="baseline">基线对齐</Radio>
                <Radio value="flex-end">底部对齐</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"大数字处理"}
            name={"tranBigNum"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"启用"}
                unCheckedChildren={"禁用"}
            />
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.tranBigNum !== currentValues.tranBigNum}}

        >
            {({getFieldValue})=>{
                if(getFieldValue(["tranBigNum"])){
                    return <Item
                        label={"保留小数位数"}
                        name={"bigNumPoint"}
                        rules={[{required: true}]}
                    >
                        <InputNumber
                            min={0}
                            max={4}
                            step={1}
                        />
                    </Item>
                }
            }}
        </Item>
        <Item
            label={"数字变化效果"}
            name={"numberDiffShow"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"启用"}
                unCheckedChildren={"禁用"}
            />
        </Item>
        <Item
            label={"数字变化动画时间"}
            name={"animateTime"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("animateTime",{
                min: 0.1,
                max: 10,
                step: 0.1,
                precision: 1,
                addonAfter: "秒"
            })}
        </Item>
    </>
}

function getDefaultValues(){
    return {
        afterType: "none",
        beforeType: "none",
        centerTextSet: {
            fontFamily: "default",
            text: null,
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        marginLeft: {
            num: 4,
            unit: "rem"
        },
        marginRight: {
            num: 4,
            unit: "rem"
        },
        alignItems: "center",
        tranBigNum: true,
        bigNumPoint: 2,
        numberDiffShow: false,
        animateTime: 0.5,

        beforeTextSet: undefined,
        beforeIconSet: undefined,
        afterTextSet: undefined,
        afterIconSet: undefined,
    }
}

//推荐数据格式
function getDemoCode(){
    return `
    返回信息可以是任意 文本 或者 数字，不做举例。
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}