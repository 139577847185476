import React, {Component} from 'react';
import {Input,Select,Row,Col,message} from "antd";
import HttpTool from "../../../tool/HttpTool";
import APILXD from "../../../http/APILXD";

class CodeAndRomSel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{
        code: null,
        romIds: null,
      },
      classList: [],
      romList: [],
    }
  }

  _initDefaultValue(data){
    this.setState({
      data:data.option.defaultValue
    });
    return data.option.defaultValue||undefined;
  }

  componentDidMount() {
    HttpTool.post(APILXD.classificationSearch,(code,msg,json)=>{
      this.setState({
        classList: json
      })
    },(code,msg)=>{
      message.error(msg);
    }, {});

    this.getRomList()
  }

  getRomList(){
    HttpTool.post(APILXD.getDeviceRomIdList,(code,msg,json)=>{
      this.setState({
        romList: json
      })
    },(code,msg)=>{
      message.error(msg);
    }, {
      code: this.state.data.code
    });
  }

  render(){
    let otherOption = Object.assign({},this.props.option);
    delete otherOption.defaultValue;
    return (
      <Row>
        <Col span={8}>
          <Select
            allowClear={true}
            placeholder={"选择分类"}
            style={{width: "100%"}}
            value={this.state.data.code}
            onChange={(value)=>{
              // log(e);
              let data = this.state.data;
              data.code = value
              this.setState({
                data:data
              },()=>{
                this.getRomList();
                this.props.verification(this.props.data,this.state.data)
              })
            }}
          >
            {
              this.state.classList?.map((item)=>{
                return <Select.Option
                  value={item.code}
                  key={item.code}
                >
                  {item.name}
                </Select.Option>
              })
            }
          </Select>
        </Col>
        <Col span={16}>
          <Select
            placeholder={"选择系统"}
            allowClear={true}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            style={{width: "100%"}}
            value={this.state.data.romIds}
            onChange={(value)=>{
              // log(e);
              let data = this.state.data;
              data.romIds = value
              this.setState({
                data:data
              },()=>{
                this.props.verification(this.props.data,this.state.data)
              })
            }}
          >
            {
              this.state.romList?.map((item)=>{
                return <Select.Option
                  value={item}
                  key={item}
                >
                  {item}
                </Select.Option>
              })
            }
          </Select>
        </Col>
      </Row>
    );
  }
}


export default CodeAndRomSel;