import React, { Component } from 'react';
import styles from "./NumberAnimation.less"
import CommonViewDiv from "../../../CommonViewDiv";

class NumberAnimation extends Component {

    constructor(props) {
        super(props);

        this.remAndPxRate = document.body.clientWidth/10/150;
    }
    render() {
        const { value,styleSet,animateTime } = this.props;

        let fontSize;
        if(styleSet.sizeUnit==="rem"){
            fontSize = Math.round(styleSet.fontSize * this.remAndPxRate)
        }else{
            fontSize = styleSet.fontSize
        }

        let styleObj = CommonViewDiv.getTextStyleObj(styleSet)
        styleObj.fontSize = fontSize + "px";
        styleObj.lineHeight = fontSize + "px";

        if (isNaN(value)) {//非数字
            return (
                <div
                    className={styles.numberAnimationWrap}
                    style={styleObj}
                >
                    <div className={styles.number}>{value}</div>
                </div>
            )
        }
        let style = {
            top: (-1 * value * fontSize) + 'px',
            transition: `top ${animateTime}s`
        }

        return (
            <div className={styles.numberAnimationWrap}
                 style={styleObj}
            >
                <div className={styles.numberAnimationWrapHidden}>0</div>
                <div className={styles.numberAnimation} style={style}>
                    <div className={styles.number}>0</div>
                    <div className={styles.number}>1</div>
                    <div className={styles.number}>2</div>
                    <div className={styles.number}>3</div>
                    <div className={styles.number}>4</div>
                    <div className={styles.number}>5</div>
                    <div className={styles.number}>6</div>
                    <div className={styles.number}>7</div>
                    <div className={styles.number}>8</div>
                    <div className={styles.number}>9</div>
                </div>
            </div>
        )
    }
}

export default NumberAnimation;