import React from "react";
import {Button, Popover} from "antd";
import less from "./FiltersIndexSet.less";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    FolderOpenOutlined,
} from "@ant-design/icons";
import classNames from "classnames";


class FiltersIndexSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            indexArr: this.props.indexArr || [],
            dragTreeNode: null,
            dropTreeNode: null,
        }
    }

    getIndexArr() {
        return this.state.indexArr;
    }

    render() {
        return <div>
            {
                this.getFiltersShow()
            }
        </div>
    }

    getFiltersShow() {
        let indexArr = this.state.indexArr;

        let defineDragOpt = (treeNode) => {
            let opt = {};

            opt.draggable = true;
            opt.onDragStart = (e) => {
                log("开始拖拽");
                this.setState({
                    dragTreeNode: treeNode
                });
            };
            opt.onDragEnd = (e) => {
                log("结束");
                this.setState({
                    dragTreeNode: null,
                    dropTreeNode: null,
                });
            };

            opt.onDragOver = (e) => {
                e.preventDefault();
            };
            opt.onDragEnter = (e) => {
                log("进入");
                this.setState({
                    dropTreeNode: treeNode
                })
            };
            opt.onDragLeave = (e) => {
                //多一个判断，解决setState的异步更新策略导致的状态合并更新
                // if (this.state.dropTreeNode && this.state.dropTreeNode._id === treeNode._id) {
                //     this.setState({
                //         dropTreeNode: null
                //     })
                // }
            };
            opt.onDrop = (e) => {
                e.preventDefault();
                log("放下", this.state.dragTreeNode);
                log("放入", this.state.dropTreeNode);

                //重新排序
                let indexArr = this.state.indexArr;
                if (this.state.dragTreeNode._id === this.state.dropTreeNode._id) {
                    //没变
                } else {
                    let newList = [];
                    indexArr.forEach((item) => {
                        if (item._id === this.state.dropTreeNode._id) {
                            newList.push(this.state.dropTreeNode);
                            newList.push(this.state.dragTreeNode);
                        } else if (item._id === this.state.dragTreeNode._id) {
                            //不要了
                        } else {
                            newList.push(item);
                        }

                    });
                    indexArr = newList;
                }

                //释放
                this.setState({
                    dragTreeNode: null,
                    dropTreeNode: null,
                    indexArr: indexArr
                })
            };

            return opt;
        };

        // let valueData = this.props.valueData;

        let list = indexArr.map((item, index) => {
            let ifSetVal = false;
            // if(valueData){
            //     let val = valueData[item.field];
            //
            //     if(Array.isArray(val)){
            //         if(val.length){
            //             ifSetVal = true;
            //         }
            //     }else{
            //         if(val!==undefined && val!==null){
            //             ifSetVal = true;
            //         }
            //     }
            // }

            return <div
                {...defineDragOpt(item)}
                key={"id_" + item._id}
                className={classNames({
                    [less.tabLine]: true,
                    [less.dropTreeHover]: this.state.dropTreeNode && this.state.dropTreeNode._id === item._id
                })}
            >
                <div className={less.col}>
                    {/*{*/}
                    {/*    ifSetVal*/}
                    {/*    ? <TagOutlined style={{color: "#0eb80c", fontSize: "20px"}}/>*/}
                    {/*    : <TagsOutlined style={{color: "#858585", fontSize: "20px"}}/>*/}
                    {/*}*/}
                    {/*&nbsp;*/}
                    {/*&nbsp;*/}
                    排序：{index + 1}
                </div>
                <div className={less.col}>
                    {
                        this.props.diyIcon
                            ?this.props.diyIcon(item)
                            :<FolderOpenOutlined style={{color: "dodgerblue", fontSize: "20px"}}/>
                    }
                </div>
                <div className={less.col}>
                    {
                        this.props.diyTitle
                            ?this.props.diyTitle(item)
                            :item.name || item.title
                    }
                </div>
                <div className={less.col}>
                    <Popover
                        content={"置顶"}
                    >
                        <Button
                            size={"small"}
                            onClick={() => {
                                let newList = [].concat(this.state.indexArr);
                                newList.unshift(item);
                                //去重
                                newList = _.uniqWith(newList, (a, b) => {
                                    return a._id === b._id
                                });
                                this.setState({
                                    indexArr: newList
                                })
                            }}
                        ><ArrowUpOutlined /></Button>
                    </Popover>
                    <Popover
                        content={"置底"}
                    >
                        <Button
                            size={"small"}
                            onClick={() => {
                                let newList = [].concat(this.state.indexArr);
                                newList.push(item);
                                //去重
                                newList.reverse();
                                newList = _.uniqWith(newList, (a, b) => {
                                    return a._id === b._id
                                });
                                newList.reverse();
                                this.setState({
                                    indexArr: newList
                                })
                            }}
                        ><ArrowDownOutlined /></Button>
                    </Popover>
                </div>
            </div>
        });

        return list;
    }
}

export default FiltersIndexSet;