import React, {Component} from "react";
const ConfigData = require('./main/leftMenu/PermissionConfig');
const KeyArray = ConfigData.keyArray;
import {message} from "antd";
import { EventEmitter } from 'events';

window.___ = {

    navigationData: [],

    ChildPage: (Page) => {
        class ChildPage extends Component {
            render() {
                return <Page {...this.props} />;
            }
        };
        return ChildPage;
    },

    toTreeData: (data, attributes, objectStyleCall) => {
        if (!data) {
            return [];
        }
        window.___.navigationData = _.merge([], data);
        //创建虚拟根结构
        let tree = [{
            [attributes.parentId]: attributes.rootIdValue,
            [attributes.children]: []
        }];

        let run = (chiArr) => {
            if (data.length !== 0) {
                for (let i = 0; i < chiArr.length; i++) {
                    for (let j = 0; j < data.length; j++) {
                        if (chiArr[i][attributes.parentId] == data[j][attributes.parentId]) {
                            let call = objectStyleCall(data[j]);
                            chiArr[i][attributes.children].push(
                                Object.assign(
                                    call,
                                    {
                                        [attributes.parentId]: data[j][attributes.curId],
                                        [attributes.children]: [],
                                    }));
                            data.splice(j, 1);
                            j--;
                        }
                    }
                    //  log(chiArr[i][attributes.children])
                    run(chiArr[i][attributes.children]);
                }
            }
        };
        run(tree);
        return tree[0][attributes.children];
    },

    //将导航与权限匹配
    checkPower(NavList,powerTree){
        if(!powerTree){
            return [];
        }
        let resultNav = [];
        for(let obj of powerTree){
            for(let nav of NavList){
                if(nav.id == obj.key || nav.parentId == obj.key){
                    nav.functions = obj.children;
                    for(let power of nav.functions){
                        if(power.name == 'Query' && !!power.permit){
                            resultNav.push(nav);
                            break;
                        }
                    }
                }
            }
        }

        return resultNav;
    },


    getPower: (path) => {
        if (!path) {
            return {};
        }
        let nav = window.___.navigationData;
        let functions = [];
        for (let obj of nav) {
            if (obj.id === path) {
                functions = obj.functions;
            }
        }

        return window.___.initPower(functions);
    },

    initPower(functions) {
        if (!functions) {
            return {};
        }
        log(functions);
        let find = (type) => {
            let index = _.findIndex(functions, (obj) => {
                return obj.permit && obj.name == type;
            });
            if (index >= 0) {
                return functions[index];
            } else {
                return null;
            }
        };

        let result = {};
        KeyArray.forEach((item)=>{
            result[item.key] = find(item.key)
        });

        return result;
    }
};

window.inputHelp = {
    getEventEmitter: () => {
        if (!window.inputHelp.event) {
            window.inputHelp.event = new EventEmitter();
        }

        return window.inputHelp.event;
    },

    /**
     * 卸载组件的时候，要解绑
     */
    unBind: (name, unkey) => {
        if (!unkey) {
            unkey = '_nokey';
        }
        window.inputHelp.getEventEmitter().removeAllListeners(name + unkey);
    },
    /**
     * 获取组件的数据源。异步获取。
     * 同时，监听是否更新了数据。如果更新了。重新获取。
     * 注：如果同一个NAME使用在了两个地方，并同时生效。
     * 这种情况，InpuMemoryView.props.unkey字段，不要重复
     * @param name
     * @param cb
     */
    getDataSource: (name, cb, unkey) => {
        //获取的同时，绑定通知更新。如果有更新。进行更新
        if (!unkey) {
            unkey = '_nokey';
        }
        let exeFun = () => {
            cb(null);
        };
        window.inputHelp.getEventEmitter().removeAllListeners(name + unkey);
        window.inputHelp.getEventEmitter().on(name + unkey, () => {
            //通知，要更新组件了
            exeFun();
        });
        exeFun();
    },
};

//格式化价格数字，每三位插入逗号
window.formatPrice = function(number){
    if (number === undefined || number === null) {
        return '';
    }

    return parseFloat(number).toLocaleString('en-US');
}

window.getUID = function () { // 获取唯一值
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

window.copyText = function (str) {
    let oInput = document.createElement('textarea')
    oInput.value = str
    document.body.appendChild(oInput)
    oInput.select()
    document.execCommand("Copy")
    oInput.style.display = 'none'
    document.body.removeChild(oInput)

    if (str && str.length > 100) {
        str = str.substring(0, 100);
    }
    message.success(<div style={{whiteSpace: "pre-wrap", textAlign: "left"}}>
        <div>{"复制成功:"}</div>
        <div>{str}</div>
    </div>)
}

//前端下载文件
window.downFileByUrl = function(url, fileName) {
    if(!url){
        message.error('无效的资源路径');
        return;
    }

    //不使用form方式下载，地址错误的时候会改变当前页面
    // let form = document.createElement("form");
    // document.body.appendChild(form);
    // form.action = url;
    // form.submit();

    let downLink = document.createElement('a')
    downLink.download = fileName || "下载内容";
    downLink.href = url;
    downLink.target= "_black";
    // 链接插入到页面
    document.body.appendChild(downLink)
    downLink.click()
    // 移除下载链接
    document.body.removeChild(downLink)
}

window.openPage = function (page, param) {
    let hash = page + '?uuid=' + window.getUID();
    if (param) {
        for (let key in param) {
            if (key) {
                hash += '&' + key + '=' + encodeURIComponent(param[key]);
            }
        }
    }

    window.location.hash = hash;
};
window.openWin = function (path, param, option) {
    let win = window.open(
      window.location.origin + '/' + window.getPath(path, param),
      option.title,
      '',
      true,
    );
};
window.getPath = function (page, param, noId) {
    let hash = page + '?uuid=' + (noId ? 'no' : getUID());
    if (param) {
        for (let key in param) {
            if (key) {
                hash += '&' + key + '=' + encodeURIComponent(param[key]);
            }
        }
    }
    // #/remote#id=YWZm
    return hash;
};

//前端生成文件
window.createDataDownload = function (data, filename, type) {
    let file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else {
        // Others
        let a = document.createElement('a'),
          url = URL.createObjectURL(file);
        a.href = url;
        a.target = '_black';
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};


window.downFileByText = function (content, fileName) {
    let downLink = document.createElement('a');
    downLink.download = fileName;
    //字符内容转换为blod地址
    let blob = new Blob([content]);
    downLink.href = URL.createObjectURL(blob);
    downLink.target = '_black';
    // 链接插入到页面
    document.body.appendChild(downLink);
    downLink.click();
    // 移除下载链接
    document.body.removeChild(downLink);
};
//前端读取本地文件的内容   下面代码中的this.result即为获取到的内容
window.uploadText = function (file, callBack) {
    //支持chrome IE10
    if (window.FileReader) {
        const { files } = file.target;

        let fileName = files[0].name;

        let reader = new FileReader();
        reader.onload = function () {
            console.log('resultresult', this.result);
            callBack(this.result);
            file.target.value = '';
            try {
                reader.close();
            } catch (e) {}
        };
        reader.readAsText(files[0]);
    }
    //支持IE 7 8 9 10
    else if (typeof window.ActiveXObject != 'undefined') {
        let xmlDoc;
        xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
        xmlDoc.async = false;
        xmlDoc.load(input.value);
        callBack(xmlDoc.xml);
    }
    //支持FF
    else if (document.implementation && document.implementation.createDocument) {
        let xmlDoc;
        xmlDoc = document.implementation.createDocument('', '', null);
        xmlDoc.async = false;
        xmlDoc.load(input.value);
        callBack(xmlDoc.xml);
    } else {
        callBack(null, '读取错误');
    }
};

window.getUrlSearch = function (str) {
    var query = {};
    var name, value;
    var num = str.indexOf('?');
    if (num < 0) {
        return query;
    }

    str = str.substr(num + 1); //取得所有参数   stringvar.substr(start [, length ]
    var arr = str.split('&'); //各个参数放到数组里

    for (var i = 0; i < arr.length; i++) {
        num = arr[i].indexOf('=');
        if (num > 0) {
            name = decodeURIComponent(arr[i].substring(0, num));
            value = decodeURIComponent(arr[i].substr(num + 1));
            query[name] = value;
        }
    }
    return query;
};
//简化展示大额数字
window.formatBigNum = (num) => {
    if (!num) {
        return 0;
    }
    if (num < 1000) {
        return num;
    }
    if (1000 <= num && num) {
        return (num / 1000).toFixed(2) + 'K';
    }
};
//简化展示大额数字 万
window.formatBigNumW = (num) => {
    if (!num) {
        return 0;
    }
    if (num < 10000) {
        return num;
    }
    if (10000 <= num && num) {
        return (num / 10000).toFixed(2) + 'W';
    }
};

//0-100 到 00-FF的转换
window.getOpacityStr = (opacity) => {
    if (!opacity) {
        return '00';
    }

    let v = Math.round((opacity / 100) * 255).toString(16);
    if (v.length === 1) {
        v = '0' + v;
    }
    return v;
};
//00-FF 到 0-100的转换
window.getOpacityNum = (opacityHex) => {
    if (!opacityHex) {
        return 0;
    }

    return Math.round((eval('0x' + opacityHex) / 255) * 100);
};

//检查参数重名
window.checkArg = (arg, succCb, errorCB) => {
    let nameEmpty = false;
    let nameSame = false;
    let nameList = [];
    if (arg && Object.keys(arg).length > 0) {
        Object.keys(arg).forEach((key) => {
            let name = arg[key].name && _.trim(arg[key].name);
            if (!name) {
                nameEmpty = true;
            } else {
                if (nameList[name]) {
                    nameSame = true;
                } else {
                    nameList[name] = true;
                }
            }
        });
    }

    if (nameEmpty || nameSame) {
        errorCB?.(
          nameEmpty ? '请完善参数名称' : nameSame ? '存在相同的参数名' : null,
        );
    } else {
        succCb?.(arg);
    }
};
//判断手机端
window.isMobile = /mobile|android|iphone|ipod|phone|ipad/i.test(
  navigator.userAgent.toLowerCase(),
);
if(document.body.clientWidth>document.body.clientHeight){
    window.isMobile = false;
}
window.help = {};
window.changeName = function (arr = {}) {
    if (arr) {
        let keys = Object.keys(arr);
        for (let key of keys) {
            let obj = arr[key];
            let type = obj.type;
            if ('string' === type || 'str' === type) {
                type = 'String';
            } else if ('bool' === type || 'boolean' === type) {
                type = 'Boolean';
            } else if ('number' === type || 'int' === type) {
                type = 'Number';
            }

            obj.type = type;
            if (!obj.key) {
                obj.key = key;
            }
            if (!obj.hasOwnProperty('name')) {
                obj.name = obj.nickname;
            }
        }
    }

    return arr;
};
//对携带rem单位的数据做转换
window.changeRemNum = function (
  setObj,
  numField = 'num',
  unitField = 'unit',
  remRate = 15,
) {
    if (!setObj) {
        return '0px';
    }

    return setObj[unitField] === 'rem'
      ? setObj[numField] / remRate + 'rem'
      : setObj[numField] + setObj[unitField];
};

