import React,{useState} from "react";
import AppOtherPage from "../AppOtherPage";
import DashboardLayoutSet from "../navigationPages/dashboard/DashboardLayoutSet";
import DarkStyleWrap from "../../DarkStyleWrap";
import EmptyView from "../../components/EmptyView/index";

export default function (props){
  const [permit] = useState(()=>{
    let result = false;

    let permissionConfig = JSON.parse(window.localStorage.getItem('permission'));
    console.log("permissionConfig",permissionConfig)

    permissionConfig?.forEach((eachConfig)=>{
      if(eachConfig.key === "DashboardThemeManage"){
        eachConfig.children?.forEach((eachPower)=>{
          if(eachPower.key === "DashboardThemeManageEdit" && eachPower.permit){
            result = true;
          }
        })
      }
    })

    return result;
  })


  return <DarkStyleWrap>
    <AppOtherPage>
      <DarkStyleWrap>
        {
          permit
            ?<DashboardLayoutSet/>
            :<EmptyView
              style={{marginTop: "150px"}}
              title={<span style={{color: "#333"}}>您没有编辑模板的权限</span>}
            />
        }
      </DarkStyleWrap>
    </AppOtherPage>
  </DarkStyleWrap>
}