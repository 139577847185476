import React from "react";
import {
    Form,
    Divider,
    Select,
    AutoComplete,
    Radio,
    Switch,
    Collapse,
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

const Item = Form.Item;

/**
 * 旭日图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }


    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"名称维度"}
                name={["dataSetConfig","labelField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"数值维度"}
                name={["dataSetConfig","valueField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"子集维度"}
                name={["dataSetConfig","childrenField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="pieSpecial"
            header="旭日图专用"
        >
            <Item
                label={"内圈半径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["pieSpecial","_radiusInner"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"外圈半径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["pieSpecial","_radiusOuter"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"圆心位置"}
            >
                <Item
                    label={"水平"}
                    name={["pieSpecial","_centerSetX"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
                <Item
                    label={"垂直"}
                    name={["pieSpecial","_centerSetY"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
            </Item>
            <Divider
                orientation="right"
            >
                <Item
                    style={{width: "150px"}}
                    labelCol={{span: 14}}
                    label={"更多配置"}
                    name={["pieSpecial","seeMore"]}
                    valuePropName={"checked"}
                >
                    <Switch
                        size={"small"}
                        checkedChildren={"已展开"}
                        unCheckedChildren={"已收起"}
                    />
                </Item>
            </Divider>
            <Item
                noStyle={true}
                dependencies={["pieSpecial","seeMore"]}
            >
                {({getFieldValue})=> {
                    let seeMore = getFieldValue(["pieSpecial", "seeMore"]);

                    return <Item
                        noStyle={true}
                        hidden={!seeMore}
                    >
                        <Item
                            label={"排序方式"}
                            name={["pieSpecial","_sort"]}
                        >
                            <Radio.Group
                            >
                                <Radio value="desc">按照数值降序</Radio>
                                <Radio value="asc">按照数值升序</Radio>
                                <Radio value="null">不排序</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            label={"需要展示所有数据的标签"}
                            name={["pieSpecial","_showAllLabel"]}
                            valuePropName={"checked"}
                            labelCol={{span: 12}}
                            wrapperCol={{span: 10}}
                            extra={"选否，会自动隐藏占比不足1%的数据的标签"}
                        >
                            <Switch
                                size={"small"}
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                    </Item>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="itemStyle"
            header="扇区样式"
        >
            <Item
                label={"描边线宽"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderWidth"], {
                    min: 0,
                    max: 10
                })}
            </Item>
            <Item
                label={"描边线颜色"}
                name={["itemStyle","borderColor"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"描边线类型"}
                name={["itemStyle","borderType"]}
            >
                <Radio.Group
                >
                    <Radio value="solid">实线</Radio>
                    <Radio value="dashed">虚线</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={"阴影大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","shadowBlur"], {
                    min: 0,
                    max: 20
                })}
            </Item>
            <Item
                label={"阴影颜色"}
                name={["itemStyle","shadowColor"]}
            >
                <ColorPicker/>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="outsideCenter">扇区外侧</Select.Option>
                    <Select.Option value="insideCenter">扇区内侧居中</Select.Option>
                    <Select.Option value="insideLeft">扇区内侧靠外</Select.Option>
                    <Select.Option value="insideRight">扇区内侧靠内</Select.Option>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: {
            labelField: null,
            valueField: null,
            childrenField: null
        },
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            }
        },
        //饼图特殊
        pieSpecial:{
            seeMore: false,
            _sort: "desc",
            _showAllLabel: false,
            _labelLine: "line",
            _radiusInner: 0,
            _radiusOuter: 75,
            _centerSetX: "50%",
            _centerSetY: "50%",

        },
        //标签
        label: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "insideCenter",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //扇区样式
        itemStyle: {
            borderWidth: 0,
            borderColor: "#ffffffff",
            borderType: "solid",
            shadowBlur: 0,
            shadowColor: "#000000e5",
        }
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 name, children, value 等 字段可以自定义。
    1.旭日图的数据结构，有层级关系，每一层都是一个数组，可以嵌套多层。给每一项数据配置数量字段，父级别数据可以不设置数量，会自动统计子集的合。
        [
            {
                name: "小说书籍销量",
                children: [
                    {
                        name: "三体",
                        value: 2600, 
                    },
                    {
                        name: "追风筝的人",
                        value: 1200, 
                    },
                ]
            },
            {
                name: "历史书籍销量",
                children: [
                    {
                        name: "中华上下五千年",
                        value: 1560, 
                    },
                    {
                        name: "温莎王朝",
                        value: 120, 
                    },
                ]
            },
            {
                name: "教材书籍销量",
                children: [
                    {
                        name: "深入理解计算机系统",
                        value: 20, 
                    },
                ]
            }
        ]
     
      2. 给父级别数据也配置数量字段，若子集的合小于父级，表示存在空缺
      [
            {
                name: "一年级二班全体人数",
                value: 60,
                children: [
                    {
                        name: "成绩90分以上",
                        value: 15,
                    },
                    {
                        name: "成绩75分以上",
                        value: 20,
                    },
                ]
            },
            ...
        ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}