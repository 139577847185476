import React from "react";
import {Progress, Space} from "antd";
import styles from "./MultiProgress.less";
import CommonViewDiv from "../../../CommonViewDiv";

export default class MultiProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progressMsg: null,
            show: false,
            width: 0,
        }
    }

    componentDidMount(){
        this.setState({
            width: Math.min(this.outer.clientWidth,this.outer.clientHeight)
        })

    }

    showValue(value) {
        this.setState({
            progressMsg: value,
            show: true
        })
    }

    render(){
        return <div className={styles.outerBox}
                    ref={(ref) => {
                        this.outer = ref;
                    }}
        >
            {this.getRealView()}
        </div>
    }

    getRealView() {
        let {show, progressMsg,width} = this.state;
        if (!show) {
            return null
        }

        if (Object.prototype.toString.call(progressMsg) !== '[object Object]') {
            return "[传入数据格式错误]"
        }

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};

        if (!progressMsg.hasOwnProperty(contentParams.totalField) || !progressMsg.hasOwnProperty(contentParams.firstField)) {
            return "[总量或第一段字段未对接成功]"
        }

        let typeOption = contentParams[contentParams.type + "Set"];
        if(contentParams.type !== 'line'){
            typeOption.width = width;
        }

        let msgView = this.getFormatView(progressMsg, contentParams);

        return <>
            <Progress
                type={contentParams.type || "circle"}
                strokeLinecap={contentParams.strokeLinecap || "round"}
                trailColor={contentParams.trailColor}
                percent={((progressMsg[contentParams.firstField] || 0) + (progressMsg[contentParams.secondField] || 0)) / progressMsg[contentParams.totalField] * 100}
                strokeColor={contentParams.secondColor}
                success={{
                    percent: (progressMsg[contentParams.firstField] || 0) / progressMsg[contentParams.totalField] * 100,
                    strokeColor: contentParams.firstColor
                }}
                {...typeOption}
                showInfo={contentParams.type !== "line"}
                format={(percent, successPercent) => {
                    return msgView
                }}
            />
            {
                contentParams.type === "line"
                    ? msgView
                    : null
            }
        </>
    }

    //展示内容
    getFormatView(progressMsg, contentParams) {
        let precision = contentParams.msgSet?.precision || 0;

        let fontStyle = CommonViewDiv.getTextStyleObj(contentParams.msgFont);

        let msg;

        switch (contentParams.msgSet.type) {
            case "setTxt":
                msg = contentParams.msgSet.textContent;
                break;
            case "number":
                msg = <div
                    className={contentParams.type === "line" ? styles.msgBoxForLine : styles.msgBox}
                    style={{
                        gap: (contentParams.msgSet?.gap || 0)/150 + "rem"
                    }}
                >
                {
                    contentParams.msgSet.showTotal
                        ? <Space
                            direction={contentParams.msgSet.wrap?"vertical":"horizontal"}
                            align={"center"}
                        >
                            <span>{contentParams["totalField"]}:</span>
                            <span>{contentParams.msgSet?.bigNumber?window.formatBigNumW(progressMsg[contentParams["totalField"]]):progressMsg[contentParams["totalField"]]}</span>
                        </Space>
                        : null
                }
                <span
                    className={contentParams.type === "line" ? styles.msgInnerForLine : styles.msgInner}
                    style={{
                        gap: (contentParams.msgSet?.gap || 0)/150 + "rem"
                    }}
                >
                    {
                        contentParams.msgSet.showFirst
                            ? <Space
                                direction={contentParams.msgSet.wrap?"vertical":"horizontal"}
                                align={"center"}
                                style={fontStyle.color?{}:{
                                    color: contentParams["firstColor"]
                                }}
                            >
                                <span>{contentParams["firstField"]}:</span>
                                <span>{contentParams.msgSet?.bigNumber?window.formatBigNumW(progressMsg[contentParams["firstField"]]):progressMsg[contentParams["firstField"]]}</span>
                            </Space>
                            : null
                    }
                    {
                        contentParams.msgSet.showSecond
                            ? <Space
                                direction={contentParams.msgSet.wrap?"vertical":"horizontal"}
                                align={"center"}
                                style={fontStyle.color?{}:{
                                    color: contentParams["secondColor"]
                                }}
                            >
                                <span>{contentParams["secondField"]}:</span>
                                <span>{contentParams.msgSet?.bigNumber?window.formatBigNumW(progressMsg[contentParams["secondField"]]):progressMsg[contentParams["secondField"]]}</span>
                            </Space>
                            : null
                    }
                </span>
                </div>
                break;
            case "numAndPct":
            case "percent":
                let firstP = ((progressMsg[contentParams["firstField"]] || 0) * 100 / progressMsg[contentParams["totalField"]]).toFixed(precision)
                let secondP = ((progressMsg[contentParams["secondField"]] || 0) * 100 / progressMsg[contentParams["totalField"]]).toFixed(precision)

                msg = <div
                    className={contentParams.type === "line" ? styles.msgBoxForLine : styles.msgBox}
                    style={{
                        gap: (contentParams.msgSet?.gap || 0)/150 + "rem"
                    }}
                >
                <span
                    className={contentParams.type === "line" ? styles.msgInnerForLine : styles.msgInner}
                    style={{
                        gap: (contentParams.msgSet?.gap || 0)/150 + "rem"
                    }}
                >
                    {
                        contentParams.msgSet.showFirst
                            ? <Space
                                direction={contentParams.msgSet.wrap?"vertical":"horizontal"}
                                align={"center"}
                                style={fontStyle.color?{}:{
                                    color: contentParams["firstColor"]
                                }}
                            >
                                <span>{contentParams["firstField"]}:</span>
                                <span>
                                    {firstP}%
                                    {
                                        contentParams.msgSet.type === "numAndPct"
                                            ?<span>({contentParams.msgSet?.bigNumber?window.formatBigNumW(progressMsg[contentParams["firstField"]]):progressMsg[contentParams["firstField"]]})</span>
                                            :null
                                    }
                                </span>
                            </Space>
                            : null
                    }
                    {
                        contentParams.msgSet.showSecond
                            ? <Space
                                direction={contentParams.msgSet.wrap?"vertical":"horizontal"}
                                align={"center"}
                                style={fontStyle.color?{}:{
                                    color: contentParams["secondColor"]
                                }}
                            >
                                <span>{contentParams["secondField"]}:</span>
                                <span>
                                    {secondP}%
                                    {
                                        contentParams.msgSet.type === "numAndPct"
                                            ?<span>({contentParams.msgSet?.bigNumber?window.formatBigNumW(progressMsg[contentParams["secondField"]]):progressMsg[contentParams["secondField"]]})</span>
                                            :null
                                    }
                                </span>
                            </Space>
                            : null
                    }
                </span>
                </div>
                break;
        }


        return <div
            style={fontStyle}
            className={styles.msgContainer}
        >
            {msg}
        </div>
    }
}