import React from "react";
import styles from "./DataBindAndPreview.less";
import {Space, Tag, Input, Button, Flex, message, Modal, Cascader, Radio, Form} from "antd";
import {CaretRightOutlined} from "@ant-design/icons";
import DataGetHelp from "../../../dataSet/components/dataSet/DataGetHelp";
import AllContentMap,{ViewContentArray} from "../AllContentDefinition";
import ViewAndCode from "./ViewAndCode";
import DefaultThemeConfig from "../../DefaultThemeConfig";
import _ from "lodash";
import ChartOptionSetView from "./ChartOptionSetView";
import {UsageNameMap} from "./UsageDictionary";

const DefaultConfigs = {//绑定元数据
  formData: {
    collectionName: undefined,    //集合名称
    query: undefined,    //查询语句 （只支持聚合管道 不同的 query 之间用 && 分割）
  },
  extraOption: {},
}

const DefaultChartItem = {
  _id: null, //服务端注册id
  categoryId: null, //分类id
  name: null, //名称
  contentMsg: {
    contentMode: "customComponent",
    dataConfig: {   //数据展示
      dataSetInputMap: null,   //已经修改过的 [输入参数对象] 的map
      contentType: null,  //组件类型(和 View 的类型一致)
      contentTitle: null, //容器标题
      coverUrl: null, //封面
      dataRequestConfig: {
        dataType: 6,  //6 是服务端自定义组件特有的类型
        configs: DefaultConfigs
      }, //数据查询配置 相当于数据集的查询实体配置
      mockParams: {}, //模拟参数
      contentParams: null,  //属性配置
    },
    styleConfig: {},  //对单个容器定义的样式配置
    proConfig: null,    //高级配置
  },
  type: 2,
  version: null,
}

export default class DataBindAndPreview extends React.Component {
  constructor(props) {
    super(props);

    let defaultChart = _.cloneDeep(this.props.editChartItem || DefaultChartItem);
    //后台自定义组件不用选择业务id,这里直接赋值使用场景
    defaultChart.contentMsg.dataConfig.mockParams.usage = this.props.usage;

    let matchContent = AllContentMap[defaultChart.contentMsg.dataConfig.contentType];
    let defaultViewTypeSel = null;
    if(matchContent){
      defaultViewTypeSel = [matchContent.groupName, defaultChart.contentMsg.dataConfig.contentType]

      console.log("defaultViewTypeSel",defaultViewTypeSel)
    }

    this.state = {
      loading: false,
      editChartItem: defaultChart,
      config: defaultChart?.contentMsg?.dataConfig?.dataRequestConfig,
      viewTypeSel: defaultViewTypeSel,
      openOptionSet: false,
      originData: props.originData || null,
      styleUpdate: 0
    }

    this.DataGetHelp = new DataGetHelp();
  }


  render() {
    let {editChartItem, config, loading, viewTypeSel, originData,openOptionSet,styleUpdate} = this.state;

    return <div className={styles.outerBox}>
      <div className={styles.header}>
        <Space>
          <Tag
            color={"blue"}
          >
            {
              UsageNameMap[this.props.usage]
            }
            组件
          </Tag>
          <Input
            placeholder={"请输入组件名称"}
            value={editChartItem?.name}
            onChange={(e) => {
              editChartItem.name = e.target.value;
              this.setState({})
            }}
          />
        </Space>
        <Space>
          <Button
            danger={true}
            ghost={true}
            onClick={() => {
              this.props.onCancel?.()
            }}
          >
            取消
          </Button>
          <Button
            type={"primary"}
            style={{backgroundColor: "limegreen", borderColor: "limegreen"}}
            onClick={() => {
              this.saveAction()
            }}
          >
            保存
          </Button>
        </Space>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyLeft}>
          <div className={styles.controlBar}>
            <Button
              loading={loading}
              icon={<CaretRightOutlined/>}
              size={"small"}
              type={"primary"}
              shape={"round"}
              onClick={() => {
                this.runAction()
              }}
            >运行</Button>
          </div>
          <div className={styles.blocklyBindContainer}>
            <Form
              ref={(ref)=>{this.requestConfigForm = ref}}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              initialValues={config.configs?.formData}
            >
              <Form.Item
                name={"collectionName"}
                label={"表名"}
                rules={[{required: true}]}
              >
                <Input
                  placeholder={"请填写表名"}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                name={"query"}
                label={"查询语句"}
                rules={[{required: true}]}
                extra={"只支持聚合管道，不同的 query 之间用 && 分割 例如：{$match: {type: 2}}&&{$project:{data: 0}}"}
              >
                <Input.TextArea
                  placeholder={"请填写查询语句"}
                  maxLength={5000}
                  allowClear={true}
                  autoSize={{minRows: 20, maxRows: 30}}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className={styles.bodyRight}>
          <div className={styles.viewControlHead}>
            <div>组件预览</div>
            <Cascader
              szie={"small"}
              style={{flex: 1, flexShrink: 0}}
              placeholder={"选择视图类型"}
              displayRender={(label, selectedOptions) => {
                return label.pop()
              }}
              showSearch={{
                filter: (inputValue, path) =>
                  path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
              }}
              allowClear={false}
              expandTrigger={"hover"}
              value={viewTypeSel}
              onChange={(value, selectedOptions) => {
                let contentType = value[value.length - 1];
                let matchContent = AllContentMap[contentType];
                editChartItem.contentMsg.dataConfig.contentType = contentType;

                editChartItem.contentMsg.dataConfig.contentParams = matchContent.setFile.getDefaultValues()

                this.setState({
                  viewTypeSel: value,
                  editChartItem: editChartItem
                })
              }}
              options={
                ViewContentArray.map((group) => {
                  return {
                    label: group.groupName,
                    value: group.groupName,
                    children: group.children?.map((chart) => {
                      return {
                        label: chart.title,
                        value: chart.type,
                      }
                    })
                  }
                })
              }
            />
            <Button
              type={"primary"}
              size={"small"}
              disabled={!viewTypeSel}
              onClick={()=>{
                this.setState({
                  openOptionSet: !openOptionSet
                })
              }}
            >{openOptionSet?"关闭属性配置":"打开属性配置"}</Button>
          </div>
          <div
            className={styles.viewContent}
          >
            <ViewAndCode
              ref={(ref) => {
                this.viewAndCode = ref
              }}
              key={"vc_" + editChartItem?.contentMsg?.dataConfig.contentType}
              dataUpdate={editChartItem?._id}
              styleUpdate={styleUpdate}
              contentMsg={editChartItem?.contentMsg}
              themeConfig={DefaultThemeConfig}
              scencStyleConfig={{}}
              defaultData={originData}
            />
          </div>
        </div>
        {
          openOptionSet
            ?<div
              className={styles.chartOptionBox}
            >
              <ChartOptionSetView
                usage={editChartItem.contentMsg.dataConfig.mockParams?.usage}
                defaultCategoryId={editChartItem.categoryId}
                defaultDataConfig={editChartItem.contentMsg.dataConfig}
                bindData={originData}
                onCategoryChange={(categoryId)=>{
                  editChartItem.categoryId = categoryId
                }}
                onContentParamsChange={(dataConfig)=>{
                  editChartItem.contentMsg.dataConfig = dataConfig
                  this.setState({
                    styleUpdate: this.state.styleUpdate + 1
                  })
                }}
              />
            </div>
            :null
        }
      </div>
    </div>
  }

  //收集配置
  getBindConfig(cb) {
    let {config} = this.state;

    this.requestConfigForm.validateFields().then((values)=>{
      config.configs.formData = values
      this.setState({
        config: config
      }, () => {
        cb(config)
      })
    }).catch(()=>{
      console.log("参数没填完")
    });
  }

  //运行
  runAction() {
    this.getBindConfig(async (config) => {
      console.log("得到的配置",config)

      //根据绑定关系处理数据
      this.setState({
        loading: true
      }, async () => {
        let err;

        let resultData = await this.DataGetHelp.getDataByBindConfig(config).catch((e) => {
          err = e;
        })

        // console.log("看看获取的数据",resultData);

        if (err) {
          Modal.error({
            title: "错误",
            content: err
          })
          this.setState({
            originData: null, //原始数据
            loading: false
          }, () => {
            this.viewAndCode?.showValue(null)
          })
          return;
        }

        this.setState({
          originData: resultData.originData, //原始数据
          loading: false
        }, () => {
          this.viewAndCode?.showValue(resultData.originData)
        })
      })
    })
  }


  //对定义的输入参数追加 value 结构
  getInputArg(inputs) {
    let scriptObj = {}
    if (inputs) {
      let keys = Object.keys(inputs);
      for (let key of keys) {
        let oldObj = inputs[key];
        let obj = {}
        obj.inputArgItem = {
          ...oldObj,
          value: oldObj.value || {
            defaultValue: null,
            source: "script"
          }
        }
        scriptObj[key] = obj;
      }
    }
    return scriptObj;
  }

  //保存行为
  saveAction() {
    let {editChartItem} = this.state;

    if (!_.trim(editChartItem.name)) {
      message.warning("请填写组件名称");
      return
    }

    // 分类不是必填
    // if(!editChartItem.categoryId){
    //   message.warning("请配置分类");
    //   return
    // }

    //收集配置
    this.getBindConfig((config) => {
      editChartItem.contentMsg.dataConfig.dataRequestConfig = config

      this.props.onSave?.(editChartItem)
    })
  }
}
