const getDefaultTheme = () => {
    return {
      "token": {
        "colorPrimary": "#8846dd",
        "colorInfo": "#8846dd",
        "colorTextBase": "#ffffff",
        "colorBgBase": "#100e38",
        "colorBgElevated": "#09092f",
        "colorBgLayout": "#191746",
        "colorBorder": "#312f62",
        "colorBorderSecondary": "#312f62",
        "colorBgSpotlight": "#312f62",
        "borderRadius": 4,
        "colorText": "#ffffffe6",
        "titleColor": "rgba(255,255,255,0.95)",
        // "colorBgContainer": "#100e38", //合并前的颜色
        "colorBgContainer": "transparent", //和老系统黑色主题合并后 配置的
        // "colorBgMask": "rgba(255,255,255,0.2)",
        "borderRadiusXS": 2,
        "wireframe": false,
        "controlHeightSM": 24,  //按钮高度
        "controlHeight": 28,
        "controlHeightLG": 32,
        "fontSize": 12,  //文字大小
        "fontSizeSM": 12,
        "fontSizeLG": 14,
        "colorTextQuaternary": "rgba(255,255,255,0.45)",
        "colorTextSecondary": "rgba(255,255,255,0.85)",
        "colorTextTertiary": "rgba(255,255,255,0.65)",
        "colorTextPlaceholder": "rgba(255,255,255,0.45)",
      },
      "components": {
        "Divider": {
          "colorSplit": "rgba(166, 164, 247, 0.2)"
        },
        "Layout": {
          "bodyBg": "rgb(16, 14, 56)",
          "footerBg": "rgb(16, 14, 56)"
        },
        "Menu": {
          "subMenuItemBg": "rgb(16, 14, 56)",
          "itemActiveBg": "rgba(100, 98, 160, 0.2)",
          "itemSelectedBg": "rgba(100, 98, 160, 0.2)",
          "colorSplit": "rgb(49, 47, 98)"
        },
        "Cascader": {
          "optionSelectedBg": "rgba(136, 70, 221, 0.2)",
          "colorSplit": "rgba(255, 255, 255, 0.1)"
        },
        "Select": {
          "optionSelectedBg": "rgb(49, 47, 98)"
        },
        "Slider": {
          "trackHoverBg": "rgb(136, 70, 221)",
          "handleColor": "rgb(49, 47, 98)",
          "trackBg": "rgba(136, 70, 221, 0.2)"
        },
        "Transfer": {
          "controlItemBgActive": "rgba(105, 51, 190, 0.2)",
          "controlItemBgActiveHover": "rgba(105, 51, 190, 0.2)"
        },
        "TreeSelect": {
          "nodeSelectedBg": "rgb(136, 70, 221)"
        },
        "Badge": {
          "colorBgContainer": "rgb(255, 255, 255)"
        },
        "Calendar": {
          "itemActiveBg": "rgba(105, 51, 190, 0.2)"
        },
        "Segmented": {
          "itemSelectedBg": "rgb(114, 46, 209)"
        },
        "Tree": {
          "nodeSelectedBg": "rgba(105, 51, 190, 0.2)"
        },
        "Collapse": {
          "headerBg": "#ffffff0a",
          "contentBg": "#ffffff0a",
        },
        "Table": {
          "headerBg": "#373c51",
          "headerColor": "#ffffff",
          "borderColor": "#373c51"
        },
        "Button":{
          "colorPrimary": "#3E63DD",
          "colorPrimaryHover": "#2d89fa",
        },
        "Modal": {
          "headerBg": "#11131F",
          "contentBg": "#11131F"
        }
      },
    }
}

export const customtheme = getDefaultTheme()