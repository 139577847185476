import React from "react";
import ShowCode from "../../../../../edit/ShowCode";

const ShowJson = React.forwardRef((props, ref) => {
  return <ShowCode
    ref={ref} // 转发 ref 到 ShowCode
    hiddenHelp={true}
    readOnly={true}
    {...props}
  />
});

export default ShowJson;