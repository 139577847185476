export default {
    publicKey:
      "-----BEGIN PUBLIC KEY-----\n" +
      "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/4ulVTnbc7RAr1xKS0B5Qrca6\n" +
      "6I/PFqqy5VvhiYgWQibAcVGiArqHo+Xv+vhyMkblIrUx6Rt/S4wysgf/zotzaimB\n" +
      "YTmoxiLHcCWeMgEPNgAwMs+w67uYVp0lMBmW7qY5/B9DnJ3i0uhnvDTojnzDpXPn\n" +
      "KEvWC/N9+irUX/DJPQIDAQAB\n" +
      "-----END PUBLIC KEY-----", //HttpTools 加密公钥；
    projectUniqueName: "SYBER_ADMIN",//项目cookies存储用
    requestTimeout: 60000,  //接口请求超时(ms)
    ampKey: "662448751d3c11172d942d7cfb0549f9",  //正式环境-高德地图key
    securityJsCode: "fb08c16a2d3519b6c7f5ef8d2d5dc7bb",  //正式环境-高德地图安全密钥
    // ampKey: "192c6aa966b2051104d124e557682db0",  //开发环境个人账号-高德地图key
    // securityJsCode: "a516207aeb84ab6e9448edc2c0fabb67",  //开发环境个人账号-高德地图安全密钥
};
