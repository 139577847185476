import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './RomRuleManage.less';
import APILXD from '../../../http/APILXD.js';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import {Button, message, Modal,Table} from "antd";
import RuleSet from "./RuleSet";
import HttpTool from "../../../tool/HttpTool";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

class RuleDetail extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                title: this.props.record?.name + " 的规则管理",
                columns: this.getTableColumns(),
                url: APILXD.getRuleList,
                otherParam: {
                    _id: this.props.record?._id
                },
            },
            search: {
                option: {
                    placeholder: '输入规则名称搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        value.name = value.keyword
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '新建规则',
                action: () => {
                    this.addNewWords()
                }
            }
        }
    }

    addNewWords(data){
        MB.show(
          {
              title: data?"编辑规则":"新增规则",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 16, offset: 0},
              },
              parameterArr: [
                  {
                      type: "Input",
                      ver: true,
                      required: true,
                      field: "name",
                      name: "规则名称",
                      reg: (v) => {
                          return !!v;
                      },
                      verMessage: "请填写",
                      option:{
                            defaultValue: data?.name
                      },
                  },
                  {
                      type: "diy",
                      ver: true,
                      required: true,
                      field: "ruleSet",
                      name: "规则值",
                      component: RuleSet,
                      reg: (v) => {
                          return v && v.type && v.value;
                      },
                      verMessage: "请填写规则值",
                      option:{
                          defaultValue: data?{
                              type: data.type,
                              value: data.value,
                          }:{
                              type: 2,
                              value: null,
                          }
                      },
                  },
              ],
          },
          {
              url: data?APILXD.updateRule:APILXD.insertRule,
              otherParam: data?{
                  _id: this.props.record?._id,
                  ruleId: data._id
              }:{
                  _id: this.props.record?._id,
              },
              beforeSubmit: (param) => {
                  param.type = param.ruleSet.type;
                  param.value = param.ruleSet.value;

                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '规则名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Rom数量',
                dataIndex: 'num',
                key: 'num',
            },
            {
                title: '添加/更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.updateTime)}</div>
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record, index) => {
                    return (<div>
                        <div
                          className={less.btnDetail}
                          onClick={() => {
                              this.seeRomList(record)
                          }}
                        >查看Rom
                        </div>
                        {
                            this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.addNewWords(record)
                                }}
                              >编辑
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Delete
                              ?(<div
                                className={less.btnDelete}
                                onClick={() => {
                                    ModalDelete.show({
                                          title: "提示",
                                          okTitle: "确定",
                                          closeTitle: "取消",
                                      },
                                      {}
                                      ,
                                      {
                                          otherParam: {
                                              _id: this.props.record?._id,
                                              ruleId: record._id
                                          },
                                          content: record.name,
                                          url: APILXD.deleteRule,
                                          apiType: 'post',
                                          tip: '删除后该规则内信息将一并删除，确定删除吗？',
                                          callBack: (state) => {
                                              //删除成功回调
                                              state === "success" && this._loadDataForNet();
                                          }
                                      });
                                }}
                              >删除
                              </div>)
                              :null
                        }
                    </div>)
                }
            },
        ]
    }

    seeRomList(record){
        this.setState({
            loading: true
        },()=>{
            HttpTool.post(APILXD.ruleRomList, (code, msg, json) => {
                this.setState({
                    loading: false
                })
                Modal.info({
                    title: "ROM列表",
                    content: <div
                      style={{maxHeight: "60vh",overflow: "auto"}}
                    >
                        <Table
                          bordered={true}
                          columns={[
                              {
                                  title: "ROM名称",
                                  key: "name",
                                  dataIndex: "name",
                              }
                          ]}
                          dataSource={json.map((name)=>{
                              return {
                                  name: name
                              }
                          })}
                          pagination={false}
                        />
                    </div>
                })

            }, (code, msg) => {
                message.error(msg);
                this.setState({
                    loading: false
                })
            }, {
                _id: this.props.record?._id,
                ruleId: record._id,
            });
        })
    }
}

module.exports = RuleDetail;