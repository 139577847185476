import React, {Component} from 'react';
import styles from "./ShowImage.less";
import axios from "axios";
import {message} from "antd";

//备注：为什么要用base64写法?
//最早版本用 background-image 使用图片，搭配了 backgroundPosition，backgroundSize 属性。
//加了仪表板缩略图功能以后，html2canvas 使用图片出现跨域问题。如果改成 <img crossOrigin={"anonymous"}/> 写法可以解决跨域，但是原来配置项中的 backgroundPosition，backgroundSize 也需要改变写法去兼容。
//所以用base64 处理。
export default class ShowImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            init: false,
        }

        this.imgBase64 = null;
    }

    componentDidMount() {
        if(this.props?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl){
            this.getImageFromOssAction(this.props?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl !== nextProps?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl) {
            if(nextProps?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl){
                this.getImageFromOssAction(nextProps?.contentMsg?.dataConfig?.contentParams?.upload_imageUrl)
            }else{
                this.imgBase64 = null;
            }

        }
    }

    getImageFromOssAction(imgUrl) {
        axios({
            method: 'get',
            url: imgUrl,
            headers: {
                "Content-Type": "text/plain",
            },
            responseType: "arraybuffer"
        }).then(({status, data}) => {
            if (status === 200) {
                this.imgBase64 = this.arrayBufferToBase64(data);
                this.setState({
                    init: true
                })
            } else {
                console.error(status)
                message.error("获取图片失败")
            }
        }).catch((err) => {
            console.error(err)
            message.error("获取图片请求报错")
        });
    }

    arrayBufferToBase64(buffer) {
        if(buffer?.byteLength>50000){
            let str = "";
            for(let i = 0;i<buffer.byteLength;i = i+50000){
                str+= String.fromCharCode(...new Uint8Array(buffer.slice(i,i + 50000)));
            }
            return window.btoa(str);
        }else{
            const str = String.fromCharCode(...new Uint8Array(buffer));
            return window.btoa(str);
        }
    }

    render(){
        let {init} = this.state;
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams || !init){
            return null
        }

        let stylesObj = {
            opacity: contentParams.opacity / 100,
            filter: `brightness(${contentParams.brightness / 100}) blur(${contentParams.blur || 0}px)`,
            backgroundImage: `url(data:image/png;base64,${this.imgBase64})`,
            backgroundSize: contentParams.backgroundImgSize === "diy"
                ?`${contentParams.backgroundImgSizeDiy.width}${contentParams.backgroundImgSizeDiy.widthUnit} ${contentParams.backgroundImgSizeDiy.height}${contentParams.backgroundImgSizeDiy.heightUnit}`
                :contentParams.backgroundImgSize,
            backgroundPosition:`${contentParams.bgImgPositionSet?.left}${contentParams.bgImgPositionSet?.leftUnit} ${contentParams.bgImgPositionSet?.top}${contentParams.bgImgPositionSet?.topUnit}`,
            borderRadius: window.changeRemNum(contentParams.radiusSet),
            cursor: contentParams.imageJumpUrl?"pointer":"unset"
        }

        return <div
            className={styles.imgBox}
            style={stylesObj}
            onClick={()=>{
                if(contentParams.imageJumpUrl && this.props.showType !== "dev"){
                    window.open(contentParams.imageJumpUrl,"_black")
                }
            }}
        >
        </div>
    }
}