import React from "react";
import {ViewContentArray} from "./view/definition";
import {WidgetContentArray} from "./widget/definition";
import {ParameterContentArray} from "./parameter/definition";
import {SystemComponentContentArray} from "./systemComponent/definition";

//转换成对象结构方便被索引
const AllContentMap = {};

function registerToMap(ContentArray,modeName){
  ContentArray.forEach((eachGroup)=>{
    eachGroup?.children?.forEach((eachContent)=>{
      eachContent.contentMode = modeName;
      eachContent.groupName = eachGroup.groupName;
      AllContentMap[eachContent.type] = eachContent;
    })
  })
}
registerToMap(ViewContentArray,"view");
registerToMap(WidgetContentArray,"widget");
registerToMap(ParameterContentArray,"parameter");
registerToMap(SystemComponentContentArray,"systemComponent");

export default AllContentMap;
export {ViewContentArray,WidgetContentArray,ParameterContentArray,SystemComponentContentArray,AllContentMap};