import React, {Component} from 'react';
import styles from "./WordCloud.less";
import LabelCloud from "../../../../../../components/labelCloud/LabelCloud";

export default class WordCloud extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    showValue(value) {
        this.lastData = _.cloneDeep(value);

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};
        if(this.labelCloud && this.labelCloud.ifReady()){
            if(Array.isArray(value)){
                let list = [];
                value.forEach((eachItem)=>{
                    if(typeof eachItem === "object"){
                        list.push({
                            label: eachItem[contentParams.labelField] || "字段未对接正确",
                            color: eachItem[contentParams.colorField] || null,
                        })
                    }else{
                        list.push({
                            label: eachItem,
                            color: null,
                        })
                    }
                })
                this.labelCloud.paint(list)
            }else{
                this.setState({
                    error: "格式不对：需要数组数据"
                })
            }
        }
    }

    render() {
        let {error} = this.state;
        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};
        return <div
            className={styles.outerBox}
        >
            <LabelCloud
                ref={ref=>this.labelCloud = ref}
                emptyMsg={"暂无数据"}
                speed={typeof contentParams.speed === "number"?contentParams.speed : 0.3}
                colorArray={contentParams.colorArray}
                colorSelType={contentParams.colorSelType}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}