import React from 'react';
import TablePageBase from "../../../base/ListPage";
import {message, Popover, Button, Popconfirm} from "antd";
import TimeHelp from "../../../../tool/TimeHelp";
import HttpTool from "../../../../tool/HttpTool";
import APILXD from "../../../../http/APILXD";

//仪表板历史记录
class SaveHistoryList extends TablePageBase {
    constructor(props) {
        super(props);

        this.apiUrlConfig = {};
        if(props.type === 1){
            message.warning("后台无法查询仪表板历史记录")
            this.apiUrlConfig = {
                getDashboardHistoryList: "",
                getDashboardHistory: "",
            }
        }else{
            this.apiUrlConfig = {
                getDashboardHistoryList: APILXD.getDashboardModelHistoryList,
                getDashboardHistory: APILXD.getDashboardHistory,
            }
        }
    }

    base_defaultStateData(){
        return {
            pageSize: 100
        }
    }

    base_getListConfig() {
        let defaultData = (this.props && this.props.post) || {};

        let url;
        if(this.props.type === 1){
            message.warning("后台无法查询仪表板历史记录")
            url =  "";
        }else{
            url =  APILXD.getDashboardModelHistoryList;
        }

        return {
            table: {
                columns: this.getTableColumns(),
                url: url,
                option: {},
                otherParam: {
                    _id: defaultData.cbid,
                }
            },
        }
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                width: 80,
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: '仪表板名称',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: '创建人',
                dataIndex: 'developer',
                key: 'developer',
                render: (text, record, index) => {
                    return record.developer?.nickname
                }
            },
            {
                title: '操作类型',
                dataIndex: 'saveType',
                key: 'saveType',
            },
            {
                title: '提交时间',
                dataIndex: 'addTime',
                key: 'addTime',
                width: 300,
                render: (text) => {
                    return <div>{TimeHelp.getYMDHM(text, true)}</div>;
                }
            },
            {
                title: '描述',
                dataIndex: 'descs',
                key: 'descs',
                render: (text, record, index) => {
                    return <div>
                        {
                            text?.length > 50?<Popover content={<div style={{wordBreak: "break-all", width: "500px"}}>{text}</div>}>{text.slice(0,50) + "..."}</Popover>:text
                        }
                    </div>
                }
            },
            {
                title: '操作',
                width: 200,
                render: (text, record, index) => {
                    return (<div>
                        <Popconfirm
                            title={"确定要还原到此版本么？还原操作会将仪表板的所有配置恢复到选择的时间点。"}
                            okText="确定"
                            cancelText="取消"
                            onConfirm={()=>{
                                this.getDashboardHistory(record)
                            }}
                        >
                            <Button
                                size={"small"}
                                type={"primary"}
                            >
                                还原到此版本
                            </Button>
                        </Popconfirm>
                    </div>)
                }
            },
        ]
    }

    //查询历史详细内容
    getDashboardHistory(record){
        this.setState({
            loading: true
        },()=>{
            let url;
            if(this.props.type === 1){
                message.warning("后台无法查询仪表板历史详情")
                url =  "";
            }else{
                url =  APILXD.getDashboardHistory;
            }
            HttpTool.post(url,(code,msg,json,option)=>{
                this.setState({
                    loading: false
                });
                this.props.recoveryData?.(json);
            },(code,msg)=>{
                message.error(msg);
                this.setState({
                    loading: false
                })
            },{_id:record._id})
        })
    }
}

export default SaveHistoryList