import React from "react";
import {
    Form,
    Select,
    Radio,
    AutoComplete,
    Collapse,
    InputNumber,
    Switch,
    Input
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";
import ColorArray from "../../../../formDiyItem/ColorArray";

const Item = Form.Item;

/**
 * 雷达图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }

    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"指标轴字段"}
                name={["dataSetConfig","indicatorField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"数据项字段"}
                name={["dataSetConfig","valueField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"系列名字段"}
                name={["dataSetConfig","seriesField"]}
                rules={[{required: false}]}
                extra={"若不指定系列名字段，则采用默认名称"}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"最大值字段"}
                name={["dataSetConfig","maxField"]}
                rules={[{required: false}]}
                extra={"可以填写字段名对接数据，也可以填写数字指定最大值。若不设定最大值，则自动根据数据值判断"}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请配置对接字段或填写数字"}
                    options={opts}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="radarSpecial"
            header="雷达图专用"
        >
            <Item
                label={"底板形状"}
                name={["radarSpecial","_radarType"]}
            >
                <Select>
                    <Select.Option
                        value="polygon"
                    >
                        多边形
                    </Select.Option>
                    <Select.Option
                        value="circle"
                    >
                        圆形
                    </Select.Option>
                </Select>
            </Item>
            <Item
                label={"底板内径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["radarSpecial","_radiusInner"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"底板外径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["radarSpecial","_radiusOuter"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"中心位置"}
            >
                <Item
                    label={"水平"}
                    name={["radarSpecial","_centerSetX"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
                <Item
                    label={"垂直"}
                    name={["radarSpecial","_centerSetY"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="axisName"
            header="指标轴名称"
        >
            <Item
                label={"是否显示"}
                name={["axisName","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"与轴距离"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["axisName","_nameGap"],{
                    min: 0,
                    max: 100
                })}
            </Item>
            <Item
                label={"名称颜色"}
                name={["axisName","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"名称大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["axisName","fontSize"],{
                    min: 5,
                    max: 32
                })}
            </Item>
            <Item
                noStyle
                shouldUpdate={true}
            >
                {({getFieldsValue,setFieldValue})=> {
                    return <>
                        <Item
                            label={"名称前缀"}
                            name={["axisName", "_axisBeforeTxt"]}
                            rules={[{required: false}]}
                        >
                            <Input
                                maxLength={20}
                                onChange={(e)=>{
                                    let allV = getFieldsValue(true);
                                    let t = `${allV.axisName._axisBeforeTxt || ""}{value}${allV.axisName._axisAfterTxt || ""}`
                                    setFieldValue(["axisName","formatter"],t)
                                }}
                            />
                        </Item>
                        <Item
                            label={"名称后缀"}
                            name={["axisName", "_axisAfterTxt"]}
                            rules={[{required: false}]}
                        >
                            <Input
                                maxLength={20}
                                onChange={(e)=>{
                                    let allV = getFieldsValue(true);
                                    let t = `${allV.axisName._axisBeforeTxt || ""}{value}${allV.axisName._axisAfterTxt || ""}`
                                    setFieldValue(["axisName","formatter"],t)
                                }}
                            />
                        </Item>
                    </>
                }}
            </Item>
            <Item
                hidden={true}
                label={"刻度标签模板"}
                name={["axisName", "formatter"]}
            >
                <Input
                    maxLength={20}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="axisSplit"
            header="指标轴刻度"
        >
            <Item
                label={"分割段数"}
                name={["axisSplit","_splitNumber"]}
            >
                <InputNumber
                    min={0}
                    max={100}
                    step={1}
                    precision={0}
                />
            </Item>
            <Item
                label={"刻度轴颜色"}
                name={["axisSplit","_allColor"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"显示刻度"}
                name={["axisSplit","axisTick","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"刻度长"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["axisSplit","axisTick","length"],{
                    min: 1,
                    max: 20
                })}
            </Item>
            <Item
                label={"显示轴线"}
                name={["axisSplit","axisLine","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"末端图形"}
                name={["axisSplit","axisLine","symbol"]}
            >
                <Select>
                    <Select.Option
                        value="none"
                    >
                        无图形
                    </Select.Option>
                    <Select.Option
                        value="arrow"
                    >
                        箭头
                    </Select.Option>
                    <Select.Option
                        value="circle"
                    >
                        圆形
                    </Select.Option>
                    <Select.Option
                        value="triangle"
                    >
                        三角形
                    </Select.Option>
                    <Select.Option
                        value="diamond"
                    >
                        菱形
                    </Select.Option>
                    <Select.Option
                        value="pin"
                    >
                        水滴
                    </Select.Option>
                </Select>
            </Item>
            <Item
                label={"图形大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["axisSplit","axisLine","_symbolSize"],{
                    min: 5,
                    max: 50
                })}
            </Item>
            <Item
                label={"显示刻度标签"}
                name={["axisSplit","axisLabel","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"标签颜色"}
                name={["axisSplit","axisLabel","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"标签大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["axisSplit","axisLabel","fontSize"],{
                    min: 5,
                    max: 50
                })}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="splitStyle"
            header="分割区域样式"
        >
            <Item
                label={"显示分割线"}
                name={["splitStyle","splitLine","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"分割线类型"}
                name={["splitStyle", "splitLine", "_type"]}
            >
                <Select
                >
                    <Select.Option value="solid">实线</Select.Option>
                    <Select.Option value="dashed">虚线</Select.Option>
                    <Select.Option value="dotted">点线</Select.Option>
                </Select>
            </Item>
            <Item
                label={"分割线颜色"}
                name={["splitStyle","splitLine","_color"]}
            >
                <ColorArray
                    max={10}
                    hasOpacity={true}
                />
            </Item>
            <Item
                label={"分割线宽度"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["splitStyle","splitLine","_width"],{
                    min: 1,
                    max: 50
                })}
            </Item>
            <Item
                label={"显示分割区域"}
                name={["splitStyle","splitArea","show"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"区域颜色"}
                name={["splitStyle","splitArea","_color"]}
            >
                <ColorArray
                    max={10}
                    hasOpacity={true}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataItemStyle"
            header="数据系列样式"
            extra={
                <Item
                    noStyle={true}
                    name={["dataItemStyle", "_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <Item
                noStyle={true}
                dependencies={["dataItemStyle","_setType"]}
            >
                {({getFieldValue})=> {
                    let setType = getFieldValue(["dataItemStyle", "_setType"]);

                    return <>
                        <Item
                            noStyle={true}
                            hidden={setType !== "normal"}
                        >
                            <Item
                                label={"标记图形"}
                                name={["dataItemStyle", "symbol"]}
                            >
                                <Select
                                >
                                    <Select.Option value="none">无图形</Select.Option>
                                    <Select.Option value="circle">圆形</Select.Option>
                                    <Select.Option value="rect">方形</Select.Option>
                                    <Select.Option value="pin">水滴</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                label={"图形大小"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","symbolSize"],{
                                    min: 1,
                                    max: 30
                                })}
                            </Item>
                            <Item
                                label={"连线类型"}
                                name={["dataItemStyle", "lineStyle", "type"]}
                            >
                                <Select
                                >
                                    <Select.Option value="solid">实线</Select.Option>
                                    <Select.Option value="dashed">虚线</Select.Option>
                                    <Select.Option value="dotted">点线</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                label={"连线宽度"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","lineStyle","width"],{
                                    min: 0,
                                    max: 30
                                })}
                            </Item>
                            <Item
                                label={"连线透明度"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","lineStyle","_opacity"],{
                                    min: 0,
                                    max: 100
                                })}
                            </Item>
                            <Item
                                label={"面积透明度"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","areaStyle","_opacity"],{
                                    min: 0,
                                    max: 100
                                })}
                            </Item>
                        </Item>
                        <Item
                            noStyle={true}
                            hidden={setType !== "emphasis"}
                        >
                            <Item
                                label={"改变哪些属性"}
                                name={["dataItemStyle","_emphasisSel"]}
                            >
                                <Select
                                    mode={"tags"}
                                >
                                    <Select.Option value={"lineOpacity"}>连线透明度</Select.Option>
                                    <Select.Option value={"areaOpacity"}>面积透明度</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                noStyle={true}
                                dependencies={["dataItemStyle","_emphasisSel"]}
                            >
                                {({getFieldValue})=> {
                                    let selFields = getFieldValue(["dataItemStyle", "_emphasisSel"]);

                                    return selFields?.map((selField)=>{
                                        switch (selField){
                                            case "lineOpacity": return <Item
                                                label={"连线透明度"}
                                            >
                                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","_emphasis", "lineOpacity"],{
                                                    min: 0,
                                                    max: 100
                                                })}
                                            </Item>;
                                            case "areaOpacity": return <Item
                                                label={"面积透明度"}
                                            >
                                                {CommonFormItems.getSliderAndInputItemGroup(["dataItemStyle","_emphasis", "areaOpacity"],{
                                                    min: 0,
                                                    max: 100
                                                })}
                                            </Item>;
                                        }
                                    })
                                }}
                            </Item>
                        </Item>
                    </>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label", "_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <div style={{
                color: "darkorange",
                padding: "0 10px"
            }}>
                注意：当 [数据系列样式] 中 [标记图形] 选择 [无图形] 时，数据标签将不会展示。
            </div>
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="top">上</Select.Option>
                    <Select.Option value="bottom">下</Select.Option>
                    <Select.Option value="left">左</Select.Option>
                    <Select.Option value="right">右</Select.Option>
                    <Select.Option value="inside">内侧</Select.Option>
                    <Select.Option value="outside">外侧</Select.Option>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"legend"}
            header={"图例"}
        >
            {EchartComItem.getLegendItems()}
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: {
            indicatorField: null,
            valueField: null,
            seriesField: null,
            maxField: null,
        },
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            }
        },
        //图例
        legend: {
            seeMore: false,
            show: false,
            type: "plain",  //plain  scroll
            icon: "roundRect",
            _position: "top",
            width: "auto",
            height: "auto",
            orient: "horizontal",
            align: "auto",
            itemGap: 10,
        },
        //雷达图特殊
        radarSpecial: {
            seeMore: false,
            _radarType: "polygon",
            _radiusInner: 0,
            _radiusOuter: 75,
            _centerSetX: "50%",
            _centerSetY: "50%",
        },
        //标签
        label: {
            _setType: "normal",
            show: false,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "top",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //指标轴名称
        axisName: {
            show: true,
            color: null,
            fontSize: 12,
            _nameGap: 15,
        },
        //指标轴刻度
        axisSplit: {
            _splitNumber: 5,
            _allColor: "#aaaaaa",
            axisTick: {
                show: false,
                length: 5,
            },
            axisLine: {
                show: false,
                symbol: "none",
                _symbolSize: 15,
            },
            axisLabel: {
                show: false,
                fontSize: 12,
                color: "#bbbbbb"
            }
        },
        //分割区域样式
        splitStyle: {
            splitLine: {
                show: false,
                _type: "solid",
                _color: ["#cccccc"],
                _width: 1,
            },
            splitArea: {
                show: true,
                _color: ['#ffffff33','#ffffff22'],
            }
        },
        //数据系列样式
        dataItemStyle: {
            _setType: "normal",
            symbol: "none",
            symbolSize: 4,
            //连线
            lineStyle: {
                width: 1,
                type: "solid",
                _opacity: 100,
            },
            //面积
            areaStyle: {
                _opacity: 50,
            },

            _emphasisSel: ["areaOpacity"],
            _emphasis: {
                areaOpacity: 80,
            },
        }
    }
}

function getDemoCode() {
    return `
     1.最简单的例子，只有一个系列的数据，可以省略系列名称，将系列数据和对应的指标轴放在一起
        1.1 一共3个轴，每个轴一个数据，3个数据作为一个默认的系列。
      [
        {
            name: "播放量",
            value: 2300
        },
         {
            name: "评论数",
            value: 8
        },
         {
            name: "点赞量",
            value: 150
        }
      ]
        1.2 可以给每个轴设定一个最大值，以此消除不同的轴本身数量级的差异。
      [
        {
            name: "播放量",
            max: 10000,
            value: 2300,
        },
         {
            name: "评论数",
            max: 100,
            value: 8,
        },
         {
            name: "点赞量",
            max: 1000,
            value: 150,
        }
      ]
      
    2.也可以将 指标轴 和 系列数据分开
        2.1 
        {
            name: ["播放量","评论数","点赞量"],
            value: [2300,8,150]
        }
        2.2 设定最大值的写法
         {
            name: [
                    {
                    name: "播放量",
                    max: 10000,
                },
                 {
                    name: "评论数",
                    max: 100,
                },
                 {
                    name: "点赞量",
                    max: 1000,
                }
            ],
            value: [2300,8,150]
        }
    
    3. 多个系列的数据展示在同一个雷达图上，将系列数据和对应的指标轴放在一起
        3.1 一共3个轴，每个轴存在2个系列对应的指标数据。
          [
            {
                name: "播放量",
                value: [2300,1600]
            },
             {
                name: "评论数",
                value: [8,12]
            },
             {
                name: "点赞量",
                value: [150,260]
            }
        ]
        3.2 给多个系列命名，这对需要展示图例和标签时比较重要
         [
            {
                name: "播放量",
                value: [{series:"短视频A",value:2300},{series:"短视频B",value:1600}]
            },
             {
                name: "评论数",
                value: [{series:"短视频A",value:8},{series:"短视频B",value:12}]
            },
             {
                name: "点赞量",
                value: [{series:"短视频A",value:150},{series:"短视频B",value:260}]
            }
        ]
        
     4. 当存在多个系列时，每次对数据项目增加名称的写法十分繁琐，建议将数据与指标轴分开定义，如下
        4.1携带系列名称
           {
                name: ["播放量","评论数","点赞量"],
                value: [
                    {
                        series: "短视频A",
                        value: [2300,8,150]
                    },
                     {
                        series: "短视频B",
                        value: [1600,12,260]
                    }
                ]
            }
        4.2 当系列名称不重要时，可采用更简化的写法
          {
                name: ["播放量","评论数","点赞量"],
                value: [
                    [2300,8,150],
                    [1600,12,260]
                ]
            }
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}