import React from 'react';
import TimeHelp from "../../../../../tool/TimeHelp";
import {message, Popover, Button, Popconfirm,Tag} from "antd";
import {Table} from "antd";
import DataDefinitionFile from "../../dataDisposition/DataDefinitionFile";

class CustomVersionUpdate extends React.Component {
  constructor(props) {
    super(props);
    
    console.log(props)
    let custromRegisterMap = {};
    props.customRegisterData?.forEach((eachGroup)=>{
      eachGroup?.child?.forEach((eachCustom)=>{
        eachCustom.groupName = eachGroup.name;
        custromRegisterMap[eachCustom._id] = eachCustom;
      })
    })

    let list = [];

    props.allCustomComponentInDashboard?.forEach((eachUseCustom)=>{
      eachUseCustom.cloneFromNewMsg = custromRegisterMap[eachUseCustom?.contentMsg?.dataConfig?.cloneFrom?._id]
      
      list.push(eachUseCustom)
    })
    
    this.state = {
      list: list
    }
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '所属场景',
        dataIndex: 'sceneTitle',
        key: 'sceneTitle',
        render: (text)=>{
          return text || "场景未命名"
        }
      },
      {
        title: '内容标题',
        dataIndex: 'contentTitle',
        key: 'contentTitle',
        render: (text,record) => {
          return <div>
            {record?.contentMsg?.dataConfig?.contentTitle}
          </div>;
        }
      },
      {
        title: '当前版本信息',
        dataIndex: 'currV',
        key: 'currV',
        render: (text,record) => {
          let cloneFrom = record.contentMsg?.dataConfig?.cloneFrom;

          return <div>
            <div>版本：{cloneFrom.version}</div>
            <div>名称：{cloneFrom.name}</div>
            <div>视图类型：{DataDefinitionFile.getChartTypeIcon(record?.contentMsg.dataConfig.contentType)}</div>
            <div>所属分类：{cloneFrom.groupName || "-"}</div>
            <div>引用/升级时间：{cloneFrom.cloneTime?TimeHelp.getYMDHM(cloneFrom.cloneTime,true):"-"}</div>
          </div>;
        }
      },
      {
        title: '最新版本信息',
        dataIndex: 'newV',
        key: 'newV',
        render: (text,record) => {
          let cloneFromNewMsg = record.cloneFromNewMsg;
          if(!cloneFromNewMsg){
            return <div style={{color: "red"}}>已删除</div>
          }
          return <div>
            <div>版本：{cloneFromNewMsg.version}</div>
            <div>名称：{cloneFromNewMsg.name}</div>
            <div>视图类型：{DataDefinitionFile.getChartTypeIcon(cloneFromNewMsg?.contentMsg.dataConfig.contentType)}</div>
            <div>所属分类：{cloneFromNewMsg.groupName}</div>
            <div>更新时间：{TimeHelp.getYMDHM(cloneFromNewMsg.updateTime || cloneFromNewMsg.addTime, true)}</div>
            <div>更新人：{cloneFromNewMsg.updater?.nickname || "-"}</div>
          </div>;
        }
      },
      {
        title: '操作',
        width: 200,
        render: (text, record, index) => {
          let cloneFrom = record.contentMsg?.dataConfig?.cloneFrom;
          let cloneFromNewMsg = record.cloneFromNewMsg;

          if(cloneFrom.version === cloneFromNewMsg.version){
            return <Tag color={"green"}>已是最新版本</Tag>
          }

          return (<div>
            <Popconfirm
              title={"确定要升级到最新版本？升级操作将会同步所有配置，自定义的配置将会被覆盖。"}
              okText="确定"
              cancelText="取消"
              onConfirm={()=>{
                let newContentItemMsg = _.cloneDeep(record);

                newContentItemMsg.contentMsg = cloneFromNewMsg.contentMsg;
                newContentItemMsg.contentMsg.dataConfig.usage = record.contentMsg.dataConfig.usage;

                newContentItemMsg.contentMsg.dataConfig.cloneFrom = {
                  _id: cloneFromNewMsg._id,
                  version: cloneFromNewMsg.version,
                  name: cloneFromNewMsg.name,
                  groupName: cloneFromNewMsg.groupName,
                  updateTime: cloneFromNewMsg.updateTime || cloneFromNewMsg.addTime,
                  cloneTime: Date.now()
                }

                //之前引用的名称 和 当前容器名称一致，说明用户没有更改过名称，那么把名称也更新到最新的；如果用户编辑过，则不更新内容名称
                if(cloneFrom.name !== record.contentMsg.dataConfig.contentTitle){
                  newContentItemMsg.contentMsg.dataConfig.contentTitle = record.contentMsg.dataConfig.contentTitle;
                }

                delete newContentItemMsg.cloneFromNewMsg;

                this.props.onUpdate?.(newContentItemMsg)
              }}
            >
              <Button
                size={"small"}
                type={"primary"}
              >
                升级到最新版本
              </Button>
            </Popconfirm>
          </div>)
        }
      },
    ]
  }

  render(){
    return <Table
      title={()=>{return <div style={{fontSize: "18px"}}>使用中的自定义组件列表</div>}}
      columns={this.getTableColumns()}
      dataSource={this.state.list}
      pagination={false}
    />
  }
}

export default CustomVersionUpdate