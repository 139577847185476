import React, {Component} from 'react';

import {Form,Row,Col,message} from 'antd';
import _ from 'lodash';

import Config from "./Config.js";
import style from './style/index.less';
import HttpTool from "../../tool/HttpTool";

const FormItem = Form.Item;

let prefix = "mylib-layout";

/**
 * SearchBox等外部组件onChange 触发时修改其它 参数的 事例
 *
 * value：当前组件收集到的值
 * state：layout的状态机
 * changeState：用于修改state
 *
 * onChange: (value,state,changeState)=>{
        let newArr = state.parameterArr;
        newArr.forEach((obj) => {
            if (obj.field === 'spaceId') {
                obj.apiConfig.param._id = value || null;
                this.TcpFunc.sendTcp("/user/teamMarket/getMTLeaseSpace",{
                    _id:  value || null
                },(code,msg,json)=>{
                    let data = []
                    json.forEach((item) => {
                        data.push({
                            title: item.name,
                            value: item._id
                        });
                    });
                    obj.ref.state.dataList = data;  //修改列表数据
                    obj.resultValue = null;     //修改表单已经收集到的值
                    //forceUpdate是每个组件自己去实现，参考select.js
                    obj.ref.state.forceUpdate = obj.ref.state.forceUpdate + 1;//重置组件，清空已选择
                    changeState('parameterArr', newArr);
                },(code,msg)=>{
                    message.warning(msg)
                })
            }
        });
        changeState('parameterArr', newArr);
    },
 */

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameterArr: this.props.parameterArr,
            loadAPI: false,
            resetCount: 0,
            upDate: 0,
        }


    }

    //修改状态机
    changeState(field,result,cb){
        if(!field){
            return;
        }

        this.setState({
            [field]:result,
        },cb)
    }


    showLoadAPi(loadAPI, loadAPIError, cb) {

        //更新API

        //通知外部，加载中

        this.setState({
            loadAPI
        }, () => {
            !this.props.load || this.props.load({
                loadAPI,
                loadAPIError
            });
            !cb || cb();
        });

    }

    componentDidMount() {
        this.showLoadAPi(true, null, () => {
            setTimeout(() => {
                this.fillDataForNet();
            }, 0)
        })
    }

    fillDataForNet() {
        //获取需要由API填充的属性，进行填充
        let arr = this.state.parameterArr
        let data = [];
        for (let i = 0; i < arr.length; i++) {
            let obj = arr[i];
            if (obj && obj.apiData) {
                obj._index = i;//记录下标
                let {apiCode, apiData, field, name, _index, type} = obj;
                data.push({apiCode, apiData, field, name, _index, type})
            }
        }
        if (data && data.length > 0) {
            // HttpTool.post("/base-basedata/dataapi/dictionarys/screen/conditions",
            //     (code, msg, json, option) => {
            //         for (let obj of json) {
            //             arr[obj._index].data = obj.data;
            //         }
            //         this.oldParameterArr = _.merge([], arr);
            //         this.state.parameterArr = arr;
            //         this.showLoadAPi(false)
            //     }, (code, msg, option) => {
            //         //不可选择
            //         this.oldParameterArr = _.merge([], arr);
            //         this.showLoadAPi(false, "高级搜索错误:" + msg)
            //     }, {
            //         "data": data
            //     });
        } else {
            this.oldParameterArr = _.merge([], arr);
            this.showLoadAPi(false)
        }
    }

    disableLayout(disable, cb) {
        this.setState({
            loadAPI: disable
        }, cb);
    }

    getLayoutValue(verification) {

        //通知UI校验
        let errorCount = 0;
        if (verification) {

            for (let ref of this.layoutItemRef) {
                if (!ref.verification()) {
                    errorCount += 1;
                }
            }
        }
        let parameter = {};
        for (let obj of this.state.parameterArr) {

            if (obj.ref && obj.ref._mergeParameter) {
                //如果存在自定义的值，去采信息
                obj.ref._mergeParameter(parameter, obj.resultValue);
            } else {
                if (obj.field) {
                    parameter[obj.field] = obj.resultValue;
                } else {
                    //不收集
                }
            }

        }
        return {
            error: errorCount > 0,
            errorCount,
            parameter
        };

    }

    resetLayoutValue(cb) {

        if (this.oldParameterArr) {
            this.setState({
                parameterArr: _.merge([], this.oldParameterArr),
                resetCount: this.state.resetCount + 1,
            }, cb);

        }


    }

    render() {
        return (
          <div
            key={this.state.resetCount}
            className={style.outerBox}
          >
              {
                  this.props.extraContent && this.props.extraContent.topContent?this.props.extraContent.topContent(this.state, this.changeState.bind(this)):null
              }
              {this.getViewLayout()}
              {
                  this.props.extraContent && this.props.extraContent.bottomContent?this.props.extraContent.bottomContent(this.state, this.changeState.bind(this)):null
              }
          </div>
        );
    }

    getViewLayout() {
        let colCount = this.props.colCount;
        this.layoutItemRef = [];
        let rowArr = [];
        let arr = this.state.parameterArr.concat();

        arr.forEach((obj,key)=>{
            if(!obj){
                return;
            }
            if(!obj.field){
                if(obj.stayAnyway){
                    rowArr.push(<Col key={key} span={24 / (obj.col?colCount/obj.col:colCount)}></Col>);
                }
                return;
            }

            rowArr.push(<LayoutItem
              ref={(ref) => {
                  if (ref) {
                      this.layoutItemRef.push(ref);
                  }

              }}
              layoutState={this.state}
              key={obj.field + key}
              data={obj}
              colCount={colCount}
              loadAPI={this.state.loadAPI}
              formItemLayout={obj.formItemLayout || this.props.formItemLayout}
              onChange={(value,func)=>{
                  //把获取的值，状态机，和修改状态机的方法都传出去
                  this.setState({
                      upDate: this.state.upDate + 1
                  })
                  if(this.props.onChange){
                      this.props.onChange(this.state,this.changeState.bind(this));
                  }
                  func&&func(value,this.state,this.changeState.bind(this));
              }}
            />)
        })

        return (
          <Form
            layout={"horizontal"}

          >
              <Row>
                  {rowArr}
              </Row>
          </Form>
        )
    }


}

class LayoutItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            help: "",
            validateStatus: "",
            upView: 0,
        }
        this.tempObject = {};
    }

    upDate(cb) {
        this.setState({
            upView: this.state.upView + 1
        }, cb);
    }

    render() {
        let {data, colCount, formItemLayout} = this.props;

        //如果子项的formItemLayout存在,以子荐为标准
        !data.formItemLayout || (formItemLayout = data.formItemLayout)

        if(data.col){
            colCount = colCount/data.col;
        }

        return (<Col span={24 / colCount} className={style[prefix + "-item"]}>
              <FormItem
                extra={data?.extra || null}
                tooltip={data?.tooltip || null}
                key={this.state.upView}
                {...formItemLayout}
                required={data.required}
                style={{width: "100%"}}
                label={data.name}
                colon={true}
                validateStatus={this.state.validateStatus}
                help={this.state.help}
              >
                  {this.clearPasswordSave(data)}
              </FormItem>
          </Col>
        );
    }

    clearPasswordSave(data) {
        let view = this.getRightType(data);
        let props = {style: {display: "none", width: 0, height: 0, zIndex: -999999}}
        if (data && data.type === "input" && data.option && data.option.type === "password") {
            return (
              <div>
                  <input type="text" {...props} />
                  <input type="password" {...props} />
                  {view}
                  <input type="text" {...props} />
                  <input type="password" {...props} />
              </div>
            )

        } else {
            return view
        }

    }

    componentDidMount() {
        let {data} = this.props;
        if (data && data.option && data.option.hasOwnProperty("defaultValue")) {
            //默认选择值
            if (data.ref && data.ref._initDefaultValue) {
                //执行初始化值方案
                data.resultValue = data.ref._initDefaultValue(data);
            } else {
                if (typeof (data.option.defaultValue) === "string") {
                    data.resultValue = data.option.defaultValue;
                } else {
                    //无初始化值方案，
                    data.resultValue = data.option.defaultValue;
                    // console.warn("Layout 子项【"+data.type +"】组件无初始值方案 defaultValue = undefined,请添加_initDefaultValue（data）=>{}")
                }
            }

        }
        if (data.apiConfig) {
            //url param apiType in data.data
            this.fillDataForNet(data)

        }
    }

    fillDataForNet(data) {
        //获取需要由API填充的属性，进行填充

        let apiConfig = data.apiConfig;
        if (!apiConfig.url) {
            console.error("please add url for apiConfig")
        }

        HttpTool.post(apiConfig.url,
          (code, msg, json, option) => {
              data.data = apiConfig.fillObject ? apiConfig.fillObject(json) : json;
              this.upDate(() => {

              });
          }, (code, msg, option) => {
              message.error(apiConfig.url + " 错误:" + msg)
          },apiConfig.param);
    }

    setHelpState(validateStatus, help, cb) {
        this.setState({
            validateStatus: validateStatus,
            help: help
        }, cb);
    }

    verValue(data, value, layoutState) {
        if (value===undefined||value===null) {
            value = ""
        }
        let state = false;
        //其他校对

        if (data.ref && data.ref._verParameter) {
            try {
                state = data.ref._verParameter(data, layoutState);
            } catch (e) {
                console.error(e)
            }
        } else {

            //字符串校对
            if (typeof data.reg == 'function') {
                state = data.reg(value, layoutState);
            } else {
                state = data.reg.test(value);
            }

            // console.error("please  add "+data.verMethod+" method for "+data.type)
            // console.error("请在"+data.type+"中添加"+data.verMethod+"方法")
        }


        return state;
    }

    verification(data, value, layoutState) {
        if (!data) {
            data = this.props.data;
            value = this.props.data.resultValue;
            layoutState = this.props.layoutState;
        }

        if (data.ver && data.reg) {
            if (this.verValue(data, value, layoutState)) {
                this.setHelpState("success", "");
                data.resultValue = value;

            } else {
                this.setHelpState("error", data.verMessage);
                data.resultValue = value;
                return false;
            }
        } else {
            data.resultValue = value;
        }

        this.props.onChange(data.resultValue,data.onChange);
        return true;

    }

    getRightType(data) {

        let option = _.merge({}, data.option) || {};
        if (!option.placeholder) {
            let preText = '请输入';
            if(['select','Select','selectAll','SelectAll','datePicker','DatePicker','rangePicker',
                'RangePicker','radio','Radio','checkbox','Checkbox'].indexOf(data.type)>=0){
                preText = '请选择';
            }

            option.placeholder = preText + data.name;
        }
        if (!option.maxLength) {
            option.maxLength = 50;
        }
        //加载中，禁用输入框 如果本身是禁用，一直禁用
        option.disabled = this.props.loadAPI || option.disabled;


        let props = {
            option: option,
            data: data,
            verification: (data, value) => {
                //2022.11.14 新增 this.props.layoutState 参数 ，用于验证的时候获取完整form数据
                this.verification(data, value, this.props.layoutState)
            }
        };
        let arr = Config.getViewArr(props);

        //查打匹配的类型
        for (let obj of arr) {
            let has = (typeof (obj.type) === "string") ? obj.type === data.type : obj.type.indexOf(data.type) >= 0
            if (has) {
                let View = obj.component;
                return <View
                  ref={(ref) => {
                      data.ref = ref;
                  }}
                  {...props}/>;
            }
        }
        //添加额外扩展
        if (data.component) {
            let View = data.component;
            return <View
              ref={(ref) => {
                  data.ref = ref;
              }}
              {...props}/>;
        }
        //不存在，执行
        return (
          <div className={style[prefix + "-text"]}>
              {option.defaultValue && option.defaultValue.toString()}
          </div>);
    }


}

Index.defaultProps = {
    defaultShow: false,
    colCount: 4,
    parameterArr: [],
    formItemLayout: {
        labelCol: {span: 8},
        wrapperCol: {span: 16, offset: 0},
    }
};

export default Index;