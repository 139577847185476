import React from "react";
import styles from "./DashboardLayoutSet.less";
import {
    Select,
    Space,
    Button,
    Tabs,
    Empty,
    Popover,
    Modal,
    message,
    Drawer,
    Switch,
    Spin,
    InputNumber,
    Dropdown,
    Menu,
    Input,
    Tooltip,
    Tag
} from "antd";
import {
    PlusCircleOutlined,
    ControlOutlined,
    PlusOutlined,
    SaveOutlined,
    PieChartOutlined,
    BuildOutlined,
    AppstoreAddOutlined,
    CopyOutlined,
    EditOutlined,
    SettingOutlined,
    DeleteOutlined,
    UploadOutlined,
    RollbackOutlined,
    ReloadOutlined,
    SendOutlined,
    ClearOutlined,
    FieldTimeOutlined,
    VerticalAlignTopOutlined,
    VerticalAlignBottomOutlined,
    MobileOutlined,
    DesktopOutlined,
    QuestionCircleOutlined,
    BlockOutlined,
    RocketOutlined,
    EllipsisOutlined,
    GiftOutlined,
    ToolOutlined
} from "@ant-design/icons";
import HttpTool from "../../../tool/HttpTool";
import GridLayout from "react-grid-layout";
import {WidthProvider} from "react-grid-layout";
import './reactGridLayout/layoutStyles.css';
import './reactGridLayout/resizableStyles.css';
import classnames from "classnames";
import ModalBase from "../../../components/modalBase/index";
import DashboardConfigSet from "./configSet/DashboardConfigSet";
import SceneConfigSet from "./configSet/SceneConfigSet";
import DefaultThemeConfig from "./DefaultThemeConfig";
import ContentStyleConfigSet from "./configSet/ContentStyleConfigSet";
import DataDisposition from "./dataDisposition/DataDisposition";
import BaseSetItem from "./configSet/BaseSetItem";
import ContentView from "./contentView/ContentView";
import BackgroundBgDiv from "./contentView/BackgroundBgDiv";
import SaveHistoryList from "./history/SaveHistoryList";
import LocalStorage from '../../../tool/LocalStorage';
import NavConfigSet from "./configSet/NavConfigSet";
import ViewTypeSel from "./dataDisposition/ViewTypeSel";
import DataDefinitionFile from "./dataDisposition/DataDefinitionFile";
import ModalConfig from "../../../components/modalConfig/index";
import FiltersIndexSet from '../../../components/indexSet/FiltersIndexSet';
import BoxDescSet from "./configSet/BoxDescSet";
import CustomComponentSel from "./dataDisposition/CustomComponentSel";
import {UsageToCodeMap,CodeToUsageMap} from "./contentView/customComponent/UsageDictionary";
import CustomVersionUpdate from "./contentView/customComponent/CustomVersionUpdate";
import _ from "lodash";
import DashboardThemeManage from "./DashboardThemeManage";
import html2canvas from "html2canvas";
import UploadHelp from '../../../components/upLoad/UploadHelp';
import APILXD from "../../../http/APILXD";

const MB = new ModalBase();
const Option = Select.Option;

const Emitter = require('component-emitter');
const ResponsiveGridLayout = WidthProvider(GridLayout);
const DefaultSizeConfig = {
    x: 25,
    y: 24,
    w: 10,
    h: 8,
    minW: 1,
    minH: 1,
    static: false,
}
const HalfX = 25;

const ContentItemSaveKey = "ContentItemSaveKey";//复制容器的时候缓存key
const DashboardConfigTempSaveKey = "DBCTSK";//仪表板配置缓存key，用于手机演示窗口获取数据
const MobileDemoPageSize = "MDPS";//打开窗口大小

//选择主题时，需要修复缺省的样式字段(用来兼容老的主题少样式问题)
const NeedMergeFieldsArr = ["commonSet", "dashboardBackgroundSet", "contentBackgroundSet", "contentSet", "contentTitle", "contentFont", "sceneSet", "sceneBackgroundSet", "titlePlus", "navigationBarBgSet", "navigationBarSet"];
//每个场景的缺省字段配置
const SceneMergeFields = ["commonSet", "sceneBackgroundSet", "contentBackgroundSet", "contentSet", "contentTitle", "contentFont", "titlePlus"];


export default class DashboardLayoutSet extends React.Component {
    constructor(props) {
        super(props);

        let urlParams = window.getUrlSearch(window.location.href);
        this.dashboardId = urlParams.id;
        this.type = Number(urlParams.type) || 1;    //1仪表板  2模板

        this.state = {
            usage: null,
            title: null,
            allConfig: null,
            dashboardDetail: null, //其它信息，如公司id、执行单元id
            allTheme: [],

            forceUpdate: 0, //控制强刷

            loading: false,
            currentSceneId: null,   //当前编辑的场景id
            currentSelContentId: null, //当前选中的容器id
            showGrade: false,   //动态高度计算出来之前，不渲染布局
            openEditContent: false, //编辑容器
            currentContentInDyTempId: null, //当前正在编辑的动态数组中的容器id
            openDashboardConfigSet: false,  //是否编辑仪表板配置
            openSceneConfigSet: false,  //是否编辑场景配置
            openNavConfigSet: false,  //是否导航样式

            currentSelectBox: null,//当前选中的容器

            allCustomComponentInDashboard: [],    //所有使用的自定义组件
            customRegisterData: null,    //注册在服务端的自定义组件数据
        }

        this.urlParams = window.getUrlSearch(window.location.href);

        this.BaseSetItem = new BaseSetItem();

        //暂存编辑中的容器信息
        this.currentEditContentMsg = null;
        //暂存编辑中的容器
        this.currentEditContent = null;
        //暂存编辑中的场景配置
        this.currentEditSceneConfig = null;

        //控制所有容器的重新渲染，但不是重新初始化
        this.allContentUpdateMap = {}
        //控制所有容器的重新初始化
        this.allContentRebuildMap = {}
        //控制所有容器数据重新请求
        this.allContentReloadMap = {}

        //是否是windows系统
        this.isWin = (window.navigator.platform === "Win32") || (window.navigator.platform === "Windows");

        this.handleKeyPress = this.handleKeyPressAction.bind(this);

        //容器与容器之间消息处理池
        this.boxMsgEmitter = new Emitter();
        //场景容器容器div
        this.scendContainerRef = null;

        this.uploadHelp = new UploadHelp();
        this.apiUrlConfig = {}
        this.typeName= "";
        if(this.type === 1){
            message.warnings("后台无法配置仪表板");
            //仪表板接口
            this.typeName= "仪表板";
            this.apiUrlConfig = {
                getDashboardDetail: "",
                updateDashboard: ""
            }
        }else{
            //模板接口
            this.typeName= "模板";
            this.apiUrlConfig = {
                getDashboardDetail: APILXD.getDashboardModelDetail,
                updateDashboard: APILXD.updateDashboardModel
            }
        }
    }

    componentDidMount() {
        if(this.type === 1){
            return;
        }


        this.loadAllTheme()

        this.loadDashboardDetail()

        if (window.location.href.indexOf("http://192.168.") === -1 && window.location.href.indexOf("http://localhost") === -1 && window.location.href.indexOf("http://127.0.0.1") === -1) {
            //非开发模式
            window.addEventListener("beforeunload", function (e) {
                //这个文案没有生效？？？
                let confirmationMessage = "关闭页面前，要记得保存！";
                (e || window.event).returnValue = confirmationMessage; // 兼容 Gecko + IE
                return confirmationMessage; // 兼容 Gecko + Webkit, Safari, Chrome
            });
        }

        window.onresize = () => {
            this.resizeAction && clearTimeout(this.resizeAction);
            this.resizeAction = setTimeout(() => {
                console.log("屏幕大小改变：" + this.dashboardId)
                this.calcBoxHeight();
                this.forceRender();
            }, 800);
        }
    }

    componentWillUnmount() {
        this.resizeAction && clearTimeout(this.resizeAction);
    }

    /**
     * 统一的请求展示交互，目前是loading
     * @param path          请求地址
     * @param param         请求参数
     * @param successCB     成功的回调
     * @param failureCB     失败的回调
     * @param showAction    是否展示交互，默认打开
     */
    commonRequest(path, param, successCB, failureCB, showAction = true) {
        if (!showAction) {
            HttpTool.post(path, successCB, failureCB,param);
        } else {
            let newSuccessCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    successCB && successCB(code, msg, obj, option);
                })
            };
            let newFailureCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    failureCB && failureCB(code, msg, obj, option);
                })
            };

            this.setState({
                loading: true
            }, () => {
                HttpTool.post(path, newSuccessCB, newFailureCB,param);
            })
        }
    }

    //计算容器高度
    calcBoxHeight() {
        // console.log("计算容器")
        // console.log(this.contentBox)
        // console.log(this.contentBox.clientHeight)
        //50行 * 50列
        //中间有49个间隙,两边有2个padding 每个间隙或padding 0px，以此计算出每行高度
        if (this.contentBox) {
            if((!this.contentBox.clientHeight || !this.contentBox.clientWidth) && this.eachLineHeight){
                this.setState({
                    showGrade: true
                })
            }else{
                this.eachLineHeight = Math.floor((this.contentBox.clientHeight - 0 * 51) / 5) / 10;
                this.eachColWidth = Math.floor((this.contentBox.clientWidth - 0 * 51) / 5) / 10;
                this.setState({
                    showGrade: true
                })
            }
        }
    }

    //所有主题
    loadAllTheme() {
        this.commonRequest(APILXD.getChartThemeListAll, {
            type: 1
        }, (code, msg, json) => {
            this.setState({
                allTheme: json
            })
        }, (code, msg, json) => {
            message.error(msg)
        })
    }

    //请求详情
    loadDashboardDetail() {
        this.commonRequest(this.apiUrlConfig.getDashboardDetail, {
            _id: this.dashboardId
        }, (code, msg, json) => {
            console.log("BashboardDetail", json)
            document.title = this.typeName + ":" + json.title;
            let allConfig = json.data;
            if (!allConfig || JSON.stringify(allConfig) === "{}") {
                //没有编辑过
                allConfig = {
                    //场景数组
                    sceneArray: [],
                    //仪表板主题配置
                    themeConfig: _.cloneDeep(DefaultThemeConfig),
                    //选择的主题
                    selectTheme: {
                        _id: null,
                        title: null,
                    },
                }
            }
            if (Object.keys(allConfig.themeConfig).length !== NeedMergeFieldsArr.length) {
                //缺少配置
                allConfig.themeConfig = this.BaseSetItem.mergeFinallyConfig(allConfig.themeConfig, DefaultThemeConfig, NeedMergeFieldsArr)
            }

            this.setState({
                usage: CodeToUsageMap[json.businessType],
                title: json.title,
                allConfig: allConfig,
                dashboardDetail: json,
                currentSceneId: allConfig.sceneArray[0]?._id || null,
            }, () => {
                this.calcBoxHeight()
                this.filterAllCustomComponentFromDashboard();
                this.loadCustomRegisterData()
            })
        }, (code, msg) => {
            Modal.error({
                title: "提示",
                content: `请求${this.typeName}详情失败：` + msg
            })
        })
    }

    //过滤出引用的所有自定义组件
    filterAllCustomComponentFromDashboard(){
        let {allConfig} = this.state;

        let allCustomComponentInDashboard = [];
        allConfig?.sceneArray?.forEach((eachScene)=>{
            eachScene.contentArray?.forEach((eachContent)=>{
                if(eachContent.contentMsg?.contentMode === "customComponent"){
                    let contentClone = _.cloneDeep(eachContent);
                    contentClone.sceneId=eachScene._id;
                    contentClone.sceneTitle=eachScene.title;
                    allCustomComponentInDashboard.push(contentClone)
                }
            })
        })

        this.setState({
            allCustomComponentInDashboard: allCustomComponentInDashboard
        })
    }

    //请求注册在服务器的自定义组件最新信息
    loadCustomRegisterData(){
        console.log("this.state",this.state)
        if(this.state.usage){
            HttpTool.post(APILXD.getModuleCategoryAndModuleList,(code,msg,json)=>{
                this.setState({
                    customRegisterData: json
                })
            },(code,msg)=>{
                message.error(msg);
            },{
                businessType: UsageToCodeMap[this.state.usage]
            })
        }
    }

    //重新渲染
    forceRender() {
        this.setState({
            forceUpdate: this.state.forceUpdate + 1
        })
    }

    handleKeyPressAction(e) {
        // console.log(e.keyCode)
        if (e.keyCode === 13) {
            let {currentSelContentId, currentSceneId, allConfig} = this.state;

            if (!currentSelContentId) {
                message.warning("请选择容器再编辑");
                return;
            }

            let sceneItemMsg = this.findSceneById(currentSceneId)

            if (!sceneItemMsg) {
                message.error("出错：找不到正在编辑的场景");
                return;
            }

            let eachContent = this.findContentByUid(currentSelContentId)

            if (!eachContent) {
                message.error("出错：找不到容器内容");
                return;
            }

            //编辑
            this.currentEditContent = null;
            this.currentEditContentFromScene = null;
            this.currentEditContentMsg = null;
            this.setState({
                openEditContent: false,
            }, () => {
                this.currentEditContent = eachContent;
                this.currentEditContentFromScene = sceneItemMsg;
                //普通容器
                this.currentEditContentMsg = eachContent.contentMsg;
                this.setState({
                    openEditContent: true,
                    openEditContentPlacement: eachContent.sizeConfig.x > HalfX ? "left" : "right",
                })
            })
        }
        if (e.keyCode === 27) {
            //ESC按钮，取消任何
            this.currentEditContentMsg = null;
            this.currentEditContent = null;
            this.currentEditSceneConfig = null;
            this.setState({
                currentSelContentId: null, //当前选中的容器id
                openEditContent: false, //编辑容器
                openSceneConfigSet: false,
                openDashboardConfigSet: false,
                currentSelectBox: null
            })
        }
        if (e.keyCode === 46) {
            //删除
            let {currentSelContentId, currentSceneId, allConfig} = this.state;
            if (!currentSelContentId) {
                message.warning("请选择容器再删除");
                return;
            }
            let sceneItemMsg = this.findSceneById(currentSceneId)

            if (!sceneItemMsg) {
                message.error("出错：找不到正在编辑的场景");
                return;
            }

            this.removeOneContent(sceneItemMsg, currentSelContentId)
        }

        if (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) {
            //复制  c
            let {currentSelContentId} = this.state;
            if (!currentSelContentId) {
                message.warning("请选择容器再复制");
                return;
            }

            let contentItem = this.findContentByUid(currentSelContentId)

            if (!contentItem) {
                message.error("出错：找不到容器内容");
                return;
            }


            console.log("复制", contentItem)

            let copyItem = _.cloneDeep(contentItem);

            delete copyItem?.contentMsg?.beConnectedConfig;
            delete copyItem?.contentMsg?.connectConfig;
            LocalStorage.set(ContentItemSaveKey, copyItem);
            message.success("已缓存容器信息");
        }
        if (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) {
            //粘贴  v

            let {currentSceneId,currentSelectBox} = this.state;

            let sceneItemMsg = this.findSceneById(currentSceneId)

            let saveItem = LocalStorage.get(ContentItemSaveKey);
            if (!saveItem) {
                message.warning("缓存中无容器信息");
                return;
            }
            let copyItem = _.cloneDeep(saveItem);

            copyItem.uid = window.getUID();
            if(currentSelectBox){
                //当前有选择的容器，放到这个容器边上
                // console.log("currentSelectBox",currentSelectBox)
                copyItem.sizeConfig.x = currentSelectBox.sizeConfig.x + 2;
                copyItem.sizeConfig.y = currentSelectBox.sizeConfig.y + 2;
            }else{
                if(this.scendContainerRef && this.scendContainerRef.clientHeight && this.scendContainerRef.scrollTop){
                    //拿到了容器div索引,且产生了滚动
                    // console.log(this.scendContainerRef.clientHeight)
                    // console.log(this.scendContainerRef.scrollHeight)
                    // console.log(this.scendContainerRef.scrollTop)
                    copyItem.sizeConfig.y = DefaultSizeConfig.y + Math.ceil(DefaultSizeConfig.y * 2 * this.scendContainerRef.scrollTop/this.scendContainerRef.clientHeight)
                }else{
                    copyItem.sizeConfig.y = DefaultSizeConfig.y;
                }
                copyItem.sizeConfig.x = DefaultSizeConfig.x;
            }
            this.addOneContent(sceneItemMsg, copyItem);
            message.success("粘贴容器到场景");
        }
    }

    //通过id查找场景
    findSceneById(sceneId) {
        let {allConfig} = this.state;

        let sceneItemMsg;
        allConfig?.sceneArray?.forEach((scene) => {
            if (scene._id === sceneId) {
                sceneItemMsg = scene;
            }
        })

        return sceneItemMsg;
    }

    //通过UID查找容器
    findContentByUid(contentUid) {
        let {allConfig} = this.state;

        let contentItem;
        allConfig?.sceneArray?.forEach((scene) => {
            scene?.contentArray?.forEach((content) => {
                if (content.uid === contentUid) {
                    contentItem = content;
                }
            })
        })

        return contentItem;
    }

    render() {
        let {forceUpdate, allTheme, openSceneConfigSet, openDashboardConfigSet, openEditContent, openEditContentPlacement, showGrade, loading, allConfig, currentSceneId, openNavConfigSet,customRegisterData,title} = this.state;

        if (!allConfig || !this.dashboardId || this.type === 1) {
            return null
        }


        let {sceneArray, selectTheme} = allConfig;
        return <Spin
            size={"large"}
            spinning={loading}
            wrapperClassName={styles.spinBoxStyle}
        >
            <div
              className={styles.outerLayoutBox}
              ref={(ref)=>{this.dashboardOuterLayoutBox = ref}}
            >
                {/* 导航栏样式设置 */}
                <Drawer
                    getContainer={false}
                    style={{position: 'absolute'}}
                    drawerStyle={{backgroundColor: "#2e3244"}}
                    placement={"right"}
                    closable={false}
                    visible={openNavConfigSet}
                    mask={true}
                    width={"95vw"}
                    destroyOnClose={true}
                    onClose={() => {
                        this.setState({
                            openNavConfigSet: false
                        })
                    }}
                >
                    <NavConfigSet
                        defaultConfig={allConfig.themeConfig}
                        allConfig={allConfig}
                        eachColWidth={this.eachColWidth}
                        eachLineHeight={this.eachLineHeight}
                        onClose={() => {
                            this.setState({
                                openNavConfigSet: false
                            })
                        }}
                        onChange={(formName, formValue) => {
                            allConfig.themeConfig[formName] = formValue;
                            this.setState({});
                        }}
                    />
                </Drawer>
                {/* 仪表板配置 */}
                <Drawer
                    getContainer={false}
                    style={{position: 'absolute'}}
                    drawerStyle={{backgroundColor: "#2e3244"}}
                    placement={"right"}
                    closable={false}
                    visible={openDashboardConfigSet}
                    mask={false}
                    width={"400px"}
                    destroyOnClose={true}
                    onClose={() => {
                        this.setState({
                            openDashboardConfigSet: false
                        })
                    }}
                >
                    <DashboardConfigSet
                        defaultConfig={allConfig.themeConfig}
                        onClose={() => {
                            this.setState({
                                openDashboardConfigSet: false
                            })
                        }}
                        onChange={(formName, formValue) => {
                            allConfig.themeConfig[formName] = formValue;

                            let allBoxUpdate = false;
                            if (["commonSet", "contentBackgroundSet", "contentTitle", "contentSet", "contentFont", "titlePlus"].includes(formName)) {
                                //是容器样式相关的，需要重新更新所有容器的样式
                                //注意：场景和仪表板的配置因为是直接在当前组件使用，不需要手动去通知更新
                                allBoxUpdate = true;
                                Object.keys(this.allContentUpdateMap).forEach((key) => {
                                    this.allContentUpdateMap[key]++
                                })
                            }

                            //通知演示页
                            this.postChangeMsgToDemoPage({
                                themeConfig: allConfig.themeConfig,    //仪表板配置
                                changeMsg: {    //修改事件的详情
                                    type: "themeConfigChange",
                                    changeFormName: formName,
                                    changeFormValue: formValue,
                                    allBoxUpdate: allBoxUpdate
                                }
                            })

                            this.setState({});
                        }}
                    />
                </Drawer>
                {/* 设置 */}
                <Drawer
                    getContainer={false}
                    style={{position: 'absolute'}}
                    drawerStyle={{backgroundColor: "#2e3244"}}
                    placement={"left"}
                    closable={false}
                    visible={openSceneConfigSet && this.currentEditSceneConfig}
                    mask={false}
                    width={"400px"}
                    destroyOnClose={true}
                    onClose={() => {
                        this.setState({
                            openSceneConfigSet: false
                        })
                    }}
                >
                    {
                        this.currentEditSceneConfig
                            ? <SceneConfigSet
                                parentConfig={allConfig.themeConfig} //用来填充缺省的默认值
                                selfConfig={this.currentEditSceneConfig} //用来判断数据值是否来源自于自己
                                onClose={() => {
                                    this.setState({
                                        openSceneConfigSet: false
                                    })
                                }}
                                onChange={(formName, changedKey, changedValue) => {
                                    //场景配置，只修改 【子一层】数据
                                    if (!changedKey) {
                                        _.set(this.currentEditSceneConfig, [formName], changedValue)
                                    } else {
                                        _.set(this.currentEditSceneConfig, [formName, changedKey], changedValue)
                                    }


                                    let allBoxUpdate = false;
                                    if (["commonSet", "contentBackgroundSet", "contentTitle", "contentSet", "contentFont", "titlePlus"].includes(formName)) {
                                        //是容器样式相关的，需要重新更新所有容器的样式
                                        //注意：场景和仪表板的配置因为是直接在当前组件使用，不需要手动去通知更新
                                        allBoxUpdate = true;
                                        Object.keys(this.allContentUpdateMap).forEach((key) => {
                                            this.allContentUpdateMap[key]++
                                        })
                                    }

                                    //通知演示页
                                    this.postChangeMsgToDemoPage({
                                        themeConfig: allConfig.themeConfig,    //仪表板配置
                                        changeMsg: {    //修改事件的详情
                                            type: "sceneConfigChange",
                                            formName: formName,
                                            changedKey: changedKey,
                                            changedValue: changedValue,
                                            allBoxUpdate: allBoxUpdate
                                        }
                                    })
                                    this.setState({});
                                }}
                                onResetField={(formName, resetKey) => {
                                    //重置某个配置
                                    delete this.currentEditSceneConfig[formName][resetKey]

                                    //通知演示页
                                    this.postChangeMsgToDemoPage({
                                        themeConfig: allConfig.themeConfig,    //仪表板配置
                                        changeMsg: {    //修改事件的详情
                                            type: "sceneConfigReset",
                                            formName: formName,
                                            resetKey: resetKey,
                                        }
                                    })
                                    this.setState({});
                                }}
                            />
                            : null
                    }
                </Drawer>
                <div className={styles.mainHeadBox}>
                    <Space>
                        {this.typeName}名称
                        <Input
                            placeholder={`请填写${this.typeName}名称`}
                            value={title}
                            maxLength={30}
                            onChange={(e)=>{
                                this.setState({
                                    title: e.target.value
                                })
                            }}
                          />
                        主题选择：
                        <Select
                            dropdownMatchSelectWidth={false}
                            allowClear={false}
                            value={selectTheme._id}
                            placeholder={"请选择一份主题"}
                            style={{
                                width: "200px"
                            }}
                            onChange={(value, option) => {
                                Modal.confirm({
                                    title: "提示",
                                    content: `所选择的 ${option?.title} 主题样式将覆盖您当前已经配置的${this.typeName}样式，是否继续？`,
                                    onOk: () => {
                                        this.commonRequest(APILXD.getThemeDetail, {
                                            _id: value
                                        }, (code, msg, json) => {
                                            allConfig.themeConfig = this.BaseSetItem.mergeFinallyConfig(json.data, DefaultThemeConfig, NeedMergeFieldsArr);
                                            allConfig.selectTheme = {
                                                _id: value,
                                                title: option?.title
                                            }
                                            this.postChangeMsgToDemoPage({
                                                themeConfig: allConfig.themeConfig,
                                                changeMsg: {    //修改事件的详情
                                                    type: "refresh",
                                                }
                                            })
                                            this.forceRender()
                                            message.success("加载主题成功");
                                        }, (code, msg) => {
                                            message.error("加载主题失败：" + msg);
                                        })
                                    }
                                })

                            }}
                        >
                            {allTheme.map((eachTheme) => {
                                return <Option
                                    key={eachTheme._id}
                                    value={eachTheme._id}
                                    title={eachTheme.title}
                                >
                                    {eachTheme.type===1
                                      ?<Tag bordered={false} color={"darkorange"}>系统</Tag>
                                      :<Tag bordered={false} color={"dodgerblue"}>公司</Tag>
                                    }
                                    {eachTheme.title}
                                </Option>
                            })}
                        </Select>
                        <Tooltip
                            title={"新增主题"}
                          >
                            <PlusCircleOutlined
                              style={{color: "limegreen"}}
                              onClick={() => {
                                  Modal.confirm({
                                      title: "提示",
                                      content: <div>
                                          <div>您是否需要将当前模板配置的主题文件保存到系统全局，供所有仪表板使用？</div>
                                          <div>请注意：1.如果只是本模板使用，则无需新增主题。</div>
                                          <div>2.新增主题后，您的后续修改不会影响已经生成的主题。</div>
                                          <div>3.您可以随时 更新/删除 已经创建系统全局的主题。</div>
                                      </div>,
                                      onOk: () => {
                                          this.addTheme()
                                      }
                                  })
                              }}
                            />
                        </Tooltip>
                        <Tooltip
                          title={"导出当前主题配置"}
                        >
                            <UploadOutlined
                              style={{color: "dodgerblue"}}
                              onClick={() => {
                                  Modal.confirm({
                                      title: "提示",
                                      content: <div>
                                          <div>是否将当前仪表盘的样式配置文件导出？</div>
                                      </div>,
                                      onOk: () => {
                                          window.downFileByText(JSON.stringify(this.state.allConfig.themeConfig), "容器样式配置.json")
                                      }
                                  })
                              }}
                            />
                        </Tooltip>
                        {/*<Tooltip*/}
                        {/*  title={"管理主题"}*/}
                        {/*>*/}
                        {/*    <SettingOutlined*/}
                        {/*      style={{color: "dodgerblue"}}*/}
                        {/*      onClick={() => {*/}
                        {/*          this.manageTheme()*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                    </Space>
                    <Space>
                        <Button
                            icon={<ClearOutlined/>}
                            shape={"round"}
                            type={"primary"}
                            style={{color: "#fff", borderColor: "darkorange", backgroundColor: "darkorange"}}
                            ghost={true}
                            onClick={() => {
                                Modal.confirm({
                                    title: "提示",
                                    content: `是否将${this.typeName}配置还原到系统默认的数值？`,
                                    onOk: () => {
                                        allConfig.themeConfig = _.cloneDeep(DefaultThemeConfig);

                                        this.postChangeMsgToDemoPage({
                                            themeConfig: allConfig.themeConfig,
                                            changeMsg: {    //修改事件的详情
                                                type: "refresh",
                                            }
                                        })
                                        this.forceRender();
                                    }
                                })
                            }}
                        >
                            还原到系统默认配置
                        </Button>
                        <Button
                            icon={<ControlOutlined/>}
                            shape={"round"}
                            type={"primary"}
                            onClick={() => {
                                this.setState({
                                    openDashboardConfigSet: true
                                })
                            }}
                        >
                            仪表板配置
                        </Button>
                        <Button
                            icon={<ControlOutlined/>}
                            shape={"round"}
                            type={"primary"}
                            onClick={() => {
                                this.setState({
                                    openNavConfigSet: true
                                })
                            }}
                        >
                            导航配置
                        </Button>
                        {/*<Button*/}
                        {/*    icon={<DesktopOutlined/>}*/}
                        {/*    type={"primary"}*/}
                        {/*    shape={"round"}*/}
                        {/*    onClick={() => {*/}
                        {/*        if(this.type===1){*/}
                        {/*            window.openWin('dashboardShow', {*/}
                        {/*                id: this.dashboardId,*/}
                        {/*                title: this.state.title*/}
                        {/*            }, {*/}
                        {/*                title: this.typeName + "展示" + this.dashboardId*/}
                        {/*            })*/}
                        {/*        }else{*/}
                        {/*            message.warning("模板无法直接进入单页展示模式");*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    单页展示*/}
                        {/*</Button>*/}
                        <Button
                            icon={<SaveOutlined/>}
                            shape={"round"}
                            type={"primary"}
                            style={{
                                backgroundColor: "limegreen",
                                borderColor: "limegreen",
                                color: "#fff",
                                width: "120px"
                            }}
                            onClick={() => {
                                this.toSave()
                            }}
                        >
                            保存
                        </Button>
                        <Button
                            icon={<FieldTimeOutlined/>}
                            shape={"round"}
                            onClick={() => {
                                this.getDashboardSaveHistory()
                            }}
                        >
                            历史
                        </Button>
                    </Space>
                </div>
                <div className={styles.mainBodyBox}>
                    <Drawer
                        getContainer={false}
                        style={{position: 'absolute'}}
                        drawerStyle={{backgroundColor: "#2e3244"}}
                        placement={openEditContentPlacement || "right"}
                        closable={false}
                        visible={openEditContent && this.currentEditContentMsg}
                        mask={false}
                        width={"350px"}
                        destroyOnClose={true}
                        onClose={() => {
                            this.setState({
                                openEditContent: false
                            })
                        }}
                    >
                        {this.getEditContentView(this.currentEditContentMsg)}
                    </Drawer>
                    {
                        sceneArray?.length > 0
                          ? <Tabs
                            key={"sceneTabs_" + forceUpdate}
                            activeKey={currentSceneId}
                            centered={true}
                            tabBarGutter={20}
                            tabBarStyle={{padding: "0 10px"}}
                            tabBarExtraContent={<Space>
                                场景操作：
                                {/*<Button*/}
                                {/*  icon={<MobileOutlined />}*/}
                                {/*  type={"primary"}*/}
                                {/*  shape={"round"}*/}
                                {/*  size={"small"}*/}
                                {/*  onClick={() => {*/}
                                {/*      this.openMobileDemoPage()*/}
                                {/*  }}*/}
                                {/*>手机Demo</Button>*/}
                                <Button
                                  icon={<ReloadOutlined/>}
                                  type={"primary"}
                                  shape={"round"}
                                  size={"small"}
                                  onClick={() => {
                                      this.calcBoxHeight()
                                      this.forceRender()
                                      this.postChangeMsgToDemoPage({
                                          themeConfig: allConfig.themeConfig,
                                          changeMsg: {    //修改事件的详情
                                              type: "refresh",
                                          }
                                      })
                                  }}
                                >重绘</Button>
                                <Button
                                  icon={<CopyOutlined/>}
                                  type={"primary"}
                                  shape={"round"}
                                  size={"small"}
                                  onClick={() => {
                                      this.copyScene()
                                  }}
                                >克隆</Button>
                                <Button
                                  icon={<PlusOutlined/>}
                                  type={"primary"}
                                  shape={"round"}
                                  size={"small"}
                                  onClick={() => {
                                      this.addScene()
                                  }}
                                >新增</Button>
                                <Dropdown
                                  overlay={
                                      <Menu
                                        onClick={({ key }) => {
                                            switch (key) {
                                                case 'delete':
                                                    Modal.confirm({
                                                        title: "删除提示",
                                                        content: "是否删除该场景？场景下的容器都会被删除！",
                                                        onOk: () => {
                                                            this.removeScene()
                                                        }
                                                    })
                                                    break;
                                                case 'rank':
                                                    this.sortScenes()
                                                    break;
                                                case 'export':
                                                    this.exportScenes()
                                                    break;
                                            }
                                        }}
                                      >
                                          <Menu.Item key="export">导出场景</Menu.Item>
                                          <Menu.Item key="import">
                                              导入场景
                                              <input className={styles.file_uploader} type='file' accept='.json'
                                                     onChange={(file)=>{
                                                         //读取内容
                                                         let callBack = (data, error)=>{
                                                             if (error) {
                                                                 message.error("error:" + error);
                                                                 return;
                                                             }
                                                             try {
                                                                 let copySceneObj = JSON.parse(data);
                                                                 if(Object.prototype.toString.call(copySceneObj) !== '[object Object]'){
                                                                     message.warning("配置文件结构不正确");
                                                                     return;
                                                                 }

                                                                 if(!copySceneObj._id || !Array.isArray(copySceneObj.contentArray)){
                                                                     message.warning("配置文件结构不正确");
                                                                     return;
                                                                 }

                                                                 this.copyScene(copySceneObj)

                                                             } catch (e) {
                                                                 message.error("导入样式失败" + e.toString())
                                                             }
                                                         }
                                                         window.uploadText(file, callBack);
                                                     }}
                                              />
                                          </Menu.Item>
                                          <Menu.Item key="rank">排序场景</Menu.Item>
                                          <Menu.Item key="delete"><span style={{color: "red"}}>删除场景</span></Menu.Item>
                                      </Menu>
                                  }
                                  placement="bottomRight"
                                  arrow={{
                                      pointAtCenter: true,
                                  }}
                                >
                                    <Button
                                      icon={<EllipsisOutlined />}
                                      type={"primary"}
                                      shape={"round"}
                                      size={"small"}
                                    >更多</Button>
                                </Dropdown>
                            </Space>}
                            onChange={(key) => {
                                this.currentEditSceneConfig = null;
                                this.currentEditContentMsg = null;
                                this.currentEditContent = null;
                                this.setState({
                                    currentSceneId: key,
                                    openSceneConfigSet: false,
                                    openEditContent: false,
                                    currentSelectBox: null,
                                    currentSelContentId: null,
                                });
                            }}
                          >
                              {
                                  sceneArray.map((sceneItemMsg, index) => {
                                      let sceneStyleFinalConfig = this.BaseSetItem.mergeFinallyConfig(sceneItemMsg.styleConfig, allConfig.themeConfig, SceneMergeFields)
                                      let platform = sceneItemMsg.styleConfig?.sceneSpecialSet?.platform || "desktop";

                                      return <Tabs.TabPane
                                        tab={<div
                                          className={sceneItemMsg._id === currentSceneId ? styles.tabSelName : null}
                                        >
                                            <Space>
                                                {
                                                    platform === "desktop"
                                                      ? <DesktopOutlined
                                                        style={{marginRight: "0"}}
                                                      />
                                                      : null
                                                }
                                                {
                                                    platform === "mobile"
                                                      ? <MobileOutlined
                                                        style={{marginRight: "0"}}
                                                      />
                                                      : null
                                                }
                                                {
                                                    sceneItemMsg.title
                                                      ? <span>{sceneItemMsg.title}</span>
                                                      : <span>{"场景" + (index + 1)}</span>
                                                }
                                                {
                                                    sceneItemMsg._id === currentSceneId
                                                      ? <EditOutlined
                                                        onClick={(e) => {
                                                            e.stopPropagation();

                                                            this.changeSceneName(sceneItemMsg)
                                                        }}
                                                      />
                                                      : null
                                                }
                                            </Space>
                                        </div>}
                                        key={sceneItemMsg._id}
                                      >
                                          <div className={styles.tabPaneContainer}>
                                              <div
                                                className={styles.leftControlBar}
                                              >
                                                  <Space
                                                    direction="vertical"
                                                    size={15}
                                                  >
                                                      {
                                                          this.type === 1
                                                            ?<Popover
                                                              color={"#2e3244"}
                                                              placement={"rightTop"}
                                                              content={<div
                                                                className={styles.leftViewSelBox}
                                                              >
                                                                  <ViewTypeSel
                                                                    contentMode={"view"}
                                                                    onChange={(type) => {
                                                                        let contentMsg = this.getContentDefaultObj("view");
                                                                        contentMsg.dataConfig.contentType = type;
                                                                        contentMsg.dataConfig.contentParams = DataDefinitionFile.getDefaultViewParams(type);
                                                                        this.addOneContent(sceneItemMsg, {
                                                                            contentMsg: contentMsg
                                                                        })
                                                                    }}
                                                                  />
                                                              </div>}
                                                            >
                                                                <div className={styles.leftControlBtn}>
                                                                    <PieChartOutlined
                                                                      style={{fontSize: "16px"}}
                                                                    />
                                                                    <div>视图</div>
                                                                </div>
                                                            </Popover>
                                                            :null
                                                      }
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"rightTop"}
                                                        content={<div
                                                          className={styles.leftViewSelBox}
                                                        >
                                                            <ViewTypeSel
                                                              contentMode={"widget"}
                                                              onChange={(type) => {
                                                                  let contentMsg = this.getContentDefaultObj("widget");
                                                                  contentMsg.dataConfig.contentType = type;
                                                                  contentMsg.dataConfig.contentParams = DataDefinitionFile.getDefaultViewParams(type);
                                                                  this.addOneContent(sceneItemMsg, {
                                                                      contentMsg: contentMsg
                                                                  })
                                                              }}
                                                            />
                                                        </div>}
                                                      >
                                                          <div className={styles.leftControlBtn}>
                                                              <BuildOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>部件</div>
                                                          </div>
                                                      </Popover>
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"rightTop"}
                                                        content={<div
                                                          className={styles.leftViewSelBox}
                                                        >
                                                            <ViewTypeSel
                                                              contentMode={"systemComponent"}
                                                              onChange={(type) => {
                                                                  let contentMsg = this.getContentDefaultObj("systemComponent");
                                                                  contentMsg.dataConfig.contentType = type;
                                                                  contentMsg.dataConfig.contentParams = DataDefinitionFile.getDefaultViewParams(type);
                                                                  this.addOneContent(sceneItemMsg, {
                                                                      contentMsg: contentMsg
                                                                  })
                                                              }}
                                                            />
                                                        </div>}
                                                      >
                                                          <div className={styles.leftControlBtn}>
                                                              <GiftOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>系统</div>
                                                          </div>
                                                      </Popover>
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"rightTop"}
                                                        content={<div
                                                          className={styles.leftViewSelBox}
                                                        >
                                                            <CustomComponentSel
                                                              option={customRegisterData}
                                                              onChange={(customChartMsg) => {
                                                                  let contentMsg = customChartMsg.contentMsg;
                                                                  contentMsg.dataConfig.usage = this.state.usage;
                                                                  contentMsg.dataConfig.contentTitle = customChartMsg.name;
                                                                  contentMsg.dataConfig.cloneFrom = {
                                                                      _id: customChartMsg._id,
                                                                      version: customChartMsg.version,
                                                                      name: customChartMsg.name,
                                                                      groupName: customChartMsg.groupName,
                                                                      updateTime: customChartMsg.updateTime || customChartMsg.addTime,
                                                                      cloneTime: Date.now()
                                                                  };


                                                                  this.addOneContent(sceneItemMsg, {
                                                                      contentMsg: contentMsg
                                                                  })
                                                              }}
                                                            />
                                                        </div>}
                                                      >
                                                          <div className={styles.leftControlBtn}>
                                                              <ToolOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>自定义</div>
                                                          </div>
                                                      </Popover>
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"rightTop"}
                                                        content={<div
                                                          className={styles.leftViewSelBox}
                                                        >
                                                            <ViewTypeSel
                                                              contentMode={"parameter"}
                                                              onChange={(type) => {
                                                                  let contentMsg = this.getContentDefaultObj("parameter");
                                                                  contentMsg.dataConfig.contentType = type;
                                                                  contentMsg.dataConfig.contentParams = DataDefinitionFile.getDefaultViewParams(type);
                                                                  this.addOneContent(sceneItemMsg, {
                                                                      contentMsg: contentMsg
                                                                  })
                                                              }}
                                                            />
                                                        </div>}
                                                      >
                                                          <div className={styles.leftControlBtn}>
                                                              <BlockOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>参数</div>
                                                          </div>
                                                      </Popover>
                                                  </Space>
                                                  <Space
                                                    direction="vertical"
                                                    size={15}
                                                  >
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"right"}
                                                        title={"自定义组件升级"}
                                                        content={<div>
                                                            查看正在使用的自定义组件和它的最新版本
                                                        </div>}
                                                      >
                                                          <div
                                                            className={styles.leftControlBtn}
                                                            onClick={() => {
                                                                this.openCustomComponentUseModal()
                                                            }}
                                                          >
                                                              <RocketOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>升级</div>
                                                          </div>
                                                      </Popover>
                                                      <Popover
                                                        color={"#2e3244"}
                                                        placement={"right"}
                                                        title={"快捷键介绍"}
                                                        content={<div>
                                                            <div>注意：当仪表板处于焦点状态(具有紫色虚线边框)下才可使用快捷键操作。</div>
                                                            <div>可用快捷键如下：</div>
                                                            <div>Enter：编辑选中的容器</div>
                                                            <div>ESC：取消选中容器/关闭侧边栏</div>
                                                            <div>DEL：删除选中的容器</div>
                                                            <div>Ctrl + C：缓存选中的容器</div>
                                                            <div>Ctrl + V：粘贴缓存的容器</div>
                                                            <div>Command + C：缓存选中的容器</div>
                                                            <div>Command + V：粘贴缓存的容器</div>
                                                        </div>}
                                                      >
                                                          <div
                                                            className={styles.leftControlBtn}
                                                          >
                                                              <QuestionCircleOutlined
                                                                style={{fontSize: "16px"}}
                                                              />
                                                              <div>热键</div>
                                                          </div>
                                                      </Popover>
                                                      <div
                                                        className={styles.leftControlBtn}
                                                        onClick={() => {
                                                            this.currentEditSceneConfig = sceneItemMsg.styleConfig;

                                                            this.setState({
                                                                openSceneConfigSet: true
                                                            })
                                                        }}
                                                      >
                                                          <ControlOutlined
                                                            style={{fontSize: "16px"}}
                                                          />
                                                          <div>设置</div>
                                                      </div>
                                                  </Space>
                                              </div>
                                              <div
                                                className={styles.dashboardContent}
                                                ref={ref => this.contentBox = ref}
                                                style={{
                                                    borderRadius: window.changeRemNum(sceneStyleFinalConfig.sceneBackgroundSet.radiusSet),
                                                    overflow: sceneStyleFinalConfig.sceneBackgroundSet.overflowHidden ? "hidden" : "unset",
                                                }}
                                              >
                                                  {
                                                      allConfig.themeConfig.dashboardBackgroundSet.use
                                                        ? <BackgroundBgDiv
                                                          backgroundSet={allConfig.themeConfig.dashboardBackgroundSet}
                                                        />
                                                        : null
                                                  }
                                                  {
                                                      sceneStyleFinalConfig.sceneBackgroundSet.use
                                                        ? <BackgroundBgDiv
                                                          backgroundSet={sceneStyleFinalConfig.sceneBackgroundSet}
                                                        />
                                                        : null
                                                  }
                                                  <div
                                                    className={
                                                        sceneItemMsg.styleConfig?.sceneSpecialSet?.platform === "mobile"
                                                          ? styles.dashboardContentHasBgMobile
                                                          : styles.dashboardContentHasBg}
                                                    style={{
                                                        backgroundSize: `${this.eachColWidth}px ${this.eachLineHeight}px`,
                                                    }}
                                                  ></div>
                                                  {
                                                      showGrade
                                                        ? this.renderScene(sceneItemMsg, sceneStyleFinalConfig)
                                                        : null
                                                  }
                                              </div>
                                          </div>
                                      </Tabs.TabPane>
                                  })
                              }
                          </Tabs>
                          : <Empty description={"请新增一个场景"} style={{margin: "150px auto"}}>
                              <Button
                                icon={<PlusOutlined/>}
                                type={"primary"}
                                shape={"round"}
                                size={"small"}
                                onClick={() => {
                                    this.addScene()
                                }}
                              >新增场景</Button>
                          </Empty>
                    }
                </div>
            </div>
        </Spin>
    }


    //渲染某个场景的编辑模式容器
    renderScene(sceneItemMsg, sceneStyleFinalConfig) {
        let {currentSelContentId, resize, currentSelectBox,dashboardDetail} = this.state;
        if ((!sceneItemMsg?.contentArray?.length)) {
            this.scendContainerRef = null;
            return <div
                tabIndex={"-1"}
                className={styles.disResizableBox}
                onKeyDown={(e) => {
                  this.handleKeyPressAction(e)
                }}
            >
                <Empty description={"未配置过任何容器，请从左侧选择一种容器"} style={{marginTop: "150px"}}>
                </Empty>
            </div>
        }

        let allParameterBoxValues = {};
        sceneItemMsg?.contentArray.forEach((eachC)=>{
            if(eachC.contentMsg.contentMode === "parameter"){
                if(eachC.contentMsg?.dataConfig?.contentType === "parameter_urlParam"){
                    //特殊的参数组件，需要收集地址栏的数据
                    eachC.contentMsg.dataConfig.parameterValue = this.urlParams[eachC.contentMsg?.dataConfig?.contentParams?.field]
                }

                allParameterBoxValues[eachC.uid] = eachC.contentMsg?.dataConfig?.parameterValue
            }
        })

        let ifResize = sceneItemMsg.styleConfig?.sceneSpecialSet?.platform === "mobile";
        //兼容老版本写法：如果配置了，就采用配置项，如果没配置，就按照平台类型决定
        let openSizeLimit = sceneItemMsg.styleConfig?.sceneSpecialSet?.hasOwnProperty("openSizeLimit")?sceneItemMsg.styleConfig?.sceneSpecialSet?.openSizeLimit:ifResize;
        let ifSelParameterBox = currentSelectBox?.contentMsg?.contentMode === "parameter";
        let selParameterBoxType = ifSelParameterBox?currentSelectBox.contentMsg.dataConfig.contentType:null;

        return <div
            tabIndex={"-1"}
            ref={ref=>this.scendContainerRef = ref}
            className={
                openSizeLimit
                    ? styles.resizableBox
                    : styles.disResizableBox}
            onKeyDown={(e) => {
                this.handleKeyPressAction(e)
            }}
        >
            <ResponsiveGridLayout
                style={{
                    minHeight: "100%"
                }}
                className="layout"
                cols={50}
                rowHeight={this.eachLineHeight}
                margin={[0, 0]}
                isBounded={!openSizeLimit}
                autoResize={openSizeLimit}
                isDraggable={true}
                isResizable={true}
                // compactType={"horizontal"}
                allowOverlap={true}
                resizeHandles={["se", "s", "e"]}
                // onLayoutChange={(layout) => {
                //     //拖拽不会触发？？？
                //     console.log("onLayoutChange",layout)
                // }}
                // onDragStart={(layout,oldItem,newItem)=>{
                //     console.log("触发了 onDragStart");
                //     console.log(layout);
                //     console.log(oldItem);
                // }}
                onDragStop={(layout, oldItem, newItem) => {
                    // console.log("onDragStop",layout)
                    // console.log("onDragStop",oldItem)
                    // console.log("onDragStop",newItem)

                    // 处理windows bug，无法触发点击事件，但是一直触发 drag事件，在drag事件结束判断点击
                    // bug无法复现，先注释这个处理方法  -- 2022.11.07
                    // bug又出现了  -- 2022.11.10
                    if (this.isWin && newItem.x === oldItem.x && newItem.y === oldItem.y) {

                        //如果位置没有变化，认为是点击事件
                        let clickContentItem;
                        sceneItemMsg.contentArray.forEach((conItem) => {
                            if (conItem.uid === newItem.i) {
                                //找打了点击的内容
                                clickContentItem = conItem;
                            }
                        })

                        if (!clickContentItem) {
                            message.warning("出错啦:匹配不到点击的容器内容");
                            return;
                        }

                        this.dealClickContentItem(clickContentItem, sceneItemMsg)
                    } else {
                        this.dealLayoutChange(sceneItemMsg, layout)
                    }
                }}
                onResize={(layout) => {
                    this.dealResizeChange(sceneItemMsg, layout)
                }}
                onResizeStart={(layout) => {
                    console.log("onResizeStart", layout)
                    this.setState({resize: true})
                }}
                onResizeStop={(layout) => {
                    console.log("onResizeStop", layout)
                    this.setState({resize: false})
                    this.dealLayoutChange(sceneItemMsg, layout)
                }}
            >
                {
                    sceneItemMsg.contentArray.sort((a, b) => {
                        return a.zIndex - b.zIndex
                    }).map((eachContent, index) => {
                        if (!this.allContentUpdateMap[eachContent.uid]) {
                            this.allContentUpdateMap[eachContent.uid] = 1;
                        }
                        if (!this.allContentRebuildMap[eachContent.uid]) {
                            this.allContentRebuildMap[eachContent.uid] = 1;
                        }
                        if (!this.allContentReloadMap[eachContent.uid]) {
                            this.allContentReloadMap[eachContent.uid] = 1;
                        }
                        let ifSelect = currentSelContentId === eachContent.uid;
                        let connectMe;
                        switch (eachContent.contentMsg.contentMode){
                            case "view":connectMe = eachContent.contentMsg.beConnectedConfig?.[currentSelContentId];break;
                            case "parameter":connectMe = eachContent.contentMsg.connectConfig?.[currentSelContentId];break;
                        }

                        return <div
                            key={eachContent.uid}
                            data-grid={eachContent.sizeConfig}
                            onClick={() => {
                                // console.log(eachContent)
                                //windows 无法触发这里的点击，外部判断
                                this.dealClickContentItem(eachContent, sceneItemMsg)
                            }}
                            className={classnames({
                                [styles.contentBox]: true,
                                [styles.selContentBox]: ifSelect,
                                [styles.connectContentBox]: !!connectMe,
                            })}
                            style={{
                                zIndex: ifSelect ? "9" : "unset"
                            }}
                        >
                            {
                                eachContent.contentMsg.contentMode==="parameter"
                                    ?<div className={styles.parameterUidShow}>参数容器-{eachContent.uid.slice(0,4)}</div>
                                    :null
                            }
                            {
                                ifSelect
                                    ? <Popover
                                        title={<Space>
                                            <div style={{color: "limegreen"}}>
                                                配置项
                                            </div>
                                        </Space>}
                                        // visible={true}
                                        placement={"rightTop"}
                                        color={"#2e3244"}
                                        trigger={"hover"}
                                        content={<div className={styles.contentSetBar}
                                            // onClick={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                            // onMouseMove={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                            // onMouseDown={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                        >
                                            <Space
                                                direction={"vertical"}
                                                // align={"center"}
                                                size={10}
                                            >
                                                <Space>
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        onClick={() => {
                                                            this.currentEditContent = null;
                                                            this.currentEditContentFromScene = null;
                                                            this.currentEditContentMsg = null;
                                                            this.setState({
                                                                openEditContent: false,
                                                            }, () => {
                                                                this.currentEditContent = eachContent;
                                                                this.currentEditContentFromScene = sceneItemMsg;
                                                                //普通容器
                                                                this.currentEditContentMsg = eachContent.contentMsg;
                                                                this.setState({
                                                                    openEditContent: true,
                                                                    openEditContentPlacement: eachContent.sizeConfig.x > HalfX ? "left" : "right",
                                                                })
                                                            })
                                                        }}
                                                    >
                                                        <EditOutlined/>
                                                        编辑
                                                    </div>
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        onClick={() => {
                                                            let copyItem = _.cloneDeep(eachContent);

                                                            copyItem.uid = window.getUID();
                                                            copyItem.sizeConfig.x = eachContent.sizeConfig.x + 1;
                                                            copyItem.sizeConfig.y = eachContent.sizeConfig.y + 1;
                                                            delete copyItem.contentMsg.beConnectedConfig;
                                                            delete copyItem.contentMsg.connectConfig;
                                                            this.addOneContent(sceneItemMsg, copyItem)
                                                        }}
                                                    >
                                                        <CopyOutlined/>
                                                        复制
                                                    </div>
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        onClick={() => {
                                                            this.transferContentItem(sceneItemMsg, eachContent)
                                                        }}
                                                    >
                                                        <SendOutlined/>
                                                        转移
                                                    </div>
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        style={{
                                                            color: "red"
                                                        }}
                                                        onClick={() => {
                                                            Modal.confirm({
                                                                title: "提示",
                                                                content: "所有容器配置将会被清空，是否删除该容器？",
                                                                onOk: () => {
                                                                    this.removeOneContent(sceneItemMsg, eachContent.uid)
                                                                }
                                                            })
                                                        }}
                                                    >
                                                        <DeleteOutlined/>
                                                        删除
                                                    </div>
                                                </Space>
                                                <Space>
                                                    <InputNumber
                                                        addonBefore={"层级"}
                                                        value={eachContent.zIndex}
                                                        precision={0}
                                                        // controls={false}
                                                        min={-1000}
                                                        max={1000}
                                                        style={{width: "100px"}}
                                                        onChange={(v) => {
                                                            eachContent.zIndex = v;
                                                            this.setState({})
                                                        }}
                                                    />
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        onClick={() => {
                                                            this.changeIndexFast(sceneItemMsg, eachContent, "top")
                                                        }}
                                                    >
                                                        <VerticalAlignTopOutlined/>
                                                        置顶
                                                    </div>
                                                    <div
                                                        className={styles.contentSetBtn}
                                                        onClick={() => {
                                                            this.changeIndexFast(sceneItemMsg, eachContent, "bottom")
                                                        }}
                                                    >
                                                        <VerticalAlignBottomOutlined/>
                                                        置底
                                                    </div>
                                                </Space>
                                            </Space>
                                        </div>}
                                    >
                                        <div className={styles.contentSetBarMark}
                                            // onClick={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                            // onMouseMove={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                            // onMouseDown={(e)=>{
                                            //     e.stopPropagation();
                                            //     e.preventDefault();
                                            // }}
                                        >
                                            <SettingOutlined
                                                style={{color: "limegreen", fontSize: "20px"}}
                                            />
                                        </div>
                                    </Popover>
                                    : null
                            }
                            {
                                resize
                                    ? <div className={styles.sizeMsg}>
                                        {eachContent.sizeConfig.tempW || eachContent.sizeConfig.w} x {eachContent.sizeConfig.tempH || eachContent.sizeConfig.h}
                                    </div>
                                    : null
                            }
                            <ContentView
                              identifyMsg={{
                                  usage: this.state.usage,
                                  companyId: dashboardDetail.companyId,
                                  spaceId: dashboardDetail.spaceId,
                                  execUnitId: dashboardDetail.execUnitId,
                                  deviceId: dashboardDetail?.deviceId,
                              }}
                              showType={"dev"}
                              key={`${eachContent.uid}_re_${this.allContentRebuildMap[eachContent.uid]}`}
                              styleUpdate={`${eachContent.uid}_su_${this.allContentUpdateMap[eachContent.uid]}`}
                              dataUpdate={`${eachContent.uid}_su_${this.allContentReloadMap[eachContent.uid]}`}
                              parentStylesConfig={sceneStyleFinalConfig}
                              contentMsg={eachContent.contentMsg}
                              allParameterBoxValues={allParameterBoxValues}
                              connectMe={connectMe}
                              selParameterBoxType={selParameterBoxType}
                              valueChanged={(newValue,oldValue,parameterStruct)=>{
                                  //只有参数容器用的到这个回调

                                  //修改参数容器的默认值
                                  eachContent.contentMsg.dataConfig.parameterValue = newValue;

                                  if(parameterStruct){
                                      //需要保存原始参数结构(转换成值parameterValue之前的结构)
                                      eachContent.contentMsg.dataConfig.parameterStruct = parameterStruct;
                                  }

                                  //触发关联视图容器的数据重新请求
                                  if(eachContent.contentMsg.connectConfig){
                                      Object.keys(eachContent.contentMsg.connectConfig).forEach((viewBoxUid)=>{
                                          this.allContentReloadMap[viewBoxUid]++
                                      })
                                  }
                                  this.setState({})
                              }}
                              paramesChecked={(checkedValue)=>{
                                  //勾选了参数
                                  if(!eachContent.contentMsg.beConnectedConfig){
                                      eachContent.contentMsg.beConnectedConfig = {};
                                  }
                                  //修改参数容器的绑定关系,修改视图容器的绑定关系
                                  if(checkedValue.length){
                                      if(!currentSelectBox.contentMsg.connectConfig){
                                          currentSelectBox.contentMsg.connectConfig = {}
                                      }

                                      currentSelectBox.contentMsg.connectConfig[eachContent.uid] = checkedValue;
                                      eachContent.contentMsg.beConnectedConfig[currentSelectBox.uid] = checkedValue;
                                  }else{
                                      delete currentSelectBox.contentMsg.connectConfig[eachContent.uid];
                                      delete eachContent.contentMsg.beConnectedConfig[currentSelectBox.uid];
                                  }
                                  //当前容器重新请求参数
                                  this.allContentReloadMap[eachContent.uid]++
                                  this.setState({})
                              }}
                              registerValueSetListener={(setValueFunc)=>{
                                  this.boxMsgEmitter.off(eachContent.uid)
                                  this.boxMsgEmitter.on(eachContent.uid,setValueFunc)
                              }}
                              broadcastData={(dataNeedBroadcast)=>{
                                  //只有视图容器用到这个回调

                                  console.log("广播拉！",dataNeedBroadcast)

                                  //触发广播
                                  if(eachContent.contentMsg.dataConfig?.contentParams?.clickActionSet){
                                      eachContent.contentMsg.dataConfig?.contentParams?.clickActionSet.toWhere?.forEach((viewBoxUid)=>{
                                          this.boxMsgEmitter.emit(viewBoxUid,dataNeedBroadcast)
                                      })
                                  }
                              }}
                            />
                        </div>
                    })
                }
            </ResponsiveGridLayout>
        </div>
    }

    //处理点击容器
    dealClickContentItem(eachContent, sceneItemMsg) {
        let {openEditContent} = this.state;
        if (openEditContent && eachContent.uid !== this.state.currentSelContentId) {
            //当前打开了编辑容器，自动切换到对应的编辑
            this.setState({
                openEditContent: false,
                currentSelContentId: eachContent.uid,
                currentSelectBox: eachContent
            }, () => {
                this.currentEditContent = eachContent;
                this.currentEditContentFromScene = sceneItemMsg;
                this.currentEditContentMsg = eachContent.contentMsg;
                this.setState({
                    openEditContent: true,
                    openEditContentPlacement: eachContent.sizeConfig.x > HalfX ? "left" : "right",
                })
            })
        } else {
            this.setState({
                currentSelContentId: eachContent.uid,
                currentSelectBox: eachContent
            })
        }
    }

    //修改场景名称
    changeSceneName(sceneMsg) {
        MB.show(
            {
                title: "编辑场景名称",
                okTitle: "确定",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 5},
                    wrapperCol: {span: 16, offset: 0},
                },
                parameterArr: [
                    {
                        ver: true,
                        name: "场景名",
                        field: "title",
                        reg: /^[\s\S]{1,50}$/,
                        verMessage: "请输入1-50个字符",
                        type: 'Input',
                        required: true,
                        option: {
                            placeholder: '请输入场景名，1-50字符',
                            maxLength: 50,
                            defaultValue: sceneMsg ? sceneMsg.title : undefined,
                        }
                    },
                ],
            }
            ,
            {
                beforeSubmit: (param) => {
                    sceneMsg.title = param.title;
                    this.setState({})
                    MB.close();
                    return false;
                },
            })
    }



    //编辑容器的界面
    getEditContentView(content) {
        if (!content) {
            return null
        }

        let {allConfig,currentSceneId} = this.state;

        let sceneItemMsg = _.cloneDeep(this.findSceneById(currentSceneId));

        return <Tabs
            tabBarExtraContent={<Button
                type={"link"}
                shape={"round"}
                size={"small"}
                onClick={() => {
                    this.setState({
                        openEditContent: false
                    })
                }}
            >
                <span style={{color: "red"}}>收起</span>
            </Button>}
        >
            <Tabs.TabPane
                tab={"数据与展示"}
                key={"data"}
            >
                <div className={styles.rightSideTabPaneBox}>
                    <DataDisposition
                        unitId={this.state.dashboardDetail.execUnitId}
                        key={"ddp_" + this.currentEditContent?.uid + "_" + content.tempId}
                        contentMsg={content}
                        sceneItemMsg={sceneItemMsg}
                        onSave={(newDataConfig, onlyStyleUpDate) => {
                            console.log("newDataConfig", newDataConfig)
                            content.dataConfig = newDataConfig;

                            if (onlyStyleUpDate) {
                                this.allContentUpdateMap[this.currentEditContent?.uid]++
                            } else {
                                //数据绑定更新，需要对这个容器强更
                                this.allContentRebuildMap[this.currentEditContent?.uid]++
                            }

                            //通知演示页
                            this.postChangeMsgToDemoPage({
                                themeConfig: allConfig.themeConfig,    //仪表板配置
                                changeMsg: {    //修改事件的详情
                                    type: "dataConfigChange",
                                    changeContentId: this.currentEditContent?.uid,
                                    dataConfig: newDataConfig,
                                    onlyStyleUpDate: onlyStyleUpDate,
                                }
                            })

                            this.setState({});
                        }}
                    />
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={"继承的配置"}
                key={"style"}
            >
                <div className={styles.rightSideTabPaneBox}>
                    <ContentStyleConfigSet
                        key={"Cscs_" + this.currentEditContent?.uid + "_" + content.tempId}
                        grandpaConfig={allConfig.themeConfig}//用来填充缺省的默认值
                        parentConfig={this.currentEditContentFromScene.styleConfig} //用来填充缺省的默认值
                        selfConfig={content.styleConfig} //用来判断数据值是否来源自于自己
                        onChange={(formName, changedKey, changedValue) => {
                            if (!changedKey) {
                                _.set(content.styleConfig, [formName], changedValue)
                            } else {
                                //容器配置，只修改 【子一层】数据
                                _.set(content.styleConfig, [formName, changedKey], changedValue)
                            }

                            if (content.tempId) {
                                //动态容器
                                this.allContentUpdateMap[content.tempId]++
                            } else {
                                this.allContentUpdateMap[this.currentEditContent.uid]++
                            }

                            //通知演示页
                            this.postChangeMsgToDemoPage({
                                themeConfig: allConfig.themeConfig,    //仪表板配置
                                changeMsg: {    //修改事件的详情
                                    type: "contentStyleConfigChange",
                                    changeContentId: this.currentEditContent?.uid,
                                    newStyleConfig: content.styleConfig,
                                }
                            })
                            this.setState({});
                        }}
                        onResetField={(formName, resetKey) => {
                            //重置某个配置
                            delete content.styleConfig[formName][resetKey]
                            if (content.tempId) {
                                //动态容器
                                this.allContentUpdateMap[content.tempId]++
                            } else {
                                this.allContentUpdateMap[this.currentEditContent.uid]++
                            }

                            //通知演示页
                            this.postChangeMsgToDemoPage({
                                themeConfig: allConfig.themeConfig,    //仪表板配置
                                changeMsg: {    //修改事件的详情
                                    type: "contentStyleConfigChange",
                                    changeContentId: this.currentEditContent?.uid,
                                    newStyleConfig: content.styleConfig,
                                }
                            })
                            this.setState({});
                        }}
                        onChangeAll={(newStyleConfig) => {
                            //整个样式配置变了(导入覆盖、重置等)
                            //重置某个配置
                            content.styleConfig = newStyleConfig;
                            if (content.tempId) {
                                //动态容器
                                this.allContentUpdateMap[content.tempId]++
                            } else {
                                this.allContentUpdateMap[this.currentEditContent.uid]++
                            }

                            //通知演示页
                            this.postChangeMsgToDemoPage({
                                themeConfig: allConfig.themeConfig,    //仪表板配置
                                changeMsg: {    //修改事件的详情
                                    type: "contentStyleConfigChange",
                                    changeContentId: this.currentEditContent?.uid,
                                    newStyleConfig: content.styleConfig,
                                }
                            })
                            this.setState({});
                        }}
                    />
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={"备注"}
                key={"descSet"}
            >
                <div className={styles.rightSideTabPaneBox}>
                    <BoxDescSet
                        key={"bds_" + this.currentEditContent?.uid + "_" + content.tempId}
                        descConfig={content.descConfig}
                        onChange={(descSet)=>{
                            content.descConfig = descSet;
                            if (content.tempId) {
                                //动态容器
                                this.allContentUpdateMap[content.tempId]++
                            } else {
                                this.allContentUpdateMap[this.currentEditContent.uid]++
                            }
                            this.setState({})
                        }}
                    />
                </div>
            </Tabs.TabPane>
        </Tabs>
    }

    //保存仪表板各项配置
    toSave() {
        let {allConfig,title} = this.state;
        console.log(allConfig);

        if(!_.trim(title)){
            message.warning(`请填写${this.typeName}名称`);
            return;
        }

        MB.show(
            {
                title: "填写保存备注",
                okTitle: "保存",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 5},
                    wrapperCol: {span: 16, offset: 0},
                },
                parameterArr: [
                    {
                        ver: true,
                        name: "保存备注",
                        field: "descs",
                        reg: /^[\s\S]{1,50}$/,
                        verMessage: "请输入1-50个字符",
                        type: 'Input',
                        required: true,
                        option: {
                            placeholder: '请输入场景名，1-50字符',
                            maxLength: 50,
                            defaultValue: "保存",
                        }
                    },
                ],
            }
            ,
            {
                beforeSubmit: (param) => {
                    this.commonRequest(this.apiUrlConfig.updateDashboard, {
                        _id: this.dashboardId,
                        title: _.trim(title),
                        files: [],
                        data: allConfig,
                        saveType: "手动保存",
                        descs: param.descs
                    }, (code, msg, json) => {
                        message.success(msg);
                        document.title = this.typeName + ":" + _.trim(title);
                    }, (code, msg) => {
                        message.error(msg)
                    })
                    MB.close();
                    if(this.type===2){
                        //录制模板，直接在保存的时候生成封面图
                        this.createDashboardImg();
                    }
                    return false;
                },
            })
    }


    //新增场景
    addScene() {
        let {sceneArray} = this.state.allConfig;
        let newSceneId = window.getUID();
        sceneArray.push({
            _id: newSceneId,
            contentArray: [],
            styleConfig: {},
        });

        this.setState({
            currentSceneId: newSceneId,
            openDashboardConfigSet: false,  //是否编辑仪表板配置
            openSceneConfigSet: false,  //是否编辑场景配置
            openEditContent: false,
            currentSelectBox: null
        }, () => {
            this.calcBoxHeight()
        })
    }

    //克隆场景
    copyScene(giveSceneObj=null) {
        let {sceneArray} = this.state.allConfig;
        let {currentSceneId} = this.state;

        let copyScene;
        if(giveSceneObj){
            copyScene = giveSceneObj
        }else{
            sceneArray.forEach((scene) => {
                if (scene._id === currentSceneId) {
                    copyScene = _.cloneDeep(scene);
                }
            })
            if (!copyScene) {
                message.error("出错：找不到正在编辑的场景");
                return;
            }
        }

        let dealAction = (data) => {
            let prototypeStr = Object.prototype.toString.call(data);
            if (prototypeStr === '[object Object]') {
                //对象，提取键
                Object.keys(data).forEach((eachKey) => {
                    if (["_id", "tempId", "uid"].includes(eachKey)) {
                        //是id,替换
                        data[eachKey] = window.getUID();
                    } else {
                        dealAction(data[eachKey])
                    }
                })
            } else if (prototypeStr === '[object Array]') {
                //数组
                data.forEach((eachItem) => {
                    dealAction(eachItem)
                })
            } else {
                //其它，不处理
            }
        }

        dealAction(copyScene);

        sceneArray.push(copyScene);

        this.setState({
            sceneArray: sceneArray,
            currentSceneId: copyScene._id,
            openDashboardConfigSet: false,  //是否编辑仪表板配置
            openSceneConfigSet: false,  //是否编辑场景配置
            openEditContent: false,
            currentSelectBox: null,
        }, () => {
            this.calcBoxHeight()
        })
    }

    //删除场景
    removeScene() {
        let {currentSceneId} = this.state;
        let {sceneArray} = this.state.allConfig;
        _.remove(sceneArray, (ti, n) => {
            return ti._id === currentSceneId
        })
        this.setState({
            currentSceneId: null
        })
    }

    //转移容器
    transferContentItem(sceneItemMsg, content) {
        let {allConfig} = this.state;

        let {sceneArray} = allConfig;

        MB.show(
            {
                title: "转移容器",
                okTitle: "确定",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 5},
                    wrapperCol: {span: 16, offset: 0},
                },
                parameterArr: [
                    {
                        ver: true,
                        name: "选择场景",
                        field: "sceneId",
                        reg: (v) => {
                            return !!v
                        },
                        type: 'Select',
                        selectType: "value",
                        required: true,
                        option: {},
                        data: sceneArray.map((eachScene, index) => {
                            return {
                                key: eachScene._id,
                                value: eachScene._id,
                                title: eachScene.title || "场景" + (index + 1)
                            }
                        })
                    },
                ],
            }
            ,
            {
                beforeSubmit: (param) => {
                    if (param.sceneId === sceneItemMsg._id) {
                        message.info("选择了当前场景");
                        return false;
                    }

                    //新增
                    sceneArray.forEach((eachScene) => {
                        if (eachScene._id === param.sceneId) {
                            if (!eachScene.contentArray) {
                                eachScene.contentArray = [];
                            }
                            let copyItem = _.cloneDeep(content);
                            copyItem.uid = window.getUID();
                            delete copyItem.contentMsg.beConnectedConfig;
                            delete copyItem.contentMsg.connectConfig;
                            eachScene.contentArray.push(copyItem)
                        }
                    })
                    //删除
                    let needRemoveBox;
                    _.remove(sceneItemMsg.contentArray, (ti, n) => {
                        if(ti.uid === content.uid){
                            needRemoveBox = ti;
                        }
                        return ti.uid === content.uid
                    })
                    if(needRemoveBox?.contentMsg?.contentMode === "view" && needRemoveBox?.contentMsg?.beConnectedConfig){
                        //转移的容器是视图容器，在关联的参数容器中删除关联内容
                        sceneItemMsg.contentArray.forEach((eachBox)=>{
                            if(needRemoveBox?.contentMsg?.beConnectedConfig[eachBox.uid]){
                                delete eachBox?.contentMsg?.connectConfig[content.uid]
                            }
                        })
                    }
                    if(needRemoveBox?.contentMsg?.contentMode === "parameter" && needRemoveBox?.contentMsg?.connectConfig){
                        //转移的容器是参数容器，在关联的视图容器中删除被关联内容，且还要触发视图容器的数据更新
                        sceneItemMsg.contentArray.forEach((eachBox)=>{
                            if(needRemoveBox?.contentMsg?.connectConfig[eachBox.uid]){
                                this.allContentReloadMap[eachBox.uid]++;
                                delete eachBox?.contentMsg?.beConnectedConfig[content.uid]
                            }
                        })
                    }

                    this.setState({})
                    MB.close();
                    return false;
                },
            })
    }

    //新增容器
    addOneContent(sceneItemMsg, extraMsg) {
        //之前为空
        if (!sceneItemMsg.contentArray) {
            sceneItemMsg.contentArray = []
        }

        let newContentItem = {
            uid: window.getUID(),
            sizeConfig: _.cloneDeep(DefaultSizeConfig),
            zIndex: 100,
            ...extraMsg
        }

        // let maxY = 0;
        // sceneItemMsg.contentArray.forEach((item) => {
        //     // let y = item.sizeConfig.y + item.sizeConfig.h;
        //     let y = item.sizeConfig.y;
        //     maxY = Math.max(maxY, y);
        // })
        //
        // newContentItem.sizeConfig.x = Infinity;
        // newContentItem.sizeConfig.y = maxY;

        //写死 位于屏幕中间

        console.log(newContentItem)
        sceneItemMsg.contentArray.push(newContentItem)

        //通知演示页
        this.postChangeMsgToDemoPage({
            sceneItemMsg: sceneItemMsg,  //场景信息
            changeMsg: {    //修改事件的详情
                type: "addOneContent",
                newContentItem: newContentItem
            }
        })

        this.setState({},()=>{
            //新增的这个获取焦点
            this.dealClickContentItem(newContentItem, sceneItemMsg)
            this.filterAllCustomComponentFromDashboard();
        })
    }

    //删除某个容器
    removeOneContent(sceneItemMsg, needRemoveUid) {
        if (!sceneItemMsg.contentArray?.length) {
            message.error("该场景下无容器可匹配");
            return;
        }

        let needRemoveBox;
        _.remove(sceneItemMsg.contentArray, (ti, n) => {
            if(ti.uid === needRemoveUid){
                needRemoveBox = ti;
            }
            return ti.uid === needRemoveUid
        })

        if(needRemoveBox?.contentMsg?.contentMode === "view" && needRemoveBox?.contentMsg?.beConnectedConfig){
            //删除的容器是视图容器，在关联的参数容器中删除关联内容
            sceneItemMsg.contentArray.forEach((eachBox)=>{
                if(needRemoveBox?.contentMsg?.beConnectedConfig[eachBox.uid]){
                    delete eachBox?.contentMsg?.connectConfig[needRemoveUid]
                }
            })
        }
        if(needRemoveBox?.contentMsg?.contentMode === "parameter" && needRemoveBox?.contentMsg?.connectConfig){
            //删除的容器是参数容器，在关联的视图容器中删除被关联内容，且还要触发视图容器的数据更新
            sceneItemMsg.contentArray.forEach((eachBox)=>{
                if(needRemoveBox?.contentMsg?.connectConfig[eachBox.uid]){
                    this.allContentReloadMap[eachBox.uid]++;
                    delete eachBox?.contentMsg?.beConnectedConfig[needRemoveUid]
                }
            })
        }

        //通知演示页
        this.postChangeMsgToDemoPage({
            sceneItemMsg: sceneItemMsg,  //场景信息
            changeMsg: {    //修改事件的详情
                type: "removeOneContent",
                needRemoveUid: needRemoveUid
            }
        })

        let ifDelSel = needRemoveUid === this.state.currentSelContentId;
        //如果删除的容器是正在编辑的容器，关闭编辑栏
        if(this.currentEditContent && needRemoveUid === this.currentEditContent.uid){
            this.currentEditContent = null;
            this.currentEditContentFromScene = null;
            this.currentEditContentMsg = null;
            this.setState({
                openEditContent: false,
                currentSelContentId: ifDelSel?null:this.state.currentSelContentId,
                currentSelectBox: ifDelSel?null:this.state.currentSelectBox,
            },()=>{
                this.filterAllCustomComponentFromDashboard();
            })
        }else{
            this.setState({
                currentSelContentId: ifDelSel?null:this.state.currentSelContentId,
                currentSelectBox: ifDelSel?null:this.state.currentSelectBox,
            },()=>{
                this.filterAllCustomComponentFromDashboard();
            })
        }
    }

    //快速修改层级
    changeIndexFast(scene, content, type = "top") {
        if (!content || !scene) {
            return;
        }

        if (type === "top") {
            //置顶，zIndex + 1 即可
            let max = -1000;
            scene.contentArray.forEach((eachContent) => {
                max = Math.max(eachContent.zIndex, max);
            })

            content.zIndex = max + 1;
        } else {
            //置底，放在数组第一位
            let min = 1000;
            scene.contentArray.forEach((eachContent) => {
                min = Math.min(eachContent.zIndex, min);
            })

            content.zIndex = min - 1;
        }

        //通知演示页
        this.postChangeMsgToDemoPage({
            sceneItemMsg: scene,  //场景信息
            changeMsg: {    //修改事件的详情
                type: "changeIndexFast",
                changeContentId: content.uid,
                zIndex: content.zIndex
            }
        })

        this.setState({})
    }

    //获取一个默认的容器对象，抽象一下，方便同一改
    //beConnectedConfig  和  connectConfig 双向关联的目的是，修改自身时，能快速定位到关联方进行对应修改
    getContentDefaultObj(contentMode) {
        let result;
        switch (contentMode) {
            case "view":
                result = {
                    contentMode: "view",   //view视图  component组件  parameter 参数  other 其它
                    dataConfig: {   //信息配置：数据展示
                        dataSetId: null,    //view模式下：数据集id
                        dataSetTitle: null,
                        contentType: null,     //view模式下：图表的类型(表格，json，饼图，地图等等)
                        contentTitle: null, //容器标题
                        contentParams: null,   //视图参数
                    },
                    styleConfig: {},  //对单个容器定义的样式配置
                    beConnectedConfig: {},  //记录下被关联的参数的  参数容器uid为键，值为参数key数组
                    proConfig: null,    //高级配置
                    descConfig: {}   //备注配置
                };
                break;
            case "widget":
                result = {
                    contentMode: "widget",
                    dataConfig: {   //信息配置
                        contentTitle: null, //容器标题
                        contentType: null,    //部件类型 （日期组件，箭头组件等）
                        contentParams: null,   //部件参数
                    },
                    styleConfig: {},  //对单个容器定义的样式配置
                    proConfig: null,    //高级配置
                    descConfig: {}   //备注配置
                };
                break;
            case "parameter":
                result = {
                    contentMode: "parameter",
                    dataConfig: {   //信息配置
                        contentTitle: null, //容器标题
                        contentType: null,//参数类型 （字符串、数字、单选等）
                        contentParams: null,  //参数容器的参数
                        parameterValue: null,   //值
                        parameterStruct: null,  //原始值的结构(可能不存在)
                    },
                    styleConfig: {},  //对单个容器定义的样式配置
                    connectConfig: {}, //关联配置  关联容器uid为键,值为参数key数组
                    proConfig: null,    //高级配置
                    descConfig: {}   //备注配置
                };
                break;
            case "systemComponent":
                result = {
                    contentMode: "systemComponent",
                    dataConfig: {   //信息配置
                        contentTitle: null, //容器标题
                        contentType: null,//系统组件类型
                        contentParams: null,  //系统组件的参数
                    },
                    styleConfig: {},  //对单个容器定义的样式配置
                    proConfig: null,    //高级配置
                    descConfig: {}   //备注配置
                };
                break;
        }

        return result;
    }

    //新增主题
    addTheme() {
        MB.show(
            {
                title: "新增主题到系统全局",
                okTitle: "确定",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 8},
                    wrapperCol: {span: 10, offset: 0},
                },
                parameterArr: [
                    {
                        type: "input",
                        ver: true,
                        verMessage: "请填写名称，1-20个字符",
                        required: true,
                        field: "title",
                        name: '主题名称',
                        reg: /^[\S\s]{1,20}$/,
                        option: {
                            placeholder: '请填写主题名称',
                            defaultValue: null,
                            maxLength: "20"
                        }
                    },
                ],
            },
            {
                otherParam: {},
                url: APILXD.addTheme,
                beforeSubmit: (param) => {
                    param.type = 1; //后台主题
                    param.data = this.state.allConfig.themeConfig;
                    // param.files = this.BaseSetItem.collectFileUrls(param.data);
                    param.files = [];
                    return true;
                },
                callBack: (state, msg, returnData, param) => {
                    //添加成功回调
                    if (state === 'success') {
                        this.loadAllTheme();
                        this.state.allConfig.selectTheme = {
                            _id: returnData._id,
                            title: returnData.title
                        }
                        this.setState({})
                    }
                }
            });
    }

    //管理主题
    manageTheme(){
        Modal.info({
            title: "管理全局主题",
            width: "80%",
            maskClosable: true,
            icon: null,
            okText: "关闭",
            content: <div
              style={{
                  height: "60vh",
                  paddingTop: "20px",
                  overflow: "auto"
              }}
            >
                <DashboardThemeManage
                  themeConfig={this.state.allConfig.themeConfig}
                />
            </div>
        })
    }

    //处理布局变化
    dealLayoutChange(sceneItemMsg, layout) {
        console.log(layout)

        let temp = {};
        layout.forEach((layItem) => {
            temp[layItem.i] = layItem;
        })
        sceneItemMsg.contentArray?.forEach((contentItem) => {
            let newSize = temp[contentItem.uid];
            if (newSize) {
                if (contentItem.sizeConfig.w !== newSize.w || contentItem.sizeConfig.h !== newSize.h) {
                    //大小变化了
                    this.allContentRebuildMap[contentItem.uid]++
                }

                contentItem.sizeConfig.w = newSize.w;
                contentItem.sizeConfig.h = newSize.h;
                contentItem.sizeConfig.x = newSize.x;
                contentItem.sizeConfig.y = newSize.y;
                contentItem.sizeConfig.static = newSize.static || false;
            }
        })

        //通知演示页
        this.postChangeMsgToDemoPage({
            sceneItemMsg: sceneItemMsg,  //场景信息
            changeMsg: {    //修改事件的详情
                type: "dealLayoutChange",
                temp: temp
            }
        })

        this.setState({})
    }

    //调整大小的过程
    dealResizeChange(sceneItemMsg, layout) {

        let temp = {};
        layout.forEach((layItem) => {
            temp[layItem.i] = layItem;
        })
        sceneItemMsg.contentArray?.forEach((contentItem) => {
            let newSize = temp[contentItem.uid];
            if (newSize) {
                contentItem.sizeConfig.tempW = newSize.w;
                contentItem.sizeConfig.tempH = newSize.h;
            }
        })

        this.setState({})
    }

    /**
     * 获取仪表板版本历史列表
     */
    getDashboardSaveHistory() {
        Modal.info({
            width: "80%",
            maskClosable: true,
            icon: null,
            okText: "关闭",
            okType: "danger",
            content: <div
              style={{
                  height: "60vh",
                  // width: window.document.body.clientWidth * 0.6
                  paddingTop: "20px",
                  // marginBottom: "40px"
              }}
            >
                <SaveHistoryList
                  type={this.type}
                  post={{cbid: this.dashboardId}}
                  recoveryData={(newData) => {
                      let allConfig = newData.data;
                      if (!allConfig || JSON.stringify(allConfig) === "{}") {
                          //没有编辑过
                          allConfig = {
                              //场景数组
                              sceneArray: [],
                              //仪表板主题配置
                              themeConfig: _.cloneDeep(DefaultThemeConfig),
                              //选择的主题
                              selectTheme: {
                                  _id: null,
                                  title: null,
                              },
                          }
                      }
                      if (Object.keys(allConfig.themeConfig).length !== NeedMergeFieldsArr.length) {
                          //缺少配置
                          allConfig.themeConfig = this.BaseSetItem.mergeFinallyConfig(allConfig.themeConfig, DefaultThemeConfig, NeedMergeFieldsArr)
                      }
                      this.setState({
                          allConfig: allConfig,

                          forceUpdate: this.state.forceUpdate + 1, //控制强刷

                          currentSceneId: allConfig.sceneArray[0]?._id || null,
                          currentSelContentId: null, //当前选中的容器id
                          openEditContent: false, //编辑容器
                          currentContentInDyTempId: null, //当前正在编辑的动态数组中的容器id
                          openDashboardConfigSet: false,  //是否编辑仪表板配置
                          openSceneConfigSet: false,  //是否编辑场景配置
                      })

                      Modal.destroyAll();
                  }}
                />
            </div>
        })
    }

    //打开手机演示窗口
    openMobileDemoPage(){
        //重新存一遍配置
        let {allConfig,currentSceneId,usage,dashboardDetail} = this.state;

        let sceneItemMsg = this.findSceneById(currentSceneId)

        if(!sceneItemMsg){
            message.warning("找不到对应场景信息");
            return;
        }
        LocalStorage.setWithoutId(DashboardConfigTempSaveKey,{
            themeConfig: allConfig.themeConfig,    //仪表板配置
            sceneItemMsg: sceneItemMsg,  //某个场景信息
            usage: usage,
            dashboardDetail: dashboardDetail
        })

        let size = LocalStorage.get(MobileDemoPageSize) || {};
        let width =  size.width || 390;
        let height=  size.height || 844;
        let features =  'height='+height+',width='+width+',top=0,left=0,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no';
        this.mobileDemoWindow = window.open("/dashboardMobileDemo",'手机Demo',features);
    }

    //发送修改事件
    postChangeMsgToDemoPage(totalDemoData = {}){
        if(this.mobileDemoWindow){
            //将2个必填项补全
            if(!totalDemoData.themeConfig){
                totalDemoData.themeConfig = this.state.allConfig.themeConfig;
            }
            if(!totalDemoData.sceneItemMsg){
                totalDemoData.sceneItemMsg = this.findSceneById(this.state.currentSceneId);
            }

            LocalStorage.setWithoutId(DashboardConfigTempSaveKey,totalDemoData,true)
        }
    }

    //排序场景
    sortScenes(){
        let {allConfig} = this.state;

        if(!allConfig?.sceneArray?.length || allConfig?.sceneArray?.length === 1){
            message.warning("无需排序");
            return;
        }

        ModalConfig.show({
                title: "拖动以下栏目修改排序",
                centered: true,
                maskClosable: true,
                width: "60%",
                okText: "确认排序",
                cancelText: "放弃排序",
                onOk: () => {
                    let newIndexArr = this.filtersIndexSet.getIndexArr();
                    console.log("新的排序");
                    console.log(newIndexArr);

                    allConfig.sceneArray = newIndexArr;

                    this.setState({
                        allConfig: allConfig
                    })
                    ModalConfig.close();
                }
            }, <div
                style={{maxHeight:"75vh",overflow: "auto"}}
            >
                <FiltersIndexSet
                    indexArr={allConfig?.sceneArray}
                    diyIcon={(item)=>{
                        let platform = item.styleConfig?.sceneSpecialSet?.platform || "desktop";
                        if(platform === "desktop"){
                            return <DesktopOutlined
                            />
                        }
                        if(platform === "mobile"){
                            return <MobileOutlined
                            />
                        }
                    }}
                    diyTitle={(item)=>{
                        return item.title || <span style={{color:"gray"}}>(未命名)</span>
                    }}
                    ref={ref => this.filtersIndexSet = ref}
                />
            </div>
        )
    }

    //导出场景文件
    exportScenes(){
        let {sceneArray} = this.state.allConfig;
        let {currentSceneId} = this.state;

        let copyScene;
        sceneArray.forEach((scene) => {
            if (scene._id === currentSceneId) {
                copyScene = _.cloneDeep(scene);
            }
        })

        if (!copyScene) {
            message.error("出错：找不到正在编辑的场景");
            return;
        }

        window.downFileByText(JSON.stringify(copyScene),"场景配置文件.json")
    }

    //查看自定义组件使用情况
    openCustomComponentUseModal(){
        Modal.info({
            width: "80%",
            maskClosable: true,
            icon: null,
            okText: "关闭",
            okType: "danger",
            content: <div
              style={{
                  height: "60vh",
                  paddingTop: "20px",
              }}
            >
                <CustomVersionUpdate
                    allCustomComponentInDashboard={this.state.allCustomComponentInDashboard}
                    customRegisterData={this.state.customRegisterData}
                    onUpdate={(newContentItemMsg)=>{
                        console.log("需要更新的自定义组件",newContentItemMsg)

                        let {allConfig} = this.state;

                        allConfig?.sceneArray?.forEach((scene) => {
                            if(scene._id === newContentItemMsg.sceneId){
                                scene?.contentArray?.forEach((content) => {
                                    if (content.uid === newContentItemMsg.uid) {
                                        content.contentMsg = newContentItemMsg.contentMsg;
                                    }
                                })
                            }
                        })

                        Modal.destroyAll();
                        message.success("升级成功")
                        this.setState({
                            allConfig: allConfig
                        },()=>{
                            this.filterAllCustomComponentFromDashboard()
                        })
                    }}
                />
            </div>
        })
    }

    //生成模板缩略图
    createDashboardImg(cb){
        if(this.dashboardOuterLayoutBox){
            html2canvas(this.dashboardOuterLayoutBox,{
                useCORS: true,
                // allowTaint: true
            }).then((canvas)=>{
                if(canvas){
                    // let v = canvas.toDataURL("image/jpeg",0.1);
                    canvas.toBlob((blobData)=>{
                        if(blobData){
                            this.uploadHelp.upload(blobData,"jpeg",(code, msg, obj) => {
                                let allParams = {
                                    _id: this.dashboardId,
                                    img: obj.url,
                                };
                                HttpTool.post(APILXD.updateDashboardModelImg,()=>{
                                    //替换缩略图成功，静默处理
                                    cb?.()
                                },(code,msg)=>{
                                    message.warning(msg);
                                    cb?.()
                                },allParams)
                            },(error,msg)=>{
                                message.warning(msg);
                            },()=>{

                            }, `img_${this.dashboardId.slice(0,8)}.jpeg`)
                        }
                    },"image/jpeg",0.2);
                }
            })
        }
    }
}