import './___.js'
import React, {Component} from "react";
import { createRoot } from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {message, ConfigProvider} from "antd";
import CookieHelp from "./tool/CookieHelp";
import HttpTool from "./tool/HttpTool";
import Config from './config/config';
import routes from "./routes";
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import AntdTheme from "./AntdTheme";
// import ech

require('./compressionAndDecompression');

//配置
moment.locale('zh-cn');

CookieHelp.userCookieKey = Config.projectUniqueName;
HttpTool.authCookieName = Config.projectUniqueName + "_AUTH";
HttpTool.timeout = Config.requestTimeout;
HttpTool.setEncrypt(Config.publicKey);

message.config({
    top: window.screen.height / 3,
});

window["_NOENCRYPT"] = JSON.stringify(['/base-usercenter/']);
window["_CONCAT_API"] = '/api';

//加载外部js
// LXDHelp.loadScript("/js/aliyun-oss-sdk-6.1.0.min.js");
// LXDHelp.loadScript("/js/jquery.min.js");

// 获取您要挂载 React 应用的 DOM 元素
const container = document.getElementById('root');

// 使用 createRoot 创建一个根节点
const root = createRoot(container);

// 使用 root.render 方法渲染组件到页面上
root.render(
  <ConfigProvider
    locale={zhCN}
    theme={AntdTheme}
  >
      <BrowserRouter>
          {routes}
      </BrowserRouter>
  </ConfigProvider>
);