import React from "react";
import {
  FieldStringOutlined,
  InsertRowAboveOutlined,
  LineChartOutlined,
  BarChartOutlined,
  PieChartOutlined,
  RadarChartOutlined,
  MenuUnfoldOutlined,
  ExceptionOutlined,
  PercentageOutlined,
  HourglassOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  AntCloudOutlined,
  ExperimentOutlined,
  TableOutlined,
  DotChartOutlined,
  LayoutOutlined,
  AlignCenterOutlined,
  PartitionOutlined,
} from "@ant-design/icons";
import WordCloud2dSet from "./wordCloud2d/WordCloud2dSet";
import WordCloud2d from "./wordCloud2d/WordCloud2d";
import WordCloudSet from "./wordCloud3d/WordCloudSet";
import WordCloud from "./wordCloud3d/WordCloud";
import LineSet from "./showEchart/line/LineSet";
import LineShow from "./showEchart/line/LineShow";
import BarSet from "./showEchart/bar/BarSet";
import BarShow from "./showEchart/bar/BarShow";
import PieSet from "./showEchart/pie/PieSet";
import PieShow from "./showEchart/pie/PieShow";
import FunnelSet from "./showEchart/funnel/FunnelSet";
import FunnelShow from "./showEchart/funnel/FunnelShow";
import RadarSet from "./showEchart/radar/RadarSet";
import RadarShow from "./showEchart/radar/RadarShow";
import ScatterSet from "./showEchart/scatter/ScatterSet";
import ScatterShow from "./showEchart/scatter/ScatterShow";
import SunburstSet from "./showEchart/sunburst/SunburstSet";
import SunburstShow from "./showEchart/sunburst/SunburstShow";
import TreemapSet from "./showEchart/treemap/TreemapSet";
import TreemapShow from "./showEchart/treemap/TreemapShow";
import TreeSet from "./showEchart/tree/TreeSet";
import TreeShow from "./showEchart/tree/TreeShow";
import TableSet from "./table/TableSet";
import ShowTable from "./table/ShowTable";
import RankListSet from "./rankList/RankListSet";
import RankList from "./rankList/RankList";
import ImgListSet from "./imgList/ImgListSet";
import ImgList from "./imgList/ImgList";
import TimeLineSet from "./timeLine/TimeLineSet";
import TimeLine from "./timeLine/TimeLine";
import ShowProgressSet from "./showProgress/ShowProgressSet";
import ShowProgress from "./showProgress/ShowProgress";
import MultiProgressSet from "./multiProgress/MultiProgressSet";
import MultiProgress from "./multiProgress/MultiProgress";
import LiquidFillSet from "./liquidfill/LiquidFillSet";
import LiquidFill from "./liquidfill/LiquidFill";
import ShowJsonSet from "./showJson/ShowJsonSet";
import ShowJson from "./showJson/ShowJson";
import ShowTreeInDashboardSet from "./showTree/ShowTreeInDashboardSet";
import ShowTreeInDashboard from "./showTree/ShowTreeInDashboard";
import SimpleMsgSet from "./simpleMsgDiv/SimpleMsgSet";
import SimpleMsgDiv from "./simpleMsgDiv/SimpleMsgDiv";
import TimeDurationSet from "./timeDuration/TimeDurationSet";
import TimeDuration from "./timeDuration/TimeDuration";
import WorkAnalyseSet from "./workAnalyse/WorkAnalyseSet";
import ShowWorkAnalyse from "./workAnalyse/ShowWorkAnalyse";

//视图内容的注册
const ViewContentArray = [
  {
    groupName: "图表",  //分组名称
    children: [
      {
        title: '2D词云',   //名称
        type: "wordCloud2d",   //匹配类型
        icon: <AntCloudOutlined />,  //小图标
        cover: require("./wordCloud2d/cover.png"),  //封面图
        renderFile: WordCloud2d, //渲染视图文件
        setFile: WordCloud2dSet,  //配置文件
      },
      {
        title: '3D词云',
        type: "wordCloud",
        icon: <AntCloudOutlined />,
        cover: require("./wordCloud3d/cover.png"),
        renderFile: WordCloud,
        setFile: WordCloudSet,
      },
      {
        title: '折线图',
        type: "echart_line",
        icon: <LineChartOutlined />,
        cover: require("./showEchart/line/cover.png"),
        renderFile: LineShow,
        setFile: LineSet,
      },
      {
        title: '柱状图',
        type: "echart_bar",
        icon: <BarChartOutlined />,
        cover: require("./showEchart/bar/cover.png"),
        renderFile: BarShow,
        setFile: BarSet,
      },
      {
        title: '饼状图',
        type: "echart_pie",
        icon: <PieChartOutlined />,
        cover: require("./showEchart/pie/cover.png"),
        renderFile: PieShow,
        setFile: PieSet,
      },
      {
        title: '漏斗图',
        type: "echart_funnel",
        icon: <AlignCenterOutlined />,
        cover: require("./showEchart/funnel/cover.png"),
        renderFile: FunnelShow,
        setFile: FunnelSet,
      },
      {
        title: '雷达图',
        type: "echart_radar",
        icon: <RadarChartOutlined />,
        cover: require("./showEchart/radar/cover.png"),
        renderFile: RadarShow,
        setFile: RadarSet,
      },
      {
        title: '散点图',
        type: "echart_scatter",
        icon: <DotChartOutlined />,
        cover: require("./showEchart/scatter/cover.png"),
        renderFile: ScatterShow,
        setFile: ScatterSet,
      },
      {
        title: '旭日图',
        type: "echart_sunburst",
        icon: <PieChartOutlined />,
        cover: require("./showEchart/sunburst/cover.png"),
        renderFile: SunburstShow,
        setFile: SunburstSet,
      },
      {
        title: '矩形树图',
        type: "echart_treemap",
        icon: <LayoutOutlined />,
        cover: require("./showEchart/treemap/cover.png"),
        renderFile: TreemapShow,
        setFile: TreemapSet,
      },
      {
        title: '树状图',
        type: "echart_tree",
        icon: <PartitionOutlined />,
        cover: require("./showEchart/tree/cover.png"),
        renderFile: TreeShow,
        setFile: TreeSet,
      },
    ]
  },
  {
    groupName: "列表",
    children: [
      {
        title: '表格',
        type: "table",
        icon: <InsertRowAboveOutlined />,
        cover: require("./table/cover.png"),
        renderFile: ShowTable,
        setFile: TableSet,
      },
      {
        title: '数量排行榜',
        type: "rankList",
        icon: <OrderedListOutlined />,
        cover: require("./rankList/cover.png"),
        renderFile: RankList,
        setFile: RankListSet,
      },
      {
        title: '图片列表',
        type: "imgList",
        icon: <TableOutlined />,
        cover: require("./imgList/cover.png"),
        renderFile: ImgList,
        setFile: ImgListSet,
      },
      {
        title: '时间轴记事',
        type: "timeLine",
        icon: <UnorderedListOutlined />,
        cover: require("./timeLine/cover.png"),
        renderFile: TimeLine,
        setFile: TimeLineSet,
      },
    ]
  },
  {
    groupName: "进度",
    children: [
      {
        title: '进度条',
        type: "progressBar",
        icon: <PercentageOutlined />,
        cover: require("./showProgress/cover.png"),
        renderFile: ShowProgress,
        setFile: ShowProgressSet,
      },
      {
        title: '双值进度条',
        type: "multiProgress",
        icon: <PercentageOutlined />,
        cover: require("./multiProgress/cover.png"),
        renderFile: MultiProgress,
        setFile: MultiProgressSet,
      },
      {
        title: '水波图(进度)',
        type: "liquidFill",
        icon: <ExperimentOutlined />,
        cover: require("./liquidfill/cover.png"),
        renderFile: LiquidFill,
        setFile: LiquidFillSet,
      },
    ]
  },
  {
    groupName: "数据结构",
    children: [
      {
        title: 'JSON',
        type: "json",
        icon: <FieldStringOutlined />,
        cover: require("./showJson/cover.png"),
        renderFile: ShowJson,
        setFile: ShowJsonSet,
      },
      {
        title: '树结构',
        type: "tree",
        icon: <MenuUnfoldOutlined />,
        cover: require("./showTree/cover.png"),
        renderFile: ShowTreeInDashboard,
        setFile: ShowTreeInDashboardSet,
      },
    ]
  },
  {
    groupName: "其它",
    children: [
      {
        title: "简略信息",
        type: "simpleMsg",
        icon: <ExceptionOutlined />,
        cover: require("./simpleMsgDiv/cover.png"),
        renderFile: SimpleMsgDiv,
        setFile: SimpleMsgSet,
      },
      {
        title: "计时器",
        type: "timeDuration",
        icon: <HourglassOutlined />,
        cover: require("./timeDuration/cover.png"),
        renderFile: TimeDuration,
        setFile: TimeDurationSet,
      },
      {
        title: "任务分析",
        type: "showWorkAnalyse",
        icon: <PieChartOutlined />,
        cover: require("./workAnalyse/cover.png"),
        renderFile: ShowWorkAnalyse,
        setFile: WorkAnalyseSet,
      },
    ]
  }
]

export {ViewContentArray};