// @flow
import React, {Component} from 'react';
import styles from './ShowCode.less';

import {Tree} from 'antd';
import TreeDataHandler from "../../tool/treeDataHandler";
import Utils from "../blockly/blockly/Utils";  //  加载  JS

/**
 * 使用方法
 * ref.showValue(object|string)
 */
export default class ShowTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            upView:0,
        };


    }

    showValue(data,meteData) {
        let treeData = this.changeTreeData(data,"ROOT",(obj,titleType,valueType,upKey,key,value)=>{

            if(titleType==="array"){
                let before  = "";
                if(obj.children&&obj.children.length>0){

                    for(let {title} of obj.children){
                        before+=title+";"
                        if(before.length>20){
                            break;
                        }
                    }
                }
                if(before.length>0){
                    before = "    ( "+before.substring(0,20)+"... )"
                }
                obj.title =  "第"+(key+1)+"条"+before;
            }else  if(titleType==="object"){

                let result = this.findTreeTitle(meteData,key,valueType);
                let before = "";
                if(result.isArray){
                    let size = obj.children?obj.children.length:0
                    before = "  ("+size+"条)"
                }
                obj.title =  result.title+before +(value?("："+value):"");
                obj.icon = result.icon;
            }else {
                obj.title =  key+"("+valueType+")(其他)"+(value?("："+value):"");;
            }
        });

        this.setState({
            upView:this.state.upView+1,
            data:treeData,
        },()=>{

        })

    }



    changeTreeData(data,upKey="ROOT",callBack) {

        //遍历数据，验证数据是否是数组，如果是数组，进行修改
        if (data === undefined || data === null) {
            return null;
        }
        if (Array.isArray(data)) {
            //如果是数组，

            let newData = [];
            let size = data.length;
            for (let i = 0; i < size; i++){
                newData.push(this.getTreeItem(upKey,i,data[i],"array",callBack));
            }
            return  newData;
        }else {
            //如果是对像
            let titleType = typeof data;
            switch (titleType) {
                case "object": //对象某个键的值还是对象
                    let newData = [];
                    Object.keys(data).forEach((key) => {
                        newData.push(this.getTreeItem(upKey,key,data[key],titleType,callBack));
                    })
                    return  newData;
                default://默认按字符串处理
                    return this.getTreeItem(upKey,"none",data,titleType,callBack);
            }
        }

    }

    getTreeItem(upKey,key,item,titleType,callBack){
        let obj = {};
        obj.key =  upKey+"-"+key

        let valueType = ""
        let value = null;
        switch (typeof item) {
            case "object": //对象某个键的值还是对象
                valueType =  "对像"

                obj.children= this.changeTreeData(item,obj.key,callBack);
                break;
            case "number"://数字要处理最大值  9007199254740992最大
                valueType =  "数字"
                value = item;
                break;
            default://默认按字符串处理
                valueType =  "文本"
                value = item;
                break;
        }
        callBack&&callBack(obj,titleType,valueType,upKey,key,value)
        return obj;

    }
    findTreeTitle(col,key,valueType){
        if(!this.titleData){
            this.titleData ={};
        }
        if(this.titleData.hasOwnProperty(key)){
            return this.titleData[key];
        }
        let selTree = TreeDataHandler.searchTreeNodeByField(col, {
            field: "_id",
            value: key
        });
        let type = "Ohter";
        let title = valueType+"["+key+"]";
        let isArray = false;
        if(selTree){
            if (selTree.isArray) {
                type = "Array_"+selTree.dataType;
            }else {
                type = selTree.dataType;``
            }
            title = selTree.title
            isArray = selTree.isArray
        }

        let imageObj = Utils.getImageObj(type);
        this.titleData[key] ={
            title:title,
            isArray:isArray,
            typeTitle:imageObj.name,
            icon : <div style={{
                width:16,
                height:16,
                backgroundImage:"url("+imageObj.url+")",
                display: "inline-block",
                backgroundSize: "100% 100%",
            }}/>,
            type:type,
        }
        return   this.titleData[key];

    }
    componentDidMount() {




    }




    render() {


        return (
            <div className={styles.main}>
                <Tree
                    key={this.state.upView}
                    selectable={true}
                    defaultExpandAll={false}
                    defaultExpandParent={true}
                    blockNode={true}

                    showIcon={true}
                    treeData={this.state.data||[]}
                />
            </div>
        );
    }

}

