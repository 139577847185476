import React, {Component} from 'react';

/** 首页 **/
import IndexPage from './indexPage/index';

//后台账号
import AccountList from './navigationPages/accountManage/AccountList';
import AddAccount from './navigationPages/accountManage/AddAccount';

//公司管理
import CompanyList from './navigationPages/accountManage/Company';

//用户管理
import AccountPersonal from './navigationPages/accountManage/AccountPersonal';

//系统配置
import SystemSet from './navigationPages/systemManage/SystemSet';

//设备统计图表
import DeviceStaticsChart from "./navigationPages/deviceStatics/DeviceStaticsChart";

//新心跳设备列表
import DeviceStaticsList from "./navigationPages/deviceStatics/DeviceStaticsList";
import DeviceStaticsDetail from "./navigationPages/deviceStatics/DeviceStaticsDetail";

//仪表板主题
import DashboardThemeList from "./navigationPages/dashboardThemeManage/DashboardThemeList";
//内置查询
import BuiltInQueryList from "./navigationPages/registOpenApi/OpenApiList";

//设备和安装包
import DeviceList from './navigationPages/deviceManage/DeviceList';
import DeviceShare from "./navigationPages/deviceManage/DeviceShare";
import SeparationList from "./navigationPages/deviceManage/SeparationList";
import DeviceShareRules from "./navigationPages/deviceManage/DeviceShareRules";
import PackageAndroid from "./navigationPages/packageManage/PackageAndroid";
import VersionList from "./navigationPages/packageManage/version/VersionList";
import VersionAdd from "./navigationPages/packageManage/version/VersionAdd";
import UpdateTest from "./navigationPages/packageManage/testManage/UpdateTest";
import UploadPackage from "./navigationPages/packageManage/UploadPackage";
import IPFSWhiteList from "./navigationPages/packageManage/IPFSWhiteList";
import DeviceAdd from './navigationPages/deviceManage/DeviceAdd';
import DeviceUnbind from './navigationPages/deviceManage/DeviceUnbind';
import SystemLogList from './navigationPages/logManage/SystemLogList';
import ShardDelDeviceConfig from './navigationPages/deviceManage/ShardDelDeviceConfig';

//ESIM
import ESIMList from "./navigationPages/esimManage/ESIMList";
import ESIMMessageList from "./navigationPages/esimManage/ESIMMessageList";

//回收站
import ESIMRecycleList from "./navigationPages/esimManage/ESIMRecycleList";

//终端列表
import RomRuleManage from "./navigationPages/romManage/RomRuleManage.js";

//仪表板模板
import DashboardModelList from "./navigationPages/dashboardThemeManage/DashboardModelList";

//自定义组件管理
import CustomComponentsList from "./navigationPages/dashboardThemeManage/CustomComponentsList";

module.exports = {
    getView(props) {
        return [
            {
                path: "IndexPage",
                component: <IndexPage {...props} />
            },
            {
                path: "AccountList",
                component: <AccountList {...props} />
            },
            {
                path: "AddAccount",
                component: <AddAccount {...props} />
            },
            {
                path: "CompanyList",
                component: <CompanyList {...props} />
            },
            {
                path: "UserList",
                component: <AccountPersonal {...props} />
            },
            {
                path: "DeviceList",
                component: <DeviceList {...props}/>
            },
            {
                path: "IndexPage",
                component: <IndexPage {...props} />
            },
            {
                path: "AccountList",
                component: <AccountList {...props} />
            },
            {
                path: "AddAccount",
                component: <AddAccount {...props} />
            },
            {
                path: "DeviceList",
                component: <DeviceList {...props}/>
            },
            {
                path: "DeviceShare",
                component: <DeviceShare {...props}/>
            },
            {
                path: "DeviceAdd",
                component: <DeviceAdd {...props}/>
            },
            {
                path: "DeviceUnbind",
                component: <DeviceUnbind {...props}/>
            },
            {
                path: "SeparationList",
                component: <SeparationList {...props}/>
            },
            {
                path: "AccountPersonal",
                component: <AccountPersonal {...props}/>
            },
            {
                path: "DeviceShareRules",
                component: <DeviceShareRules {...props}/>
            },
            {
                path: "PackageAndroid",
                component: <PackageAndroid {...props}/>
            },
            {
                path: "VersionList",
                component: <VersionList {...props}/>
            },
            {
                path: "VersionAdd",
                component: <VersionAdd {...props}/>
            },
            {
                path: "UpdateTest",
                component: <UpdateTest {...props}/>
            },
            {
                path: "UploadPackage",
                component: <UploadPackage {...props}/>
            },
            {
                path: "SystemLogList",
                component: <SystemLogList {...props} />
            },
            {
                path: "SystemSet",
                component: <SystemSet {...props} />
            },
            {
                path: "DeviceStaticsChart",
                component: <DeviceStaticsChart {...props} />
            },
            {
                path: "DeviceStaticsList",
                component: <DeviceStaticsList {...props} />
            },
            {
                path: "DeviceStaticsDetail",
                component: <DeviceStaticsDetail {...props} />
            },
            {
                path: "DashboardThemeList",
                component: <DashboardThemeList {...props} />
            },
            {
                path: "BuiltInQueryList",
                component: <BuiltInQueryList {...props} />
            },
            {
                path: "IPFSWhiteList",
                component: <IPFSWhiteList {...props} />
            },
            {
                path: "ShardDelDeviceConfig",
                component: <ShardDelDeviceConfig {...props} />
            },
            {
                path: "ESIMList",
                component: <ESIMList {...props} />
            },
            {
                path: "ESIMRecycleList",
                component: <ESIMRecycleList {...props} />
            },
            {
                path: "ESIMMessageList",
                component: <ESIMMessageList {...props} />
            },
            {
                path: "RomRuleManage",
                component: <RomRuleManage {...props} />
            },
            {
                path: "DashboardModelList",
                component: <DashboardModelList {...props} />
            },
            {
                path: "CustomComponentsList",
                component: <CustomComponentsList {...props} />
            },
        ]
    }
};