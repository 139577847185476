import React from "react";
import styles from "./DashboardConfigSet.less";
import {Form, Button, message, Tabs, Modal, Space, Tooltip, Radio, Switch} from "antd";
import BaseSetItem from "./BaseSetItem";
import TimeHelp from '../../../../tool/TimeHelp';
import LocalStorage from '../../../../tool/LocalStorage';
import {FormatPainterOutlined,BgColorsOutlined,CameraOutlined} from "@ant-design/icons";
import UploadFile from "../../../../components/upLoad/UploadFile"

const DashboardItemStyleObjSaveKey = "DashboardItemStyleObjSaveKey";

const NeedDealKeys = ["commonSet","sceneBackgroundSet","contentBackgroundSet","titlePlus","contentSet","contentTitle","contentFont"]

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
    size: "small"
};

export default class SceneConfigSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSelTabKey: "sceneBackgroundSet",

            formUpdate: 0
        }

        //自己已经配置过的
        this.selfConfig = _.cloneDeep(this.props.selfConfig) || {};
        //父集的配置
        this.parentConfig = _.cloneDeep(this.props.parentConfig) || {};



        this.BaseSetItem = new BaseSetItem({
            selfConfig: this.selfConfig,
            resetField: (formName,resetKey)=>{
                delete this.selfConfig[formName][resetKey];
                //重新合并
                this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);

                this.setState({
                    formUpdate: this.state.formUpdate + 1
                },()=>{
                    this.props.onResetField?.(formName,resetKey)
                })
            }
        })

        this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);
    }

    //延迟触发，避免太频繁
    delayChangeAction(action){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            action?.();
        },300)
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    render() {
        let {currentSelTabKey,formUpdate} = this.state;
        return <div className={styles.dashboardSetBox}>
            <div className={styles.dashboardSetHead}>
                <Space>
                    场景适配：
                    <Radio.Group
                        onChange={(e) => {
                            let v = e.target.value;
                            if(!this.selfConfig.sceneSpecialSet){
                                //父级没有场景特殊，所以这里可能是不存在的
                                this.selfConfig.sceneSpecialSet = {};
                            }
                            this.selfConfig.sceneSpecialSet.platform= v;
                            this.BaseSetItem.upDateSelfConfig(this.selfConfig);
                            //通知外部改变
                            this.props.onChange("sceneSpecialSet","platform",v);
                        }}
                        defaultValue={this.formDefaultValues.sceneSpecialSet?.platform || "desktop"}
                    >
                        <Radio value={"all"}>全部</Radio>
                        <Radio value={"desktop"}>桌面端</Radio>
                        <Radio value={"mobile"}>移动端</Radio>
                    </Radio.Group>
                </Space>
                <Button
                    type={"link"}
                    onClick={() => {
                        this.props.onClose?.()
                    }}
                >
                    <span style={{color: "red"}}>收起</span>
                </Button>
            </div>
            <div className={styles.dashboardSetHead}>
                <Space>
                    无边界模式：
                    <Switch
                        defaultChecked={this.formDefaultValues.sceneSpecialSet?.openSizeLimit}
                        checkedChildren={"已开启"}
                        unCheckedChildren={"已关闭"}
                        onChange={(checked)=>{
                            if(!this.selfConfig.sceneSpecialSet){
                                //父级没有场景特殊，所以这里可能是不存在的
                                this.selfConfig.sceneSpecialSet = {};
                            }
                            this.selfConfig.sceneSpecialSet.openSizeLimit= checked;
                            this.BaseSetItem.upDateSelfConfig(this.selfConfig);
                            //通知外部改变
                            this.props.onChange("sceneSpecialSet","openSizeLimit",checked);
                        }}
                    />
                </Space>
            </div>
            <div
                className={styles.dashboardSetBody}
            >
                <Form.Provider
                    onFormChange={(formName, info) => {

                        this.delayChangeAction(()=>{
                            console.log(formName)
                            console.log(info)

                            let changedDataNames = info.changedFields?.[0]?.name;
                            let changedKey = changedDataNames[0];

                            let changedValue;
                            if(changedDataNames.length === 1){
                                //单键修改，单键值替换
                                changedValue = info.changedFields?.[0].value
                            }else{
                                //组合修改，把组合的值都取出来，全替换
                                let formAllValue = info.forms[formName].getFieldsValue();
                                changedValue = formAllValue[changedKey];
                            }

                            //修改组件内部的，让 label 重新判断
                            _.set(this.selfConfig,[formName,changedKey],changedValue);
                            this.BaseSetItem.upDateSelfConfig(this.selfConfig);

                            //通知外部改变
                            this.props.onChange(formName,changedKey,changedValue);
                        })

                    }}
                >
                    <Tabs
                        activeKey={currentSelTabKey}
                        tabPosition={"left"}
                        type={"card"}
                        onChange={(activeKey) => {
                            this.setState({
                                currentSelTabKey: activeKey
                            })
                        }}
                    >
                        <Tabs.TabPane
                            tab={"场景缩略图"}
                            key={"thumbsImgSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("thumbsImgSet","场景缩略图")}
                                <UploadFile
                                    key={"upf_" + formUpdate}
                                    accept={"image/*"}
                                    bigLabel={true}
                                    toCN={true}
                                    size={1}
                                    data={{
                                        option: {
                                            size: 1,
                                            defaultValue: this.formDefaultValues.thumbsImgSet?.upload_imageUrl ? [{
                                                url: this.formDefaultValues.thumbsImgSet.upload_imageUrl
                                            }] : null
                                        }
                                    }}
                                    onChange={(fileList) => {
                                        let newUrl = fileList[0]?.url || null;
                                        if(!this.selfConfig.thumbsImgSet){
                                            //父级没有缩略图配置，没地方继承样式，所以这里可能是不存在的
                                            this.selfConfig.thumbsImgSet = {};
                                        }
                                        this.selfConfig.thumbsImgSet.upload_imageUrl= newUrl;
                                        this.BaseSetItem.upDateSelfConfig(this.selfConfig);
                                        //通知外部改变
                                        this.props.onChange("thumbsImgSet","upload_imageUrl",newUrl);
                                    }}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"场景背景"}
                            key={"sceneBackgroundSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("sceneBackgroundSet","场景背景")}
                                <Form
                                    key={"sceneBackgroundSet_" + formUpdate}
                                    name={"sceneBackgroundSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.sceneBackgroundSet}
                                >
                                    {this.BaseSetItem.getBackgroundSet("sceneBackgroundSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器配置"}
                            key={"commonSet"}
                            // forceRender={true}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("commonSet_","容器配置")}
                                <Form
                                    key={"commonSet_" + formUpdate}
                                    name={"commonSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.commonSet}
                                >
                                    {this.BaseSetItem.getCommonSetForm("commonSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器背景"}
                            key={"contentBackgroundSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentBackgroundSet","容器背景")}
                                <Form
                                    key={"contentBackgroundSet_" + formUpdate}
                                    name={"contentBackgroundSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.contentBackgroundSet}
                                >
                                    {this.BaseSetItem.getBackgroundSet("contentBackgroundSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器标题"}
                            key={"contentTitle"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentTitle","容器标题")}
                                <Form
                                    key={"contentTitle_" + formUpdate}
                                    name={"contentTitle"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.contentTitle}
                                >
                                    {this.BaseSetItem.getContentTitle("contentTitle")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"标题背景"}
                            key={"titlePlus"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("titlePlus","标题背景")}
                                <Form
                                    key={"titlePlus_" + formUpdate}
                                    name={"titlePlus"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.titlePlus}
                                >
                                    {this.BaseSetItem.getTitlePlusSet("titlePlus")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"内容样式"}
                            key={"contentSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentSet","内容样式")}
                                <Form
                                    key={"contentSet_" + formUpdate}
                                    name={"contentSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.formDefaultValues.contentSet}
                                >
                                    {this.BaseSetItem.getContentSet("contentSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        {/*<Tabs.TabPane*/}
                        {/*    tab={"内容字体"}*/}
                        {/*    key={"contentFont"}*/}
                        {/*>*/}
                        {/*    <div className={styles.tabPaneRightBox}>*/}
                        {/*        {this.getStylesCopyBtns("contentFont","内容字体")}*/}
                        {/*        <Form*/}
                        {/*            key={"contentFont_" + formUpdate}*/}
                        {/*            name={"contentFont"}*/}
                        {/*            {...formItemLayout}*/}
                        {/*            requiredMark={false}*/}
                        {/*            initialValues={this.formDefaultValues.contentFont}*/}
                        {/*        >*/}
                        {/*            {this.BaseSetItem.getContentFontSet("contentFont")}*/}
                        {/*        </Form>*/}
                        {/*    </div>*/}
                        {/*</Tabs.TabPane>*/}
                    </Tabs>
                </Form.Provider>
            </div>
        </div>
    }

    getStylesCopyBtns(formName,title){
        return <div
            className={styles.copyItemsBtnBox}
        >
            <Space>
                <Tooltip
                    title={"收集样式"}
                >
                    <Button
                        icon={<FormatPainterOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={(e)=>{
                            LocalStorage.setWithoutId(DashboardItemStyleObjSaveKey,{
                                formName: formName,
                                title: title,
                                time: Date.now(),
                                styleObj: this.selfConfig[formName]
                            })
                            message.success(`已收集到【${title}】样式`);
                        }}
                    >

                    </Button>
                </Tooltip>
                <Tooltip
                    title={"样式粘贴"}
                >
                    <Button
                        icon={<BgColorsOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={()=>{
                            let obj = LocalStorage.getWithoutId(DashboardItemStyleObjSaveKey);

                            if(!obj){
                                message.warning("缓存中无收集到的样式");
                                return;
                            }

                            if(obj.formName !== formName){
                                message.warning(`收集的样式【${obj.title}】 与 该栏目不匹配`);
                                return;
                            }


                            Modal.confirm({
                                title: "提示",
                                content: <div>
                                    <div>是否将【{title}】的样式配置替换成【收集】到的样式？</div>
                                    <div>收集时间：{TimeHelp.getYMDHMS(obj.time,true)}</div>
                                </div>,
                                onOk: ()=>{
                                    //修改组件内部的，让 label 重新判断
                                    _.set(this.selfConfig,[formName],obj.styleObj);
                                    this.BaseSetItem.upDateSelfConfig(this.selfConfig);
                                    this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);

                                    //通知外部改变
                                    this.setState({
                                        formUpdate: this.state.formUpdate + 1
                                    },()=>{
                                        this.props.onChange(formName,null,obj.styleObj);
                                    })
                                }
                            })
                        }}
                    >

                    </Button>
                </Tooltip>
            </Space>
        </div>
    }
}