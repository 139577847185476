import React from "react";
import {Tag,Select} from "antd";

export default class StringArraySelectShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let options = [];
        if(contentParams.list?.length){
            options = options.concat(contentParams.list.map((str)=>{
                return {
                    label: str,
                    value: str
                }
            }))
        }
        if(contentParams.listSpecial?.length){
            let vs = contentParams.listSpecial.filter((item)=>{
                return !!item.label && !!item.value
            })
            options = options.concat(vs)
        }


        return <div
            style={{width: "100%",
                display: "flex",
                alignItems: "center",
            }}
            className={"swiper-no-swiping"}
        >
            {
                contentParams.label
                    ?<span>{contentParams.label}</span>
                    :null
            }
            <div
                style={{flex: 1}}
            >
                <Select
                    allowClear={true}
                    mode={"multiple"}
                    {...contentParams.selectOption}
                    defaultValue={parameterValue || []}
                    showSearch={true}
                    filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={options}
                    onChange={(value)=>{
                        this.props.valueChanged?.(value,parameterValue)
                    }}
                    style={{width: "100%"}}
                    tagRender={(props) => {
                        return <Tag
                            color={"cyan"}
                            closable={props.closable}
                            style={{marginRight: 3}}
                            onClose={props.onClose}
                        >
                            {props.label}
                        </Tag>
                    }}
                >

                </Select>
            </div>
        </div>
    }
}