import React from "react";
import {
    Form,
    Radio,
    Input, Select, Tag
} from "antd";
import SelectOptionMake from "../showStringSelect/SelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"输入框大小"}
            name={["inputOption","size"]}
        >
           <Radio.Group>
               <Radio value={"small"}>小</Radio>
               <Radio value={"middle"}>中</Radio>
               <Radio value={"large"}>大</Radio>
           </Radio.Group>
        </Item>
        <Item
            label={"提示语"}
            name={["inputOption","placeholder"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"前缀标签"}
            name={["inputOption","addonBefore"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"后缀标签"}
            name={["inputOption","addonAfter"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
          label={"普通选项"}
          name={["list"]}
        >
            <Select
              mode={"tags"}
              allowClear={true}
              placeholder={"请填写定义下拉选项"}
              tagRender={(props) => {
                  return <Tag
                    color={"cyan"}
                    closable={props.closable}
                    style={{marginRight: 3}}
                    onClose={props.onClose}
                  >
                      {props.label}
                  </Tag>
              }}
              maxTagTextLength={30}
            ></Select>
        </Item>
        <Item
          label={"映射选项"}
          name={["listSpecial"]}
        >
            <SelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        inputOption: {
            size: "middle",
            placeholder: "请填写内容",
            addonBefore: null,
            addonAfter: null,
        },
        list: [],
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}