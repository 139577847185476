import React from "react";
import {
    Form,
    Radio,
    Input,
    Switch,
    InputNumber
} from "antd";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"输入框大小"}
            name={["inputOption","size"]}
        >
           <Radio.Group>
               <Radio value={"small"}>小</Radio>
               <Radio value={"middle"}>中</Radio>
               <Radio value={"large"}>大</Radio>
           </Radio.Group>
        </Item>
        <Item
            label={"最小值"}
            name={["inputOption","min"]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"最大值"}
            name={["inputOption","max"]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"精度"}
            name={["inputOption","precision"]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"提示语"}
            name={["inputOption","placeholder"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"前缀标签"}
            name={["inputOption","addonBefore"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"后缀标签"}
            name={["inputOption","addonAfter"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        inputOption: {
            size: "middle",
            placeholder: "请填写内容",
            addonBefore: null,
            addonAfter: null,
            min: 0,
            max: 1000,
            precision: 2,
        }
    }
}

export default {
    getSetItems,
    getDefaultValues,
}