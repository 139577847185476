import React from "react";
import {Radio,Tabs,Empty} from "antd";
import ShowCode from "../../../../edit/ShowCode";
import AllContentMap from "../AllContentDefinition";
import styles from "./ViewAndCode.less";
import BaseSetItem from "../../configSet/BaseSetItem";

const ContentMergeFields = ["commonSet", "contentBackgroundSet", "contentSet", "contentTitle", "contentFont", "titlePlus"];

export default class ViewAndCode extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      showType: "chartShow",
    }

    this.BaseSetItem = new BaseSetItem();

    this.matchContent = null;
    this.demoCodeValue = null;

    if(this.props.contentMsg){
      this.matchContent = AllContentMap[this.props.contentMsg?.dataConfig?.contentType];
      if(this.matchContent){
        this.demoCodeValue = this.matchContent.setFile?.getDemoCode?.()
      }
    }

    this.originValue = this.props.defaultData;
  }

  componentDidMount(){
    if(this.originValue){
      this.showValue(this.originValue);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.styleUpdate !== nextProps.styleUpdate) {
      //需要样式更新，数据不用更新
      console.log("样式变了，重新渲染")
      this.newProps = nextProps;
      this.dealStylesConfigMerge();
    }
  }

  dealStylesConfigMerge() {
    let contentStyle = this.newProps.contentMsg?.styleConfig || {};
    let parentStylesConfig;
    if (this.newProps.parentStylesConfig) {
      //传入了合并完的场景样式
      parentStylesConfig = _.cloneDeep(this.newProps.parentStylesConfig)
    } else {
      //分别传入 仪表板 和 场景未合并的样式
      let themeConfig = _.cloneDeep(this.newProps.themeConfig);
      let sceneStyleConfig = _.cloneDeep(this.newProps.scencStyleConfig);
      parentStylesConfig = this.BaseSetItem.mergeFinallyConfig(sceneStyleConfig, themeConfig, ContentMergeFields);
    }

    this.finallyStyleConfig = this.BaseSetItem.mergeFinallyConfig(contentStyle, parentStylesConfig, ContentMergeFields);
    console.log("this.finallyStyleConfig", this.finallyStyleConfig)
  }

  showValue(value){
    console.log("展示数据",value)

    this.originValue = value;
    let {showType} = this.state;

    if(showType === "chartShow" && this.viewBoxRef){
      this.viewBoxRef.showValue(value)
    }

    if(showType === "dataCode" && this.showCode){
      this.showCode.showValue(value)
    }
  }

  render(){
    let {showType,data} = this.state;
    let ViewDiv = this.matchContent?.renderFile;

    let renderContentMsg = _.cloneDeep(Object.assign({},this.props.contentMsg,{
      styleConfig: this.finallyStyleConfig
    }))

    return <div className={styles.outerBox}>
      <Tabs
        style={{height: "100%"}}
        activeKey={showType}
        onChange={(newKey)=>{
          this.setState({
            showType: newKey,
          },()=>{
            setTimeout(()=>{
              this.showValue(this.originValue)
            },200)
          })
        }}
        tabBarStyle={{margin: 0, padding: "0 20px"}}
        type={"card"}
        centered
        items={[
          {
            label: "图表视图",
            key: "chartShow",
            children: <div className={styles.tabPaneBox}>
              {
                ViewDiv
                  ?<ViewDiv
                    ref={ref => this.viewBoxRef = ref}
                    styleUpdate={this.newProps?.styleUpdate}
                    contentMsg={renderContentMsg}
                  />
                  :<Empty description={"未选择视图类型"} style={{marginTop: "100px"}}/>
              }
            </div>
          },
          {
            label: "数据展示",
            key: "dataCode",
            children: <div className={styles.tabPaneBox}>
              <ShowCode
                ref={(ref) => {
                  this.showCode = ref;
                }}
                readOnly={true}
                hiddenHelp={true}
                defaultValue={this.originValue}
                wait={500}
              />
            </div>
          },
          {
            label: "数据结构",
            key: "demoCode",
            children: <div className={styles.tabPaneBox}>
              {
                this.props.contentMsg
                  ?<ShowCode
                    ref={(ref) => {
                      this.demoCode = ref;
                    }}
                    readOnly={true}
                    hiddenHelp={true}
                    defaultValue={this.demoCodeValue}
                    wait={500}
                  />
                  :<Empty description={"未选择视图类型"} style={{marginTop: "100px"}}/>
              }
            </div>
          }
        ]}
      />
    </div>
  }
}