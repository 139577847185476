import React from "react";
import {
    Form,
    Divider,
    Select,
    AutoComplete,
    Radio,
    Switch,
    Collapse,
    Input
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

const Item = Form.Item;

/**
 * 矩形树图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }


    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"名称维度"}
                name={["dataSetConfig","labelField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"数值维度"}
                name={["dataSetConfig","valueField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"子集维度"}
                name={["dataSetConfig","childrenField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="treemapSpecial"
            header="矩形树图专用"
        >
            <Item
                label={"展示方式"}
                name={["treemapSpecial","leafDepth"]}
            >
                <Select
                    placeholder={"选择数据展示方式"}
                >
                    <Select.Option value={null}>展示全部数据</Select.Option>
                    <Select.Option value={1}>按层级展示，数据下钻</Select.Option>
                </Select>
            </Item>
            <Item
                noStyle={true}
                shouldUpdate={(prevValues, currentValues) => prevValues.treemapSpecial.leafDepth !== currentValues.treemapSpecial.leafDepth}
            >
                {({getFieldValue})=>{
                    if(getFieldValue(["treemapSpecial","leafDepth"])){
                        return  <Item
                            label={"可下钻标记"}
                            name={["treemapSpecial","drillDownIcon"]}
                        >
                            <AutoComplete
                                placeholder={"填写下钻提示符"}
                                maxLength={10}
                                options={[
                                    {value: '▶'},
                                    {value: '▼'},
                                    {value: '★'},
                                    {value: '◆'},
                                    {value: '➤'},
                                    {value: '[可展开]'},
                                ]}
                            />
                        </Item>
                    }
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="itemStyle"
            header="面积样式"
            extra={
                <Item
                    noStyle={true}
                    name={["itemStyle","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <Item
                noStyle={true}
                dependencies={["itemStyle", "_setType"]}
            >
                {({getFieldValue}) => {
                    let setType = getFieldValue(["itemStyle", "_setType"]);

                    return <>
                        <Item
                            noStyle={true}
                            hidden={setType !== "normal"}
                        >
                            <Item
                                label={"描边线宽"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderWidth"], {
                                    min: 0,
                                    max: 10
                                })}
                            </Item>
                            <Item
                                label={"各矩形间距"}
                                name={["itemStyle","gapWidth"]}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","gapWidth"], {
                                    min: 0,
                                    max: 50
                                })}
                            </Item>
                            <Item
                                label={"矩形圆角"}
                                name={["itemStyle","borderRadius"]}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderRadius"], {
                                    min: 0,
                                    max: 100
                                })}
                            </Item>
                            <Item
                                label={"边框颜色饱和度"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderColorSaturation"], {
                                    min: 0,
                                    max: 1,
                                    step: 0.1,
                                    precision: 1
                                })}
                            </Item>
                            <Item
                                label={"阴影大小"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","shadowBlur"], {
                                    min: 0,
                                    max: 20
                                })}
                            </Item>
                            <Item
                                label={"阴影颜色"}
                                name={["itemStyle","shadowColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                        </Item>
                        <Item
                            noStyle={true}
                            hidden={setType !== "emphasis"}
                        >
                            <Item
                                label={"改变哪些属性"}
                                name={["itemStyle", "_emphasisSel"]}
                            >
                                <Select
                                    mode={"tags"}
                                >
                                    <Select.Option value={"borderColorSaturation"}>颜色饱和度</Select.Option>
                                    <Select.Option value={"shadowColor"}>阴影颜色</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                noStyle={true}
                                dependencies={["itemStyle", "_emphasisSel"]}
                            >
                                {({getFieldValue}) => {
                                    let selFields = getFieldValue(["itemStyle", "_emphasisSel"]);

                                    return selFields?.map((selField) => {
                                        switch (selField) {
                                            case "borderColorSaturation":
                                                return <Item
                                                    label={"边框颜色饱和度"}
                                                >
                                                    {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","_emphasis","borderColorSaturation"], {
                                                        min: 0,
                                                        max: 1,
                                                        step: 0.1,
                                                        precision: 1
                                                    })}
                                                </Item>
                                            case "shadowColor":
                                                return <Item
                                                    key={"shadowColor"}
                                                    label={"阴影颜色"}
                                                    name={["itemStyle", "_emphasis", "shadowColor"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                        }
                                    })
                                }}
                            </Item>
                        </Item>
                    </>
                }}

            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="usual"
            header="布局"
        >
            <Item
                label={"网格"}
            >
                <Item
                    label={"上"}
                    name={["usual", "grid", "top"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"下"}
                    name={["usual", "grid", "bottom"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"左"}
                    name={["usual", "grid", "left"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
                <Item
                    label={"右"}
                    name={["usual", "grid", "right"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="inside">矩形中心</Select.Option>
                    <Select.Option value="insideLeft">靠左</Select.Option>
                    <Select.Option value="insideRight">靠右</Select.Option>
                    <Select.Option value="insideTop">靠上</Select.Option>
                    <Select.Option value="insideBottom">靠下</Select.Option>
                    <Select.Option value="insideTopLeft">左上角</Select.Option>
                    <Select.Option value="insideTopRight">右上角</Select.Option>
                    <Select.Option value="insideBottomLeft">左下角</Select.Option>
                    <Select.Option value="insideBottomRight">右下角</Select.Option>
                </>,
                labelShowPlanOptions: <>
                    <Select.Option value="normal">普通</Select.Option>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="upperLabel"
            header="父级数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["upperLabel","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="insideLeft">靠左</Select.Option>
                    <Select.Option value="insideRight">靠右</Select.Option>
                    <Select.Option value="insideTop">居中</Select.Option>
                </>,
                labelShowPlanOptions: <>
                    <Select.Option value="normal">普通</Select.Option>
                </>
            },"upperLabel")}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"breadcrumb"}
            header={"层级面包屑导航"}
        >
            <Item
                label={"是否展示"}
                name={["breadcrumb","show"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"位置"}
                name={["breadcrumb", "_position"]}
            >
                <Select>
                    <Select.Option value="top">上</Select.Option>
                    <Select.Option value="bottom">下</Select.Option>
                    <Select.Option value="left">左</Select.Option>
                    <Select.Option value="right">右</Select.Option>
                    <Select.Option value="topLeft">左上</Select.Option>
                    <Select.Option value="topRight">右上</Select.Option>
                    <Select.Option value="bottomLeft">左下</Select.Option>
                    <Select.Option value="bottomRight">右下</Select.Option>
                    <Select.Option value="center">完全居中</Select.Option>
                </Select>
            </Item>
            <Item
                label={"文字颜色"}
                name={["breadcrumb","textStyle","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"背景颜色"}
                name={["breadcrumb","itemStyle","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"边框颜色"}
                name={["breadcrumb","itemStyle","borderColor"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"边框粗细"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["breadcrumb","itemStyle","borderWidth"],{
                    min: 0,
                    max: 10,
                })}
            </Item>
            <Item
                label={"边框类型"}
                name={["breadcrumb","itemStyle","borderType"]}
            >
                <Radio.Group>
                    <Radio value="solid">实线</Radio>
                    <Radio value="dashed">虚线</Radio>
                    <Radio value="dotted">点线</Radio>
                </Radio.Group>
            </Item>
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: {
            labelField: null,
            valueField: null,
            childrenField: null
        },
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        usual: {
            grid: {
                left: "10px",
                right: "10px",
                top: "10px",
                bottom: "10px"
            }
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            }
        },
        //标签
        label: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "inside",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //父级标签
        upperLabel: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "insideLeft",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //面积区样式
        itemStyle: {
            _setType: "normal",
            borderWidth: 2,
            borderColorSaturation: 0.5,
            borderRadius: 0,
            gapWidth: 0,
            shadowBlur: 0,
            shadowColor: "#000000e5",

            _emphasisSel: [],
            _emphasis: {},
        },
        //矩形树图特殊
        treemapSpecial:{
            leafDepth: null,
            drillDownIcon: "▶",
        },
        //面包屑导航
        breadcrumb: {
            show: true,
            _position: "topLeft",
            itemStyle: {
                color: "#000000aa",
                borderColor: "#000000ff",
                borderWidth: 0,
                borderType: "solid",
            },
            textStyle: {
                color: "#ffffffff",
            }
        }
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 name, children, value 等 字段可以自定义。
    1.矩形树图的数据结构，有层级关系，每一层都是一个数组，可以嵌套多层。给每一项数据配置数量字段，父级别数据可以不设置数量，会自动统计子集的合。
        [
            {
                name: "小说书籍销量",
                children: [
                    {
                        name: "三体",
                        value: 2600, 
                    },
                    {
                        name: "追风筝的人",
                        value: 1200, 
                    },
                ]
            },
            {
                name: "历史书籍销量",
                children: [
                    {
                        name: "中华上下五千年",
                        value: 1560, 
                    },
                    {
                        name: "温莎王朝",
                        value: 120, 
                    },
                ]
            },
            {
                name: "教材书籍销量",
                children: [
                    {
                        name: "深入理解计算机系统",
                        value: 20, 
                    },
                ]
            }
        ]
     
      2. 给父级别数据也配置数量字段，用于分配同层级数据之间的占据面积比例。
         注意：当子集数据合 小于 父级时，不存在空缺面积(和旭日图有区别)。
      [
            {
                name: "一年级一班全体人数",
                value: 50,
                children: [
                    {
                        name: "成绩90分以上",
                        value: 20,
                    },
                    {
                        name: "成绩75分以上",
                        value: 25,
                    },
                ]
            },
             {
                name: "一年级二班全体人数",
                value: 70,
                children: [
                    {
                        name: "成绩90分以上",
                        value: 15,
                    },
                    {
                        name: "成绩75分以上",
                        value: 40,
                    },
                ]
            },
            ...
        ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}