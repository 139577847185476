import _ from "lodash";

class TreeDataHandler {
    constructor(props) {
        //树结构里递归子集的键名
        this.childField = (props && props.childField) || "children";
    }

    /**
     * 根据传入的字段对数据排序
     * @param treeData  需要排序的数据，可以是数组，也可以是对象
     * @param sortField     排序字段，如果是多个，传入数组，eg:["time","number"]
     * @param sortMode  升序或降序  asc:升序   desc:降序
     *              如果sortField是数组，则sortMode也可以是数组，一一对应，eg:["asc","desc"]
     * @param childField    子集键名        //如果不想排序子集，可以填一个不存在的键，比如：noChild
     */
    sortByField(treeData, sortField, sortMode = "desc", childField = this.childField) {
        if (!sortField) {
            return treeData;
        }

        let sortAction = (data) => {
            if (Array.isArray(data)) {
                //当前层排序
                data = _.orderBy(data, sortField, sortMode);
                return data.map((item) => {
                    if (item[childField]) {
                        item[childField] = sortAction(item[childField]);
                    }
                    return item;
                })
            } else {
                if (data[childField]) {
                    data[childField] = sortAction(data[childField]);
                }
                return data;
            }
        };

        return sortAction(treeData);
    }

    /**
     * 替换字段，返回新的数据
     * @param treeData    用于匹配的原数据
     * @param matchRule   新数据的键和值来源规则 eg:{key:"_id",title:"name",children:"scriptModules"}  _id 替换成 key
     * @param changeType    修改类型  replace:替换键   new:新的数据只保留规则定义的键   add:在原数据上增加规则定义的键
     * @param childField    子集键名
     *
     */
    changeField(treeData, matchRule, changeType = "replace", childField = this.childField) {
        console.log("changeField",treeData)
        let newTreeData = JSON.parse(JSON.stringify(treeData));
        if (!matchRule) {
            return newTreeData;
        }

        //每一个节点
        let handleAction = (data) => {
            let newItem = changeType === "new" ? {} : data;
            for (let key in matchRule) {
                if (data.hasOwnProperty(matchRule[key])) {
                    newItem[key] = data[matchRule[key]];
                    if (changeType === "replace") {
                        delete newItem[matchRule[key]];
                    }
                }
            }
            return newItem;
        };

        let changeAction = (data) => {
            if (Array.isArray(data)) {
                return data.map((item) => {
                    //先替换子节点，否则 childField 替换不到
                    if (item[childField]) {
                        item[childField] = changeAction(item[childField]);
                    }

                    return handleAction(item);
                })
            } else {
                //先替换子节点，否则 childField 替换不到
                if (data[childField]) {
                    data[childField] = changeAction(data[childField]);
                }
                return handleAction(data);
            }
        };

        return changeAction(newTreeData);
    }

    /**
     * 搜索树数据
     * @param treeData 搜索的数据
     * @param filter 过滤条件
     * @param once 是否搜索到一个，就直接终止并返回结果
     * @param childField    子集键名
     * @return {Array}
     */
    searchTreeNodes = (treeData, filter, once, childField = this.childField) => {
        let arr = [];
        if (treeData) {
            let find = (treeNode) => {
                if (treeNode) {
                    if (filter(treeNode)) {
                        arr.push(treeNode)
                    }
                    if (once && arr.length > 0) {
                        return true;
                    }
                    if (treeNode[childField]) {
                        for (let i = 0, l = treeNode[childField].length; i < l; i++) {
                            if (find(treeNode[childField][i])) {
                                return true;
                            }
                        }
                    }
                    return false;
                } else {
                    return false;
                }
            };

            if (Array.isArray(treeData)) {
                for (let i = 0, l = treeData.length; i < l; i++) {
                    find(treeData[i]);
                    if (once && arr.length > 0) {
                        break;
                    }
                }
            } else {
                find(treeData);
            }
            return arr;
        } else {
            return arr;
        }
    };

    /**
     * 通过特殊字段搜索目标节点
     * @param treeData 搜索的数据
     * @param matchRule    匹配规则，eg:{field:"key", value:"123456789"}
     * @param childField    子集键名
     * @return {*}
     */
    searchTreeNodeByField = (treeData, matchRule, childField = this.childField) => {
        let result = this.searchTreeNodes(treeData, (action) => {
            return action[matchRule.field] === matchRule.value;
        }, true, childField);

        if (result && result.length > 0) {
            return result[0];
        } else {
            return null;
        }
    };

    /**
     * 获取从根到目标节点经过的所有节点
     * @param treeData 搜索的数据
     * @param filter
     * @param childField    子集键名
     * @return {*}
     */
    getNodeDeepPath = (treeData, filter, childField = this.childField) => {
        console.log("childField",childField)
        console.log("this.childField",this.childField)
        let pathArr = [];
        if (treeData) {
            let find = (treeNode) => {
                if (treeNode) {
                    pathArr.push(treeNode);
                    if (filter(treeNode)) {
                        return true;
                    } else if (treeNode[childField]) {
                        for (let i = 0, l = treeNode[childField].length; i < l; i++) {
                            if (find(treeNode[childField][i])) {
                                return true;
                            }
                        }
                    }

                    //没找到，把最后一个剔除
                    pathArr.pop();
                    return false;
                } else {
                    return false;
                }
            };
            if (Array.isArray(treeData)) {
                for (let i = 0, l = treeData.length; i < l; i++) {
                    if (find(treeData[i])) {
                        break;
                    }
                }
            } else {
                find(treeData);
            }
            return pathArr;
        } else {
            return pathArr;
        }
    };


    getFull = (treeData,_id, childField = this.childField,matchField="_id")=>{
        let path = this.getNodeDeepPath(treeData, (o) => {
            return o[matchField] === _id
        },childField);
        let fullTitle = "";
        let fullPath = [];

        if (path.length >= 0) {
            path.forEach((item) => {
                fullTitle += item.title + "/";
                if(item.type === "key"||item.type === "prop"){
                    fullPath.push({
                        _id: item._id,
                        isArray: item.isArray,
                        title: item.title,
                        dataType: item.dataType,
                        mdId: item.mdId
                    });
                }
            });
            //去头去尾
            fullTitle = fullTitle.slice(fullTitle.indexOf("/") + 1);
            fullTitle = fullTitle.slice(0, fullTitle.length - 1);
        }
        return {fullPath,fullTitle}
    }

    //递归处理每一项
    recursionDeal(treeData,handleAction,childField = this.childField){
        let newTreeData = JSON.parse(JSON.stringify(treeData));
        //递归逻辑
        let changeAction = (data) => {
            if (Array.isArray(data)) {
                return data.map((item) => {
                    if (item[childField]) {
                        item[childField] = changeAction(item[childField]);
                    }
                    return handleAction(item);
                })
            } else {
                return handleAction(data);
            }
        };

        return changeAction(newTreeData);
    }
}

export default new TreeDataHandler();