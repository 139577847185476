import React from "react";
import {
    Form,
    Divider,
    Radio,
    Select,
    Input
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import DatePickerForDuration from "../../../formDiyItem/DatePickerForDuration";

const Item = Form.Item;

function getSetItems(){
    return <>
        <Divider orientation="left">
            时间类型设置
        </Divider>
        <Item
            label={"计时类型"}
            name={"timeType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="cutDown">倒计时</Radio>
                <Radio value="duration">正计时</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"格式"}
            name={"timeFormat"}
            rules={[{required: true}]}
        >
            <Select>
                <Select.Option value={1}>根据时间判断</Select.Option>
                <Select.Option value={2}>分:秒</Select.Option>
                <Select.Option value={3}>时:分</Select.Option>
                <Select.Option value={4}>时:分:秒</Select.Option>
                <Select.Option value={5}>天:时</Select.Option>
                <Select.Option value={6}>天:时:分</Select.Option>
                <Select.Option value={7}>天:时:分:秒</Select.Option>
            </Select>
        </Item>
        <Item
            label={"默认时间点"}
            extra={"如果绑定了数据集，则此项可不填"}
            name={"defaultTime"}
            rules={[{required: false}]}
        >
            <DatePickerForDuration
                placeholder={"选择一个时间"}
                showTime
                format="YYYY-MM-DD HH:mm:ss"
            />
        </Item>
        <Item
            label={"转为时间戳"}
            shouldUpdate
        >
            {({getFieldValue})=>{
                return <span>{getFieldValue("defaultTime")}</span>;
            }}
        </Item>
        <Divider orientation="left">
            标题设置
        </Divider>
        <Item
            label={"标题"}
            name={"beforeType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">文字</Radio>
                <Radio value="icon">图标</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.beforeType !== currentValues.beforeType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("beforeType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("beforeTextSet");break;
                    case "icon": items = CommonFormItems.getIconSet("beforeIconSet",getFieldValue);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            时间数字样式
        </Divider>
        {CommonFormItems.getFontSet("centerTextSet",true)}
        <Divider orientation="left">
            单位文字样式
        </Divider>
        {CommonFormItems.getFontSet("unitTextSet",true)}
        <Item
            label={"数字与单位间隙"}
        >
            {CommonFormItems.distanceSet(["unitGap", "num"], ["unitGap", "unit"], ["px", "rem"])}
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.timeType !== currentValues.timeType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("timeType")){
                    case "duration": items = null;break;
                    case "cutDown": items = <>
                        <Divider orientation="left">
                            超时后标题设置
                        </Divider>
                        <Item
                            label={"超时标题"}
                            name={"beforeTypeOverTime"}
                            rules={[{required: true}]}
                        >
                            <Radio.Group>
                                <Radio value="none">无</Radio>
                                <Radio value="text">文字</Radio>
                                <Radio value="icon">图标</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues)=>{return prevValues.beforeTypeOverTime !== currentValues.beforeTypeOverTime}}
                        >
                            {({getFieldValue})=>{
                                let items;
                                switch (getFieldValue("beforeTypeOverTime")){
                                    case "none": items = null;break;
                                    case "text": items = CommonFormItems.getFontSet("beforeTextOverTimeSet");break;
                                    case "icon": items = CommonFormItems.getIconSet("beforeIconOverTimeSet",getFieldValue);break;
                                }
                                return items;
                            }}
                        </Item>
                        <Divider orientation="left">
                            超时后时间样式设置
                        </Divider>
                        <Item
                            label={"超时后文案"}
                            name={["overTimeTextSet","text"]}
                            extra={"如果不填写文案，则继续展示负方向累计时间"}
                        >
                            <Input
                                maxLength={"30"}
                            />
                        </Item>
                        {CommonFormItems.getFontSet("overTimeTextSet",true)}
                    </>;break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            其它设置
        </Divider>
        <Item
            label={"排版方式"}
            name={"flexDirection"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="row">左右</Radio>
                <Radio value="column">上下</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"对齐方式"}
            name={"alignItems"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="flex-start">顶部对齐</Radio>
                <Radio value="center">居中对齐</Radio>
                <Radio value="baseline">基线对齐</Radio>
                <Radio value="flex-end">底部对齐</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"标题与时间间隙"}
        >
            {CommonFormItems.distanceSet(["gap", "num"], ["gap", "unit"], ["px", "rem"])}
        </Item>
    </>
}

function getDefaultValues(){
    return {
        timeType: "cutDown",//cutDown,duration
        timeFormat: 1,
        defaultTime: null,
        centerTextSet: {
            fontFamily: "Date-Number",
            color: "#48ff05",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        unitTextSet: {
            fontFamily: "default",
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        overTimeTextSet: {  //超时数字样式
            fontFamily: "Date-Number",
            text: null,
            color: "#ff0000",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        gap: {
            num: 10,
            unit: "rem"
        },
        unitGap: {
            num: 5,
            unit: "rem"
        },
        flexDirection: "row",
        alignItems: "center",
        beforeType: "text",
        beforeTypeOverTime: "text",
        beforeTextSet: {
            fontFamily: "default",
            text: "默认标题",
            color: "#6f18f2",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        beforeTextOverTimeSet: {
            fontFamily: "default",
            text: "已超时",
            color: "#ff0000",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        beforeIconSet: undefined,
        beforeIconOverTimeSet: undefined,
    }
}

function getDemoCode(){
    return `
    需要返回一个时间戳，如 2022年11月21日14:40 的时间戳值如下：
    1669012791505
    `
}
export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}