import React from "react";
import { Input, InputNumber } from "antd";

export default class NumberShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.contentMsg?.dataConfig?.parameterValue
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.contentMsg?.dataConfig?.parameterValue !== nextProps.contentMsg?.dataConfig?.parameterValue) {
            this.setState({
                value: nextProps.contentMsg?.dataConfig?.parameterValue
            })
        }
    }

    setValue(newValue){
        let v = Number(newValue);
        this.setState({
            value: v
        },()=>{
            this.props.valueChanged?.(v)
        })
    }


    render(){
        let {contentParams} = this.props.contentMsg.dataConfig;
        let {value} = this.state;
        if(!contentParams){
            return null
        }

        return <InputNumber
            onClick={(e)=>{
                let inputDom = e.target;
                if(inputDom){
                    inputDom.focus();
                    let contentLen = inputDom.value.length;
                    inputDom.setSelectionRange(contentLen, contentLen);
                }
            }}
            onDoubleClick={(e)=>{
                let inputDom = e.target;
                if(inputDom){
                    inputDom.focus();
                    let contentLen = inputDom.value.length;
                    inputDom.setSelectionRange(0, contentLen);
                }
            }}
            {...contentParams.inputOption}
            controls={false}
            value={value}
            style={{width: "100%"}}
            onBlur={(e)=>{
                this.props.valueChanged?.(value)
            }}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    this.props.valueChanged?.(value)
                }
            }}
            onChange={(v)=>{
                this.setState({
                    value: v
                })
            }}
        />
    }
}