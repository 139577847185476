import React from "react";
import {DatePicker} from "antd";
import moment from "moment";

//包装日期组件给Form用，时间戳和moment对象的转换在这个组件完成
export default class DatePickerForDuration extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            numValue: null
        }
    }

    render(){
        let {value} = this.props;
        return <DatePicker
            {...this.props}
            value={value?moment(value):null}
            onChange={(value, dateString)=>{
                console.log(value)
                if(!value){
                    this.props.onChange(null)
                }else{
                    this.props.onChange(value.valueOf())
                }
            }}
            onOk={(momentObj)=>{
                console.log(momentObj)
            }}
        />
    }
}