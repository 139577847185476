import React from "react";
import {
    Form,
    Radio,
} from "antd";
import ColorPicker from "../../../formDiyItem/ColorPicker";
import CommonFormItems from "../../../CommonFormItems";
import {RightOutlined,CaretRightOutlined,RightCircleOutlined,DoubleRightOutlined,VerticalLeftOutlined,StepForwardOutlined,FastForwardOutlined,SwapRightOutlined,ArrowRightOutlined,RightSquareOutlined} from "@ant-design/icons";

const Item = Form.Item;

function getSetItems(){
    return <>
        <Item
            label={"方向"}
            name={["direction"]}
        >
            <Radio.Group>
                <Radio value="left">向左</Radio>
                <Radio value="right">向右</Radio>
                <Radio value="top">向上</Radio>
                <Radio value="bottom">向下</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"形状"}
            name={["type"]}
        >
            <Radio.Group>
                <Radio value="ArrowRightOutlined"><ArrowRightOutlined /></Radio>
                <Radio value="RightOutlined"><RightOutlined /></Radio>
                <Radio value="CaretRightOutlined"><CaretRightOutlined /></Radio>
                <Radio value="RightCircleOutlined"><RightCircleOutlined /></Radio>
                <Radio value="DoubleRightOutlined"><DoubleRightOutlined /></Radio>
                <Radio value="VerticalLeftOutlined"><VerticalLeftOutlined /></Radio>
                <Radio value="StepForwardOutlined"><StepForwardOutlined /></Radio>
                <Radio value="FastForwardOutlined"><FastForwardOutlined /></Radio>
                <Radio value="SwapRightOutlined"><SwapRightOutlined /></Radio>
                <Radio value="RightSquareOutlined"><RightSquareOutlined /></Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"颜色"}
            name={["color"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"大小"}
        >
            {CommonFormItems.distanceSet(["size","num"],["size","unit"],["px","rem"])}
        </Item>
        <Item
            label={"动画效果"}
            name={["animation"]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="shine">呼吸灯</Radio>
                <Radio value="shineMore">闪烁</Radio>
                <Radio value="move">移动</Radio>
                <Radio value="moveLine">摆动</Radio>
            </Radio.Group>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        direction: "right",
        color: "#843cff",
        type: "ArrowRightOutlined",
        size: {
            num: 50,
            unit: "rem"
        },
        animation: "shine"
    }
}
export default {
    getSetItems,
    getDefaultValues,
}