import React, {Component} from 'react';
import styles from "./ShowTime.less";
import moment_tz from "moment-timezone";
import {Space} from "antd";
import CommonViewDiv from "../../../CommonViewDiv";

export default class ShowImage extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount(){
        this.loop = setInterval(()=>{
            this.setState({})
        },1000)
    }

    componentWillUnmount(){
        this.loop && clearInterval(this.loop);
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let timeStr = moment_tz().tz(this.getTimeZoneAreaName(contentParams.timeZoneSet?.selectZone)).format(contentParams.dateFormat?.diyFormat)

        return <div
            className={styles.timeBox}
        >
            <Space>
                {
                    contentParams.timeZoneSet?.show
                        ?<span
                        >{CommonViewDiv.getText(contentParams.zoneTxtSet,contentParams.timeZoneSet?.zoneCityName)}</span>
                        :null
                }

                <span
                    style={{
                        display: "inline-block",
                        color: contentParams.textSet.color,
                        fontSize: window.changeRemNum(contentParams.textSet,"fontSize","sizeUnit"),
                        lineHeight: window.changeRemNum(contentParams.textSet,"fontSize","sizeUnit"),

                        fontWeight: contentParams.textSet.fontBold ? "bold" : "normal",
                        fontStyle: contentParams.textSet.fontItalic ? "italic" : "normal",
                        fontFamily: (contentParams.textSet?.fontFamily === "default")
                            ?"unset"
                            :(contentParams.textSet?.fontFamily||"Date-Number")
                    }}
                >
                    {timeStr}
                 </span>
            </Space>
        </div>
    }

    getTimeZoneAreaName(timeZoneValue){
        let v = {
            0: "Atlantic/Azores",
            1: "Africa/El_Aaiun",
            2: "Antarctica/Troll",
            3: "Asia/Baghdad",
            4: "Asia/Baku",
            5: "Asia/Dushanbe",
            6: "Asia/Urumqi",
            7: "Asia/Bangkok",
            8: "Asia/Shanghai",
            9: "Asia/Yakutsk",
            10: "Asia/Vladivostok",
            11: "Pacific/Kosrae",
            12: "Pacific/Funafuti",
            [-1]: "Atlantic/Cape_Verde",
            [-2]: "America/Noronha",
            [-3]: "Atlantic/Stanley",
            [-4]: "America/La_Paz",
            [-5]: "America/Guayaquil",
            [-6]: "Pacific/Galapagos",
            [-7]: "America/Fort_Nelson",
            [-8]: "Pacific/Pitcairn",
            [-9]: "Pacific/Gambier",
            [-10]: "Pacific/Tahiti",
            [-11]: "Pacific/Niue",
            [-12]: "Pacific/Wallis",
        }[timeZoneValue];
        if(!v){
            v = "Asia/Shanghai"
        }
        return v;
    }
}