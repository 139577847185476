import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "./LiquidFill.less";
import {message} from "antd";

const Cetacean = 'path://M367.855,428.202c-3.674-1.385-7.452-1.966-11.146-1.794c0.659-2.922,0.844-5.85,0.58-8.719 c-0.937-10.407-7.663-19.864-18.063-23.834c-10.697-4.043-22.298-1.168-29.902,6.403c3.015,0.026,6.074,0.594,9.035,1.728 c13.626,5.151,20.465,20.379,15.32,34.004c-1.905,5.02-5.177,9.115-9.22,12.05c-6.951,4.992-16.19,6.536-24.777,3.271 c-13.625-5.137-20.471-20.371-15.32-34.004c0.673-1.768,1.523-3.423,2.526-4.992h-0.014c0,0,0,0,0,0.014 c4.386-6.853,8.145-14.279,11.146-22.187c23.294-61.505-7.689-130.278-69.215-153.579c-61.532-23.293-130.279,7.69-153.579,69.202 c-6.371,16.785-8.679,34.097-7.426,50.901c0.026,0.554,0.079,1.121,0.132,1.688c4.973,57.107,41.767,109.148,98.945,130.793 c58.162,22.008,121.303,6.529,162.839-34.465c7.103-6.893,17.826-9.444,27.679-5.719c11.858,4.491,18.565,16.6,16.719,28.643 c4.438-3.126,8.033-7.564,10.117-13.045C389.751,449.992,382.411,433.709,367.855,428.202z';

const Rabbit = 'path://M710.927599 364.822939c-19.45233-26.058781-50.649462-26.058781-74.873119-31.931183-2.936201-15.415054-40.739785-89.554122-57.622939-88.453046-16.516129 1.101075-4.771326 11.744803-1.835125 44.777061-7.707527-8.808602-25.324731-41.84086-32.298208-25.324732-16.883154 40.739785 29.362007 97.995699 28.260932 107.905377-1.101075 9.542652-4.404301 48.447312-22.388531 56.521864-6.973477 2.936201-71.202867 5.872401-142.405734 44.777061s-85.883871 93.224373-101.298925 151.581362c-4.037276 15.782079-5.872401 30.463082-5.505377 43.675985-2.936201 2.202151-6.239427 6.239427-9.909677 13.946954-9.175627 19.085305 7.340502 39.271685 27.159857 46.612186 4.037276 6.606452 8.808602 12.111828 13.212903 15.782079 20.553405 17.617204 92.490323 11.744803 101.298925 14.681003 8.808602 2.936201 35.968459 13.579928 45.878136 13.579929 9.909677 0 40.739785 2.936201 40.739785-8.808602 0-26.058781-26.425806-40.005735-26.425807-40.005735s15.415054-8.808602 37.069534-21.287455c21.287455-12.478853 35.234409-31.931183 42.941936-33.032258 15.048029-1.835125 13.579928 48.447312 24.223656 60.192114 10.643728 11.744803 21.287455 44.410036 35.968459 49.548387 11.010753 4.037276 48.814337-1.101075 51.750537-12.478853 2.936201-11.744803-4.771326-12.478853-12.845878-23.489605-7.707527-10.643728-16.516129-53.585663-8.808602-70.101793 7.707527-16.516129 23.489606-85.516846 25.324731-114.511828 1.835125-28.994982 1.101075-48.447312 16.516129-87.351971 25.324731-9.542652 33.032258-34.867384 34.133333-47.713262 1.835125-12.478853-8.808602-33.032258-28.260931-59.091039z m-26.058782 26.058781c-6.239427 0-11.377778-5.138351-11.377778-11.377777s5.138351-11.377778 11.377778-11.377778c6.239427 0 11.377778 5.138351 11.377778 11.377778s-4.771326 11.377778-11.377778 11.377777z'

const Snail = 'path://M817.364875 463.552688c-4.037276-20.18638-22.755556-31.931183-28.627957-35.601434-4.404301-2.936201 14.681004-108.272401 14.681003-108.272401 4.771326-0.367025 8.808602-4.771326 8.808602-9.542652 0-5.505376-4.404301-9.909677-9.909677-9.909678-5.505376 0-9.909677 4.404301-9.909678 9.909678 0 3.670251 1.835125 6.606452 4.771327 8.441577 0 0-20.92043 104.969176-24.223656 106.070251-3.303226 1.101075-17.250179 1.835125-18.351255-0.367025-0.73405-2.202151-1.835125-105.336201-1.835125-105.336201 4.404301-1.101075 7.340502-4.771326 7.340502-9.542652 0-5.505376-4.404301-9.909677-9.909678-9.909678-5.505376 0-9.909677 4.404301-9.909677 9.909678 0 4.037276 2.569176 7.707527 5.872401 9.175627 0 0-1.101075 105.703226-6.239426 107.171326-5.505376 1.101075-16.149104 6.606452-23.489606 11.744803-25.324731 17.617204-35.234409 82.21362-41.84086 113.410752-16.149104-33.032258-31.564158-104.602151-79.644445-150.480286-68.633692-65.330466-236.731183-68.633692-285.545519 62.76129-37.436559 101.298925-5.505376 162.22509 4.404301 180.209319-33.032258 0.367025-55.787814 0.73405-55.787814 0.73405-31.197133 6.606452-64.596416 46.612186 13.579928 48.814337 78.176344 2.202151 376.567742 30.463082 437.860932-10.643727 48.814337-32.665233 69.734767-93.224373 65.330466-126.623656-4.037276-33.766308 51.383513-45.511111 42.574911-92.123298z m-33.032259-6.973477c3.670251 0 6.973477 2.936201 6.973477 6.973477s-2.936201 6.973477-6.973477 6.973477-6.973477-2.936201-6.973476-6.973477 2.936201-6.973477 6.973476-6.973477z'

const Rocket = 'path://M528.032 226.7648l0.0832-0.0768c105.5232-105.536 252.2688-143.0656 382.72-111.2 31.872 130.4576-5.664 277.1968-111.1936 382.7264-1.4592 1.4592-2.9248 2.9056-4.4032 4.3392l-58.0352 58.0416 47.9808 84.768a76.8 76.8 0 0 1-3.7888 81.6832l-126.272 181.5488c-24.2112 34.816-72.0768 43.4112-106.8992 19.2a76.8 76.8 0 0 1-3.712-2.7584l0.3264-0.4096a41.6192 41.6192 0 0 1-14.8544-54.4768 34.624 34.624 0 0 0-0.0896-0.0384c24.9984-42.6752 28.0128-98.496 11.0464-155.456-61.984 13.824-129.4848-3.392-177.7152-51.616-48.2176-48.2176-65.4272-115.6992-51.6288-177.6704-56.928-16.9472-112.7232-13.9264-155.3792 11.0592a35.2384 35.2384 0 0 0-0.0384-0.0896 41.6192 41.6192 0 0 1-54.4768-14.848l-0.3584 0.2816c-23.4112-30.1312-21.28-73.6832 6.4-101.3632a76.8 76.8 0 0 1 10.4576-8.7488l181.6512-126.336a76.8 76.8 0 0 1 81.5232-3.8784l84.4032 47.5136 60.3648-60.3648c0.6208-0.6272 1.2544-1.2352 1.888-1.8304z m-261.504 378.8608c17.8752 45.9968 37.2864 79.4624 58.2272 100.4032 20.512 20.512 52.096 38.6112 94.7584 54.2912a19.2 19.2 0 0 1 4.896 33.3824l-72.8576 54.6368a19.2 19.2 0 0 1-30.1888-19.8336l3.1808-13.2736a19.2 19.2 0 0 0-27.8784-21.3248l-64.2624 35.1232a19.2 19.2 0 0 1-26.0544-26.048l37.4784-68.5824a19.2 19.2 0 0 0-19.5648-28.2176l-32.3264 4.6272a19.2 19.2 0 0 1-18.08-30.528l59.4112-79.2192a19.2 19.2 0 0 1 33.2544 4.5632zM621.2032 405.12c29.9904 29.9968 78.6176 29.9968 108.608 0 29.9968-29.984 29.9968-78.6112 0-108.608-29.9904-29.9904-78.6176-29.9904-108.608 0-29.9904 29.9968-29.9904 78.624 0 108.608z'

const MapleLeaf = 'path://M853.32992 494.92992L921.6 307.2 648.52992 443.72992 716.8 170.67008l-136.52992 68.25984L512 102.4l-68.27008 136.52992L307.2 170.67008l68.27008 273.05984L102.4 307.2l68.27008 187.72992L102.4 580.27008 326.69696 692.4288l-53.62688 160.90112 204.8-58.50112V921.6h68.25984V794.8288l204.8 58.50112-53.62688-160.90112L921.6 580.27008z'


const Tree = 'path://M884.609673 756.965216L724.832793 575.98875h61.258804c18.019648 0 33.959337-9.999805 41.559188-26.11949 7.599852-16.079686 5.0999-34.519326-6.559872-48.09906L664.833965 319.99375h57.778872c18.199645 0 34.599324-10.699791 41.719185-27.219468 7.039863-16.259682 3.719927-35.179313-8.479835-48.15906L535.316495 9.659811c-12.059764-12.899748-34.559325-12.899748-46.639089 0L268.121713 244.615222c-12.199762 12.979746-15.499697 31.899377-8.479834 48.15906C266.76174 309.293959 283.181419 319.99375 301.381064 319.99375h57.778871l-156.276947 181.816449c-11.619773 13.559735-14.119724 31.979375-6.539873 48.079061C203.942967 565.988946 219.862656 575.98875 237.902304 575.98875h61.258803L139.384228 756.965216c-11.999766 13.579735-14.719713 32.179371-7.119861 48.519052 7.499854 16.099686 23.999531 26.499482 42.019179 26.499482H447.9982v48.899045l-60.578817 96.79811c-10.639792 21.279584 4.839905 46.319095 28.619441 46.319095h191.916252c23.779536 0 39.259233-25.039511 28.619441-46.319095L575.9957 880.882795V831.98375h273.714654c18.019648 0 34.519326-10.399797 42.019179-26.499482 7.599852-16.339681 4.879905-34.939318-7.11986-48.519052z'

export default class LiquidFill extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }

        this.remAndPxRate = document.body.clientWidth/10/150;
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    getPxAndRemChange(pxNum){
        return Math.round(pxNum * this.remAndPxRate)
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    getShape(type){
        let v;
        switch (type){
            case "cetacean": v = Cetacean;break;
            case "rabbit": v = Rabbit;break;
            case "snail": v = Snail;break;
            case "rocket": v = Rocket;break;
            case "mapleLeaf": v = MapleLeaf;break;
            case "tree": v = Tree;break;
            default: v = type;
        }

        return v;
    }

    //获取默认的
    getChartOption() {
        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {}

        let option =  {
            tooltip: {
                show: true
            },
            backgroundColor: "transparent",
            series: {
                name: "水波图",
                type: 'liquidFill',
                data: [],
                color: contentParams.colorArray || [],
                center: ['50%', '50%'],

                radius: contentParams.radius +"%",
                amplitude: contentParams.amplitude +"%",
                phase: "auto",
                waveLength: contentParams.waveLength +"%",

                waveAnimation: contentParams.waveAnimation || false,
                direction: contentParams.direction || "right",
                animationEasing: 'linear',
                animationEasingUpdate: 'linear',
                animationDuration: 2000,
                animationDurationUpdate: 1000,
                shape: contentParams.shape?this.getShape(contentParams.shape) : "circle",
                outline: {
                    show: contentParams.outline?.show || false,
                    borderDistance: contentParams.outline?.borderDistance,
                    itemStyle: {
                        borderColor: contentParams.outline?.lineStyle?.borderColor,
                        borderWidth: contentParams.outline?.lineStyle?.borderWidth,
                        shadowBlur: contentParams.outline?.lineStyle?.shadowSet?.blur,
                        shadowColor: contentParams.outline?.lineStyle?.shadowSet?.color + window.getOpacityStr(contentParams.outline?.lineStyle?.shadowSet?.opacity)
                    }
                },

                backgroundStyle: {
                    color: contentParams.backgroundSet?.color
                },

                itemStyle: {
                    opacity: contentParams.waveStyle?.opacity / 100,
                    shadowBlur: contentParams.waveStyle?.shadowSet?.blur,
                    shadowColor: contentParams.waveStyle?.shadowSet?.color + window.getOpacityStr(contentParams.waveStyle?.shadowSet?.opacity)
                },

                label: {
                    show: contentParams.label?.show || false,
                    color:  contentParams.label?.color,
                    insideColor: contentParams.label?.insideColor,
                    fontSize: contentParams.label?.fontSize? this.getPxAndRemChange(contentParams.label?.fontSize) : 20,
                    fontWeight: contentParams.label?.fontWeightBold?'bold':"normal",

                    align: contentParams.label?.align || 'center',
                    baseline: contentParams.label?.baseline || 'middle',
                    position: contentParams.label?.position || 'inside',
                    fontFamily: (contentParams.label.fontFamily && contentParams.label.fontFamily !== "default")?contentParams.label.fontFamily:"sans-serif",
                    formatter: (param)=>{
                        return `${contentParams.label?.beforeText || ""}${contentParams.label?.showType === 1
                            ?(param.value * 100).toFixed(contentParams.label?.precision || 0) + "%"
                            :(
                                contentParams.label?.showType === 2
                                ? (contentParams.label?.bigNumber?window.formatBigNumW(Math.round(param.value * contentParams["fillValue"])):Math.round(param.value * contentParams["fillValue"]))
                                    :""
                            )
                        }${contentParams.label?.afterText || ""}`
                    }
                },
            }
        }

        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {}

        let option = this.getChartOption();


        //给option 填充数据
        let items = [];

        if(Object.prototype.toString.call(data) === '[object Object]'){
            if(data.hasOwnProperty(contentParams["valueField"])){
                let total = contentParams["fillValue"];
                let needChangeFormater = false;
                if(data.hasOwnProperty(contentParams["totalField"])){
                    total = data[contentParams["totalField"]]
                    needChangeFormater = true;
                }

                let item = {
                    value: (data[contentParams["valueField"]]/total).toFixed((contentParams.label?.precision || 0) + 2),
                    name: data[contentParams["nameField"]] || "名称未匹配到"
                };

                if(needChangeFormater && contentParams.label?.showType === 2){
                    item.label = _.cloneDeep(option.series.label);
                    item.label.formatter = (param)=>{
                        return `${contentParams.label?.beforeText || ""}${contentParams.label?.showType === 1
                            ?(param.value * 100).toFixed(contentParams.label?.precision || 0) + "%"
                            :(
                                contentParams.label?.showType === 2
                                    ? Math.round(param.value * total)
                                    :""
                            )
                        }${contentParams.label?.afterText || ""}`
                    }
                }

                items.push(item)
            }else{
                this.setState({
                    error: "数据对接错误"
                })
                return;
            }
        }else if(Array.isArray(data)){
            //数组数据
            data.forEach((eachData)=>{
                if(eachData.hasOwnProperty(contentParams["valueField"])){
                    let total = contentParams["fillValue"];
                    let needChangeFormater = false;
                    if(eachData.hasOwnProperty(contentParams["totalField"])){
                        total = eachData[contentParams["totalField"]]
                        needChangeFormater = true;
                    }

                    let item = {
                        value: (eachData[contentParams["valueField"]]/total).toFixed((contentParams.label?.precision || 0) + 2),
                        name:  eachData[contentParams["nameField"]] || "名称未匹配到"
                    };

                    if(needChangeFormater && contentParams.label?.showType === 2){
                        item.label = _.cloneDeep(option.series.label);
                        item.label.formatter = (param)=>{
                            return `${contentParams.label?.beforeText || ""}${contentParams.label?.showType === 1
                                ?(param.value * 100).toFixed(contentParams.label?.precision || 0)+ "%"
                                :(
                                    contentParams.label?.showType === 2
                                        ? Math.round(param.value * total)
                                        :""
                                )
                            }${contentParams.label?.afterText || ""}`
                        }
                    }

                    items.push(item)
                }else if(typeof Number(eachData) === "number"){
                    items.push((Number(eachData)/contentParams["fillValue"]).toFixed((contentParams.label?.precision || 0) + 2))
                }
            })
        }else if(typeof Number(data) === "number"){
            items.push((Number(data)/contentParams["fillValue"]).toFixed((contentParams.label?.precision || 0) + 2))
        }else{
            this.setState({
                error: "数据结构错误"
            })
        }

        items = items.sort((a,b)=>{
            if(typeof a === "object"){
                return b.value - a.value
            }else{
                return b - a
            }
        })

        option.series.data = items;

        //自定义转换数据和配置项
        if(this.props.contentMsg.dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${this.props.contentMsg.dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        log("option",option)

        if (this.myChart) {
            // this.myChart.clear();    //使用clear会重新从底部涨上来
            // this.myChart.resize();
            this.myChart.setOption(option);
        }
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBoxOuter}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}