let getImageObj = function (type,path = "datatype") {
    let value = null;
    if(path==="datatype"){
        let allType = {
            "Array":"数组",
            "Any":"任意",
            "Other":"其他",
            "Array_Number":"数字数组",
            "Array_String":"文本数组",
            "Array_Boolean":"真假数组",
            "Array_Object":"对象数组",
            "Number":"数字",
            "String":"文本",
            "Boolean":"真假",
            "Object":"对象",
            "Select":"选择",
            "Multiple":"多选",
            "Email": "邮箱",
            "Date": "日期",
            "Url":  "网址链接",
            "File":"媒体或文件",
            "By":"节点条件",

        }

        if(!allType[type]){
            type = "Other";
        }
        value = allType[type];
    }else  if(path==="module"){
        let allType = {
            "atom":"原子",
            "instruct":"指令",
            "business":"业务",
            "Other":"其他",
        }


        if(!allType[type]){
            type = "Other";
        }
        value = allType[type];

        console.log("path",type,value)
    }else {
        value = "图片";
    }
    return  {
        url:"/media/"+path+"/"+type+".png",
        name:value
    };
}

export default {
    getImageObj:getImageObj
}