import React from "react";
import {
    Form,
    Divider,
    Input,
    Select,
    Slider,
    Radio,
    Switch,
    AutoComplete,
    InputNumber
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import ColorPicker from "../../../formDiyItem/ColorPicker";

const Item = Form.Item;

function getSetItems(extendData= {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"地址字段"}
            name={"urlField"}
            rules={[{required: false}]}
            extra={"若打算使用字符串数组，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"名称字段"}
            name={"nameField"}
            rules={[{required: false}]}
            extra={"若打算使用字符串数组，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
          label={"跳转地址字段"}
          name={"jumpUrlField"}
          rules={[{required: false}]}
          extra={"若打算使用字符串数组，此项可不填"}
        >
            <AutoComplete
              placeholder={"请选择或填写对接字段"}
              options={opts}
            />
        </Item>
        <Divider orientation="left">
            排版设置
        </Divider>
        <Item
            name={["typesetting"]}
            label={"布局方式"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="floatType">指定每项宽高</Radio>
                <Radio value="flexType">设置行列</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues) => prevValues.typesetting !== currentValues.typesetting}
        >
            {({getFieldValue})=>{
                let v;
                switch (getFieldValue("typesetting")){
                    case "floatType": v = <>
                        <Item
                            label={"每项宽"}
                        >
                            {CommonFormItems.distanceSet(["floatSet","width"],["floatSet","widthUnit"],["px","rem"])}
                        </Item>
                        <Item
                            label={"每项高"}
                        >
                            {CommonFormItems.distanceSet(["floatSet","height"],["floatSet","heightUnit"],["px","rem"])}
                        </Item>
                    </>
                        break;
                    case "flexType": v = <>
                        <Item
                            label={"行数"}
                            name={["flexSet","rowNum"]}
                        >
                            <InputNumber
                                min={1}
                                max={30}
                                step={1}
                                precision={0}
                            />
                        </Item>
                        <Item
                            label={"列数"}
                            name={["flexSet","colNum"]}
                        >
                            <InputNumber
                                min={1}
                                max={30}
                                step={1}
                                precision={0}
                            />
                        </Item>
                        <Item
                            label={"对齐"}
                            name={["flexSet","justifyContent"]}
                        >
                            <Radio.Group>
                                <Radio value="flex-start">顶部</Radio>
                                <Radio value="center">居中</Radio>
                                <Radio value="flex-end">底部</Radio>
                            </Radio.Group>
                        </Item>
                    </>
                        break;
                }

                return v;
            }}
        </Item>
        <Item
            label={"横间距"}
        >
            {CommonFormItems.distanceSet(["gapSet","gapH"],["gapSet","gapHUnit"],["px","rem"])}
        </Item>
        <Item
            label={"竖间距"}
        >
            {CommonFormItems.distanceSet(["gapSet","gapV"],["gapSet","gapVUnit"],["px","rem"])}
        </Item>
        <Divider orientation="left">
            图片边框设置
        </Divider>
        <Item
            label={"边框颜色"}
            name={["borderSet","color"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"边框宽度"}
        >
            {CommonFormItems.distanceSet(["borderSet", "width"], ["borderSet", "widthUnit"], ["px", "rem"])}
        </Item>
        <Item
            label={"圆角"}
        >
            {CommonFormItems.distanceSet(["borderSet", "radius"], ["borderSet", "radiusUnit"], ["px", "%"])}
        </Item>
        <Divider orientation="left">
            图片名称设置
        </Divider>
        <Item
            name={["nameSet","position"]}
            label={"名称位置"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="top">顶部</Radio>
                <Radio value="bottom">底部</Radio>
                <Radio value="insideBottom">内嵌</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"与图片间距"}
            rules={[{required: true}]}
        >
            {CommonFormItems.distanceSet(["nameSet","gapWithImg"], ["nameSet","gapUnit"], ["px", "rem"])}
        </Item>
        {CommonFormItems.getFontSet("fontSet",true)}
    </>
}

function getDefaultValues(){
    return {
        urlField: null,
        nameField: null,
        jumpUrlField: null,
        typesetting: "floatType",
        floatSet: {
            width: 100,
            widthUnit: "px",
            height: 100,
            heightUnit: "px",
        },
        flexSet: {
            rowNum: 2,
            colNum: 6,
            justifyContent: "center"
        },
        gapSet: {
            gapH: 5,
            gapHUnit: "px",
            gapV: 5,
            gapVUnit: "px",
        },
        borderSet: {
            width: 1,
            widthUnit: "px",
            color: "#ffffffff",
            radius: 0,
            radiusUnit: "%"
        },
        nameSet: {
            position: "bottom",
            gapWithImg: 4,
            gapUnit: "px",
        },
        fontSet: {
            color: "#ffffff",
            fontFamily: "default",
            fontSize: 12,
            sizeUnit: "px", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        }
    }
}

function getDemoCode(){
    return `
    以下示例中 url、name 均可在 数据对接设置 中修改替换成其它单词

    1.返回图片的存储地址
    ["http://example.a.jpg","http://example.b.jpg","http://example.c.jpg",...]
    
    2.可返回对象数组，指定每个图片的名称
    [
        {
            url: "http://example.a.jpg",
            name: "狸花猫",
        },
        {
         url: "http://example.b.jpg",
            name: "石斑鱼",
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}