import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";
import TreeDataHandler from "../../../../../../../tool/treeDataHandler";

export default class TreeShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;

        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        if(!Array.isArray(data) && Object.prototype.toString.call(data) !== '[object Object]'){
            this.setState({
                error: "数据格式不匹配"
            })
            return;
        }

        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "tree",
                roam: dataConfig.contentParams?.dataZoom.roam,
                zoom: dataConfig.contentParams?.dataZoom.zoom,
            };

            //特殊配置
            seriesItem = Object.assign(seriesItem,dataConfig.contentParams?.treeSpecial);
            seriesItem.edgeForkPosition = seriesItem.edgeForkPosition + "%";

            //布局
            seriesItem = Object.assign(seriesItem,dataConfig.contentParams?.usual?.grid);

            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color,
                    position: dataConfig?.contentParams?.label.position,
                    distance: dataConfig?.contentParams?.label.distance,
                }

                if(dataConfig?.contentParams?.label?._beforeTxt || dataConfig?.contentParams?.label?._afterTxt){
                    seriesItem.label.formatter = `${dataConfig?.contentParams?.label?._beforeTxt || ""}{b}${dataConfig?.contentParams?.label?._afterTxt || ""}`
                }

                switch (dataConfig?.contentParams?.label._showPlan){
                    case "normal":break;
                    case "pop":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                }
            }

            //标记样式
            seriesItem.itemStyle = dataConfig?.contentParams?.itemStyle;

            //叶节点特殊样式
            seriesItem.leaves = dataConfig?.contentParams?.leaves;

            //连线样式
            seriesItem.lineStyle = dataConfig?.contentParams?.lineStyle;

            //高亮配置
            seriesItem.emphasis = {}
            //高亮下的标记
            if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.itemStyle = _.cloneDeep(seriesItem.itemStyle);

                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("color")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.itemStyle?._emphasis?.color){
                        seriesItem.emphasis.itemStyle.color = dataConfig?.contentParams?.itemStyle?._emphasis?.color;
                    }else{
                        delete seriesItem.emphasis.itemStyle.color
                    }
                }
                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("borderColor")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.itemStyle?._emphasis?.borderColor){
                        seriesItem.emphasis.itemStyle.borderColor = dataConfig?.contentParams?.itemStyle?._emphasis?.borderColor;
                    }else{
                        delete seriesItem.emphasis.itemStyle.borderColor
                    }
                }
            }
            //高亮下的连线
            if(dataConfig?.contentParams?.lineStyle?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.lineStyle = _.cloneDeep(seriesItem.lineStyle);

                if(dataConfig?.contentParams?.lineStyle?._emphasisSel?.includes("color")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.lineStyle?._emphasis?.color){
                        seriesItem.emphasis.lineStyle.color = dataConfig?.contentParams?.lineStyle?._emphasis?.color;
                    }else{
                        delete seriesItem.emphasis.lineStyle.color
                    }
                }
                if(dataConfig?.contentParams?.lineStyle?._emphasisSel?.includes("shadowColor")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.lineStyle?._emphasis?.shadowColor){
                        seriesItem.emphasis.lineStyle.shadowColor = dataConfig?.contentParams?.lineStyle?._emphasis?.shadowColor;
                    }else{
                        delete seriesItem.emphasis.lineStyle.shadowColor
                    }
                }
                if(dataConfig?.contentParams?.lineStyle?._emphasisSel?.includes("width")){
                    seriesItem.emphasis.lineStyle.width = dataConfig?.contentParams?.lineStyle?._emphasis?.width;
                }
                if(dataConfig?.contentParams?.lineStyle?._emphasisSel?.includes("shadowBlur")){
                    seriesItem.emphasis.lineStyle.shadowBlur = dataConfig?.contentParams?.lineStyle?._emphasis?.shadowBlur;
                }
            }

            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }


            //根据数据和配置填充其它属性
            let newData = [];
            if(dataConfig?.contentParams?.dataSetConfig?.labelField && dataConfig?.contentParams?.dataSetConfig?.childrenField){
                let matchRule = {
                    name: dataConfig?.contentParams?.dataSetConfig?.labelField,
                    children: dataConfig?.contentParams?.dataSetConfig?.childrenField
                }
                if(dataConfig?.contentParams?.dataSetConfig?.valueField){
                    matchRule["value"] = dataConfig?.contentParams?.dataSetConfig?.valueField
                }
                newData = TreeDataHandler.changeField(data,matchRule,"new",dataConfig?.contentParams?.dataSetConfig?.childrenField)
            }

            if(Array.isArray(newData)){
                seriesItem.data = [{
                    name: "根节点",
                    children: newData
                }]
            }else{
                seriesItem.data = [newData]
            }

            option.series = [seriesItem];
        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}