import React from "react";
import {
    Form,
    Divider,
    Select,
    Radio,
    Switch,
    Collapse,
    AutoComplete
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import PointDataSet from "./PointDataSet";

const Item = Form.Item;

/**
 * 散点图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    let optionsForAxis = [];
    if(extendData?.bindData && Object.prototype.toString.call(extendData?.bindData) === '[object Object]'){
        //验证下格式，
        Object.keys(extendData?.bindData).forEach((eachKey)=>{
            let v = extendData.bindData[eachKey];

            if(v?.length){
                if(Array.isArray(v[0])){
                    //二维数组，是点数据
                    opts.push({
                        value: eachKey,
                        label: eachKey,
                        key: eachKey
                    })
                }else if(typeof v[0] === "string" || typeof v[0] === "number"){
                    //维度数据
                    optionsForAxis.push({
                        value: eachKey,
                        label: eachKey,
                        key: eachKey
                    })
                }

            }
        })
    }


    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >

            <Item
                noStyle={true}
                name={["dataSetConfig"]}
                rules={[{required: true}]}
            >
                <PointDataSet
                    optionsForAxis={optionsForAxis}
                    options={opts}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="scatterSpecial"
            header="散点图专用"
        >
            <Item
                label={"散点形状"}
                name={["scatterSpecial","_symbolSet","symbol"]}
            >
                <Select>
                    <Select.Option value={"circle"}>圆</Select.Option>
                    <Select.Option value={"rect"}>矩形</Select.Option>
                    <Select.Option value={"roundRect"}>圆角矩形</Select.Option>
                    <Select.Option value={"triangle"}>三角形</Select.Option>
                    <Select.Option value={"diamond"}>菱形</Select.Option>
                    <Select.Option value={"pin"}>坐标</Select.Option>
                    <Select.Option value={"arrow"}>箭头</Select.Option>
                </Select>
            </Item>
            <Item
                label={"散点大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["scatterSpecial","_symbolSet", "symbolSize"],{
                    min: 0,
                    max: 80,
                })}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="usual"
            header="布局"
        >
            <Item
                label={"坐标系"}
                name={["scatterSpecial","_coordinateSystem"]}
                extra={"不同的坐标系采用不同的布局配置，需要分别设置"}
            >
                <Select>
                    <Select.Option value={"cartesian2d"}>直角坐标系</Select.Option>
                    <Select.Option value={"polar"}>极坐标系</Select.Option>
                </Select>
            </Item>
            <Item
                noStyle={true}
                shouldUpdate={(prevValues, currentValues) => prevValues.scatterSpecial._coordinateSystem !== currentValues.scatterSpecial._coordinateSystem}
            >
                {({getFieldValue})=>{
                        let ifUse = getFieldValue(["scatterSpecial","_coordinateSystem"]) === "cartesian2d";
                        return <>
                            <Item
                                noStyle={true}
                                hidden={!ifUse}
                            >
                                {EchartComItem.getUsualItems()}
                            </Item>
                            <Item
                                noStyle={true}
                                hidden={ifUse}
                            >
                                <Item
                                    label={"内圈半径"}
                                >
                                    {CommonFormItems.getSliderAndInputItemGroup(["usualForPolar","_radiusInner"],{
                                        min: 0,
                                        max: 100,
                                        step: 1,
                                        addonAfter: "%"
                                    })}
                                </Item>
                                <Item
                                    label={"外圈半径"}
                                >
                                    {CommonFormItems.getSliderAndInputItemGroup(["usualForPolar","_radiusOuter"],{
                                        min: 0,
                                        max: 100,
                                        step: 1,
                                        addonAfter: "%"
                                    })}
                                </Item>
                                <Item
                                    label={"圆心位置"}
                                >
                                    <Item
                                        label={"水平"}
                                        name={["usualForPolar","_centerSetX"]}
                                    >
                                        <AutoComplete
                                            maxLength={10}
                                            placeholder="请选择或填写"
                                            options={[
                                                { value: '100px' },
                                                { value: '50%' },
                                            ]}
                                        />
                                    </Item>
                                    <Item
                                        label={"垂直"}
                                        name={["usualForPolar","_centerSetY"]}
                                    >
                                        <AutoComplete
                                            maxLength={10}
                                            placeholder="请选择或填写"
                                            options={[
                                                { value: '100px' },
                                                { value: '50%' },
                                            ]}
                                        />
                                    </Item>
                                </Item>
                            </Item>
                        </>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"legend"}
            header={"图例"}
        >
            {EchartComItem.getLegendItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="xAxis"
            header="主轴配置"
        >
            {EchartComItem.getAxisItems("xAxis")}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="yAxis"
            header="辅轴配置"
        >
            {EchartComItem.getAxisItems("yAxis")}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataZoom"
            header="数据缩放"
        >
            <Item
                label={"主轴缩放"}
                name={["dataZoom","useX"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Item
                label={"辅轴缩放"}
                name={["dataZoom","useY"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Item
                label={"无延迟缩放"}
                name={["dataZoom","_instantly"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: null,
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: "#333333ff"
            }
        },
        //散点图特殊
        scatterSpecial:{
            _coordinateSystem: "cartesian2d",
            _symbolSet: {
                symbol: "circle",
                symbolSize: 10,
            }
        },
        //布局
        usual: {
            _categoryWhere: "x",
            grid: {
                top: "10px",
                bottom: "10px",
                left: "10px",
                right: "10px",
                containLabel: true,
            }
        },
        //极坐标模式的布局
        usualForPolar: {
            _radiusInner: 0,
            _radiusOuter: 75,
            _centerSetX: "50%",
            _centerSetY: "50%",
        },
        //数据缩放
        dataZoom: {
            useX: false,
            useY: false,
            _instantly: true,
        },
        //主轴
        xAxis: {
            seeMore: false,
            show: true,
            showSplitLine: false,
            position: "bottom",
            name: null,
            nameLocation: "end",
            nameGap: 15,
            axisLabel: {
                rotate: 0
            }
        },
        //辅轴
        yAxis: {
            seeMore: false,
            show: true,
            showSplitLine: false,
            position: "left",
            name: null,
            nameLocation: "end",
            nameGap: 15,
            axisLabel: {
                rotate: 0
            }
        },
        //图例
        legend: {
            seeMore: false,
            show: false,
            type: "plain",  //plain  scroll
            icon: "roundRect",
            _position: "top",
            width: "auto",
            height: "auto",
            orient: "horizontal",
            align: "auto",
            itemGap: 10,
        },
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 point, x, y 等 字段可以自定义。
    1.类目轴模式的散点图的数据结构，必须是一个对象，必须包含 类目轴字段 与 点集合字段。
        {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            y: ["凌晨","早晨","上午","中午","下午","傍晚","晚上","午夜"],
            point: [[0,1,5],[2,5,5],[1,3,1]]
        }
        数据解析：x轴代表 星期，y轴代表时间。一共存在三个点，
        第一个点[0,1,5]，0对应 周日，1对应早晨，5表示点的大小。
        
    这种格式也可以改写成明确指定点的位置的写法，如下：
        {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            y: ["凌晨","早晨","上午","中午","下午","傍晚","晚上","午夜"],
            point: [["周日","早晨",5],["周二","傍晚",5],["周一","中午",1]]
        }
    如果不需要配置点的大小，则可以更简单：
      {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            y: ["凌晨","早晨","上午","中午","下午","傍晚","晚上","午夜"],
            point: [["周日","早晨"],["周二","傍晚"],["周一","中午"]]
        }
        
    2.数值轴模式的散点图的数据结构，必须是一个对象，至少包含 点集合 字段。如下，x是类目轴，y是数值轴，所以y可以不传。
        {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            point: [["周日",15,5],["周二",3,5],["周一",20,1]]
        }
        
    3.如果所有的点都一样大，则每个点的数据，可以只传2个维度，如下：
        {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            y: ["凌晨","早晨","上午","中午","下午","傍晚","晚上","午夜"],
            point: [["周日","早晨"],["周二","傍晚"],["周一","中午"]]
        }
    4.最简单的例子，x和y都是数值轴，且所有点的大小相同，如下：
       {
            point: [[1,20],[6,52],[11,30],[75,12],[32,55]]
        }
    5.多个系列的散点，可以定义多个 点集合字段，如下：
        {
            x: ["周日","周一","周二","周三","周四","周五","周六"],
            y: ["凌晨","早晨","上午","中午","下午","傍晚","晚上","午夜"],
            point01: [[0,1,5],[2,5,5],[1,3,1]],
            point02: [[1,1,3],[1,2,3],[3,3,6],[2,2,2]],
            point03: [[2,1,3],[0,2,1],
        }
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}