import React from "react";
import {
    Form,
    Divider,
    Radio,
    InputNumber,
    Switch,
    Input,
    AutoComplete,
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(extendData = {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }

    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"总数字段名"}
            name={"totalField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"成功数字段名"}
            name={"successField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            顶部信息设置
        </Divider>
        <Item
            label={"信息类型"}
            name={"topType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">文字</Radio>
                <Radio value="icon">图标</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle
            dependencies={['topType']}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("topType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("topTextSet");break;
                    case "icon": items = CommonFormItems.getIconSet("topIconSet",getFieldValue);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            底部信息设置
        </Divider>
        <Item
            label={"底部信息类型"}
            name={"beforeType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">文字</Radio>
                <Radio value="icon">图标</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.beforeType !== currentValues.beforeType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("beforeType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("beforeTextSet");break;
                    case "icon": items = CommonFormItems.getIconSet("beforeIconSet",getFieldValue);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            进度信息设置
        </Divider>
        <Item
            label={"进度信息类型"}
            name={"afterType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="none">无</Radio>
                <Radio value="text">数字百分比</Radio>
                <Radio value="towNum">完成/总量</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"处理大数字"}
            name={["bigNumber"]}
            rules={[{required: true}]}
            extra={"当数量超过1万时，以W为单位展示"}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.afterType !== currentValues.afterType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("afterType")){
                    case "none": items = null;break;
                    case "text": items = <Item
                        name={"precision"}
                        label={"小数点后几位"}
                    >
                        <InputNumber
                            min={0}
                            max={4}
                            step={1}
                        />
                    </Item>;break;
                    case "towNum": items = null;break;
                }
                return items;
            }}
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues)=>{return prevValues.afterType !== currentValues.afterType}}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("afterType")){
                    case "none": items = null;break;
                    case "text": items = CommonFormItems.getFontSet("afterTextSet", true);break;
                    case "towNum": items = CommonFormItems.getFontSet("afterTextSet", true);break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            进度条设置
        </Divider>
        <Item
            label={"高度"}
        >
            {CommonFormItems.distanceSet(["borderSet","heightSet", "num"], ["borderSet","heightSet", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"圆角"}
        >
            {CommonFormItems.distanceSet(["borderSet","radiusSet", "num"], ["borderSet","radiusSet", "unit"], ["px", "rem", "%"])}
        </Item>
        <Item
            label={"边框宽度"}
        >
            {CommonFormItems.distanceSet(["borderSet","borderWidthSet", "num"], ["borderSet","borderWidthSet", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"上边距"}
        >
            {CommonFormItems.distanceSet(["borderSet","marginTop", "num"], ["borderSet","marginTop", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"下边距"}
        >
            {CommonFormItems.distanceSet(["borderSet","marginBottom", "num"], ["borderSet","marginBottom", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"边框颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["borderSet","borderColor"])}
        </Item>
        <Item
            label={"背景颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["borderSet","backgroundColor"])}
        </Item>
        <Item
            label={"背景不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["borderSet","backgroundOpacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"进度条颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["barSet","successColorStart"])}
        </Item>
        <Item
            label={"进度条颜色渐变"}
        >
            {CommonFormItems.getColorSetItemInner(["barSet","successColorEnd"])}
        </Item>
        <Item
            label={"进度条不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["barSet","backgroundOpacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"进度条起始方向"}
            name={["barSet","barPosition"]}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="left">左侧</Radio>
                <Radio value="right">右侧</Radio>
            </Radio.Group>
        </Item>
        <Divider orientation="left">
            其它设置
        </Divider>
        <Item
            label={"上下换位"}
            name={"topAndBottomReverse"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"左右换位"}
            name={"leftAndRightReverse"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        totalField: null,
        successField: null,

        beforeType: "none", //none  text  icon
        beforeTextSet: {
            fontFamily: "default",
            color: "#999999",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
            text: "进度条名称",
        },
        beforeIconSet: undefined,
        afterType: "none", //none  text  towNum
        bigNumber: false,
        afterTextSet: {
            fontFamily: "default",
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        precision: 2,   //精确到小数点后几位
        topType: "none", //none  icon
        topTextSet: undefined,
        topIconSet: undefined,
        borderSet: {
            heightSet: {
                num: 6,
                unit: "rem", //px rem
            },
            radiusSet: {
                num: 2,
                unit: "rem",    //px rem %
            },
            backgroundColor: "#ffffff",
            backgroundOpacity: 100,
            borderWidthSet: {
                num: 0,
                unit: "px",    //px rem
            },
            borderColor: "#eeeeee",
            marginTop: {
                num: 4,
                unit: "rem"
            },
            marginBottom: {
                num: 4,
                unit: "rem"
            },
        },
        barSet: {
            successColorStart: "#09b0ff",
            successColorEnd: "#37d200",
            backgroundOpacity: 100,
            barPosition: "left", //left,right  从哪边开始涨
        },
        topAndBottomReverse: false, //上下换位
        leftAndRightReverse: false, //左右换位
    }
}

//推荐数据格式
function getDemoCode(){
    return `
    以下示例中 total、success 均可在 数据对接设置 中修改替换成其它单词
    {
        total: 200,
        success: 160
    }`
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}