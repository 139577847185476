import React from "react";
import {
    Form,
    Divider,
    Radio,
    InputNumber,
    Switch,
    Input
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import UploadFile from "../../../../../../components/upLoad/UploadFile"

const Item = Form.Item;

function getSetItems(){
    return <>
        <Divider orientation="left">
            图片参数设置
        </Divider>
        <Item
            label={"图片圆角"}
        >
            {CommonFormItems.distanceSet(["radiusSet", "num"],["radiusSet", "unit"],["px","rem","%"])}
        </Item>
        <Item
            label={"不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("opacity", {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"亮度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("brightness", {
                min: 0,
                max: 200
            })}
        </Item>
        <Item
            label={"模糊效果"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("blur", {
                min: 0,
                max: 20
            })}
        </Item>
        <Form.Item
            name={"backgroundImgSize"}
            label={"图片大小"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="cover">剪裁铺满</Radio>
                <Radio value="contain">包含</Radio>
                <Radio value="100% 100%">拉伸填充</Radio>
                <Radio value="diy">自定义比例</Radio>
            </Radio.Group>
        </Form.Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.backgroundImgSize !== currentValues.backgroundImgSize}
        >
            {({getFieldValue}) => {
                let showItem;
                if (getFieldValue(["backgroundImgSize"]) === "diy") {
                    showItem = <>
                        <Item
                            label={"宽"}
                        >
                            {CommonFormItems.distanceSet(["backgroundImgSizeDiy", "width"], ["backgroundImgSizeDiy", "widthUnit"])}
                        </Item>
                        <Item
                            label={"高"}
                        >
                            {CommonFormItems.distanceSet(["backgroundImgSizeDiy", "height"], ["backgroundImgSizeDiy", "heightUnit"])}
                        </Item>
                    </>
                } else {
                    showItem = <>

                    </>
                }
                return showItem
            }}
        </Item>
        <Form.Item
            label={"图片位置"}
        >
            <Item
                label={"距左"}
            >
                {CommonFormItems.distanceSet(["bgImgPositionSet", "left"], ["bgImgPositionSet", "leftUnit"])}
            </Item>
            <Item
                label={"距顶"}
            >
                {CommonFormItems.distanceSet(["bgImgPositionSet", "top"], ["bgImgPositionSet", "topUnit"])}
            </Item>
        </Form.Item>
        <Form.Item
            noStyle={true}
            shouldUpdate
        >
            {({getFieldValue}) => {
                let defaultImgUrl = getFieldValue(["upload_imageUrl"]);
                return <Form.Item
                    name={["upload_imageUrl"]}
                    label={"上传图片"}
                    rules={[{required: true}]}
                    normalize={(value, prevValue, prevValues) => {
                        value = value?.[0]?.url || null
                        return value
                    }}
                >
                    <UploadFile
                        toCN={true}
                        accept={"image/*"}
                        size={1}
                        bigLabel={true}
                        data={{
                            option: {
                                size: 1,
                                defaultValue: defaultImgUrl ? [{
                                    url: defaultImgUrl
                                }] : null
                            }
                        }}
                    />
                </Form.Item>
            }}
        </Form.Item>
        <Form.Item
            name={"imageJumpUrl"}
            label={"图片超链接"}
        >
            <Input
                placeholder={"请输入跳转网址"}
            />
        </Form.Item>
    </>
}

function getDefaultValues(){
    return {
        imageJumpUrl: null, //点击跳转地址
        upload_imageUrl: null,
        backgroundImgSize: "contain", //cover contain fill diy
        backgroundImgSizeDiy:{
            width: 100,
            widthUnit: "%",
            height: 100,
            heightUnit: "%",
        },
        bgImgPositionSet:{
            top: 50,
            topUnit: "%",
            left: 50,
            leftUnit: "%",
        },
        radiusSet: {
            num: 0,
            unit: "px" //px  %
        }, //圆角
        opacity: 100,   //不透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
    }
}
export default {
    getSetItems,
    getDefaultValues,
}