import React from "react";
import styles from "./contentView/ContentView.less";

//获取文字渲染
function getText(textSet,innerText){
    return <div
        style={getTextStyleObj(textSet)}
    >{innerText}</div>
}

//根据字体样式配置对象生成 html内嵌样式
function getTextStyleObj(textSet){
    if(!textSet){
        return {}
    }
    let styleObj = {
        display: "inline-block",
        color: textSet.color,
        fontSize: window.changeRemNum(textSet,"fontSize","sizeUnit"),
        fontWeight: textSet.fontBold ? "bold" : "normal",
        fontStyle: textSet.fontItalic ? "italic" : "normal",
    };

    if(textSet.fontFamily && textSet.fontFamily !== "default"){
        styleObj.fontFamily = textSet.fontFamily
    }

    return styleObj;
}

 //图标渲染
function getIcon(iconSet){
    if(!iconSet){
        return null
    }
    return <div
        className={styles.iconSetImg}
        style={{
            width: window.changeRemNum(iconSet,"iconW","iconWUnit"),
            height: window.changeRemNum(iconSet,"iconH","iconHUnit"),
            backgroundImage: `url(${iconSet.upload_imageUrl})`,
        }}
    >
    </div>
}

export default {
    getText,
    getIcon,
    getTextStyleObj
}