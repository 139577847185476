import React from "react";
import {Space, InputNumber, Switch} from "antd";

//可选择设置 1个值(数字)  或  4个不同的值(数组)
//常见的用法 如 padding 的设置
export default class SetOneOrFour extends React.Component {
    constructor(props) {
        super(props);

        let {value} = this.props;

        this.state = {
            value: value,
            ifChecked: !Array.isArray(value)
        }
    }

    render() {
        let {ifChecked, value} = this.state;
        let {step, precision, min, max} = this.props;
        let extraOptions = {step, precision, min, max};

        return <Space
            style={{width: "100%"}}
            align={"start"}
            wrap={true}
        >
            <Switch
                checked={ifChecked}
                checkedChildren={"统一设置"}
                unCheckedChildren={"差异设置"}
                onChange={(v) => {
                    let value;
                    if (v) {
                        //只留一个值
                        value = this.state.value?.[0] || 0
                    } else {
                        //需要四个值
                        value = Array(4).fill(Number(this.state.value) || 0)
                    }
                    this.setState({
                        ifChecked: v,
                        value: value
                    }, () => {
                        this.props.onChange?.(this.state.value)
                    })
                }}
            />
            {
                ifChecked
                    ? <InputNumber
                        {...extraOptions}
                        value={value}
                        onChange={(v) => {
                            value = v;
                            this.setState({
                                value: value
                            }, () => {
                                this.props.onChange?.(this.state.value)
                            })
                        }}
                    />
                    : <Space
                        // style={{width: "100%"}}
                        direction="vertical"
                    >
                        <InputNumber
                            {...extraOptions}
                            addonBefore={"上"}
                            value={value[0]}
                            onChange={(v) => {
                                value[0] = v;
                                this.setState({
                                    value: value
                                }, () => {
                                    this.props.onChange?.(this.state.value)
                                })
                            }}
                        />
                        <InputNumber
                            {...extraOptions}
                            addonBefore={"下"}
                            value={value[2]}
                            onChange={(v) => {
                                value[2] = v;
                                this.setState({
                                    value: value
                                }, () => {
                                    this.props.onChange?.(this.state.value)
                                })
                            }}
                        />
                        <InputNumber
                            {...extraOptions}
                            addonBefore={"左"}
                            value={value[3]}
                            onChange={(v) => {
                                value[3] = v;
                                this.setState({
                                    value: value
                                }, () => {
                                    this.props.onChange?.(this.state.value)
                                })
                            }}
                        />
                        <InputNumber
                            {...extraOptions}
                            addonBefore={"右"}
                            value={value[1]}
                            onChange={(v) => {
                                value[1] = v;
                                this.setState({
                                    value: value
                                }, () => {
                                    this.props.onChange?.(this.state.value)
                                })
                            }}
                        />
                    </Space>
            }
        </Space>
    }
}