import React from "react";
import {message,AutoComplete,Row,Col,Space,Button} from "antd";
import {MinusCircleOutlined,PlusOutlined,LinkOutlined} from "@ant-design/icons";
import less from "../ShowEchart.less";
import ColorPicker from "../../../../formDiyItem/ColorPicker";
import CommonFormItems from "../../../../CommonFormItems";

export default class PointDataSet extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            dataSetConfig: this.props.value || {
                labelField: null,
                valueField: null,
                pieSetArr: null,
            },
            
            listChange: 0,
            opts: [],
            optionsForField: [],
        }
    }

    componentDidMount() {
        if(this.props.bindData){
            let opts = CommonFormItems.getAutoCompleteOptionsByBindData(this.props.bindData);

            this.setState({
                opts: opts
            },()=>{
                this.createOptionForField(this.props.bindData,this.state.dataSetConfig?.labelField)
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.bindData){
            let opts = CommonFormItems.getAutoCompleteOptionsByBindData(nextProps.bindData);

            this.setState({
                opts: opts
            },()=>{
                this.createOptionForField(nextProps.bindData,this.state.dataSetConfig?.labelField)
            })
        }
    }

    configChanged(){
        this.props.onChange?.(this.state.dataSetConfig);
    }

    render(){
        let {dataSetConfig,listChange,optionsForField,opts} = this.state;

        return <div>
            <Row>
                <Col span={6} offset={2}>名称维度：</Col>
                <Col span={10}>
                    <AutoComplete
                        size={"small"}
                        style={{width: "100%"}}
                        value={dataSetConfig?.labelField}
                        placeholder={"请选择或填写对接字段"}
                        options={opts}
                        onChange={(v)=>{
                            dataSetConfig.labelField = v;
                            this.setState({},()=>{
                                this.configChanged()
                                this.createOptionForField(this.props.bindData,v)
                            })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={6} offset={2}>数值维度：</Col>
                <Col span={10}>
                    <AutoComplete
                        size={"small"}
                        style={{width: "100%"}}
                        value={dataSetConfig?.valueField}
                        placeholder={"请选择或填写对接字段"}
                        options={opts}
                        onChange={(v)=>{
                            dataSetConfig.valueField = v;
                            this.setState({},()=>{
                                this.configChanged()
                            })
                        }}
                    />
                </Col>
            </Row>
            {
                dataSetConfig?.pieSetArr?.map((eachPieSet,index)=>{
                    eachPieSet.index = index;
                    return <Row
                        key={"la" + index + listChange}
                        className={less.tabLine}
                        style={{cursor: "unset"}}
                    >
                        <Col span={6}>
                            颜色映射{index + 1}：</Col>
                        <Col span={10}>
                            <AutoComplete
                                size={"small"}
                                style={{width: "100%"}}
                                value={eachPieSet.field}
                                placeholder={"请选择或填写对接字段"}
                                options={optionsForField}
                                onChange={(v)=>{
                                    eachPieSet.field = v;
                                    this.setState({},()=>{
                                        this.configChanged()
                                    })
                                }}
                            />
                        </Col>
                        <Col span={6} offset={1}>
                            <Space>
                                <MinusCircleOutlined
                                    style={{
                                        color: "red"
                                    }}
                                    onClick={()=>{
                                        this.deleteColorSet(index)
                                    }}
                                />
                                <ColorPicker
                                    value={eachPieSet.extraStyle?.color}
                                    hideRecommendBtn={true}
                                    onChange={(v)=>{
                                        if(!eachPieSet.extraStyle){
                                            eachPieSet.extraStyle = {};
                                        }
                                        eachPieSet.extraStyle.color = v;

                                        this.setState({
                                            dataSetConfig: dataSetConfig
                                        },()=>{
                                            this.configChanged()
                                        })
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                })
            }
            <Row>
                <Col span={6} offset={4}>
                    <Button
                        icon={<LinkOutlined />}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addAll()
                        }}
                    >
                        自动提取
                    </Button>
                </Col>
                <Col span={6} offset={4}>
                    <Button
                        icon={<PlusOutlined />}
                        style={{color: "limegreen"}}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addOneColorSetData()
                        }}
                    >
                        增加一个扇区配置
                    </Button>
                </Col>
            </Row>
        </div>
    }

    //生成扇区名称选择下拉
    createOptionForField(bindData,labelField){
        let optionsForField = [];
        if(!labelField || !bindData){
            optionsForField = [];
        }else{
            if(Array.isArray(bindData)){
                bindData.forEach((eachData)=>{
                    if(eachData[labelField]){
                        optionsForField.push({
                            title: eachData[labelField],
                            value: eachData[labelField],
                        })
                    }
                })
            }else if(Array.isArray(bindData[labelField])){
                bindData[labelField].forEach((eachTitle)=>{
                    optionsForField.push({
                        title: eachTitle,
                        value: eachTitle
                    })
                })
            }
        }

        this.setState({
            optionsForField: optionsForField
        })
    }


    addOneColorSetData(){
        let {dataSetConfig} = this.state;

        if(!dataSetConfig.pieSetArr){
            dataSetConfig.pieSetArr = [];
        }

        if(dataSetConfig.pieSetArr.length>=20){
            message.warning("最多配置20项")
            return;
        }
        dataSetConfig.pieSetArr.push({
            field: null,
            extraStyle: null,
        })

        this.setState({})
    }

    deleteColorSet(index){
        let {dataSetConfig,listChange} = this.state;

        _.remove(dataSetConfig.pieSetArr,(ti,n)=>{
            return n === index
        })

        this.setState({listChange: listChange + 1},()=>{
            this.configChanged()
        })
    }

    addAll(){
        let {dataSetConfig,optionsForField} = this.state;

        if(!dataSetConfig.pieSetArr){
            dataSetConfig.pieSetArr = [];
        }

        let has = [];
        dataSetConfig?.pieSetArr?.forEach((lineSet)=>{
            has.push(lineSet.field);
        })
        optionsForField?.forEach((opt)=>{
            if(!has.includes(opt.value)){
                if(dataSetConfig.pieSetArr?.length< 20){
                    dataSetConfig.pieSetArr.push({
                        field: opt.value,
                        extraStyle: null,
                    })
                }
            }
        })

        this.setState({},()=>{
            this.configChanged()
        })
    }
}