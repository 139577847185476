import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";

const SliceLength = 500;//数据分段展示限制长度

export default class FunnelShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;


        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position){
            case "top": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "center",
            });break;
            case "bottom": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "center",
            });break;
            case "left": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "5%",
            });break;
            case "right": option.legend =  Object.assign(option.legend,{
                top: "center",
                right: "5%",
            });break;
            case "topLeft": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "5%",
            });break;
            case "topRight": option.legend =  Object.assign(option.legend,{
                top: "5%",
                right: "5%",
            });break;
            case "bottomLeft": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "5%",
            });break;
            case "bottomRight": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                right: "5%",
            });break;
            case "center": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "center",
            });break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        let limitNum = dataConfig?.contentParams?.limitNum || SliceLength;

        if(!dataConfig?.contentParams?.dataSetConfig?.labelField || !dataConfig?.contentParams?.dataSetConfig?.valueField){
            this.setState({
                error: "请填写数据对接字段"
            })
            return;
        }

        if(data?.length>limitNum){
            this.setState({
                error: `数据量超过${limitNum}条，不予以展示，请优化数据或修改配置`
            })
            return;
        }

        //另一种格式的数据的判断
        if(dataConfig?.contentParams?.dataSetConfig?.labelField){
            if(data?.[dataConfig?.contentParams?.dataSetConfig?.labelField]?.length>limitNum){
                this.setState({
                    error: `数据量超过${limitNum}条，不予以展示，请优化数据或修改配置`
                })
                return;
            }
        }

        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "funnel",
                min: dataConfig.contentParams?.funnelSpecial?.min,
                max: dataConfig.contentParams?.funnelSpecial?.max,
                minSize: dataConfig.contentParams?.funnelSpecial?.minSize + "%",
                maxSize: dataConfig.contentParams?.funnelSpecial?.maxSize + "%",
                gap: dataConfig.contentParams?.funnelSpecial?.gap,
                sort: dataConfig.contentParams?.funnelSpecial?.sort,
                orient: dataConfig.contentParams?.usual?._orient,
                funnelAlign: dataConfig.contentParams?.usual?._funnelAlign,
            };

            //布局
            seriesItem = Object.assign(seriesItem,dataConfig.contentParams?.usual?.grid)


            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                    position: dataConfig?.contentParams?.label.position,
                }

                if(dataConfig?.contentParams?.label?._beforeTxt || dataConfig?.contentParams?.label?._afterTxt){
                    seriesItem.label.formatter = `${dataConfig?.contentParams?.label?._beforeTxt || ""}{b}${dataConfig?.contentParams?.label?._afterTxt || ""}`
                }

                switch (dataConfig?.contentParams?.label._showPlan){
                    case "normal":break;
                    case "pop":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                }
            }

            //面积样式
            seriesItem.itemStyle = dataConfig?.contentParams?.itemStyle;

            //高亮配置
            seriesItem.emphasis = {}
            //高亮下的面积
            if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.itemStyle = _.cloneDeep(seriesItem.itemStyle);

                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("borderType")){
                    seriesItem.emphasis.itemStyle.borderType = dataConfig?.contentParams?.itemStyle?._emphasis?.borderType;
                }
                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("borderColor")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.itemStyle?._emphasis?.borderColor){
                        seriesItem.emphasis.itemStyle.borderColor = dataConfig?.contentParams?.itemStyle?._emphasis?.borderColor;
                    }else{
                        delete seriesItem.emphasis.itemStyle.borderColor
                    }
                }
            }

            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }


            //根据数据和配置填充其它属性
            let newData = [];
            if(Array.isArray(data)){
                data.forEach((eachItem)=>{
                    newData.push({
                        name: eachItem[dataConfig?.contentParams?.dataSetConfig?.labelField] || "未匹配到名称",
                        value: eachItem[dataConfig?.contentParams?.dataSetConfig?.valueField] || 0,
                    })
                })

                seriesItem.data = newData
            }else if(Object.prototype.toString.call(data) === '[object Object]'){
                if(Array.isArray(data[dataConfig?.contentParams?.dataSetConfig?.labelField]) && Array.isArray(data[dataConfig?.contentParams?.dataSetConfig?.valueField])){

                    data[dataConfig?.contentParams?.dataSetConfig?.labelField].forEach((eachItemStr,index)=>{
                        newData.push({
                            name: eachItemStr,
                            value: data[dataConfig?.contentParams?.dataSetConfig?.valueField][index] || 0
                        })
                    })
                    seriesItem.data = newData
                }else{
                    this.setState({
                        error: "数据结构错误"
                    })
                    return;
                }
            }

            option.series = [seriesItem];
        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}