import React from "react";
import {
    Form,
    Divider,
    Radio,
    InputNumber,
    Switch,
    Input,
    Row,
    Col,
    AutoComplete
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(extendData = {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }

    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"名称字段名"}
            name={"titleField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"数量字段名"}
            name={"numField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            名称字体设置
        </Divider>
        <Item
            name={"showIndex"}
            label={"显示排序数字"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        {CommonFormItems.getFontSet("titleTextSet",true)}
        <Divider orientation="left">
            数量字体设置
        </Divider>
        {CommonFormItems.getFontSet("numTextSet",true)}
        <Item
            label={"处理大数字"}
            name={["bigNumber"]}
            rules={[{required: true}]}
            extra={"当数量超过1万时，以W为单位展示"}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"携带单位"}
            name={["unitStr"]}
            extra={"如果不填写单位文案，则不展示"}
        >
            <Input
                maxLength={"30"}
            />
        </Item>
        <Item
            name={"unitStyleUse"}
            label={"单位独立样式"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            noStyle
            dependencies={["unitStyleUse"]}
        >
            {({getFieldValue})=>{
                let items;
                switch (getFieldValue("unitStyleUse")){
                    case false: items = null;break;
                    case true: items = <>
                        <Divider orientation="left">
                            数量单位样式设置
                        </Divider>
                        {CommonFormItems.getFontSet("unitTextSet", true)}
                    </>;break;
                }
                return items;
            }}
        </Item>
        <Divider orientation="left">
            进度条样式设置
        </Divider>
        <Item
            label={"高度"}
        >
            {CommonFormItems.distanceSet(["borderSet","heightSet", "num"], ["borderSet","heightSet", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"圆角"}
        >
            {CommonFormItems.distanceSet(["borderSet","radiusSet", "num"], ["borderSet","radiusSet", "unit"], ["px", "rem", "%"])}
        </Item>
        <Item
            label={"边框宽度"}
        >
            {CommonFormItems.distanceSet(["borderSet","borderWidthSet", "num"], ["borderSet","borderWidthSet", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"上边距"}
        >
            {CommonFormItems.distanceSet(["borderSet","marginTop", "num"], ["borderSet","marginTop", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"下边距"}
        >
            {CommonFormItems.distanceSet(["borderSet","marginBottom", "num"], ["borderSet","marginBottom", "unit"], ["px", "rem"])}
        </Item>
        <Item
            label={"边框颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["borderSet","borderColor"])}
        </Item>
        <Item
            label={"背景颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["borderSet","backgroundColor"])}
        </Item>
        <Item
            label={"背景不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["borderSet","backgroundOpacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"进度条颜色"}
        >
            {CommonFormItems.getColorSetItemInner(["barSet","successColorStart"])}
        </Item>
        <Item
            label={"进度条颜色渐变"}
        >
            {CommonFormItems.getColorSetItemInner(["barSet","successColorEnd"])}
        </Item>
        <Item
            label={"进度条不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["barSet","backgroundOpacity"], {
                min: 0,
                max: 100
            })}
        </Item>
    </>
}

function getDefaultValues(){
    return {
        titleField: null,
        numField: null,
        showIndex: false,   //显示排序数字
        unitStr: null,
        bigNumber: false,
        unitStyleUse: false,
        titleTextSet: {
            fontFamily: "default",
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        numTextSet: {
            fontFamily: "default",
            color: "#02dee9",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
        unitTextSet: null,
        borderSet: {
            heightSet: {
                num: 6,
                unit: "rem", //px rem
            },
            radiusSet: {
                num: 2,
                unit: "rem",    //px rem %
            },
            backgroundColor: "#ffffff",
            backgroundOpacity: 100,
            borderWidthSet: {
                num: 0,
                unit: "px",    //px rem
            },
            borderColor: "#eeeeee",
            marginTop: {
                num: 4,
                unit: "rem"
            },
            marginBottom: {
                num: 4,
                unit: "rem"
            },
        },
        barSet: {
            successColorStart: "#09b0ff",
            successColorEnd: "#37d200",
            backgroundOpacity: 100
        },
    }
}

//推荐数据格式
function getDemoCode(){
    return `
   以下示例中 title、num 均可在 数据对接设置 中修改替换成其它单词
    [
        {
            title: "标题1",
            num: 160
        },
        {
            title: "标题2",
            num: 88
        },
        {
            title: "标题3",
            num: 150
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}