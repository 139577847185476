import React from "react";
import styles from "./DashboardConfigSet.less";
import {Form, Button, message, Tabs, Radio, Tooltip, Space, Modal} from "antd";
import BaseSetItem from "./BaseSetItem";
import {FormatPainterOutlined, BgColorsOutlined} from "@ant-design/icons";
import TimeHelp from '../../../../tool/TimeHelp';
import LocalStorage from '../../../../tool/LocalStorage';
import BackgroundBgDiv from "../contentView/BackgroundBgDiv";
import CommonViewDiv from "../CommonViewDiv";
import {Swiper, SwiperSlide} from "swiper/react";
import {
    Thumbs,
} from 'swiper';
import 'swiper/swiper-bundle.css';

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
    size: "small"
};

const DashboardItemStyleObjSaveKey = "DashboardItemStyleObjSaveKey";

export default class NavConfigSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSelTabKey: "navigationBarSet",
            formUpdate: 0,
            swiperUpdate: 0,
            themeConfig: this.props.defaultConfig || {},
            sceneUse: 1,
            currentShowSceneIndex: 0
        }

        this.BaseSetItem = new BaseSetItem();

        this.sceneArrayDemo = [{
            _id: "demo01",
            title: "演示场景A",
        },
            {
                _id: "demo02",
                title: "演示场景B",
            },
            {
                _id: "demo03",
                title: "演示场景C",
            },
            {
                _id: "demo04",
                title: "演示场景D",
            },
            {
                _id: "demo05",
                title: "演示场景E",
            },
            {
                _id: "demo06",
                title: "演示场景F",
            },
        ];
    }

    //延迟触发，避免太频繁
    delayChangeAction(action) {
        if (this.delayMark) {
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(() => {
            action?.();
        }, 300)
    }

    componentWillUnmount() {
        if (this.delayMark) {
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    render() {
        let {currentSelTabKey, formUpdate, themeConfig,sceneUse,swiperUpdate} = this.state;
        let sceneArray = sceneUse === 1?this.sceneArrayDemo : this.props.allConfig.sceneArray;
        console.log(themeConfig)

        return <div className={styles.dashboardSetBox}>
            <div className={styles.dashboardSetHead}>
                <div>导航样式设置</div>
                <Button
                    type={"link"}
                    onClick={() => {
                        this.props.onClose?.()
                    }}
                >
                    <span style={{color: "red"}}>收起</span>
                </Button>
            </div>
            <div
                className={styles.navDemoSetBody}
            >
                <div className={styles.navDemoSetBodyLeft}>
                    <Form.Provider
                        key={"fp_" + formUpdate}
                        onFormChange={(formName, info) => {
                            this.delayChangeAction(() => {
                                console.log(formName)
                                console.log(info)
                                let formAllValue = info.forms[formName].getFieldsValue();
                                themeConfig[formName] = formAllValue;
                                this.setState({
                                    themeConfig: themeConfig,
                                    swiperUpdate: swiperUpdate + 1,
                                }, () => {
                                    this.props.onChange(formName, formAllValue)
                                })
                            })
                        }}
                    >
                        <Tabs
                            activeKey={currentSelTabKey}
                            tabPosition={"left"}
                            type={"card"}
                            onChange={(activeKey) => {
                                this.setState({
                                    currentSelTabKey: activeKey
                                })
                            }}
                        >
                            <Tabs.TabPane
                                tab={"导航配置"}
                                key={"navigationBarSet"}
                            >
                                <div className={styles.tabPaneRightBox}
                                    style={{top: "0px"}}
                                >
                                    {this.getStylesCopyBtns("navigationBarSet", "导航配置")}
                                    <Form
                                        name={"navigationBarSet"}
                                        {...formItemLayout}
                                        requiredMark={false}
                                        initialValues={this.props.defaultConfig?.navigationBarSet}
                                    >
                                        {this.BaseSetItem.getNavigationBarSet("navigationBarSet")}
                                    </Form>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={"导航背景"}
                                key={"navigationBarBgSet"}
                            >
                                <div className={styles.tabPaneRightBox}
                                     style={{top: "0px"}}
                                >
                                    {this.getStylesCopyBtns("navigationBarBgSet", "导航背景")}
                                    <Form
                                        name={"navigationBarBgSet"}
                                        {...formItemLayout}
                                        requiredMark={false}
                                        initialValues={this.props.defaultConfig?.navigationBarBgSet}
                                    >
                                        {this.BaseSetItem.getBackgroundSet("navigationBarBgSet")}
                                    </Form>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Form.Provider>
                </div>
            </div>
            <Radio.Group
                value={sceneUse}
                onChange={(e)=>{
                    this.setState({
                        sceneUse: e.target.value,
                        swiperUpdate: swiperUpdate + 1,
                    })
                }}
            >
                <Radio.Button value={1}>使用演示场景名</Radio.Button>
                <Radio.Button value={2}>使用我的场景名</Radio.Button>
            </Radio.Group>
            <div className={styles.navDemoSetFooter}
                 style={{
                     backgroundSize:`${this.props.eachColWidth}px ${this.props.eachLineHeight}px`,
                 }}
            >
                <div className={styles.swiperContent}>
                    仪表板内容Demo
                </div>
                {
                    themeConfig.navigationBarSet.show
                        ?<div style={this.getThumbsBoxStyleObj(themeConfig)}>
                            <div style={this.getThumbsBoxInnerStyleObj(themeConfig)}>
                                {
                                    themeConfig.navigationBarBgSet.use
                                        ?<BackgroundBgDiv
                                            backgroundSet={themeConfig.navigationBarBgSet}
                                        />
                                        :null
                                }
                                {
                                    sceneArray?.length>0
                                        ?<Swiper
                                            key={"s_" + swiperUpdate}
                                            style={{
                                                flex: 1,
                                                overflow: "hidden"
                                            }}
                                            modules={[Thumbs]}
                                            spaceBetween={themeConfig.navigationBarSet.spaceBetween || 10}
                                            slidesPerView={themeConfig.navigationBarSet.slidesPerView || 10}
                                            watchSlidesVisibility={true}
                                            watchSlidesProgress={true}
                                            centerInsufficientSlides={themeConfig.navigationBarSet.centerInsufficientSlides}
                                            onInit={(swiper) => {
                                                this.swiperNav = swiper;
                                            }}
                                        >
                                            {
                                                sceneArray.map((sceneItemMsg, index) => {
                                                    return <SwiperSlide
                                                        key={sceneItemMsg._id}
                                                        virtualIndex={index}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentShowSceneIndex: index
                                                            })
                                                        }}
                                                    >
                                                        {({isActive, isVisible}) => {
                                                            let ifSel = this.state.currentShowSceneIndex === index;
                                                            let itemStyleSet = this.getItemStyleFinally(ifSel,themeConfig.navigationBarSet)
                                                            let backgroundSet = this.getBgSetFinally(ifSel,themeConfig.navigationBarSet)

                                                            return <>
                                                                <BackgroundBgDiv
                                                                    backgroundSet={backgroundSet}
                                                                    injectImgUrl={sceneItemMsg.styleConfig?.thumbsImgSet?.upload_imageUrl ? sceneItemMsg.styleConfig?.thumbsImgSet?.upload_imageUrl + "?x-oss-process=image/resize,w_300" : null}
                                                                />
                                                                <div
                                                                    className={styles.swiperTitleBox}
                                                                    style={{
                                                                        borderRadius: window.changeRemNum(backgroundSet?.radiusSet),
                                                                    }}
                                                                >
                                                                    {
                                                                        itemStyleSet.show === true || itemStyleSet.show === undefined
                                                                            ?<span
                                                                                style={CommonViewDiv.getTextStyleObj(itemStyleSet?.fontSet)}
                                                                            >
                                                                    {sceneItemMsg.title || "场景" + (index + 1)}
                                                                </span>
                                                                            :null
                                                                    }
                                                                </div>
                                                            </>;
                                                        }}
                                                    </SwiperSlide>
                                                })
                                            }
                                        </Swiper>
                                        :null
                                }
                            </div>
                        </div>
                        :null
                }
            </div>
        </div>
    }

    //字体样式
    getItemStyleFinally(ifSel,navigationBarSet){
        if(!ifSel){
            return navigationBarSet.normalItemStyleSet
        }else{
            if(navigationBarSet?.normalItemStyleSet?.hasOwnProperty("_emphasis")){
                //新版选中配置方式
                let activeItemStyleSet = _.cloneDeep(navigationBarSet.normalItemStyleSet);
                if(activeItemStyleSet._emphasis?.hasOwnProperty("show")){
                    activeItemStyleSet.show = activeItemStyleSet._emphasis.show;
                }
                if(activeItemStyleSet._emphasis?.hasOwnProperty("color")){
                    activeItemStyleSet.fontSet.color = activeItemStyleSet._emphasis.color;
                }
                if(activeItemStyleSet._emphasis?.hasOwnProperty("fontSize")){
                    activeItemStyleSet.fontSet.fontSize = activeItemStyleSet._emphasis.fontSize;
                    activeItemStyleSet.fontSet.sizeUnit = activeItemStyleSet._emphasis.sizeUnit;
                }
                if(activeItemStyleSet._emphasis?.hasOwnProperty("fontBold")){
                    activeItemStyleSet.fontSet.fontBold = activeItemStyleSet._emphasis.fontBold;
                    activeItemStyleSet.fontSet.fontItalic = activeItemStyleSet._emphasis.fontItalic;
                }
                return activeItemStyleSet
            }else{
                //旧版，兼容
                return navigationBarSet?.activeItemStyleSet || navigationBarSet?.normalItemStyleSet
            }
        }
    }

    //背景样式
    getBgSetFinally(ifSel,navigationBarSet){
        if(!ifSel){
            return navigationBarSet.normalItemBgSet
        }else{
            if(navigationBarSet?.normalItemBgSet?.hasOwnProperty("_emphasis")){
                //新版选中配置方式
                let activeBGSet = _.cloneDeep(navigationBarSet.normalItemBgSet);
                if(activeBGSet._emphasis?.hasOwnProperty("use")){
                    activeBGSet.use = activeBGSet._emphasis.use;
                }
                if(activeBGSet._emphasis?.hasOwnProperty("radiusSet")){
                    activeBGSet.radiusSet = activeBGSet._emphasis.radiusSet;
                }
                if(activeBGSet._emphasis?.hasOwnProperty("opacity")){
                    activeBGSet.opacity = activeBGSet._emphasis.opacity;
                }
                if(activeBGSet._emphasis?.hasOwnProperty("brightness")){
                    activeBGSet.brightness = activeBGSet._emphasis.brightness;
                }
                if(activeBGSet._emphasis?.hasOwnProperty("modeSet")){
                    activeBGSet.modeSet = activeBGSet._emphasis.modeSet;
                }
                return activeBGSet
            }else{
                //旧版，兼容
                return navigationBarSet?.activeItemBgSet || navigationBarSet?.normalItemBgSet
            }
        }
    }

    getStylesCopyBtns(formName, title) {
        return <div
            className={styles.copyItemsBtnBox}
        >
            <Space>
                <Tooltip
                    title={"收集样式"}
                >
                    <Button
                        icon={<FormatPainterOutlined/>}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={(e) => {
                            LocalStorage.setWithoutId(DashboardItemStyleObjSaveKey, {
                                formName: formName,
                                title: title,
                                time: Date.now(),
                                styleObj: this.props.defaultConfig?.[formName]
                            })
                            message.success(`已收集到【${title}】样式`);
                        }}
                    >

                    </Button>
                </Tooltip>
                <Tooltip
                    title={"样式粘贴"}
                >
                    <Button
                        icon={<BgColorsOutlined/>}
                        type={"primary"}
                        size={"small"}
                        ghost={true}
                        onClick={() => {
                            let obj = LocalStorage.getWithoutId(DashboardItemStyleObjSaveKey);

                            if (!obj) {
                                message.warning("缓存中无收集到的样式");
                                return;
                            }

                            if (obj.formName !== formName) {
                                message.warning(`收集的样式【${obj.title}】 与 该栏目不匹配`);
                                return;
                            }


                            Modal.confirm({
                                title: "提示",
                                content: <div>
                                    <div>是否将【{title}】的样式配置替换成【收集】到的样式？</div>
                                    <div>收集时间：{TimeHelp.getYMDHMS(obj.time, true)}</div>
                                </div>,
                                onOk: () => {
                                    this.props.onChange(formName, obj.styleObj)
                                    setTimeout(() => {
                                        this.setState({
                                            formUpdate: this.state.formUpdate + 1
                                        })
                                    })

                                }
                            })
                        }}
                    >

                    </Button>
                </Tooltip>
            </Space>
        </div>
    }

    //导航栏样式
    getThumbsBoxStyleObj(themeConfig){
        let {navigationBarSet,navigationBarBgSet} = themeConfig;

        let resultObj;
        if (navigationBarSet.positionSet.layoutMode === "absolute") {
            resultObj = {
                position: "absolute",
                zIndex: 9,
                display: "flex",
                height: window.changeRemNum(navigationBarSet,"navHeight","navHeightUnit"),
                top: (typeof navigationBarSet.positionSet.absoluteSet.top === "number")
                    ? navigationBarSet.positionSet.absoluteSet.top + navigationBarSet.positionSet.absoluteSet.topUnit
                    : "unset",
                left: (typeof navigationBarSet.positionSet.absoluteSet.left === "number")
                    ? navigationBarSet.positionSet.absoluteSet.left + navigationBarSet.positionSet.absoluteSet.leftUnit
                    : "unset",
                right: (typeof navigationBarSet.positionSet.absoluteSet.right === "number")
                    ? navigationBarSet.positionSet.absoluteSet.right + navigationBarSet.positionSet.absoluteSet.rightUnit
                    : "unset",
                bottom: (typeof navigationBarSet.positionSet.absoluteSet.bottom === "number")
                    ? navigationBarSet.positionSet.absoluteSet.bottom + navigationBarSet.positionSet.absoluteSet.bottomUnit
                    : "unset",
                transform: `translate(${(typeof navigationBarSet.positionSet.absoluteSet.translateX === "number")
                    ? navigationBarSet.positionSet.absoluteSet.translateX + navigationBarSet.positionSet.absoluteSet.translateXUnit : "0px"},${(typeof navigationBarSet.positionSet.absoluteSet.translateY === "number")
                    ? navigationBarSet.positionSet.absoluteSet.translateY + navigationBarSet.positionSet.absoluteSet.translateYUnit : "0px"})`,
            }
        } else {
            resultObj = {
                height: window.changeRemNum(navigationBarSet,"navHeight","navHeightUnit"),
                position: "relative",
                display: "flex",
                order: (navigationBarSet.positionSet.flexSet.flexWhere === "right" || navigationBarSet.positionSet.flexSet.flexWhere === "bottom") ? 10 : 1,
                justifyContent: navigationBarSet.flexAlignItems,
                alignItems: "stretch"
            }
        }

        return resultObj;
    }

    //导航一层样式
    getThumbsBoxInnerStyleObj(themeConfig){
        let {navigationBarSet,navigationBarBgSet} = themeConfig;

        let resultObj;
        if (navigationBarSet.positionSet.layoutMode === "absolute") {
            resultObj = {
                flex: 1,
                width: "100%",
                height: "100%",
                borderRadius: window.changeRemNum(navigationBarBgSet.radiusSet),
                overflow: "hidden",
                paddingTop: window.changeRemNum(navigationBarSet.padding,"top","topUnit"),
                paddingBottom: window.changeRemNum(navigationBarSet.padding,"bottom","bottomUnit"),
                paddingLeft: window.changeRemNum(navigationBarSet.padding,"left","leftUnit"),
                paddingRight: window.changeRemNum(navigationBarSet.padding,"right","rightUnit"),
            }
        } else {
            resultObj = {
                display: "flex",
                position: "relative",
                width: navigationBarSet.navWidth?window.changeRemNum(navigationBarSet,"navWidth","navWidthUnit"):"100%",
                borderRadius: window.changeRemNum(navigationBarBgSet.radiusSet),
                overflow: "hidden",
                paddingTop: window.changeRemNum(navigationBarSet.padding,"top","topUnit"),
                paddingBottom: window.changeRemNum(navigationBarSet.padding,"bottom","bottomUnit"),
                paddingLeft: window.changeRemNum(navigationBarSet.padding,"left","leftUnit"),
                paddingRight: window.changeRemNum(navigationBarSet.padding,"right","rightUnit"),
            }
        }

        return resultObj;
    }
}