import React from 'react';
import styles from "./ShowProgress.less";
import CommonViewDiv from "../../../CommonViewDiv";

export default class ShowProgress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            progressMsg: null,
            show: false
        }
    }

    showValue(value){
        this.setState({
            progressMsg: value,
            show: true
        })
    }

    render(){
        log("this.props.contentMsg",this.props.contentMsg)

        let {show,progressMsg} = this.state;
        if(!show){
            return null
        }

        if(Object.prototype.toString.call(progressMsg) !== '[object Object]'){
            return "[传入数据格式错误]"
        }

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};

        if(!progressMsg.hasOwnProperty(contentParams.totalField) || !progressMsg.hasOwnProperty(contentParams.successField)){
            return "[总量或成功数字段未对接成功]"
        }

        let msgStr = "";
        let numCal = progressMsg[contentParams.totalField]?(progressMsg[contentParams.successField]*100/progressMsg[contentParams.totalField]).toFixed(contentParams.precision || 2):0;
        if(contentParams.afterType === "towNum"){
            //双数展示
            if(contentParams.bigNumber){
                msgStr = `${window.formatBigNumW(progressMsg[contentParams.successField])} / ${window.formatBigNumW(progressMsg[contentParams.totalField])}`;
            }else{
                msgStr = `${progressMsg[contentParams.successField]} / ${progressMsg[contentParams.totalField]}`;
            }
        }else{
            //百分比展示
            msgStr = `${numCal} %`;
        }

        return <div
            className={styles.outerBox}
            style={{
                flexDirection: contentParams.topAndBottomReverse?"column-reverse":"column",
            }}
        >
            {
                contentParams.topType === "none"
                    ?null
                    :<div
                        className={styles.headMsgBox}
                        style={{
                        }}
                    >
                        {
                            contentParams.topType === "text"
                                ?CommonViewDiv.getText(contentParams.topTextSet,contentParams.topTextSet?.text)
                                :null
                        }
                        {
                            contentParams.topType === "icon"
                                ?CommonViewDiv.getIcon(contentParams.topIconSet)
                                :null
                        }
                    </div>
            }
            <div
                className={styles.barBorderContainer}
                style={{
                    borderRadius: window.changeRemNum(contentParams.borderSet.radiusSet),
                    borderWidth: window.changeRemNum(contentParams.borderSet.borderWidthSet),
                    marginTop: window.changeRemNum(contentParams.borderSet.marginTop),
                    marginBottom: window.changeRemNum(contentParams.borderSet.marginBottom),
                    borderColor: contentParams.borderSet.borderColor,
                    height: window.changeRemNum(contentParams.borderSet.heightSet),

                }}
            >
                <div
                    className={styles.barContainer}
                    style={{
                        backgroundColor: contentParams.borderSet.backgroundColor +  window.getOpacityStr(typeof contentParams.borderSet?.backgroundOpacity === "number"?contentParams.borderSet.backgroundOpacity:100),
                    }}
                >
                    <div
                        className={styles.innerBar}
                        style={{
                            [contentParams.barSet.barPosition || "left"]: 0,
                            width: `${numCal}%`,
                            borderRadius: window.changeRemNum(contentParams.borderSet.radiusSet),
                            background: `linear-gradient(90deg, ${contentParams.barSet.successColorStart} 0%, ${contentParams.barSet.successColorEnd} 100%)`,
                            opacity: typeof contentParams.barSet?.backgroundOpacity === "number"?contentParams.barSet.backgroundOpacity/100:1,
                        }}
                    >
                    </div>
                </div>
            </div>
            <div
                className={styles.footerMsgBox}
                style={{
                    flexDirection: contentParams.leftAndRightReverse?"row-reverse":"row",
                    justifyContent: contentParams.beforeType === "none" || contentParams.afterType === "none"?"center":"space-between"
                }}
            >
                {
                    contentParams.beforeType === "none"
                        ?null
                        :<div>
                            {
                                contentParams.beforeType === "text"
                                    ?CommonViewDiv.getText(contentParams.beforeTextSet,contentParams.beforeTextSet?.text)
                                    :null
                            }
                            {
                                contentParams.beforeType === "icon"
                                    ?CommonViewDiv.getIcon(contentParams.beforeIconSet)
                                    :null
                            }
                        </div>
                }
                {
                    contentParams.afterType === "none"
                        ?null
                        :<div>
                            {CommonViewDiv.getText(contentParams.afterTextSet,msgStr)}
                        </div>
                }
            </div>
        </div>
    }
}