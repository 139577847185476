import React, {useEffect, useState, useRef} from 'react';
import {Button, Table, Card, Space, Switch, message, List, Spin, Input, Modal, Segmented} from 'antd';
import styles from './TemplateList.less';
import HttpTool from "../../../tool/HttpTool";
import ModalBase from "../../../components/modalBase/index";
import ModalConfig from '../../../components/modalConfig';
import FiltersIndexSet from '../../../components/indexSet/FiltersIndexSet';
import {SortAscendingOutlined, PlusOutlined, RedoOutlined, SearchOutlined,BarsOutlined,AppstoreOutlined} from '@ant-design/icons';
import moment from 'moment/moment';
import _ from "lodash";
import {UsageToCodeMap} from "./UsageDictionary";
import EmptyView from "../../../components/EmptyView/index";
import APILXD from '../../../http/APILXD';

const MB = new ModalBase();

const ossResizeImage = (url)=>{
  return url + `?x-oss-process=image/resize,p_70,q_80`;
}

export default function (props) {
  console.log("powerConfig",props.powerConfig)

  const originDataRef = useRef(null);
  const [type, setType] = useState(true); // 显示类型
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    HttpTool.post(
      APILXD.getDashboardModelList,
      (code, msg, json) => {
        console.log("json", json)

        let data = json.map((v) => {
          if (v.img) v.img = ossResizeImage(random(v.img));
          return v;
        })
        originDataRef.current = data;
        setData(data);
        setLoading(false);
      },
      (c, e) => {
        message.error(e)
        setLoading(false);
      },
      {
        businessType: UsageToCodeMap[props.usage],
      }
    );
  };


  useEffect(() => {
    if (originDataRef.current) {
      if (_.trim(filterName)) {
        console.log(originDataRef.current)

        let matchStr = _.trim(filterName).toLowerCase();
        let newData = originDataRef.current.filter((v) => {
          return v.title.toLowerCase().includes(matchStr)
        })

        setData(newData)
      } else {
        setData(originDataRef.current)
      }
    }

  }, [filterName])


  const onItemClick = (record) => {
    //跳转去设置页
    //由于模板的展示必须依赖上下文(挂载在具体空间、执行单元下)，无法直接查看。
    //只能刚进入编辑模式，各组件采用模拟数据

    openDashboardSet(record._id)
  };

  return (
    <Spin
      size={'large'}
      spinning={loading}
      wrapperClassName={styles.spinBoxStyle}
    >
      <div className={styles.layout}>
        <div className={styles.toolbar}>
          <Space>
            <div
              style={{fontSize: "16px"}}
            >
              {
                {
                  "company": "公司",
                  "space": "空间",
                  "unit": "执行单元",
                  "device": "数字人",
                }[props.usage]
              }
              模板</div>
            <Segmented
              options={[
                {
                  value: 'list',
                  icon: <BarsOutlined />,
                },
                {
                  value: 'card',
                  icon: <AppstoreOutlined />,
                },
              ]}
              value={type?"card":"list"}
              onChange={(value)=>{
                setType(value==="list"?false:true)
              }}
            />
          </Space>
          <Space>
            <Input
              prefix={<SearchOutlined/>}
              allowClear={true}
              placeholder={"输入名称过滤"}
              value={filterName}
              onChange={(e) => {
                setFilterName(e.target.value)
              }}
              style={{
                width: "250px",
              }}
            />
            {
              props.powerConfig.New
                ?<Button
                  size={"small"}
                  className={styles.addBtn}
                  width={80}
                  type={'primary'}
                  icon={<PlusOutlined/>}
                  style={{color: '#fff', backgroundColor: 'limegreen', borderColor: 'limegreen'}}
                  onClick={() => {
                    addOneDashboard(props.usage, () => {
                      loadData();
                    });
                  }}
                >
                  新建
                </Button>
                :null
            }
            {
              props.powerConfig.Edit
                ?<Button
                  size={"small"}
                  disabled={!data?.length}
                  className={styles.sortBtn}
                  width={80}
                  type={'primary'}
                  icon={<SortAscendingOutlined/>}
                  style={{color: '#fff'}}
                  onClick={() => {
                    sortDashboard(data, () => {
                      loadData();
                    });
                  }}
                >
                  排序
                </Button>
                :null
            }
            <Button
              size={"small"}
              className={styles.refreshBtn}
              icon={<RedoOutlined/>}
              type={'primary'}
              onClick={() => {
                setLoading(true);
                loadData();
              }}
            >刷新</Button>
          </Space>
        </div>
        {type ? (
          <CardLayout
            usage={props.usage}
            powerConfig={props.powerConfig}
            data={data}
            onItemClick={onItemClick}
            onDelete={(dbid, name) => {
              deleteTemplate(dbid, name, () => {
                message.success("删除成功");
                loadData();
              })
            }}
            onCopy={(dbid, name) => {
              copyDashboard(dbid, name, props.usage, () => {
                message.success("克隆成功");
                loadData();
              })
            }}
          />
        ) : (
          <ListLayout
            usage={props.usage}
            powerConfig={props.powerConfig}
            data={data}
            onItemClick={onItemClick}
            onDelete={(dbid, name) => {
              deleteTemplate(dbid, name, () => {
                message.success("删除成功");
                loadData();
              })
            }}
            onCopy={(dbid, name) => {
              copyDashboard(dbid, name, props.usage, () => {
                message.success("克隆成功");
                loadData();
              })
            }}
          />
        )}
      </div>
    </Spin>
  );
}

/**
 * 卡片模式
 * @param { object } props
 * @returns
 */
function CardLayout(props) {
  const {data, onItemClick, onDelete,onCopy} = props;

  return (
    <div
      className={styles.cardLayout}
    >
      {
        data?.length
          ?<List
            grid={{gutter: 16, column: 3}}
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <Card
                  title={<span style={{fontSize: 16}}>{item.title}</span>}
                  style={{
                    backgroundColor: '#2E3244',
                    marginBottom: 16,
                    border: '1px solid rgb(62 62 62)',
                  }}
                  key={index}
                  cover={
                    <img
                      className={styles.img}
                      alt="封面"
                      loading="lazy"
                      src={item.img ? item.img : require("../../../images/dashboard/defaultCover.png")}
                      onClick={() => {
                        onItemClick(item);
                      }}
                      style={{
                        backgroundColor: '#2E3244',
                        objectFit: 'cover',
                      }}
                    />
                  }
                  bordered={false}
                  hoverable={true}
                  extra={
                    <div>
                      <Space size={0}>
                        {
                          props.powerConfig.Edit
                            ?<Button
                              type={"text"}
                              onClick={() => {
                                openDashboardSet(item?._id, item?.title);
                              }}
                            >
                              编辑
                            </Button>
                            :null
                        }
                        {
                          props.powerConfig.Edit
                            ? <Button
                              type={"text"}
                              onClick={() => {
                                onCopy(item?._id, item?.title);
                              }}
                            >
                              克隆
                            </Button>
                            :null
                        }
                        {
                          props.powerConfig.Delete
                            ? <Button
                              danger={true}
                              type={"text"}
                              onClick={() => {
                                onDelete(item._id, item.title);
                              }}
                            >
                              删除
                            </Button>
                            :null
                        }
                      </Space>
                    </div>
                  }
                  bodyStyle={{
                    padding: 0,
                  }}
                />
              </List.Item>
            )}
          />
          :<EmptyView
            title={"暂无数据"}
            subTitle={"您可以新建模板"}
          />
      }

    </div>
  );
}

/**
 * 列表模式
 * @param {*} props
 * @returns
 */
function ListLayout(props) {
  const {data, onItemClick, onDelete,onCopy} = props;

  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => {
        return (
          <a
            onClick={() => {
              onItemClick(record);
            }}
          >
            {record.title}
          </a>
        );
      },
    },
    {
      title: '封面',
      dataIndex: 'img',
      key: 'img',
      render: (_, record) =>
        <img
          height={80}
          width={160}
          loading="lazy"
          alt="封面"
          style={{
            backgroundColor: '#2E3244',
            objectFit: 'cover',
          }}
          src={record.img?record.img:require("../../../images/dashboard/defaultCover.png")}
        />
    },
    {
      title: '创建时间',
      dataIndex: 'addTime',
      key: 'addTime',
      render: (_, record) => (record?.addTime ? moment(record.addTime).format('YYYY-MM-DD hh:mm:ss') : '-'),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      render: (_, record) => (record?.updateTime ? moment(record.updateTime).format('YYYY-MM-DD hh:mm:ss') : '-'),
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Space size={0}>
          {
            props.powerConfig.Edit
              ?<Button
                type={"link"}
                onClick={() => {
                  openDashboardSet(record?._id, record?.title);
                }}
              >
                编辑
              </Button>
              :null
          }
          {
            props.powerConfig.Edit
              ?<Button
                type={"link"}
                onClick={() => {
                  onCopy(record?._id, record?.title);
                }}
              >
                克隆
              </Button>
              :null
          }
          {
            props.powerConfig.Delete
              ?<Button
                danger={true}
                type={"link"}
                onClick={() => {
                  onDelete(record._id, record.title);
                }}
              >
                删除
              </Button>
              :null
          }
        </Space>
      ),
    },
  ];

  return <div
    className={styles.cardLayout}
  >
    <Table dataSource={data} columns={columns} pagination={false}/>
  </div>
}

//修改排序
function sortDashboard(list, cb) {
  let filtersIndexSet = null;

  ModalConfig.show(
    {
      title: '拖动以下栏目修改排序',
      maskClosable: true,
      width: '800px',
      okText: '确认排序',
      cancelText: '放弃排序',
      onOk: () => {
        let newIndexArr = filtersIndexSet.getIndexArr();
        console.log('新的排序');
        console.log(newIndexArr);
        HttpTool.post(
          APILXD.setDashboardModelSort,
          (code, msg) => {
            message.success(msg);
            cb && cb();
            ModalConfig.close();
          },
          (code, msg) => {
            message.error(msg);
          },
          {
            list: newIndexArr.map((item, index) => {
              return {
                _id: item._id,
                sort: index +1,
              };
            }),
          },
        );
      },
    },
    <div style={{maxHeight: '75vh', overflow: 'auto'}}>
      <FiltersIndexSet indexArr={list} ref={(ref) => (filtersIndexSet = ref)}/>
    </div>,
  );
}

/**
 * 新增一个仪表板
 * @param { object} props
 */
function addOneDashboard(usage, cb) {

  MB.show(
    {
      title: `新增数据展示模板`,
      okTitle: '保存',
      closeTitle: '取消',
      width: 600,
    },
    {
      colCount: 1,
      formItemLayout: {
        labelCol: {span: 5},
        wrapperCol: {span: 16, offset: 0},
      },
      parameterArr: [
        {
          ver: true,
          name: '仪表板模板名称',
          field: 'title',
          reg: (txt) => {
            return !!txt;
          },
          verMessage: '请填写仪表板模板名称',
          type: 'input',
          required: true,
          option: {
            // defaultValue: data?.title,
          },
        },
      ],
    },
    {
      otherParam: {
        businessType: UsageToCodeMap[usage],
      },
      url: APILXD.addDashBoardModel,
      beforeSubmit: (param) => {

        return true;
      },
      callBack: (state, msg, returnData, param) => {
        //添加成功回调

        if (state === 'success') {
          cb?.();
        }
      },
    },
  );
}


/**
 * 编辑
 * @param {*} dbid
 * @param {*} name
 */
function openDashboardSet(dbid, name) {
  window.openWin(
    'dashboardSet',
    {
      type: 2, //1仪表板  2模板
      id: dbid,
    },
    {
      title: '模板内容配置' + dbid,
    },
  );
}

/**
 * 防止图片缓存
 * @param {*} url
 * @returns
 */
function random(url) {
  return url + `?t=` + Date.now();
}

//删除模板
function deleteTemplate(dbid, name, cb) {
  Modal.confirm({
    title: "提示",
    content: "是否删除模板：" + name,
    onOk: () => {
      HttpTool.post(APILXD.delDashboardModel,  (code, msg) => {
        cb?.()
      }, (code, msg) => {
        message.error(msg)
      },{
        _id: dbid
      })
    }
  })
}

//克隆模板
function copyDashboard(dbid, name, usage, cb) {
  Modal.confirm({
    title: "提示",
    content: "是否克隆模板：" + name,
    onOk: () => {
      HttpTool.post(APILXD.copyDashboard, (code, msg) => {
        cb?.()
      }, (code, msg) => {
        message.error(msg)
      }, {
        _id: dbid,
        businessType: UsageToCodeMap[usage],
      })
    }
  })
}