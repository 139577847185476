import React from "react";
import {
    Collapse,
    Divider,
    Form,
    Input
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(extendData= {}){
    return <>
        <Divider orientation="left">
            字段值设置
        </Divider>
        <Item
            label={"提取字段"}
            name={["field"]}
        >
            <Input
                maxLength={50}
            />
        </Item>
        {CommonFormItems.getFontSet(["fieldTextSet"],true)}
        <Divider orientation="left">
            前缀文案设置
        </Divider>
        <Item
            label={"文案"}
            name={["beforeTextSet","text"]}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        {CommonFormItems.getFontSet(["beforeTextSet"],true)}
    </>
}

function getDefaultValues(){
    return {
        field: null,
        fieldTextSet: {
            fontFamily: "default",
            text: null,
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
        beforeTextSet: {
            fontFamily: "default",
            text: null,
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
    }
}

export default {
    getSetItems,
    getDefaultValues,
}