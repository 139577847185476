import React, {Component} from 'react';
import {Input,Select,Row,Col} from "antd";

class RuleSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:{
        type: 2,
        value: "",
      },
    }
  }

  _initDefaultValue(data){
    this.setState({
      data:data.option.defaultValue
    });
    return data.option.defaultValue||undefined;
  }

  render(){
    let otherOption = Object.assign({},this.props.option);
    delete otherOption.defaultValue;
    return (
      <Row>
        <Col span={8}>
          <Select
            style={{width: "100%"}}
            value={this.state.data.type}
            onChange={(value)=>{
              // log(e);
              let data = this.state.data;
              data.type = value
              this.setState({
                data:data
              },()=>{
                this.props.verification(this.props.data,this.state.data)
              })
            }}
          >
            <Select.Option value={1}>
              等于
            </Select.Option>
            <Select.Option value={2}>
              以此开头
            </Select.Option>
            <Select.Option value={3}>
              包含
            </Select.Option>
          </Select>
        </Col>
        <Col span={16}>
          <Input
            style={{width: "100%"}}
            value={this.state.data.value}
            {...otherOption}
            onChange={(e)=>{
              let data = this.state.data;
              data.value = e.target.value
              this.setState({
                data:data
              },()=>{
                this.props.verification(this.props.data,this.state.data)
              })
            }}
          />
        </Col>
      </Row>
    );
  }
}


export default RuleSet;