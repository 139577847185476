const UsageNameMap = {
  "company": "公司",
  "space": "空间",
  "unit": "执行单元",
  "device": "数字人"
}

const UsageToCodeMap = {
  "company": 1,
  "space": 2,
  "unit": 3,
  "device": 4
}

const CodeToUsageMap = {
  1:"company",
  2:"space",
  3:"unit",
  4:"device"
}

export {UsageNameMap,UsageToCodeMap,CodeToUsageMap}