import React from "react";
import {Slider} from "antd";

export default class SliderShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let marks = {};
        if(contentParams.minLabel && typeof contentParams.sliderOption.min === "number"){
            marks[contentParams.sliderOption.min] = contentParams.minLabel;
        }
        if(contentParams.maxLabel && typeof contentParams.sliderOption.max === "number"){
            marks[contentParams.sliderOption.max] = contentParams.maxLabel;
        }

        return <div
            style={contentParams.sliderOption?.vertical === true?{
                height: "100%"
            }:{
                width: "100%",
            }}
            className={"swiper-no-swiping"}
        >
            <Slider
                allowClear={true}
                marks={marks}
                tipFormatter={(v)=>{
                    return (contentParams.tooltipLabel || "")+v;
                }}
                {...contentParams.sliderOption}
                defaultValue={parameterValue}
                onAfterChange={(v)=>{
                    this.props.valueChanged?.(v,parameterValue)
                }}
            />
        </div>
    }
}