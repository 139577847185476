import React from "react";
import styles from "./DashboardConfigSet.less";
import {Form, Button, message, Tabs, Drawer, Tooltip, Space,Modal} from "antd";
import BaseSetItem from "./BaseSetItem";
import {FormatPainterOutlined,BgColorsOutlined} from "@ant-design/icons";
import TimeHelp from '../../../../tool/TimeHelp';
import LocalStorage from '../../../../tool/LocalStorage';

const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
    size: "small"
};

const DashboardItemStyleObjSaveKey = "DashboardItemStyleObjSaveKey";

export default class DashboardConfigSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSelTabKey: "dashboardBackgroundSet",
            formUpdate: 0,
        }

        this.BaseSetItem = new BaseSetItem();
    }

    //延迟触发，避免太频繁
    delayChangeAction(action){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            action?.();
        },300)
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    render() {
        let {currentSelTabKey,formUpdate} = this.state;
        return <div className={styles.dashboardSetBox}>
            <div className={styles.dashboardSetHead}>
                <div>仪表板主题设置</div>
                <Button
                    type={"link"}
                    onClick={() => {
                        this.props.onClose?.()
                    }}
                >
                    <span style={{color: "red"}}>收起</span>
                </Button>
            </div>
            <div
                className={styles.dashboardSetBody}
            >
                <Form.Provider
                    key={"fp_" + formUpdate}
                    onFormChange={(formName, info) => {
                        this.delayChangeAction(()=>{
                            console.log(formName)
                            console.log(info)
                            let formAllValue = info.forms[formName].getFieldsValue();
                            this.props.onChange(formName,formAllValue)
                        })
                    }}
                >
                    <Tabs
                        activeKey={currentSelTabKey}
                        tabPosition={"left"}
                        type={"card"}
                        onChange={(activeKey) => {
                            this.setState({
                                currentSelTabKey: activeKey
                            })
                        }}
                    >
                        <Tabs.TabPane
                            tab={"仪表板背景"}
                            key={"dashboardBackgroundSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("dashboardBackgroundSet","仪表板背景")}
                                <Form
                                    name={"dashboardBackgroundSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.dashboardBackgroundSet}
                                >
                                    {this.BaseSetItem.getBackgroundSet("dashboardBackgroundSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"场景切换"}
                            key={"sceneSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("sceneSet","场景切换")}
                                <Form
                                    name={"sceneSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.sceneSet}
                                >
                                    {this.BaseSetItem.getSceneSwiperOptionSet("sceneSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"场景背景"}
                            key={"sceneBackgroundSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("sceneBackgroundSet","场景背景")}
                                <Form
                                    name={"sceneBackgroundSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.sceneBackgroundSet}
                                >
                                    {this.BaseSetItem.getBackgroundSet("sceneBackgroundSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器配置"}
                            key={"commonSet"}
                            // forceRender={true}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("commonSet","容器配置")}
                                <Form
                                    name={"commonSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.commonSet}
                                >
                                    {this.BaseSetItem.getCommonSetForm("commonSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器背景"}
                            key={"contentBackgroundSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentBackgroundSet","容器背景")}
                                <Form
                                    name={"contentBackgroundSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.contentBackgroundSet}
                                >
                                    {this.BaseSetItem.getBackgroundSet("contentBackgroundSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"容器标题"}
                            key={"contentTitle"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentTitle","容器标题")}
                                <Form
                                    name={"contentTitle"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.contentTitle}
                                >
                                    {this.BaseSetItem.getContentTitle("contentTitle")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"标题背景"}
                            key={"titlePlus"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("titlePlus","标题背景")}
                                <Form
                                    name={"titlePlus"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.titlePlus}
                                >
                                    {this.BaseSetItem.getTitlePlusSet("titlePlus")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={"内容样式"}
                            key={"contentSet"}
                        >
                            <div className={styles.tabPaneRightBox}>
                                {this.getStylesCopyBtns("contentSet","内容样式")}
                                <Form
                                    name={"contentSet"}
                                    {...formItemLayout}
                                    requiredMark={false}
                                    initialValues={this.props.defaultConfig?.contentSet}
                                >
                                    {this.BaseSetItem.getContentSet("contentSet")}
                                </Form>
                            </div>
                        </Tabs.TabPane>
                        {/*<Tabs.TabPane*/}
                        {/*    tab={"内容字体"}*/}
                        {/*    key={"contentFont"}*/}
                        {/*>*/}
                        {/*    <div className={styles.tabPaneRightBox}>*/}
                        {/*        {this.getStylesCopyBtns("contentFont","内容字体")}*/}
                        {/*        <Form*/}
                        {/*            name={"contentFont"}*/}
                        {/*            {...formItemLayout}*/}
                        {/*            requiredMark={false}*/}
                        {/*            initialValues={this.props.defaultConfig?.contentFont}*/}
                        {/*        >*/}
                        {/*            {this.BaseSetItem.getContentFontSet("contentFont")}*/}
                        {/*        </Form>*/}
                        {/*    </div>*/}
                        {/*</Tabs.TabPane>*/}
                    </Tabs>
                </Form.Provider>
            </div>
        </div>
    }

    getStylesCopyBtns(formName,title){
        return <div
            className={styles.copyItemsBtnBox}
        >
            <Space>
                <Tooltip
                    title={"收集样式"}
                >
                    <Button
                        icon={<FormatPainterOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={(e)=>{
                            LocalStorage.setWithoutId(DashboardItemStyleObjSaveKey,{
                                formName: formName,
                                title: title,
                                time: Date.now(),
                                styleObj: this.props.defaultConfig?.[formName]
                            })
                            message.success(`已收集到【${title}】样式`);
                        }}
                    >

                    </Button>
                </Tooltip>
                <Tooltip
                    title={"样式粘贴"}
                >
                    <Button
                        icon={<BgColorsOutlined />}
                        type={"primary"}
                        size={"small"}
                        ghost={true}
                        onClick={()=>{
                            let obj = LocalStorage.getWithoutId(DashboardItemStyleObjSaveKey);

                            if(!obj){
                                message.warning("缓存中无收集到的样式");
                                return;
                            }

                            if(obj.formName !== formName){
                                message.warning(`收集的样式【${obj.title}】 与 该栏目不匹配`);
                                return;
                            }


                            Modal.confirm({
                                title: "提示",
                                content: <div>
                                    <div>是否将【{title}】的样式配置替换成【收集】到的样式？</div>
                                    <div>收集时间：{TimeHelp.getYMDHMS(obj.time,true)}</div>
                                </div>,
                                onOk: ()=>{
                                    this.props.onChange(formName,obj.styleObj)
                                    setTimeout(()=>{
                                        this.setState({
                                            formUpdate: this.state.formUpdate + 1
                                        })
                                    })

                                }
                            })
                        }}
                    >

                    </Button>
                </Tooltip>
            </Space>
        </div>
    }
}