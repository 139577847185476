import React from "react";
import {Checkbox} from "antd";

export default class StringArrayCheckboxShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let options = [];
        if(contentParams.list?.length){
            options = options.concat(contentParams.list.map((str)=>{
                return {
                    label: str,
                    value: str
                }
            }))
        }
        if(contentParams.listSpecial?.length){
            let vs = contentParams.listSpecial.filter((item)=>{
                return !!item.label && !!item.value
            })
            options = options.concat(vs)
        }


        return <div
            style={{width: "100%",
                display: "flex",
                alignItems: "center",
            }}
        >
            {
                contentParams.label
                    ?<span>{contentParams.label}</span>
                    :null
            }
            <div
                style={{flex: 1}}
            >
                <Checkbox.Group
                    {...contentParams.selectOption}
                    defaultValue={parameterValue}
                    options={options}
                    onChange={(checkedValue)=>{
                        this.props.valueChanged?.(checkedValue,parameterValue)
                    }}
                >

                </Checkbox.Group>
            </div>
        </div>
    }
}