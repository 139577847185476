import React from "react";
import {
    Form,
    Input,
} from "antd";
import NumberSelectOptionMake from "../showNumberSelect/NumberSelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"标签"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"映射选项"}
            name={["listSpecial"]}
        >
            <NumberSelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: "请选择：",
        list: [],
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}