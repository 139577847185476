import React,{useRef} from "react"
import ProTable,{ProColumns} from "@ant-design/pro-table"
import {Tag,Flex,Button,message,InputNumber,Switch,Popconfirm,Space} from "antd";
import TimeHelp from "../../../tool/TimeHelp";
import HttpTool from "../../../tool/HttpTool";
import APILXD from "../../../http/APILXD";

export default function (props){
  const tableRef = useRef()

  const changeSort=(record,sort)=>{
    HttpTool.post(APILXD.setThemeSort,(code,msg,json,option)=>{
      tableRef.current?.reload()
    },(code,msg)=>{
      message.error(msg);
    },{
      _id: record._id,
      sort: sort,
    })
  }

  const deleteTheme=(record)=>{
    HttpTool.post(APILXD.delTheme,(code,msg,json,option)=>{
      tableRef.current?.reload()
    },(code,msg)=>{
      message.error(msg);
    },{
      _id: record._id,
    })
  }

  const editTheme=(record)=>{
    HttpTool.post(APILXD.editTheme,(code,msg,json,option)=>{
      tableRef.current?.reload()
    },(code,msg)=>{
      message.error(msg);
    },{
      _id: record._id,
      title: record.title,
      data: props.themeConfig,
      files: [],
    })
  }

  const exportTheme=(record)=>{
    HttpTool.post(APILXD.getThemeDetail,(code,msg,json,option)=>{
      window.downFileByText(JSON.stringify(res.data.data), record.title + "_容器样式配置.json")
    },(code,msg)=>{
      message.error(msg);
    },{
      _id: record._id,
    })
  }

  const columns = [
    {
      title: '主题名称',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_, record) => {
        return <Switch
          checked={record.status===1}
          checkedChildren={"启用"}
          unCheckedChildren={"禁用"}
          onChange={(checked)=>{
            // request("/platform/platform/chartTheme/setChartThemeStatus",{
            //   data: {
            //     _id: record._id,
            //     status: checked?1:2
            //   }
            // }).then((res)=>{
            //   if(res.code===200){
            //     message.success(res.message);
            //     tableRef.current?.reload()
            //   }else{
            //     message.error(res.message);
            //   }
            // }).catch((err)=>{
            //   message.error(err.toString());
            // })
          }}
        />
      },
    },
    {
      title: '排序编号',
      dataIndex: 'sort',
      key: 'sort',
      align: 'center',
      render: (_, record) => {
        return <InputNumber
          defaultValue={record.sort}
          controls={false}
          max={99999}
          min={1}
          precision={0}
          onBlur={(e)=>{
            let v = Number(e.target.value);
            if(v !== record.sort){
              changeSort(record,v)
            }
          }}
          onPressEnter={(e)=>{
            let v = Number(e.target.value);
            if(v !== record.sort){
              changeSort(record,v)
            }
          }}
        />
      },
    },
    {
      title: '创建/更新时间',
      dataIndex: 'updatetime',
      key: 'updatetime',
      align: 'center',
      render: (_, record) => {
        return  <div>{record.updatetime ? TimeHelp.getYMDHM(record.updatetime) : "-"}</div>
      },
    },
    {
      title: '更新者',
      dataIndex: 'updater',
      key: 'updater',
      align: 'center',
      render: (text, record, index) => {
        return  text?text.nickname:"-"
      }
    },
    {
      title: '操作',
      key: 'option',
      fixed: 'right',
      width: 250,
      align: 'center',
      render: (text, record, _, action) => {
        return <Space wrap>
          <Popconfirm
            key={'delete'}
            icon={null}
            title={`更新操作将自动提取当前仪表板的主题配置，覆盖需要更新的主题，是否继续？`}
            description={"更新主题不会影响之前使用该主题的仪表板。"}
            okText="继续"
            cancelText="取消"
            onConfirm={() => {
              editTheme(record)
            }}
          >
            <Button
              size={"small"}
              type={"link"}
            >更新</Button>
          </Popconfirm>
          <Popconfirm
            key={'delete'}
            icon={null}
            title={`是否将该主题样式配置文件导出？`}
            okText="导出"
            cancelText="取消"
            onConfirm={() => {
              exportTheme(record)
            }}
          >
            <Button
              size={"small"}
              type={"link"}
            >导出</Button>
          </Popconfirm>
          <Popconfirm
            key={'delete'}
            icon={null}
            title={`确定要删除主题 ${record.title} 吗?`}
            description={"删除主题不会影响之前使用该主题的仪表板。"}
            okText="确认删除"
            cancelText="取消"
            onConfirm={() => {
              deleteTheme(record)
            }}
          >
            <Button
              size={"small"}
              type={"link"}
              danger
            >删除</Button>
          </Popconfirm>
        </Space>
      }
    }
  ]

  return <ProTable
    rowKey={'_id'}
    search={false}
    actionRef={tableRef}
    columns={columns}
    cardBordered
    request={async (params, sort, filter) => {
      return new Promise((resovle, reject) => {
        HttpTool.post(APILXD.getThemeList,(code,msg,json,option)=>{
          resovle({
            data: json,
            success: true,
            total: option,
          })
        },(code,msg)=>{
          message.error(msg)
          reject([])
        },{
          ...params, page: params.current
        })
      })
    }}
    pagination={{ pageSize: 10 }}
  />
}