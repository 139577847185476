import React from "react";
import styles from "./DashboardConfigSet.less";
import {Form, message, Collapse,Button,Modal,Space,Tooltip} from "antd";
import BaseSetItem from "./BaseSetItem";
import {ClearOutlined,FormatPainterOutlined,UploadOutlined,DownloadOutlined,BgColorsOutlined} from "@ant-design/icons";
import TimeHelp from '../../../../tool/TimeHelp';
import LocalStorage from '../../../../tool/LocalStorage';
import _ from "lodash";

const DashboardItemStyleObjSaveKey = "DashboardItemStyleObjSaveKey";
const ContentStyleConfigSaveKey = "ContentStyleConfigSaveKey";

const NeedDealKeys =  ["commonSet","contentBackgroundSet","contentSet","contentTitle","titlePlus","contentFont"];

const formItemLayout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 17,
    },
    size: "small"
};

export default class ContentStyleConfigSet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSelTabKey: "commonSet",

            formUpdate: 0
        }

        //自己已经配置过的
        this.selfConfig = _.cloneDeep(this.props.selfConfig) || {};
        //父集的配置
        this.grandpaConfig = _.cloneDeep(this.props.grandpaConfig) || {};
        this.parentConfig = _.cloneDeep(this.props.parentConfig) || {};

        this.BaseSetItem = new BaseSetItem({
            selfConfig: this.selfConfig,
            resetField: (formName,resetKey)=>{
                delete this.selfConfig[formName][resetKey];
                //重新合并
                //这里this.parentConfig已经处理过了，不再重复处理
                this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);

                this.setState({
                    formUpdate: this.state.formUpdate + 1
                },()=>{
                    this.props.onResetField?.(formName,resetKey)
                })
            }
        })
        //先处理父级的合并
        this.parentConfig = this.BaseSetItem.mergeFinallyConfig(this.parentConfig,this.grandpaConfig,NeedDealKeys);
        //再处理自己的
        this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);
    }

    //延迟触发，避免太频繁
    delayChangeAction(action){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            action?.();
        },300)
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    render() {
        let {currentSelTabKey,formUpdate} = this.state;
        return <div className={styles.dashboardSetBox}>
            <div className={styles.dashboardSetHead}>
                <Space>
                    <Tooltip
                        title={"收集 容器 整体的样式"}
                    >
                        <Button
                            icon={<FormatPainterOutlined />}
                            type={"primary"}
                            ghost={true}
                            onClick={(e)=>{
                                LocalStorage.setWithoutId(ContentStyleConfigSaveKey,this.selfConfig)
                                message.success("已【收集】到容器整体样式");
                            }}
                        >

                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={"粘贴 容器 整体的样式"}
                    >
                        <Button
                            icon={<BgColorsOutlined />}
                            type={"primary"}
                            ghost={true}
                            onClick={()=>{
                                Modal.confirm({
                                    title: "提示",
                                    content: "是否将该容器的样式配置替换成【收集】到的样式？",
                                    onOk: ()=>{
                                        let copyStyleObj = LocalStorage.getWithoutId(ContentStyleConfigSaveKey)
                                        if(!copyStyleObj){
                                            message.warning("没有可用于粘贴的样式数据");
                                            return;
                                        }
                                        this.changeAllConfig(copyStyleObj)
                                    }
                                })
                            }}
                        >

                        </Button>
                    </Tooltip>
                </Space>
                <Space>
                    <Tooltip
                        title={"样式导出"}
                    >
                        <Button
                            icon={<UploadOutlined/>}
                            type={"primary"}
                            ghost={true}
                            onClick={()=>{
                                window.downFileByText(JSON.stringify(this.selfConfig),"容器样式配置.json")
                            }}
                        >

                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={"样式导入"}
                    >
                        <Button
                            icon={<DownloadOutlined/>}
                            type={"primary"}
                            ghost={true}
                            style={{
                                position: "relative"
                            }}
                        >
                            <input className={styles.file_uploader} type='file' accept='.json'
                                   onChange={(file) => {
                                       let callBack = (data, error) => {
                                           if (error) {
                                               message.error("error:" + error);
                                               return;
                                           }
                                           try {
                                               let copyStyle = JSON.parse(data);
                                               if(Object.prototype.toString.call(copyStyle) !== '[object Object]'){
                                                   message.warning("样式结构不正确");
                                                   return;
                                               }
                                               //只取有效字段
                                               let newObj = {};
                                               NeedDealKeys.forEach((str)=>{
                                                   if(copyStyle[str]){
                                                       newObj[str] = copyStyle[str];
                                                   }
                                               })

                                               message.success("样式导入成功");

                                               this.changeAllConfig(newObj)

                                           } catch (e) {
                                               message.error("导入样式失败" + e.toString())
                                           }

                                       }
                                       //读取内容
                                       window.uploadText(file, callBack);
                                   }}/>
                        </Button>
                    </Tooltip>
                </Space>
                <Space>
                    <Tooltip
                        title={"样式重置"}
                    >
                        <Button
                            icon={<ClearOutlined />}
                            type={"danger"}
                            style={{
                                color: "#fff",
                                backgroundColor: "darkorange",
                                borderColor: "darkorange"
                            }}
                            onClick={()=>{
                                Modal.confirm({
                                    title: "提示",
                                    content: "是否将该容器所有已经设置过的样式还原到默认值？",
                                    onOk: ()=>{
                                        this.changeAllConfig({})
                                    }
                                })
                            }}
                        ></Button>
                    </Tooltip>
                </Space>
            </div>
            <div
                className={styles.dashboardSetBody}
            >
                <Form.Provider
                    onFormChange={(formName, info) => {

                        this.delayChangeAction(()=>{
                            console.log(formName)
                            console.log(info)

                            let changedDataNames = info.changedFields?.[0]?.name;
                            let changedKey = changedDataNames[0];

                            let changedValue;
                            if(changedDataNames.length === 1){
                                //单键修改，单键值替换
                                changedValue = info.changedFields?.[0].value
                            }else{
                                //组合修改，把组合的值都取出来，全替换
                                let formAllValue = info.forms[formName].getFieldsValue();
                                changedValue = formAllValue[changedKey];
                            }

                            //修改组件内部的，让 label 重新判断
                            _.set(this.selfConfig,[formName,changedKey],changedValue);
                            this.BaseSetItem.upDateSelfConfig(this.selfConfig);

                            //通知外部改变
                            this.props.onChange(formName,changedKey,changedValue);
                        })
                    }}
                >
                    <Collapse
                        accordion={true}
                        activeKey={currentSelTabKey}
                        onChange={(activeKey)=>{
                            this.setState({
                                currentSelTabKey: activeKey
                            })
                        }}
                    >
                        <Collapse.Panel
                            header={"容器配置"}
                            key={"commonSet"}
                        >
                            {this.getStylesCopyBtns("commonSet","容器配置")}
                            <Form
                                key={"commonSet_" + formUpdate}
                                name={"commonSet"}
                                {...formItemLayout}
                                requiredMark={false}
                                initialValues={this.formDefaultValues.commonSet}
                            >
                                {this.BaseSetItem.getCommonSetForm("commonSet")}
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={"容器背景"}
                            key={"contentBackgroundSet"}
                        >
                            {this.getStylesCopyBtns("contentBackgroundSet","容器背景")}
                            <Form
                                key={"contentBackgroundSet_" + formUpdate}
                                name={"contentBackgroundSet"}
                                {...formItemLayout}
                                requiredMark={false}
                                initialValues={this.formDefaultValues.contentBackgroundSet}
                            >
                                {this.BaseSetItem.getBackgroundSet("contentBackgroundSet")}
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={"容器标题"}
                            key={"contentTitle"}
                        >
                            {this.getStylesCopyBtns("contentTitle","容器标题")}
                            <Form
                                key={"contentTitle_" + formUpdate}
                                name={"contentTitle"}
                                {...formItemLayout}
                                requiredMark={false}
                                initialValues={this.formDefaultValues.contentTitle}
                            >
                                {this.BaseSetItem.getContentTitle("contentTitle")}
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={"标题背景"}
                            key={"titlePlus"}
                        >
                            {this.getStylesCopyBtns("titlePlus","标题背景")}
                            <Form
                                key={"titlePlus_" + formUpdate}
                                name={"titlePlus"}
                                {...formItemLayout}
                                requiredMark={false}
                                initialValues={this.formDefaultValues.titlePlus}
                            >
                                {this.BaseSetItem.getTitlePlusSet("titlePlus")}
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel
                            header={"内容样式"}
                            key={"contentSet"}
                        >
                            {this.getStylesCopyBtns("contentSet","内容样式")}
                            <Form
                                key={"contentSet_" + formUpdate}
                                name={"contentSet"}
                                {...formItemLayout}
                                requiredMark={false}
                                initialValues={this.formDefaultValues.contentSet}
                            >
                                {this.BaseSetItem.getContentSet("contentSet")}
                            </Form>
                        </Collapse.Panel>
                        {/*<Collapse.Panel*/}
                        {/*    header={"内容字体"}*/}
                        {/*    key={"contentFont"}*/}
                        {/*>*/}
                        {/*    {this.getStylesCopyBtns("contentFont","内容字体")}*/}
                        {/*    <Form*/}
                        {/*        key={"contentFont_" + formUpdate}*/}
                        {/*        name={"contentFont"}*/}
                        {/*        {...formItemLayout}*/}
                        {/*        requiredMark={false}*/}
                        {/*        initialValues={this.formDefaultValues.contentFont}*/}
                        {/*    >*/}
                        {/*        {this.BaseSetItem.getContentFontSet("contentFont")}*/}
                        {/*    </Form>*/}
                        {/*</Collapse.Panel>*/}
                    </Collapse>
                </Form.Provider>
            </div>
        </div>
    }

    //修改整个配置项
    changeAllConfig(newConfig){

        //修改组件内部的，让 label 重新判断
        this.selfConfig = newConfig;
        this.BaseSetItem.upDateSelfConfig(this.selfConfig);

        this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);

        this.setState({
            formUpdate: this.state.formUpdate + 1
        })
        //通知外部改变
        this.props.onChangeAll?.(newConfig)
    }

    getStylesCopyBtns(formName,title){
        return <div
            className={styles.copyItemsBtnBox}
        >
            <Space>
                <Tooltip
                    title={"收集样式"}
                >
                    <Button
                        icon={<FormatPainterOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={(e)=>{
                            LocalStorage.setWithoutId(DashboardItemStyleObjSaveKey,{
                                formName: formName,
                                title: title,
                                time: Date.now(),
                                styleObj: this.selfConfig[formName]
                            })
                            message.success(`已收集到【${title}】样式`);
                        }}
                    >

                    </Button>
                </Tooltip>
                <Tooltip
                    title={"样式粘贴"}
                >
                    <Button
                        icon={<BgColorsOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={()=>{
                            let obj = LocalStorage.getWithoutId(DashboardItemStyleObjSaveKey);

                            if(!obj){
                                message.warning("缓存中无收集到的样式");
                                return;
                            }

                            if(obj.formName !== formName){
                                message.warning(`收集的样式【${obj.title}】 与 该栏目不匹配`);
                                return;
                            }


                            Modal.confirm({
                                title: "提示",
                                content: <div>
                                    <div>是否将【{title}】的样式配置替换成【收集】到的样式？</div>
                                    <div>收集时间：{TimeHelp.getYMDHMS(obj.time,true)}</div>
                                </div>,
                                onOk: ()=>{
                                    //修改组件内部的，让 label 重新判断
                                    _.set(this.selfConfig,[formName],obj.styleObj);
                                    this.BaseSetItem.upDateSelfConfig(this.selfConfig);
                                    this.formDefaultValues = this.BaseSetItem.mergeFinallyConfig(this.selfConfig,this.parentConfig,NeedDealKeys);

                                    //通知外部改变
                                    this.setState({
                                        formUpdate: this.state.formUpdate + 1
                                    },()=>{
                                        this.props.onChange(formName,null,obj.styleObj);
                                    })
                                }
                            })
                        }}
                    >

                    </Button>
                </Tooltip>
            </Space>
        </div>
    }
}