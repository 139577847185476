// @flow
import React, {Component} from 'react';
import styles from './ShowCode.less';
import {Popover} from 'antd';
import {QuestionCircleOutlined} from "@ant-design/icons";
import jsBeautify from "js-beautify";
// const {dialog} = require('electron').remote
// const styles ={};
// const styles = require("./Root.less")

import * as CodeMirror from 'codemirror/lib/codemirror'
import './codemirror/codemirror.css'
import './codemirror/monokai.css'
import './codemirror/show-hint.css'
import "./codemirror/foldgutter.css"

//模式
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/clike/clike'
import 'codemirror/mode/http/http'
import 'codemirror/keymap/emacs.js'

//高亮当前行
import 'codemirror/addon/selection/active-line'
import 'codemirror/addon/selection/selection-pointer'
import 'codemirror/addon/selection/mark-selection'

import 'codemirror/addon/comment/comment'
import 'codemirror/addon/comment/continuecomment'

import 'codemirror/addon/scroll/simplescrollbars'

import "codemirror/addon/fold/foldcode.js"
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/indent-fold.js"
import "codemirror/addon/fold/comment-fold.js"


// Search
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/search/search';
import 'codemirror/addon/scroll/annotatescrollbar';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/jump-to-line';


/**
 * 使用方法
 * ref.showValue(object|string)
 */
export default class ShowCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || undefined,
    };

    this.startTime = Date.now();
  }

  showValue(v) {

    if(this.props.wait && (Date.now() - this.startTime < this.props.wait)){
      //延迟，只需要保存值，时间到了会触发exe
      this.setState({
        value: v
      })
      return;
    }

    if (typeof v === "object") {
      v = JSON.stringify(v);
    } else if (typeof v === "string") {

    } else if (typeof v === "undefined") {
      v = "undefined";
    } else {
      v = JSON.stringify(v);
    }


    v = jsBeautify(v, {
      brace_style: "expand"
    });
    this.codeEditor?.setValue(v);


  }

  getValue(){
    return this.codeEditor.getValue();
  }

  componentDidMount() {


    let option = {
      readOnly: this.props.readOnly===undefined?true:this.props.readOnly,
      lineNumbers: true,
      theme: 'monokai', //编辑器主题
      mode: this.props.mode || {name: "javascript", json: true},
      styleActiveLine: true,//设置光标所在行高亮true/false
      selectionPointer: "pointer",//选择区，光标变手
      smartIndent: true, //智能缩进
      indentUnit: 4, // 智能缩进单位为4个空格长度
      indentWithTabs: true,  // 使用制表符进行智能缩进
      lineWrapping: true,////代码折叠
      // 在行槽中添加行号显示器、折叠器、语法检测器`
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
      foldGutter: true, // 启用行槽中的代码折叠
      autofocus: !this.props.readOnly && !this.props.noFocus,  //自动聚焦

      foldOptions: {
        widget: (from, to) => {
          var count = undefined;

          // Get open / close token
          var startToken = '{', endToken = '}';
          var prevLine = this.codeEditor.getLine(from.line);
          if (prevLine.lastIndexOf('[') > prevLine.lastIndexOf('{')) {
            startToken = '[', endToken = ']';
          }

          // Get json content
          var internal = this.codeEditor.getRange(from, to);
          var toParse = startToken + internal + endToken;

          // Get key count
          try {
            var parsed = JSON.parse(toParse);
            count = Object.keys(parsed).length;
          } catch (e) {
          }

          return count ? `${"---" + count}条---` : '无';
        }
      }
    }

    let exe = ()=>{
      this.codeEditor = CodeMirror.fromTextArea(this.editor, option);
      this.resetSize();
      if (this.state.value !== undefined) {
        this.showValue(this.state.value)
      }
    }
    if(this.props.wait){
      this.delayExe = setTimeout(()=>{
        exe();
      },this.props.wait)
    }else {
      exe();
    }

    if(this.props.autoSave){
      this.saveInterval = setInterval(()=>{
        this.props.autoSave(this.getValue())
      },1000)
    }
  }

  componentWillUnmount(){
    this.saveInterval && clearInterval(this.saveInterval);
    this.delayExe && clearTimeout(this.delayExe);
  }

  resetSize() {
    let rect = this.code.getBoundingClientRect();
    this.codeEditor.setSize(rect.width, rect.height);
  }


  render() {


    return (
      <div className={styles.main}>
        <div className={styles.code}
             ref={(ref)=>{this.code = ref}}
        >
                   <textarea
                     style={{display:"none"}}
                     ref={(ref)=>{this.editor = ref}}
                   />
        </div>
        {
          this.props.hiddenHelp?null:<Popover
            placement={"left"}
            title={<div style={{color: "#48b5ff"}}>快捷键提示</div>}
            content={<div
              style={{width: "250px"}}
            >
                        <span>
                            <span className={styles.label}>搜索:</span>
                            Ctrl-F / Cmd-F
                        </span>
              <br/>
              <span>
                              <span className={styles.label}>下一个:</span>
                            Ctrl-G / Cmd-G
                        </span>
              <br/>
              <span>
                               <span className={styles.label}>上一个:</span>
                            Shift-Ctrl-G / Shift-Cmd-G
                        </span>
              <br/>
              <span>
                               <span className={styles.label}>跳转到行:</span>
                            Alt-G
                        </span>
            </div>}
          >
            <QuestionCircleOutlined className={styles.tool}/>
          </Popover>
        }

      </div>
    );
  }

}