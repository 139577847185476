import React from "react";
import {
    AutoComplete,
    Form,
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

function getSetItems(extendData){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }


    return <>
        <Form.Item
          label={"对接关联KEY"}
          name={["dataSetConfig","keyField"]}
          rules={[{required: true}]}
        >
            <AutoComplete
              size={"small"}
              style={{width: "100%"}}
              placeholder={"请选择或填写对接字段"}
              options={opts}
            />
        </Form.Item>
        <Form.Item
          label={"对接关联类型"}
          name={["dataSetConfig","typeField"]}
          rules={[{required: true}]}
        >
            <AutoComplete
              size={"small"}
              style={{width: "100%"}}
              placeholder={"请选择或填写对接字段"}
              options={opts}
            />
        </Form.Item>
    </>
}

function getDefaultValues(){
    return {
        dataSetConfig: null,
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 key, type 等 字段可以自定义。
        返回的数据中，包含 key 与 type，如下：
        {
            key: "64113ba19334804xxxxxxx"，
            type: "测试"
        }
    `
}
export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}