import React from "react";
import {
  ArrowRightOutlined,
  PictureOutlined,
  VideoCameraOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";
import ShowImageSet from "./showImage/ShowImageSet";
import ShowImage from "./showImage/ShowImage";
import ShowVideoSet from "./showVideo/ShowVideoSet";
import ShowVideo from "./showVideo/ShowVideo";
import ShowTimeSet from "./showTime/ShowTimeSet";
import ShowTime from "./showTime/ShowTime";
import ShowArrowSet from "./arrow/ShowArrowSet";
import ShowArrow from "./arrow/ShowArrow";

//部件内容的注册
const WidgetContentArray = [
  {
    groupName: "资源展示",  //分组名称
    children: [
      {
        title: '图片',   //名称
        type: "component_image",   //匹配类型
        icon: <PictureOutlined />,  //小图标
        cover: require("./showImage/cover.png"),  //封面图
        renderFile: ShowImage, //渲染视图文件
        setFile: ShowImageSet,  //配置文件
      },
      {
        title: '视频',
        type: "component_video",
        icon: <VideoCameraOutlined />,
        cover: require("./showVideo/cover.png"),
        renderFile: ShowVideo,
        setFile: ShowVideoSet,
      },
    ]
  },
  {
    groupName: "其它",
    children: [
      {
        title: '日期时间',
        type: "component_time",
        icon: <FieldTimeOutlined />,
        cover: require("./showTime/cover.png"),
        renderFile: ShowTime,
        setFile: ShowTimeSet,
      },
      {
        title: '图标箭头',
        type: "component_arrow",
        icon: <ArrowRightOutlined />,
        cover: require("./arrow/cover.png"),
        renderFile: ShowArrow,
        setFile: ShowArrowSet,
      },
    ]
  },
]

export {WidgetContentArray};