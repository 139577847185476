import React, {Component} from 'react';
import ReactDom from 'react-dom';
import less from './CountView.less';
import TimeHelp from '../../../../tool/TimeHelp.js';
import APILXD from "../../../../http/APILXD";
import {message, Spin, DatePicker, Row, Col} from 'antd';
import moment from "moment/moment";
import HttpTool from "../../../../tool/HttpTool";

const RangePicker = DatePicker.RangePicker;

//按需加载 Echarts的组件
//引入 Echarts 主模块
// let echarts = require('echarts/lib/echarts');
// // //线型图
// require('echarts/lib/chart/line');
// // //提示框
// require('echarts/lib/component/tooltip');
// // //图例
// require('echarts/lib/component/legend');
// // //标题
// require('echarts/lib/component/title');

class CountView extends Component {
    constructor(props) {
        super(props);
        this.id = this.props.id;
        this.timeNow = Date.now();
        this.timePre = this.timeNow - 86400000 * 30;
        this.state = {
            data: [],
            selDate: [this.timePre, this.timeNow],
            loading: false,
        };

        //基础配置
        this.option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            legend: {
                data: ['总在线时长', 'TV时长',"可工作时长"],
                left: "center"
            },
            // title: {
            //     text: '在线时长',
            //     x: 'center'
            // },
            calculable: true,
            yAxis:
                {
                    type: 'value',
                    name: '时长(h)',
                    splitNumber: 6,
                    minInterval: 1,
                    min: 0,
                    max: 24,
                },
            xAxis:
                {
                    type: 'category',
                    boundaryGap: false,
                    // axisLabel:{
                    //     rotate:45,
                    // },
                    data: []
                },
            series: [
                {
                    name: '总在线时长',
                    type: 'line',
                    itemStyle: {
                        normal: {
                            color: '#2f86fc',
                        }
                    },
                    data: []
                },
                {
                    name: 'TV时长',
                    type: 'line',
                    itemStyle: {
                        normal: {
                            color: 'darkorange',
                        }
                    },
                    data: []
                },
                {
                    name: '可工作时长',
                    type: 'line',
                    itemStyle: {
                        normal: {
                            color: 'limegreen',
                        }
                    },
                    data: []
                },
            ]
        };
    }

    componentDidMount() {
        //初始化 echarts 实例
        setTimeout(() => {
            let chartDom = ReactDom.findDOMNode(this.chart);
            this.myChart = echarts.init(chartDom);
        }, 0);

        this.loadData();
    }

    loadData() {
        if (!this.id) {
            message.error("缺少设备id");
            return;
        }
        let param = {
            deviceId: this.id,
            starttime: this.state.selDate[0].valueOf(),
            endtime: this.state.selDate[1].valueOf(),
        };
        let successCB = (code, msg, json, option) => {
            this.setState({
                loading: false,
                data: json,
            }, () => {
                //绘制
                this.paintChart();
            });
        };
        let failureCB = (code, msg) => {
            this.setState({loading: false}, () => {
                message.error(msg);
            });
        };

        this.setState({loading: true}, () => {
            HttpTool.post(APILXD.getDeviceDayOnline, successCB, failureCB, param);
        });
    }

    render() {
        return (
            <div className={less.mainBox}>
                <Spin spinning={this.state.loading} size={'large'}>
                    <div className={less.dateSel02}>
                        <Row>
                            <Col offset={1}>
                                选择日期查看在线时长：
                                <RangePicker
                                    allowClear={false}
                                    showToday={false}
                                    value={[moment(this.state.selDate[0]), moment(this.state.selDate[1])]}
                                    format={"YYYY-MM-DD"}
                                    disabledDate={((currentDate) => {
                                        return currentDate && currentDate.valueOf() >= (Date.now())
                                    })}
                                    onChange={(date, dateString) => {
                                        let start = new Date(dateString[0]).valueOf();
                                        let end = new Date(dateString[1]).valueOf();
                                        let dayNum = (end - start) / 86400000 + 1;
                                        if (dayNum > 180) {
                                            message.warning('日期范围不能大于180天');
                                            return;
                                        }
                                        this.setState({
                                            selDate: [start, end],
                                        }, () => {
                                            this.loadData();
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div
                        ref={(ref) => {
                            this.chart = ref;
                        }}
                        className={less.countBox02}
                    >
                    </div>
                </Spin>
            </div>
        );
    }

    //配置数据
    getOptionConfig() {
        let option = _.merge({}, this.option);
        let selDate = this.state.selDate;
        let newData = this.state.data;

        let setData = [];
        let resultData = [];    //总
        let resultData2 = [];   //tv
        let resultData3 = [];   //可工作
        let titleData = [];
        let beginTime = selDate[0];
        let endTime = selDate[1];

        newData.forEach((obj) => {
            setData[TimeHelp.getYMDFormat(obj.time,"yyyyMMdd")] = obj;
        });

        let indexTime = beginTime;
        while (indexTime <= endTime) {
            let timeStr = TimeHelp.getYMDFormat(indexTime, 'yyyyMMdd');
            let timeStr02 = TimeHelp.getMD(indexTime);
            let item = setData[timeStr];
            titleData.push(timeStr02);
            if (item) {
                resultData.push({
                    yyyymmdd: timeStr02,
                    value: Math.ceil(item.duration / 36000) / 100,
                });
                resultData2.push({
                    yyyymmdd: timeStr02,
                    value: Math.ceil(item.playDuration / 36000) / 100,
                });
                resultData3.push({
                    yyyymmdd: timeStr02,
                    value: Math.ceil(item.freeDuration / 36000) / 100,
                });
            } else {
                resultData.push({
                    yyyymmdd: timeStr02,
                    value: 0,
                });
                resultData2.push({
                    yyyymmdd: timeStr02,
                    value: 0,
                });
                resultData3.push({
                    yyyymmdd: timeStr02,
                    value: 0,
                });
            }

            indexTime += 86400000;
        }

        //配置线数据
        option.xAxis.data = titleData;
        option.series[0].data = resultData;
        option.series[1].data = resultData2;
        option.series[2].data = resultData3;

        return option;
    }


    //绘制方法
    paintChart() {
        this.option = this.getOptionConfig();

        this.myChart.setOption(this.option);
    }
}

CountView.defaultProps = {
    sourceData: [],
};

module.exports = CountView;