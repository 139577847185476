const Emitter = require('component-emitter');

class LocalStorage {

    constructor() {
        this.emitter = new Emitter();

        this.userKey = null;
        this.reSetUserKey();
        this.id = null;

        window.addEventListener('storage', (e) => {
            this.change(e.key, e.newValue);
        })
    }

    change(key, value) {
        //因为可能拼接过userKey再存的，但是监听的事件没有拼接 userKey，这里需要截取原始key
        if(key.indexOf(this.userKey) !== -1){
            key = key.slice(4)
        }

        if (this.emitter.hasListeners(key)) {
            this.emitter.emit(key, value ? JSON.parse(value).v : null);
        } else {
        }
    }

    getUserKey(){
        return this.userKey
    }

    reSetUserKey() {
        let userInfo = this.getWithoutId("userInfo") || {};
        //注：保证是四个字符
        this.userKey = userInfo._id ? userInfo._id.slice(0, 4) : "UUUU";
        console.log("userKey：", this.userKey)
    }


    getEmitter() {
        return this.emitter;
    }

    /**
     * pc客户端的id
     * @returns {*}
     */
    getId() {
        // let id = window.localStorage.getItem("elf_id");
        // if(id&&id.indexOf("pc:")!==0){
        //     id =null;
        // }
        // if (!id) {
        //     id = "pc:" + this.getUUid();
        //     window.localStorage.setItem("elf_id", id);
        // }
        if (!this.id) {
            this.id = "pc:" + "web_" + this.cans()
            console.log("pc:", this.id)
        }


        return this.id;
    }

    set(key, value, refresh) {
        if (key === "elf_id") {
            key = key + "_1";
        }
        //组装上用户信息key再存
        return this.setWithoutId(this.userKey + key, value, refresh)
    }

    //不区分用户的缓存
    setWithoutId(key,value, refresh) {
        if(key==="elf_id"){
            key = key+"_1";
        }
        if(key==="userInfo"){
            //加密
            value = this.encrypt(JSON.stringify(value))
        }

        // console.log("存",key,value)
        let str =JSON.stringify( {v:value,uid:refresh?window.getUID():null});
        if(refresh){
            this.change(key,str);
        }

        window.localStorage.setItem(key, str);
        if(key === "userInfo"){
            //this.userKey是根据用户id算出来的，如果用户信息重新存了，需要重新计算key
            this.reSetUserKey();
        }
    }

    //区分用户的缓存
    get(key) {
        if (key === "elf_id") {
            key = key + "_1";
        }

        //组装上用户信息key再取值
        return this.getWithoutId(this.userKey + key)
    }

    //不区分用户的缓存
    getWithoutId(key) {
        if (key === "elf_id") {
            key = key + "_1";
        }

        let o = window && window.localStorage.getItem(key)

        let returnData = o ? JSON.parse(o).v : null;

        if(key==="userInfo" && typeof returnData === "string"){
            //解密
            returnData = JSON.parse(this.decrypt(returnData))
        }

        return returnData;
    }


    remove(key) {
        this.removeWithoutId(this.userKey + key)
    }

    removeWithoutId(key){
        if(key==="elf_id"){
            key = key+"_1";
        }

        window && window.localStorage.removeItem(key);
    }
}

export default new LocalStorage();