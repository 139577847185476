import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";
import TreeDataHandler from "../../../../../../../tool/treeDataHandler";

export default class SunburstShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;

        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position){
            case "top": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "center",
            });break;
            case "bottom": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "center",
            });break;
            case "left": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "5%",
            });break;
            case "right": option.legend =  Object.assign(option.legend,{
                top: "center",
                right: "5%",
            });break;
            case "topLeft": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "5%",
            });break;
            case "topRight": option.legend =  Object.assign(option.legend,{
                top: "5%",
                right: "5%",
            });break;
            case "bottomLeft": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "5%",
            });break;
            case "bottomRight": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                right: "5%",
            });break;
            case "center": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "center",
            });break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        if(!Array.isArray(data)){
            this.setState({
                error: "数据格式不匹配"
            })
            return;
        }

        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "sunburst",
                sort: dataConfig?.contentParams?.pieSpecial?._sort
            };

            //旭日图殊配置
            //圆心
            seriesItem.center = [dataConfig?.contentParams?.pieSpecial?._centerSetX || "50%",dataConfig?.contentParams?.pieSpecial?._centerSetY || "50%"]

            //半径
            seriesItem.radius = [(dataConfig?.contentParams?.pieSpecial?._radiusInner || 0) + "%",(dataConfig?.contentParams?.pieSpecial?._radiusOuter || 0) + "%"]

            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                }

                switch (dataConfig?.contentParams?.label.position){
                    case "outsideCenter":
                        seriesItem.label.position= "outside";
                        seriesItem.label.align= "center";
                        break;
                    case "insideCenter":
                        seriesItem.label.position= "inside";
                        seriesItem.label.align= "center";
                        break;
                    case "insideLeft":
                        seriesItem.label.position= "inside";
                        seriesItem.label.align= "left";
                        break;
                    case "insideRight":
                        seriesItem.label.position= "inside";
                        seriesItem.label.align= "right";
                        break;
                }

                switch (dataConfig?.contentParams?.label._showPlan){
                    case "normal":break;
                    case "pop":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                }

                //展示所有标签
                if(dataConfig?.contentParams?.pieSpecial?._showAllLabel){
                    seriesItem.label.minAngle = 0;
                }else{
                    seriesItem.label.minAngle = 3.6;
                }

                if(dataConfig?.contentParams?.label._beforeTxt || dataConfig?.contentParams?.label._afterTxt){
                    //特殊格式
                    seriesItem.label.formatter = `${dataConfig?.contentParams?.label._beforeTxt}{b}${dataConfig?.contentParams?.label._afterTxt}`
                }
            }

            //扇区样式
            if(dataConfig?.contentParams?.itemStyle){
                seriesItem.itemStyle = {
                    borderWidth: dataConfig.contentParams.itemStyle.borderWidth,
                    borderColor: dataConfig.contentParams.itemStyle.borderColor,
                    borderType: dataConfig.contentParams.itemStyle.borderType,
                    shadowBlur: dataConfig.contentParams.itemStyle.shadowBlur,
                    shadowColor: dataConfig.contentParams.itemStyle.shadowColor,
                }
            }

            //高亮配置
            seriesItem.emphasis = {}

            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }

            //根据数据和配置填充其它属性
            let newData = [];
            if(dataConfig?.contentParams?.dataSetConfig?.labelField && dataConfig?.contentParams?.dataSetConfig?.childrenField){
                let matchRule = {
                    name: dataConfig?.contentParams?.dataSetConfig?.labelField,
                    children: dataConfig?.contentParams?.dataSetConfig?.childrenField
                }
                if(dataConfig?.contentParams?.dataSetConfig?.valueField){
                    matchRule["value"] = dataConfig?.contentParams?.dataSetConfig?.valueField
                }
                newData = TreeDataHandler.changeField(data,matchRule,"new",dataConfig?.contentParams?.dataSetConfig?.childrenField)
            }

            seriesItem.data = newData
            option.series = [seriesItem];
        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}