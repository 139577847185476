import React, {Component} from 'react';
import styles from "./RankList.less";
import CommonViewDiv from "../../../CommonViewDiv";

export default class RankList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listData: []
        }

        this.maxInData = 0;
    }


    showValue(value){
        this.setState({
            listData: value
        })
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let {listData} = this.state;

        if(!listData?.length){
            return null
        }

        if(!contentParams.titleField || !contentParams.numField){
            return "数据对接未配置完整";
        }

        if(!Array.isArray(listData)){
            return "传入的排行数据不是数组类型";
        }

        this.maxInData = 0;

        listData.forEach((a)=>{
            if(Number(a[contentParams.numField])){
                this.maxInData = Math.max(this.maxInData,a[contentParams.numField]);
            }
        })

        return <div
            className={styles.outerBox}
            ref={ref=>this.outerBox = ref}
        >
            <div
                className={styles.listBox}
                ref={ref=>this.innerBox = ref}
            >
                {listData.sort((a,b)=>{
                    return b[contentParams.numField] -  a[contentParams.numField]
                }).map((eachItem,index)=>{
                    //校验字段
                    if(eachItem[contentParams.numField] && isNaN(Number(eachItem[contentParams.numField]))){
                        return <div className={styles.itemErr}>错误项：数量字段值不对</div>
                    }
                    if(eachItem[contentParams.titleField] && typeof eachItem[contentParams.titleField] === "object"){
                        return <div className={styles.itemErr}>错误项：名称字段值不对</div>
                    }

                    let numCal = (eachItem[contentParams.numField] || 0)*100/this.maxInData;

                    return <div
                        key={"ea_" +index}
                    >
                        <div
                            className={styles.headMsgBox}
                        >
                            <span
                                className={styles.onelineBox}
                                style={CommonViewDiv.getTextStyleObj(contentParams.titleTextSet)}
                            >
                             {
                                 contentParams.showIndex
                                     ?index+1 + "." + eachItem[contentParams.titleField]
                                     :eachItem[contentParams.titleField]
                             }
                            </span>
                            <span
                                style={{flexShrink: 0}}
                            >
                                {CommonViewDiv.getText(contentParams.numTextSet,(contentParams.bigNumber?window.formatBigNumW(eachItem[contentParams.numField]):eachItem[contentParams.numField]))}
                                <span
                                    style={{paddingLeft: "5px"}}
                                >
                                    <span
                                        style={CommonViewDiv.getTextStyleObj(contentParams.unitStyleUse?contentParams.unitTextSet:contentParams.numTextSet)}
                                    >
                                       {contentParams.unitStr?`${contentParams.unitStr}` : ""}
                                    </span>
                                </span>
                            </span>
                        </div>
                        <div
                            className={styles.barBorderContainer}
                            style={{
                                borderRadius: window.changeRemNum(contentParams.borderSet.radiusSet),
                                borderWidth: window.changeRemNum(contentParams.borderSet.borderWidthSet),
                                marginTop: window.changeRemNum(contentParams.borderSet.marginTop),
                                marginBottom: window.changeRemNum(contentParams.borderSet.marginBottom),
                                borderColor: contentParams.borderSet.borderColor,
                                height: window.changeRemNum(contentParams.borderSet.heightSet),
                            }}
                        >
                            <div
                                className={styles.barContainer}
                                style={{
                                    backgroundColor: contentParams.borderSet.backgroundColor +  window.getOpacityStr(typeof contentParams.borderSet?.backgroundOpacity === "number"?contentParams.borderSet.backgroundOpacity:100),
                                }}
                            >
                                <div
                                    className={styles.innerBar}
                                    style={{
                                        left: 0,
                                        width: `${numCal}%`,
                                        minWidth: "1px",
                                        borderRadius: window.changeRemNum(contentParams.borderSet.radiusSet),
                                        background: `linear-gradient(90deg, ${contentParams.barSet.successColorStart} 0%, ${contentParams.barSet.successColorEnd} 100%)`,
                                        opacity: typeof contentParams.barSet?.backgroundOpacity === "number"?contentParams.barSet.backgroundOpacity/100:1,
                                    }}
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    }
}