import React from "react";

const AllDIY = {
    type01: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path
            fill="currentColor"
            d="M1640.887937 1021.823295H2.217725V3.070588h1204.352664l1.02353 0.682352 75.741159 57.317634h357.552859v960.752721zM8.017724 1016.023296h1627.070214V66.870573H1281.629196L1204.523331 8.870586H8.017724v1007.15271z"
            p-id="2688"/>
        <path fill="currentColor" d="M1593.123242 1013.635061h50.152929v9.894116h-50.152929z" p-id="2689"/>
        <path fill="currentColor"
              d="M1643.289819 973.250012v50.152929h-9.894116v-50.152929zM10.276311 973.84707v50.15293h-9.894115v-50.15293z"
              p-id="2690"/>
        <path fill="currentColor"
              d="M50.436185 1023.447294H0.283255v-9.894115h50.15293zM50.436185 10.620821H0.283255v-9.894115h50.15293z"
              p-id="2691"/>
        <path fill="currentColor"
              d="M0.000078 50.743165V0.590235h9.894116v50.15293zM1396.264464 54.247046h-73.011748l-45.376461-34.117639h73.011748z"
              p-id="2692"/>
        <path fill="currentColor" d="M1485.311502 54.247046h-63.799985l-45.376461-34.117639H1439.935042z"
              p-id="2693"/>
        <path fill="currentColor" d="M1565.146778 54.247046h-54.588223l-45.37646-34.117639h54.929399z" p-id="2694"/>
        <path fill="currentColor" d="M1636.111467 54.247046h-45.717636l-45.035284-34.117639h90.75292z" p-id="2695"/>
    </svg>,
    type02: <svg
        viewBox="0 0 1638 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path fill="currentColor"
              d="M0 295.936l3.754667 3.754667v15.36l-1.706667 1.365333v143.018667H0zM16.042667 106.496v-15.701333L27.306667 80.213333v15.701334z"
              p-id="2052"/>
        <path fill="currentColor" d="M16.042667 122.538667v-12.629334L27.306667 99.328v12.629333z" p-id="2053"/>
        <path fill="currentColor" d="M16.042667 135.509333V126.293333L27.306667 115.712v9.216z" p-id="2054"/>
        <path fill="currentColor" d="M16.042667 146.090667v-6.485334L27.306667 129.024v21.504z" p-id="2055"/>
        <path fill="currentColor"
              d="M1614.165333 25.6h-23.210666L1565.696 0H72.704l-25.258667 25.6H24.576L0 49.834667v239.957333l4.778667 4.778667V212.309333l7.168-7.168V77.824l2.048 1.706667v7.850666l12.288-11.605333v-12.629333L75.093333 15.018667h53.930667l9.557333-10.581334H1499.818667l9.557333 10.581334H1563.306667l48.810666 48.128v12.629333l12.288 11.605333v-7.850666l2.048-1.706667v127.317333l7.168 7.168v82.261334l4.778667-4.778667V49.834667zM1638.4 295.936l-3.754667 3.754667v15.36l1.706667 1.365333v143.018667h2.048z"
              p-id="2056"/>
        <path fill="currentColor" d="M1622.357333 106.496v-15.701333l-11.264-10.581334v15.701334z" p-id="2057"/>
        <path fill="currentColor" d="M1622.357333 122.538667v-12.629334l-11.264-10.581333v12.629333z" p-id="2058"/>
        <path fill="currentColor" d="M1622.357333 135.509333V126.293333l-11.264-10.581333v9.216z" p-id="2059"/>
        <path fill="currentColor" d="M1622.357333 146.090667v-6.485334l-11.264-10.581333v21.504z" p-id="2060"/>
        <path fill="currentColor"
              d="M1638.4 728.064l-3.754667-3.754667v-15.36l1.706667-1.365333v-143.018667h2.048zM1622.357333 917.504v15.701333l-11.264 10.581334v-15.701334z"
              p-id="2061"/>
        <path fill="currentColor" d="M1622.357333 901.461333v12.629334l-11.264 10.581333v-12.629333z" p-id="2062"/>
        <path fill="currentColor" d="M1622.357333 888.490667v9.216l-11.264 10.581333v-9.216z" p-id="2063"/>
        <path fill="currentColor" d="M1622.357333 877.909333v6.485334l-11.264 10.581333v-21.504z" p-id="2064"/>
        <path fill="currentColor"
              d="M1633.621333 729.429333v82.261334l-7.168 7.168v127.317333l-2.048-1.706667v-7.850666l-12.288 11.605333v12.629333L1563.306667 1008.981333h-53.930667l-9.557333 10.581334H138.581333l-9.557333-10.581334H75.093333L26.282667 960.853333v-12.629333l-12.288-11.605333v7.850666l-2.048 1.706667v-127.317333l-7.168-7.168v-82.261334L0 734.208v239.957333l24.576 24.234667h22.869333l25.258667 25.6h1492.992l25.258667-25.6h23.210666l24.234667-24.234667v-239.957333zM0 728.064l3.754667-3.754667v-15.36l-1.706667-1.365333v-143.018667H0z"
              p-id="2065"/>
        <path fill="currentColor" d="M16.042667 917.504v15.701333l11.264 10.581334v-15.701334z" p-id="2066"/>
        <path fill="currentColor" d="M16.042667 901.461333v12.629334l11.264 10.581333v-12.629333z" p-id="2067"/>
        <path fill="currentColor" d="M16.042667 888.490667v9.216l11.264 10.581333v-9.216z" p-id="2068"/>
        <path fill="currentColor" d="M16.042667 877.909333v6.485334l11.264 10.581333v-21.504z" p-id="2069"/>
    </svg>,
    type03: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path fill="currentColor"
              d="M1637.600139 170.960131h-2.047427v-163.794137h-166.182802l-11.602085 12.625798H700.902414l-12.28456-12.625798h-651.764173L3.412378 41.63101v12.28456H1.364951v-13.308273L35.829968 4.777329h653.811599l12.28456 12.625798h754.817985l11.602084-12.625798h169.253943zM1027.797977 1024h-900.52652v-10.919609h900.52652z"
              p-id="3307"/>
        <path fill="currentColor"
              d="M1599.722744 1019.959745H2.047427v-120.798177h2.388664v118.409512h1594.26294l33.441303-34.465016v-21.497981l-6.824756-13.990749v-9.895896l12.284561-12.28456v3.412378L1627.704243 938.403914v8.872182l6.824756 13.990749v22.862932l-0.341238 0.341238zM3.753616 204.060196H1.364951V78.825929l8.189707-8.189707v-27.640261L38.55987 13.649511l1.364951 1.706189-27.981498 28.663974V71.659935l-8.189707 8.189707z"
              p-id="3308"/>
        <path fill="currentColor"
              d="M14.673225 63.128991H6.824756L3.07114 69.612508 6.824756 76.096026h7.848469l3.753615-6.483518zM1579.248477 1010.405087H1170.104371l-9.21342-9.213421h-140.248731l-13.308274 7.848469-1.364951-2.047426 13.99075-8.189707h141.954919l9.21342 9.21342h407.09668l36.171205-35.829968v-34.465016l20.13303-20.133029v-55.962997h2.047426v56.645472l-20.133029 20.474267v34.465017l-0.341238 0.341238zM1633.505285 966.72665l-51.868143 52.209381h17.744365l34.465016-36.171205z"
              p-id="3309"/>
    </svg>,
    type04: <svg
        viewBox="0 0 1638 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >

        <path fill="currentColor"
              d="M10.581333 109.226667h-10.24V53.589333L53.930667 0h68.266666v10.24H58.026667L10.581333 58.026667zM1638.058667 122.197333h-10.24V58.026667L1580.373333 10.24h-51.541333V0h55.637333l53.589334 53.589333zM1584.469333 1024h-68.266666v-10.24H1580.373333l47.445334-47.786667v-51.2h10.24v55.637334zM109.568 1024H53.930667L0.341333 970.410667v-68.266667h10.24V965.973333L58.026667 1013.76h51.541333z"
              p-id="2999"/>
        <path fill="currentColor"
              d="M1569.109333 1001.472h-335.189333L1225.386667 986.453333H419.84l-8.533333 14.677334h-344.746667L24.576 959.488V70.314667L66.56 28.330667h344.746667l8.533333 14.677333h805.546667l8.533333-14.677333h338.602667L1611.093333 70.314667V959.146667l-41.984 42.325333z m-333.141333-3.413333h331.776l39.936-39.936V71.68L1571.157333 31.744h-335.189333l-8.533333 14.677333H417.792l-8.533333-14.677333h-341.333334L27.989333 71.68v886.101333l39.936 39.936h341.333334l8.533333-14.677333h809.642667l8.533333 15.018667z"
              p-id="3000"/>
        <path fill="currentColor"
              d="M418.816 20.821333l8.533333 14.677334h790.528l8.533334-14.677334zM1226.410667 1008.981333l-8.533334-14.677333H427.349333l-8.533333 14.677333z"
              p-id="3001"/>
    </svg>,
    type05: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >

        <path fill="currentColor"
              d="M1624.064 1009.664H14.677333V18.090667h1609.386667v991.573333z m-1604.266667-5.12h1599.146667V23.210667H19.797333v981.333333z"
              p-id="3461"/>
        <path fill="currentColor"
              d="M148.48 16.042667H11.264v137.216h10.922667V90.794667l6.485333-4.437334V33.450667h52.906667l4.437333-6.485334h62.464zM11.264 874.154667v137.216h137.216v-10.922667H86.016l-4.437333-6.485333H28.672v-52.906667l-6.485333-4.437333v-62.464zM1490.602667 1011.370667h137.216v-137.216h-10.922667v62.464l-6.826667 4.437333v52.906667h-52.906666l-4.096 6.485333h-62.464zM1627.818667 153.258667V16.042667h-137.216v10.922666H1553.066667l4.096 6.485334h52.906666v52.906666l6.826667 4.437334v62.464z"
              p-id="3462"/>
    </svg>,
    type06: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >

        <path fill="currentColor"
              d="M1203.882667 23.893333l74.752 66.56 2.048 1.706667H1614.506667v881.322667l-19.797334 23.210666H44.714667L23.893333 976.554667V48.810667L46.08 23.893333h1157.802667m2.389333-6.826666H43.008L17.066667 46.08V979.626667l24.917333 23.893333h1556.138667l23.210666-27.306667V85.333333h-338.261333L1206.272 17.066667z"
              p-id="2845"/>
        <path fill="currentColor"
              d="M30.378667 106.837333l-17.066667 13.994667V38.570667l24.234667-23.893334h185.685333l-19.114667 17.066667H44.373333l-13.994666 13.994667zM30.378667 917.162667l-17.066667-13.994667v81.92l24.234667 24.234667h185.685333l-19.114667-17.066667H44.373333l-13.994666-14.336zM1609.728 917.162667l17.066667-13.994667v81.92l-24.234667 24.234667h-185.685333l19.114666-17.066667h159.744l13.994667-14.336zM1625.770667 128.682667l-46.762667-47.104h46.762667z"
              p-id="2846"/>
        <path fill="currentColor"
              d="M1608.704 106.837333h-122.88l-44.032-26.624h-156.330667L1229.824 31.061333h-1068.373333v-3.413333h1069.738666L1286.826667 76.8h155.648l44.373333 26.624h121.856zM231.765333 1003.52l-18.090666-18.090667H53.589333l-16.384-16.384v-53.589333L30.72 909.653333V115.370667l12.629333-8.533334v-58.026666l27.648-27.989334 2.389334 2.389334-26.624 26.965333v58.368L34.133333 117.077333V907.946667l6.485334 5.802666v53.930667l14.336 14.336H215.04l19.114667 19.114667zM1608.704 237.909333h18.090667v565.589334h-18.090667z"
              p-id="2847"/>
    </svg>,
    type07: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >

        <path fill="currentColor"
              d="M1626.453333 1012.053333H11.946667V11.946667h1614.506666v1000.106666z m-1611.093333-3.413333h1607.68V15.36H15.36v993.28z"
              p-id="3153"/>
        <path fill="currentColor"
              d="M18.773333 47.104h-10.24V8.533333h38.570667v10.24H18.773333zM47.104 1015.466667H8.533333v-38.570667h10.24v28.330667h28.330667zM1629.866667 1015.466667h-38.570667v-10.24h28.330667v-28.330667h10.24zM1629.866667 47.104h-10.24V18.773333h-28.330667v-10.24h38.570667z"
              p-id="3154"/>
    </svg>,
    type08: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path fill="currentColor"
              d="M1596.416 1018.88H8.533333V85.333333l41.301334-44.373333h344.746666l33.450667-34.133333h1205.248v965.632l-36.864 46.421333z m-1584.469333-3.413333h1582.762666l35.157334-44.373334V10.24H429.397333L395.946667 44.373333H51.2L11.946667 86.698667v928.768z"
              p-id="2221"/>
        <path fill="currentColor" d="M243.712 3.413333l-30.72 30.72h27.306667l30.72-30.72z" p-id="2222"/>
        <path fill="currentColor" d="M281.258667 3.413333l-30.72 30.72h27.306666l30.72-30.72z" p-id="2223"/>
        <path fill="currentColor" d="M318.805333 3.413333l-30.72 30.72h27.306667l30.72-30.72z" p-id="2224"/>
        <path fill="currentColor" d="M356.352 3.413333l-30.72 30.72h27.306667l30.72-30.72z" p-id="2225"/>
        <path fill="currentColor" d="M393.898667 3.413333l-30.72 30.72h27.306666l30.72-30.72z" p-id="2226"/>
        <path fill="currentColor"
              d="M15.018667 126.976h-10.24V83.968L47.786667 37.205333h92.842666v10.24H52.224L15.018667 88.064zM70.997333 1022.293333H4.437333v-57.002666h10.24v46.762666h56.32zM1597.781333 1021.610667h-74.752v-10.24h69.973334l33.450666-41.984v-51.882667h10.24V972.8zM1636.693333 51.882667h-10.24V12.288h-41.984v-10.24h52.224z"
              p-id="2227"/>

    </svg>,
    type09: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path fill="currentColor" d="M30.72 121.856H23.893333V21.162667h664.576v6.826666H30.72z" p-id="2377"/>
        <path fill="currentColor"
              d="M44.373333 154.965333l-20.48 16.042667V16.725333h196.266667l-18.773333 20.48H44.373333z"
              p-id="2378"/>
        <path fill="currentColor"
              d="M1390.933333 983.04H22.186667V564.224l23.210666-18.432v-138.581333l-11.264-11.605334V155.648h6.826667V392.533333l11.264 11.946667v144.725333l-23.210667 18.432V976.213333H1389.226667l51.2-24.576 3.072 6.144z"
              p-id="2379"/>
        <path fill="currentColor"
              d="M1609.728 983.722667h-206.165333l50.858666-23.893334h131.413334v-142.677333l23.893333-34.133333z"
              p-id="2380"/>
        <path fill="currentColor"
              d="M1585.152 807.936l-5.461333-3.754667 23.893333-33.109333v-164.864l-15.36-8.533333v-402.773334l15.36-8.533333V24.234667H730.794667l-22.869334 22.869333h-193.877333v-6.826667h191.146667l22.869333-22.869333h882.346667v173.056l-15.36 8.533333V593.92l15.36 8.533333v170.666667z"
              p-id="2381"/>
        <path fill="currentColor" d="M1603.584 209.237333h6.826667v378.88h-6.826667z" p-id="2382"/>
        <path fill="currentColor" d="M29.013333 410.624h6.826667v132.437333h-6.826667z" p-id="2383"/>

    </svg>,
    type10: <svg
        viewBox="0 0 1644 1024"
        width="100%"
        height="100%"
        preserveAspectRatio="none meet"
    >
        <path fill="currentColor"
              d="M1587.541333 1009.664H50.858667L14.677333 973.141333V672.426667l20.821334-12.629334v-295.253333L14.677333 351.573333V50.858667L50.858667 14.677333H1587.2L1623.722667 51.2v299.349333l-23.210667 13.994667v295.253333l23.210667 13.994667v299.349333l-36.181334 36.522667z m-1534.634666-5.12h1532.245333l33.450667-33.450667v-294.229333l-23.210667-13.994667V361.813333l23.210667-13.994666V53.248l-33.450667-33.450667H53.248l-33.450667 33.450667v295.594667l20.821334 12.629333v301.056l-20.821334 12.629333v295.936l33.109334 33.450667z"
              p-id="2535"/>
        <path fill="currentColor"
              d="M7.850667 355.669333v312.661334l23.210666-13.994667v-284.672zM1628.16 668.330667v-312.661334l-23.210667 13.994667v284.672zM1623.722667 46.08l-30.72-30.72h30.72zM1593.002667 1009.322667l30.72-30.72v30.72zM14.677333 978.602667l30.72 30.72h-30.72zM45.397333 15.36l-30.72 30.72v-30.72z"
              p-id="2536"/>
    </svg>,
}

let getSVGBorder = (type) => {
    let V = AllDIY[type];

    return V;
}

let getSVGName = (type)=>{
    let V = {
        type01: "边框1",
        type02: "边框2",
        type03: "边框3",
        type04: "边框4",
        type05: "边框5",
        type06: "边框6",
        type07: "边框7",
        type08: "边框8",
        type09: "边框9",
        type10: "边框10",
    }[type];

    return V;
}

let getAllTypes = ()=>{
    return AllDIY
}

export default {
    getSVGBorder,
    getSVGName,
    getAllTypes
};