import React from "react";
import {
    Form,
    Divider,
    Select,
    AutoComplete,
    Radio,
    Switch,
    Collapse,
    InputNumber
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

const Item = Form.Item;

/**
 * 树图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }


    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"名称维度"}
                name={["dataSetConfig","labelField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"数值维度"}
                name={["dataSetConfig","valueField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"子集维度"}
                name={["dataSetConfig","childrenField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="treeSpecial"
            header="树图专用"
        >
            <Item
                label={"展示方式"}
                name={["treeSpecial","layout"]}
            >
                <Radio.Group>
                    <Radio value={"orthogonal"}>正交模式</Radio>
                    <Radio value={"radial"}>径向模式</Radio>
                </Radio.Group>
            </Item>
            {
                <Item
                    noStyle={true}
                    shouldUpdate={(prevValues, currentValues) => prevValues.treeSpecial.layout !== currentValues.treeSpecial.layout}
                >
                    {({getFieldValue})=>{
                        if(getFieldValue(["treeSpecial","layout"]) === "orthogonal"){
                            return <>
                                <Item
                                    label={"展示方向"}
                                    name={["treeSpecial","orient"]}
                                >
                                    <Select>
                                        <Select.Option value={"LR"}>从左往右</Select.Option>
                                        <Select.Option value={"RL"}>从右往左</Select.Option>
                                        <Select.Option value={"TB"}>从上往下</Select.Option>
                                        <Select.Option value={"BT"}>从下往上</Select.Option>
                                    </Select>
                                </Item>
                            </>
                        }
                    }}
                </Item>
            }
            <Item
                label={"初始展示层级"}
                name={["treeSpecial","initialTreeDepth"]}
                extra={"设置为-1则展示所有层级"}
            >
                <InputNumber
                    step={1}
                    precision={0}
                    max={100}
                    min={-1}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            <Item
                label={"画布背景色"}
                name={["theme", "backgroundColor"]}
                rules={[{required: false}]}
            >
                <ColorPicker
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="usual"
            header="布局"
        >
            <Item
                label={"网格"}
            >
                <Item
                    label={"上"}
                    name={["usual", "grid", "top"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"下"}
                    name={["usual", "grid", "bottom"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"左"}
                    name={["usual", "grid", "left"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
                <Item
                    label={"右"}
                    name={["usual", "grid", "right"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="itemStyle"
            header="标记样式"
            extra={
                <Item
                    noStyle={true}
                    name={["itemStyle","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <Item
                noStyle={true}
                dependencies={["itemStyle", "_setType"]}
            >
                {({getFieldValue}) => {
                    let setType = getFieldValue(["itemStyle", "_setType"]);

                    return <>
                        <Item
                            noStyle={true}
                            hidden={setType !== "normal"}
                        >
                            <Item
                                label={"标记图形"}
                                name={["treeSpecial","symbol"]}
                                extra={"空心系列图形，描边样式不生效。"}
                            >
                                <Select>
                                    <Select.Option value={null}>不展示标记</Select.Option>
                                    <Select.Option value={"emptyCircle"}>空心圆</Select.Option>
                                    <Select.Option value={"circle"}>实心圆</Select.Option>
                                    <Select.Option value={"emptyRect"}>空心矩形</Select.Option>
                                    <Select.Option value={"rect"}>实心矩形</Select.Option>
                                    <Select.Option value={"emptyRoundRect"}>空心圆角矩形</Select.Option>
                                    <Select.Option value={"roundRect"}>实心圆角矩形</Select.Option>
                                    <Select.Option value={"emptyTriangle"}>空心三角</Select.Option>
                                    <Select.Option value={"triangle"}>实心三角</Select.Option>
                                    <Select.Option value={"emptyDiamond"}>空心菱形</Select.Option>
                                    <Select.Option value={"diamond"}>实心菱形</Select.Option>
                                    <Select.Option value={"emptyPin"}>空心坐标</Select.Option>
                                    <Select.Option value={"pin"}>实心坐标</Select.Option>
                                    <Select.Option value={"emptyArrow"}>空心箭头</Select.Option>
                                    <Select.Option value={"arrow"}>实心箭头</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                label={"标记大小"}
                                name={["treeSpecial","symbolSize"]}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["treeSpecial","symbolSize"], {
                                    min: 0,
                                    max: 50
                                })}
                            </Item>
                            <Item
                                label={"图形颜色"}
                                name={["itemStyle","color"]}
                            >
                                <ColorPicker/>
                            </Item>
                            <Item
                                label={"描边线宽"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderWidth"], {
                                    min: 0,
                                    max: 10
                                })}
                            </Item>
                            <Item
                                label={"描边线颜色"}
                                name={["itemStyle","borderColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                            <Item
                                label={"描边线类型"}
                                name={["itemStyle","borderType"]}
                            >
                                <Radio.Group>
                                    <Radio value="solid">实线</Radio>
                                    <Radio value="dashed">虚线</Radio>
                                    <Radio value="dotted">点线</Radio>
                                </Radio.Group>
                            </Item>
                            <Item
                                label={"阴影大小"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","shadowBlur"], {
                                    min: 0,
                                    max: 20
                                })}
                            </Item>
                            <Item
                                label={"阴影颜色"}
                                name={["itemStyle","shadowColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                        </Item>
                        <Item
                            noStyle={true}
                            hidden={setType !== "emphasis"}
                        >
                            <Item
                                label={"改变哪些属性"}
                                name={["itemStyle", "_emphasisSel"]}
                            >
                                <Select
                                    mode={"tags"}
                                >
                                    <Select.Option value={"color"}>图形颜色</Select.Option>
                                    <Select.Option value={"borderColor"}>描边线颜色</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                noStyle={true}
                                dependencies={["itemStyle", "_emphasisSel"]}
                            >
                                {({getFieldValue}) => {
                                    let selFields = getFieldValue(["itemStyle", "_emphasisSel"]);

                                    return selFields?.map((selField) => {
                                        switch (selField) {
                                            case "color":
                                                return <Item
                                                    key={"color"}
                                                    label={"图形颜色"}
                                                    name={["itemStyle", "_emphasis", "color"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                            case "borderColor":
                                                return <Item
                                                    key={"borderColor"}
                                                    label={"描边线颜色"}
                                                    name={["itemStyle", "_emphasis", "borderColor"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                        }
                                    })
                                }}
                            </Item>
                        </Item>
                    </>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="lineStyle"
            header="连线样式"
            extra={
                <Item
                    noStyle={true}
                    name={["lineStyle","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <Item
                noStyle={true}
                dependencies={["lineStyle", "_setType"]}
            >
                {({getFieldValue}) => {
                    let setType = getFieldValue(["lineStyle", "_setType"]);
                    let edgeShape = getFieldValue(["treeSpecial", "edgeShape"]);

                    return <>
                        <Item
                            noStyle={true}
                            hidden={setType !== "normal"}
                        >
                            <Item
                                label={"连线类型"}
                                name={["treeSpecial","edgeShape"]}
                                extra={"仅对正交模式生效"}
                            >
                                <Radio.Group>
                                    <Radio value={"curve"}>曲线</Radio>
                                    <Radio value={"polyline"}>折线</Radio>
                                </Radio.Group>
                            </Item>
                            <Item
                                label={"折线分叉位置"}
                                hidden={edgeShape === "curve"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["treeSpecial","edgeForkPosition"],{
                                    min: 0,
                                    max: 100,
                                    addonAfter: "%"
                                })}
                            </Item>
                            <Item
                                label={"曲线弧度"}
                                hidden={edgeShape === "polyline"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["lineStyle","curveness"], {
                                    min: 0,
                                    max: 1,
                                    step: 0.1,
                                    precision: 1,
                                })}
                            </Item>
                            <Item
                                label={"连线宽"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["lineStyle","width"], {
                                    min: 0,
                                    max: 10,
                                    step: 0.1,
                                    precision: 1,
                                })}
                            </Item>
                            <Item
                                label={"连线颜色"}
                                name={["lineStyle","color"]}
                            >
                                <ColorPicker/>
                            </Item>
                            <Item
                                label={"阴影大小"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["lineStyle","shadowBlur"], {
                                    min: 0,
                                    max: 20
                                })}
                            </Item>
                            <Item
                                label={"阴影颜色"}
                                name={["lineStyle","shadowColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                        </Item>
                        <Item
                            noStyle={true}
                            hidden={setType !== "emphasis"}
                        >
                            <Item
                                label={"改变哪些属性"}
                                name={["lineStyle", "_emphasisSel"]}
                            >
                                <Select
                                    mode={"tags"}
                                >
                                    <Select.Option value={"color"}>连线颜色</Select.Option>
                                    <Select.Option value={"width"}>连线宽</Select.Option>
                                    <Select.Option value={"shadowBlur"}>阴影大小</Select.Option>
                                    <Select.Option value={"shadowColor"}>阴影颜色</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                noStyle={true}
                                dependencies={["lineStyle", "_emphasisSel"]}
                            >
                                {({getFieldValue}) => {
                                    let selFields = getFieldValue(["lineStyle", "_emphasisSel"]);

                                    return selFields?.map((selField) => {
                                        switch (selField) {
                                            case "color":
                                                return <Item
                                                    key={"color"}
                                                    label={"连线颜色"}
                                                    name={["lineStyle", "_emphasis", "color"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                            case "shadowColor":
                                                return <Item
                                                    key={"shadowColor"}
                                                    label={"阴影颜色"}
                                                    name={["lineStyle", "_emphasis", "shadowColor"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                            case "width":
                                                return <Item
                                                    label={"连线宽"}
                                                >
                                                    {CommonFormItems.getSliderAndInputItemGroup(["lineStyle","_emphasis","width"], {
                                                        min: 0,
                                                        max: 10,
                                                        step: 0.1,
                                                        precision: 1,
                                                    })}
                                                </Item>
                                            case "shadowBlur":
                                                return <Item
                                                    label={"阴影大小"}
                                                >
                                                    {CommonFormItems.getSliderAndInputItemGroup(["lineStyle","_emphasis","shadowBlur"], {
                                                        min: 0,
                                                        max: 20
                                                    })}
                                                </Item>
                                        }
                                    })
                                }}
                            </Item>
                        </Item>
                    </>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="节点标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="top">上</Select.Option>
                    <Select.Option value="bottom">下</Select.Option>
                    <Select.Option value="left">左</Select.Option>
                    <Select.Option value="right">右</Select.Option>
                    <Select.Option value="insideBottomRight">左上</Select.Option>
                    <Select.Option value="insideBottomLeft">右上</Select.Option>
                    <Select.Option value="insideTopRight">左下</Select.Option>
                    <Select.Option value="insideTopLeft">右下</Select.Option>
                    <Select.Option value="inside">标签内部</Select.Option>
                    <Select.Option value="outside">标签外部</Select.Option>
                </>,
                extendItems: <>
                    <Item
                        label={"与标记图形距离"}
                    >
                        {CommonFormItems.getSliderAndInputItemGroup(["label","distance"],{
                            min: 1,
                            max: 35,
                        })}
                    </Item>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="leaves_label"
            header="叶节点标签特殊配置"
        >
            <Item
                label={"图形颜色"}
                name={["leaves","itemStyle","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"文字颜色"}
                name={["leaves","label","color"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"位置"}
                name={["leaves","label","position"]}
            >
                <Select>
                    <Select.Option value="top">上</Select.Option>
                    <Select.Option value="bottom">下</Select.Option>
                    <Select.Option value="left">左</Select.Option>
                    <Select.Option value="right">右</Select.Option>
                    <Select.Option value="insideBottomRight">左上</Select.Option>
                    <Select.Option value="insideBottomLeft">右上</Select.Option>
                    <Select.Option value="insideTopRight">左下</Select.Option>
                    <Select.Option value="insideTopLeft">右下</Select.Option>
                    <Select.Option value="inside">标签内部</Select.Option>
                    <Select.Option value="outside">标签外部</Select.Option>
                </Select>
            </Item>
            <Item
                label={"与标记图形距离"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["leaves","label","distance"],{
                    min: 1,
                    max: 20,
                })}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataZoom"
            header="数据缩放"
        >
            <Item
                label={"启用缩放"}
                name={["dataZoom","roam"]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Item
                label={"默认缩放比例"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["dataZoom","zoom"],{
                    min: 0.1,
                    max: 10,
                    step: 0.1,
                    precision: 1
                })}
            </Item>
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据集
        dataSetConfig: {
            labelField: null,
            valueField: null,
            childrenField: null
        },
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
        },
        usual: {
            grid: {
                left: "5%",
                right: "15%",
                top: "10%",
                bottom: "10%"
            },
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            }
        },
        //标签
        label: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "bottom",
            _beforeTxt: "",
            _afterTxt: "",
            distance: 5,

            _emphasisSel: [],
            _emphasis: {},
        },
        //树图特殊
        treeSpecial:{
            layout: "orthogonal",
            orient: "LR",
            edgeShape: "curve",
            edgeForkPosition: 50,
            initialTreeDepth: -1,
            symbol: "circle",
            symbolSize: 10,
        },
        //缩放
        dataZoom: {
            roam: true,
            zoom: 1
        },
        //标记样式
        itemStyle: {
            _setType: "normal",
            color: "#FE7D14FF",
            borderWidth: 0,
            borderColor: null,
            borderType: "solid",
            shadowBlur: 0,
            shadowColor: null,

            _emphasisSel: [],
            _emphasis: {},
        },
        //连线样式
        lineStyle: {
            _setType: "normal",
            color: "#ccccccff",
            width: 3,
            curveness: 0.5,
            shadowBlur: 4,
            shadowColor: "#FE7D14FF",

            _emphasisSel: [],
            _emphasis: {},
        },
        //叶节点
        leaves: {
            label: {
                color: "#ffffffff",
                distance: 5,
                position: "right",
            },
            itemStyle: {
                color: "#42D0FFFF",
            }
        }
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 name, children, value 等 字段可以自定义。
    1.树图的数据结构，有层级关系，每一节点都是一个对象，可以嵌套多层。可以给叶节点(最后一层数据)配置数量字段。
        {
            name: "书籍销量概览",
            children: [
            {
                name: "小说书籍销量",
                children: [
                    {
                        name: "三体",
                        value: 2600, 
                    },
                    {
                        name: "追风筝的人",
                        value: 1200, 
                    },
                ]
            },
            {
                name: "历史书籍销量",
                children: [
                    {
                        name: "中华上下五千年",
                        value: 1560, 
                    },
                    {
                        name: "温莎王朝",
                        value: 120, 
                    },
                ]
            },
            {
                name: "教材书籍销量",
                children: [
                    {
                        name: "深入理解计算机系统",
                        value: 20, 
                    },
                ]
            }
        ]
        }
        
    2.也可以只传递数组，忽略第一层节点的名称，程序将会自动包装一层根节点。
        [
            {
                name: "小说书籍销量",
                children: [
                    {
                        name: "三体",
                        value: 2600, 
                    },
                    {
                        name: "追风筝的人",
                        value: 1200, 
                    },
                ]
            },
            {
                name: "历史书籍销量",
                children: [
                    {
                        name: "中华上下五千年",
                        value: 1560, 
                    },
                    {
                        name: "温莎王朝",
                        value: 120, 
                    },
                ]
            },
            {
                name: "教材书籍销量",
                children: [
                    {
                        name: "深入理解计算机系统",
                        value: 20, 
                    },
                ]
            }
        ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}