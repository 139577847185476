import React from "react";
import {
    Form,
    Radio,
    Input
} from "antd";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"开关大小"}
            name={["inputOption","size"]}
        >
           <Radio.Group>
               <Radio value={"small"}>小</Radio>
               <Radio value={"default"}>正常</Radio>
           </Radio.Group>
        </Item>
        <Item
            label={"标签名称"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"选中时的内容"}
            name={["inputOption","checkedChildren"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"非选中时的内容"}
            name={["inputOption","unCheckedChildren"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: null,
        inputOption: {
            size: "default",
            checkedChildren: "是",
            unCheckedChildren: "否",
        }
    }
}

export default {
    getSetItems,
    getDefaultValues,
}