import React from "react";
import {
    Form,
    Radio,
    Input,
} from "antd";
import NumberSelectOptionMake from "../showNumberSelect/NumberSelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"展示类型"}
            name={["selectOption","optionType"]}
        >
            <Radio.Group>
                <Radio value={"default"}>默认</Radio>
                <Radio value={"button"}>按钮模式</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues) => prevValues.selectOption.optionType !== currentValues.selectOption.optionType}
        >
            {({getFieldValue})=>{
                let type = getFieldValue(["selectOption","optionType"]);

                if(type === "button"){
                    return <>
                        <Item
                            label={"按钮大小"}
                            name={["selectOption","size"]}
                        >
                            <Radio.Group>
                                <Radio value={"small"}>小</Radio>
                                <Radio value={"middle"}>中</Radio>
                                <Radio value={"large"}>大</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            label={"按钮风格"}
                            name={["selectOption","buttonStyle"]}
                        >
                            <Radio.Group>
                                <Radio value={"solid"}>填色</Radio>
                                <Radio value={"outline"}>描边</Radio>
                            </Radio.Group>
                        </Item>
                    </>
                }
            }}
        </Item>
        <Item
            label={"标签"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"映射选项"}
            name={["listSpecial"]}
        >
            <NumberSelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: "请选择：",
        selectOption: {
            optionType: "default",
            size: "middle",
            buttonStyle: "solid",
        },
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}