import React from "react";
import ShowTree from "../../../../../edit/ShowTree";

const ShowTreeInDashboard = React.forwardRef((props, ref) => {
  return <ShowTree
    ref={ref} // 转发 ref 到 ShowCode
    {...props}
  />
});

export default ShowTreeInDashboard;