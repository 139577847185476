//仪表板主题配置
const CommonBackgroundModeSet = {
    mode: "color",  //背景类型  color  border  image  video  eachBorder
    color: "#060346",
    upload_imageUrl: null,
    upload_videoUrl: null,
    backgroundImgSize: "cover", //cover contain diy
    backgroundImgRepeat: "no-repeat", //repeat no-repeat
    borderBgSet:{
        width: 2,
        type: "solid",  //solid dashed dotted
    },
    borderSVGType: null,    //svg类型
    borderRight: null,  //选择差异边框eachBorder  的时候每个边的样式
    borderLeft: null,
    borderTop: null,
    borderBottom: null,
    backgroundImgSizeDiy:{
        width: 100,
        widthUnit: "%",
        height: 100,
        heightUnit: "%",
    },
    bgImgPositionSet:{
        top: 100,
        topUnit: "%",
        left: 100,
        leftUnit: "%",
    },
    bgVdoPositionSet: {
        objectFit:  "cover", //cover contain fill
        scale: 1,
        scaleOption: "center",  //"center"  "top left"  "bottom left"  "top right"  "bottom right"
        absoluteSet: {
            translateX: 0,
            translateXUnit: "%",
            translateY: 0,
            translateYUnit: "%",
        }
    },
}
const DefaultThemeConfig = {
    //通用
    commonSet: {
        colorMode: "dark", //色彩模式  light  dark
        padding: {  //容器外层壳的padding
            top: 5,
            topUnit: "px",//px rem
            left: 5,
            leftUnit: "px",
            right: 5,
            rightUnit: "px",
            bottom: 5,
            bottomUnit: "px",
        },
        refreshSet: {
            time: 5, //刷新频率
            unit: 60, //1秒  60 分钟  360 小时
        }
    },
    //仪表板背景
    dashboardBackgroundSet: {
        use: false,
        radiusSet: {
            num: 0,
            unit: "px" //px  %
        }, //圆角
        overflowHidden: false,//是否隐藏超出部分
        opacity: 100,   //不透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
        baseLaySet: { //基底颜色
            color: "#000000",
            opacity: 0
        },
        modeSet: CommonBackgroundModeSet
    },
    //容器背景
    contentBackgroundSet: {
        use: false,
        radiusSet: {
            num: 0,
            unit: "px"
        }, //圆角
        overflowHidden: false,//是否隐藏超出部分
        opacity: 100,   //透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
        baseLaySet: { //基底颜色
            color: "#000000",
            opacity: 0
        },
        modeSet: CommonBackgroundModeSet
    },
    //容器标题
    contentTitle: {
        show: true, //是否显示
        color: "#ffffff",
        fontSet: {
            fontFamily: "default",
            fontSize: 18,
            sizeUnit: "rem", //px rem
            wordSpacing: 10,
            fontBold: true, //加粗
            fontItalic: false, //倾斜
            lineHeight: 20,
        },
        padding: {
            top: 5,
            topUnit: "px",//px rem
            left: 10,
            leftUnit: "px",
            right: 10,
            rightUnit: "px",
            bottom: 5,
            bottomUnit: "px",
        },
        positionSet: {
            layoutMode: "flex", //absolute  flex
            writingMode: "lr-tb",   //lr-tb  tb-rl
            flexSet: {
                flexWhere: "top", //上下左右
                flexAlignItems: "center", //flex-start  center  flex-end
            },
            absoluteSet: {
                top: 0,
                topUnit: "%",//px %
                left: 0,
                leftUnit: "%",
                right: null,
                rightUnit: "%",
                bottom: null,
                bottomUnit: "%",
                translateX: 0,
                translateXUnit: "%",
                translateY: 0,
                translateYUnit: "%",
            }
        },
        iconSet: {
            use: false,
            iconW: 20,
            iconWUnit: "rem",//px rem
            iconH: 20,
            iconHUnit: "rem",
            spaceWidthTxt: 10,    //距离标题间隙
            spaceWidthTxtUnit: "rem",//px rem
            upload_imageUrl: null,
        }
    },
    //标题背景
    titlePlus: {
        use: false,
        radiusSet: {
            num: 0,
            unit: "px"
        }, //圆角
        overflowHidden: false,//是否隐藏超出部分
        opacity: 100,   //透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
        longSet: {
            num: 50,
            unit: "%", //px %
        },
        baseLaySet: { //基底颜色
            color: "#000000",
            opacity: 0
        },
        modeSet: CommonBackgroundModeSet,
    },
    //内容样式
    contentSet: {
        opacity: 100,
        padding: {
            top: 0,
            topUnit: "px",//px rem
            left: 0,
            leftUnit: "px",
            right: 0,
            rightUnit: "px",
            bottom: 0,
            bottomUnit: "px",
        },
        alignItems: "center",   //内部垂直方向对齐
        justifyContent: "center",   //内部水平方向对齐
        // cardStyle: {    //卡片样式设置
        //     titleColor: "#0000ff",     //标题颜色
        //     valueColor: "#ffffff"      //内容颜色
        // },
    },
    //内容字体
    contentFont: {
        color: "#ffffff",
        fontSet: {
            fontFamily: "default",
            fontSize: 14,
            sizeUnit: "px", //px rem
            wordSpacing: 4,
            fontBold: false, //加粗
            fontItalic: false, //倾斜
            lineHeight: 16,
        }
    },
    //场景设置
    sceneSet: {
        effects: "slide", //fade  slide cube  coverflow  flip  cards
        changeSet: {
            when: "time",   //time  定时  none  无
            delayTime: 3,
            delayTimeUnit: 60,   //1秒  60分钟
            disableOnInteraction: false,    //用户操作后停止
        },
        direction: "horizontal", //horizontal  vertical
        speed: 1000,
        loopMode: "rewind",
        allowTouchMove: true,   //是否允许鼠标拖拽切换
        keyboard: true,   //方向键切换
        paginationSet: {
            color: "#843CFF",
            show: false,
            type: "bullets",
            size: {
                num: 12,
                unit: "px"
            },
            bottom: {
                num: 5,
                unit: "%"
            },
            fontSet: {
                color: "#843CFF",
                fontFamily: "default",
                fontSize: 16,
                sizeUnit: "px", //px rem
                fontBold: true, //加粗
                fontItalic: false, //倾斜
            }
        },
        navigationSet: {
            color: "#843CFF",
            show: false,
            size: {
                num: 30,
                unit: "rem"
            },
        },
        scrollbarSet: {
            color: "#843CFF",
            show: false,
            size: {
                num: 10,
                unit: "px"
            },
        }
    },
    //场景背景
    sceneBackgroundSet: {
        use: false,
        radiusSet: {
            num: 0,
            unit: "px"
        }, //圆角
        overflowHidden: false,//是否隐藏超出部分
        opacity: 100,   //透明度
        brightness: 100,  //亮度
        blur: 0,  //模糊
        baseLaySet: { //基底颜色
            color: "#000000",
            opacity: 0
        },
        modeSet: CommonBackgroundModeSet
    },
    //导航栏设置
    navigationBarSet: {
        show: true,
        slidesPerView: 10,    //最多展示几个标题
        slidesPerViewMobile: 4,    //最多展示几个标题
        centerInsufficientSlides: false, //内容居中
        spaceBetween: 10,    //每个tab间距
        navHeight: 50,
        navHeightUnit: "px",
        navWidth: 100,
        navWidthUnit: "%",
        flexAlignItems: "center", //flex-start  center  flex-end
        padding: {
            top: 10,
            topUnit: "rem",//px rem
            left: 20,
            leftUnit: "rem",
            right: 20,
            rightUnit: "rem",
            bottom: 10,
            bottomUnit: "rem",
        },
        positionSet: {
            layoutMode: "flex", //absolute  flex
            flexSet: {
                flexWhere: "top", //上下
            },
            absoluteSet: {
                top: null,
                topUnit: "%",//px %
                left: 20,
                leftUnit: "%",
                right: 20,
                rightUnit: "%",
                bottom: 10,
                bottomUnit: "%",
                translateX: 0,
                translateXUnit: "%",
                translateY: 0,
                translateYUnit: "%",
            }
        },
        normalItemStyleSet: {
            show: true,
            fontSet: {
                color: "#ffffff",
                fontFamily: "default",
                fontSize: 14,
                sizeUnit: "px", //px rem
                fontBold: false, //加粗
                fontItalic: false, //倾斜
            },
            _setType: "normal",
            _emphasisSel: ["color"],   //新版本选中配置 - 2023.01.06  新增
            _emphasis: {
                color: "#843cff"
            },
        },
        activeItemStyleSet: {   //老版本选中配置，做了兼容
            show: true,
            fontSet: {
                color: "#843CFF",
                fontFamily: "default",
                fontSize: 14,
                sizeUnit: "px", //px rem
                fontBold: true, //加粗
                fontItalic: false, //倾斜
            }
        },
        normalItemBgSet: {
            use: true,
            radiusSet: {
                num: 0,
                unit: "rem"
            }, //圆角
            opacity: 30,   //透明度
            brightness: 100,  //亮度
            modeSet: {
                mode: "color",  //背景类型  color  border  image   eachBorder  injectImage
                color: "#000000",
                upload_imageUrl: null,
                backgroundImgSize: "cover", //cover contain diy
                borderBgSet:{
                    width: 2,
                    type: "solid",  //solid dashed dotted
                },
                backgroundImgSizeDiy:{
                    width: 100,
                    widthUnit: "%",
                    height: 100,
                    heightUnit: "%",
                },
            },
            _setType: "normal",
            _emphasisSel: ["opacity"],   //新版本选中配置 - 2023.01.06  新增
            _emphasis: {
                opacity: 100
            },
        },
        activeItemBgSet: {
            use: true,
            radiusSet: {
                num: 10,
                unit: "rem"
            }, //圆角
            opacity: 80,   //透明度
            brightness: 100,  //亮度
            modeSet: {
                mode: "color",  //背景类型  color  border  image   eachBorder  injectImage
                color: "#000000",
                upload_imageUrl: null,
                backgroundImgSize: "cover", //cover contain diy
                backgroundImgSizeDiy:{
                    width: 100,
                    widthUnit: "%",
                    height: 100,
                    heightUnit: "%",
                },
            }
        }
    },
    //导航条背景设置
    navigationBarBgSet: {
        use: true,
        radiusSet: {
            num: 0,
            unit: "rem"
        }, //圆角
        overflowHidden: false,//是否隐藏超出部分
        opacity: 100,   //透明度
        brightness: 100,  //亮度
        blur: 2,  //模糊
        baseLaySet: { //基底颜色
            color: "#000000",
            opacity: 0
        },
        modeSet: CommonBackgroundModeSet
    },
}

export default DefaultThemeConfig;