import React from "react";
import {
    Form,
    Input,
    Select,
    Tag,
    Radio,
    InputNumber
} from "antd";
import NumberSelectOptionMake from "../showNumberSelect/NumberSelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"选择框大小"}
            name={["selectOption","size"]}
        >
            <Radio.Group>
                <Radio value={"small"}>小</Radio>
                <Radio value={"middle"}>中</Radio>
                <Radio value={"large"}>大</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"提示语"}
            name={["selectOption","placeholder"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"最多显示多少个"}
            name={["selectOption","maxTagCount"]}
        >
            <InputNumber
                min={1}
                max={100}
                step={1}
                precision={0}
            />
        </Item>
        <Item
            label={"标签"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"映射选项"}
            name={["listSpecial"]}
        >
            <NumberSelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: "请选择：",
        selectOption: {
            size: "middle",
            placeholder: "请选择内容",
            maxTagCount: 10,
        },
        list: [],
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}