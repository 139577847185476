import React from "react";
import ReactDom from "react-dom";
import styles from "./labelCloud.less";
import {Empty} from "antd";
import classnames from "classnames";

const Colors = ['#21eff5','#8a00ff','#c4e15e','#e744ed','#0084ff','#00ffcc','#40efa7','#72e054','#f0d360','#c6a3ff','#00eaff','#6748ea','#59e2a9']

export default class LabelCloud extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            ready: true,
            ifEmpty: false,
            upDate: 0
        }

        this.labels = [];
    }

    componentDidMount(){
    }

    componentWillUnmount(){
        this.loopShowLabels && clearInterval(this.loopShowLabels);
        this.shineAction && clearTimeout(this.shineAction);
    }

    ifReady(){
        return this.state.ready;
    }

    upView(){
        this.setState({
            upDate: this.state.upDate + 1
        })
    }

    paint(labels){
        if(!labels){
            labels = [];
        }


        this.setState({
            ifEmpty: !labels?.length
        })

        if(labels.length> 50){
            this.labels = labels;
            //随机取50个，自动切换
            this.loopShowLabels && clearInterval(this.loopShowLabels);

            let rePaint = ()=>{
                let index = parseInt(Math.random()*(this.labels.length - 50));
                let needRenderList = this.labels.slice(index,index + 50);
                this.paintAction(needRenderList)

                return rePaint;
            }
            this.loopShowLabels = setInterval(rePaint(),15000)
        }else{
            this.paintAction(labels)
        }
    }

    paintAction(needRenderList){
        let colorArray = Colors;
        let colorSelType = this.props.colorSelType || "random";
        if(this.props.colorArray?.length){
            colorArray = this.props.colorArray;
        }

        let div = ReactDom.findDOMNode(this.cloudBoxDom);
        let height = div.clientHeight;
        let data = needRenderList.map((txt,index)=>{
            if(typeof txt === "object"){
                let color = txt.color;
                if(!color){
                    if(colorSelType === "random"){
                        // 颜色随机
                        color = colorArray[parseInt(Math.random()*(colorArray.length))]
                    }else{
                        color = colorArray[index%colorArray.length]
                    }
                }
                return {
                    label: txt.label,
                    fontColor: color
                }
            }else{
                let color;
                if(colorSelType === "random"){
                    // 颜色随机
                    color = colorArray[parseInt(Math.random()*(colorArray.length))]
                }else{
                    color = colorArray[index%colorArray.length]
                }
                return {
                    label: txt,
                    fontColor: color,
                }
            }
        });

        let seting = {
            entries: data,//数据
            width: '100%', //宽度
            height: height, // 高度
            radius: '90%', // 圆角大小
            bgDraw : false, // 是否显示背景
            bgColor : "#000", // 背景颜色
            speed: typeof this.props.speed === "number"?this.props.speed:0.3, // 速度
            opacityOut : .4 // 透明度
        };

        this.upView();
        this.shineAction = setTimeout(()=>{
            new window.SVG3DTagCloud(div,seting)
        },500)
    }

    render(){
        let {ifEmpty,upDate} = this.state;
        return <div
                className={classnames({
                    [styles.labelCloudOuter]: true,
                    [styles.labelCloudShineOne]: upDate%2 === 0,
                    [styles.labelCloudShineTwo]: upDate%2 === 1,
                })}
        >
            <div
                className={styles.labelCloudContainer}
                ref={ref=>this.cloudBoxDom = ref}
                style={{opacity: ifEmpty? 0:1}}
            >
            </div>
            <div className={styles.emptyBox}>
                {
                    ifEmpty
                        ?<Empty description={this.props.emptyMsg || "该数字人设备暂无标签"}/>
                        :null
                }
            </div>
        </div>
    }
}