import React from "react";
import ShowListData from "../../../../../scriptModule/metaDataManager/ShowListData";

const ShowTable = React.forwardRef((props, ref) => {
  return <ShowListData
    ref={ref} // 转发 ref 到 ShowListData
    cols={null}
    values={[]}
    hidePagination={false}
    needMatchCols={true}
    contentMsg={props.contentMsg}
    styleDiy={props.contentMsg?.dataConfig?.contentParams}
    hideFooter={props.contentMsg?.dataConfig?.contentParams?.hideFooter}
    hideOnSinglePage={props.contentMsg?.dataConfig?.contentParams?.hideOnSinglePage}
    imgColArr={props.contentMsg?.dataConfig?.contentParams?.imgColArr}
    imgSize={props.contentMsg?.dataConfig?.contentParams?.imgSize || 30}
    autoTableLayout={props.contentMsg?.dataConfig?.contentParams?.autoTableLayout || false}
    {...props}/>
});

export default ShowTable;