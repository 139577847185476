import React from "react";
import {Select} from "antd";

export default class NumberSelectShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let optionsSpecial = []
        if(contentParams.listSpecial?.length){
            optionsSpecial = contentParams.listSpecial.filter((item)=>{
                return !!item.label
            })
        }

        return <div
            style={{width: "100%",
                display: "flex",
                alignItems: "center",
            }}
            className={"swiper-no-swiping"}
        >
            {
                contentParams.label
                    ?<span>{contentParams.label}</span>
                    :null
            }
            <div
                style={{flex: 1}}
            >
                <Select
                    style={{width: "100%"}}
                    allowClear={true}
                    {...contentParams.selectOption}
                    defaultValue={parameterValue}
                    showSearch={true}
                    filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onChange={(v)=>{
                        this.props.valueChanged?.(v,parameterValue)
                    }}
                >
                    {optionsSpecial.map((item,index)=>{
                        return <Select.Option
                            key={"str_" + item.value + index}
                            value={item.value}
                        >
                            {item.label}
                        </Select.Option>
                    })}
                </Select>
            </div>
        </div>
    }
}