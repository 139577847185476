import React from "react";
import {
  FieldStringOutlined,
  FieldNumberOutlined,
  PoweroffOutlined,
  SlidersOutlined,
  ApiOutlined,
  DownSquareOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  SearchOutlined
} from "@ant-design/icons";
import StringSet from "./showString/StringSet";
import StringShow from "./showString/StringShow";
import StringSelectSet from "./showStringSelect/StringSelectSet";
import StringSelectShow from "./showStringSelect/StringSelectShow";
import StringRadioSet from "./showStringRadio/StringRadioSet";
import StringRadioShow from "./showStringRadio/StringRadioShow";
import NumberSet from "./showNumber/NumberSet";
import NumberShow from "./showNumber/NumberShow";
import NumberSelectSet from "./showNumberSelect/NumberSelectSet";
import NumberSelectShow from "./showNumberSelect/NumberSelectShow";
import NumberRadioSet from "./showNumberRadio/NumberRadioSet";
import NumberRadioShow from "./showNumberRadio/NumberRadioShow";
import SliderSet from "./showSlider/SliderSet";
import SliderShow from "./showSlider/SliderShow";
import SwitchSet from "./showSwitch/SwitchSet";
import SwitchShow from "./showSwitch/SwitchShow";
import StringArrayCheckboxSet from "./showStringArrayCheckbox/StringArrayCheckboxSet";
import StringArrayCheckboxShow from "./showStringArrayCheckbox/StringArrayCheckboxShow";
import StringArraySelectSet from "./showStringArraySelect/StringArraySelectSet";
import StringArraySelectShow from "./showStringArraySelect/StringArraySelectShow";
import NumberArrayCheckboxSet from "./showNumberArrayCheckbox/NumberArrayCheckboxSet";
import NumberArrayCheckboxShow from "./showNumberArrayCheckbox/NumberArrayCheckboxShow";
import NumberArraySelectSet from "./showNumberArraySelect/NumberArraySelectSet";
import NumberArraySelectShow from "./showNumberArraySelect/NumberArraySelectShow";
import UrlParamSet from "./showUrlParam/UrlParamSet";
import UrlParamShow from "./showUrlParam/UrlParamShow";

//参数内容的注册
const ParameterContentArray = [
  {
    groupName: "文本类型",  //分组名称
    children: [
      {
        title: '文本输入框',   //名称
        type: "parameter_string",   //匹配类型
        icon: <FieldStringOutlined />,  //小图标
        cover: require("./showString/cover.png"),  //封面图
        renderFile: StringShow, //渲染视图文件
        setFile: StringSet,  //配置文件
        valueType: "String",  //值类型
        allowSetValue: true,  //是否允许外部设置值
      },
      {
        title: '文本下拉单选',
        type: "parameter_stringSelect",
        icon: <DownSquareOutlined />,
        cover: require("./showStringSelect/cover.png"),
        renderFile: StringSelectShow,
        setFile: StringSelectSet,
        valueType: "String",
        allowSetValue: true,
      },
      {
        title: '文本平铺单选',
        type: "parameter_stringRadio",
        icon: <CheckCircleOutlined />,
        cover: require("./showStringRadio/cover.png"),
        renderFile: StringRadioShow,
        setFile: StringRadioSet,
        valueType: "String",
        allowSetValue: false,
      },
    ]
  },
  {
    groupName: "数字类型",
    children: [
      {
        title: '数字输入框',
        type: "parameter_number",
        icon: <FieldNumberOutlined />,
        cover: require("./showNumber/cover.png"),
        renderFile: NumberShow,
        setFile: NumberSet,
        valueType: "Number",
        allowSetValue: true,
      },
      {
        title: '数字下拉单选',
        type: "parameter_numberSelect",
        icon: <DownSquareOutlined />,
        cover: require("./showNumberSelect/cover.png"),
        renderFile: NumberSelectShow,
        setFile: NumberSelectSet,
        valueType: "Number",
        allowSetValue: false,
      },
      {
        title: '数字平铺单选',
        type: "parameter_numberRadio",
        icon: <DownSquareOutlined />,
        cover: require("./showNumberRadio/cover.png"),
        renderFile: NumberRadioShow,
        setFile: NumberRadioSet,
        valueType: "Number",
        allowSetValue: false,
      },
      {
        title: '滑动条',
        type: "parameter_slider",
        icon: <SlidersOutlined />,
        cover: require("./showSlider/cover.png"),
        renderFile: SliderShow,
        setFile: SliderSet,
        valueType: "Number",
        allowSetValue: false,
      },
    ]
  },
  {
    groupName: "真假类型",
    children: [
      {
        title: '开关控件',
        type: "parameter_switch",
        icon: <PoweroffOutlined />,
        cover: require("./showSwitch/cover.png"),
        renderFile: SwitchShow,
        setFile: SwitchSet,
        valueType: "Boolean",
        allowSetValue: false,
      },
    ]
  },
  {
    groupName: "多选类型(数组)",
    children: [
      {
        title: '文本平铺多选',
        type: "parameter_stringArrayCheckbox",
        icon: <CheckSquareOutlined />,
        cover: require("./showStringArrayCheckbox/cover.png"),
        renderFile: StringArrayCheckboxShow,
        setFile: StringArrayCheckboxSet,
        valueType: "Array_String",
        allowSetValue: false,
      },
      {
        title: '文本下拉多选',
        type: "parameter_stringArraySelect",
        icon: <DownSquareOutlined />,
        cover: require("./showStringArraySelect/cover.png"),
        renderFile: StringArraySelectShow,
        setFile: StringArraySelectSet,
        valueType: "Array_String",
        allowSetValue: false,
      },
      {
        title: '数字平铺多选',
        type: "parameter_numberArrayCheckbox",
        icon: <CheckSquareOutlined />,
        cover: require("./showNumberArrayCheckbox/cover.png"),
        renderFile: NumberArrayCheckboxShow,
        setFile: NumberArrayCheckboxSet,
        valueType: "Array_Number",
        allowSetValue: false,
      },
      {
        title: '数字下拉多选',
        type: "parameter_numberArraySelect",
        icon: <DownSquareOutlined />,
        cover: require("./showNumberArraySelect/cover.png"),
        renderFile: NumberArraySelectShow,
        setFile: NumberArraySelectSet,
        valueType: "Array_Number",
        allowSetValue: false,
      },
    ]
  },
  {
    groupName: "特殊文本",
    children: [
      {
        title: '地址栏参数',
        type: "parameter_urlParam",
        icon: <SearchOutlined />,
        cover: require("./showUrlParam/cover.png"),
        renderFile: UrlParamShow,
        setFile: UrlParamSet,
        valueType: "String",
        allowSetValue: false,
      },
    ]
  },
]

export {ParameterContentArray};