import React from 'react';
import ListPage from '../../base/ListPage.js';
import less from './RomRuleManage.less';
import APILXD from '../../../http/APILXD.js';
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index"
import {Button,message} from "antd";
import HttpTool from "../../../tool/HttpTool";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

class RomRuleList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                title: "分类列表",
                columns: this.getTableColumns(),
                url: APILXD.getClassificationList,
                otherParam: null,
            },
            search: {
                option: {
                    placeholder: '请输入分类名称搜索',
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        value.name = value.keyword
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '新建分类',
                action: () => {
                    this.addNewGroup()
                }
            },
            extraBox: ()=>{
                return <div style={{float:"right"}}>
                    <Button
                      type={"primary"}
                      style={{marginLeft: "20px"}}
                      onClick={()=>{
                          this.renewal()
                      }}
                    >
                        更新
                    </Button>
                </div>
            }
        }
    }

    renewal(){
        this.setState({
            loading: true
        },()=>{
            HttpTool.post(APILXD.renewal, (code, msg, json) => {
                message.success(msg)
                this.setState({
                    loading: false
                })
            }, (code, msg) => {
                message.error(msg);
                this.setState({
                    loading: false
                })
            }, {});
        })
    }

    addNewGroup(data){
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[a-zA-Z0-9]{6,12}$/,
            verMessage: "6-12位数字或英文字母",
            required: true,
        };
        MB.show(
          {
              title: data?"编辑分类":"新增分类",
              okTitle: "确定",
              closeTitle: "取消",
              width: "60%"
          },
          {
              colCount: 1,
              formItemLayout: {
                  labelCol: {span: 4},
                  wrapperCol: {span: 11, offset: 0},
              },
              parameterArr: [
                  {
                      ...defaultOption,
                      field: "name",
                      name: "分类名称",
                      reg: /^[\S\s]{1,32}$/,
                      verMessage: "1-32个字符",
                      option:{
                          maxLength: 32,
                          defaultValue: data?.name
                      },
                  },
              ],
          },
          {
              url: data?APILXD.updateClassification:APILXD.insertClassification,
              otherParam: data?{_id: data._id}:{},
              beforeSubmit: (param) => {


                  return true;
              },
              callBack: (state, msg) => {
                  //添加成功回调
                  state == 'success' && this._loadDataForNet();
              }
          });
    }


    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '分类名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '规则数量',
                dataIndex: 'num',
                key: 'num',
            },
            {
                title: '添加/更新时间',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render:(text,record)=>{
                    return <div>{TimeHelp.getYMDHM(record.updateTime)}</div>
                }
            },
            {
                title: '操作',
                width: 250,
                render: (text, record, index) => {
                    return (<div>
                        {
                            this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.addNewGroup(record)
                                }}
                              >编辑
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Delete
                              ?(<div
                                className={less.btnDelete}
                                onClick={() => {
                                    ModalDelete.show({
                                          title: "提示",
                                          okTitle: "确定",
                                          closeTitle: "取消",
                                      },
                                      {}
                                      ,
                                      {
                                          otherParam: {_id: record._id},
                                          content: record.name,
                                          url: APILXD.deleteClassification,
                                          apiType: 'post',
                                          tip: '删除后该分类内的信息将一并删除，确定删除吗？',
                                          callBack: (state) => {
                                              //删除成功回调
                                              state === "success" && this._loadDataForNet();

                                              this.props.onDeleteGroup?.(record._id)
                                          }
                                      });
                                }}
                              >删除
                              </div>)
                              :null
                        }
                        {
                            this.powerConfig.Edit
                              ?(  <div
                                className={less.btnEdit}
                                onClick={() => {
                                    this.props.manageWords(record)
                                }}
                              >管理规则->
                              </div>)
                              :null
                        }
                    </div>)
                }
            },
        ]
    }
}

module.exports = RomRuleList;