import React from "react";
import {
    Form,
    Divider,
    Input,
    Radio,
    AutoComplete
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import ColorArray from "../../../formDiyItem/ColorArray";

const Item = Form.Item;

function getSetItems(extendData= {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"词语字段"}
            name={"labelField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"颜色字段"}
            name={"colorField"}
            rules={[{required: false}]}
            extra={"若打算手动配置颜色，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            动画效果设置
        </Divider>
        <Item
            label={"转动速度"}
            name={"speed"}
            rules={[{required: false}]}
        >
            {CommonFormItems.getSliderAndInputItemGroup("speed",{
                min: 0,
                max: 2,
                step: 0.1,
                precision: 1,
            })}
        </Item>
        <Item
            label={"颜色采用方式"}
            name={"colorSelType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="random">随机获取</Radio>
                <Radio value="next">依次获取</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"手动配置颜色"}
            name={"colorArray"}
            rules={[{required: false}]}
            extra={"若不配置颜色，则会使用随机色"}
        >
            <ColorArray
                max={10}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        labelField: null,
        colorField: null,
        speed: 0.3,
        colorArray: ['#21eff5','#8a00ff','#c4e15e','#e744ed','#0084ff','#00ffcc','#40efa7','#72e054','#f0d360','#c6a3ff','#00eaff','#6748ea','#59e2a9'],
        colorSelType: "random", //random  next
    }
}

function getDemoCode(){
    return `
    以下示例中 word、color 均可在 数据对接设置 中修改替换成其它单词
      1.最简略的词云数据，无需对接字段，直接返回 文本数组
    ["文案A","文案B","文案C",...]
    
    2.可返回对象数组，并与设置的词语字段 对接 
    [
        {
            word: "文案A",
        },
        {
            word: "文案B",
        },
        ...
    ]
    
     3.每个词汇采用自定义颜色
    [
        {
            word: "文案A",
            color: "red",
        },
        {
            word: "文案B",
            color: "#ff00ff",
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}