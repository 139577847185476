import React from "react";
import HttpTool from "../../../../../tool/HttpTool";
import {message, Tooltip, Switch, Space, Input, Cascader, Empty, Drawer, Button, Modal,Segmented} from "antd";
import styles from "./CustomRegister.less";
import {
  PlusOutlined,
  BarsOutlined,
  AppstoreOutlined,
  SearchOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import AllContentMap, {ViewContentArray} from "../AllContentDefinition";
import _ from "lodash";
import {UsageToCodeMap} from "./UsageDictionary";
import DataGetHelp from "../../../dataSet/components/dataSet/DataGetHelp";
import CustomOneShow from "./CustomOneShow";
import DataBindAndPreview from "./DataBindAndPreview";
import EmptyView from "../../../../../components/EmptyView/index";
import APILXD from "../../../../../http/APILXD";

export default class CustomRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registerList: null, //已经注册的自定义组件
      filterName: null,
      filterType: null,
      ifListShowCard: true,
      selectContent: null,  //选中的
      openDrawer: false,
      originData:null,
    }

    this.DataGetHelp = new DataGetHelp();
    this.powerConfig = props.powerConfig;
  }

  componentDidMount() {
    this.getListData()
  }

  //请求自定义组件列表
  getListData() {
    HttpTool.post(APILXD.getCustomModuleList, (code, msg, json, option) => {
      this.setState({
        registerList: json
      })

    }, (code, msg) => {
      message.error(msg)
    }, {
      businessType: UsageToCodeMap[this.props.usage]
    })
  }


  //新增
  addOne() {
    this.setState({
      selectContent: null,
      originData: null,
      openDrawer: true
    })
  }

  //左侧已注册组件的视图
  listShow() {
    let {ifListShowCard, filterName, filterType, registerList, selectContent} = this.state;

    if (!registerList?.length) {
      return <div
        className={styles.emptyMsg}
      >
        <Empty
          description={"暂无注册的组件"}
        />
      </div>
    }

    let list = registerList;
    let matchStr = _.trim(filterName)
    if (matchStr || filterType) {
      list = list.filter((eachItem) => {
        let ifMatch = true;

        if (matchStr) {
          ifMatch = eachItem.name.toLowerCase().includes(matchStr)
        }

        if (ifMatch && filterType) {
          ifMatch = eachItem.contentMsg?.dataConfig?.contentType === filterType
        }


        return ifMatch
      })
    }

    if (!list?.length) {
      return <div
        className={styles.emptyMsg}
      >
        <Empty
          description={"筛选后无组件"}
        />
      </div>
    }

    if (ifListShowCard) {
      //card模式
      return list?.map((eachChart, index) => {
        let coverUrl;
        if (eachChart.contentMsg?.dataConfig?.coverUrl) {
          coverUrl = eachChart.contentMsg?.dataConfig?.coverUrl;
        } else if (eachChart.contentMsg?.dataConfig?.contentType) {
          coverUrl = AllContentMap[eachChart.contentMsg.dataConfig.contentType]?.cover
        }


        return <div
          key={"c_" + eachChart._id}
          className={selectContent?._id === eachChart._id ? styles.chartItemBoxSel : styles.chartItemBox}
          onClick={() => {
            this.setState({
              selectContent: eachChart
            })
          }}
        >
          <div
            className={styles.chartTitle}
          >{eachChart.name}</div>
          {
            coverUrl
              ? <div
                className={styles.chartCover}
                style={{
                  backgroundImage: `url(${coverUrl})`
                }}
              >
              </div>
              : <div
                className={styles.chartCover}
              >
                暂无封面
              </div>
          }
        </div>
      })
    } else {
      //list模式
      return list?.map((eachChart, index) => {
        return <div
          key={"l_" + eachChart._id}
          className={selectContent?._id === eachChart._id ? styles.chartItemBoxSel : styles.chartItemBox}
          onClick={() => {
            this.setState({
              selectContent: eachChart
            })
          }}
        >
          <div
            className={styles.chartTitle}
          >{eachChart.name}</div>
        </div>
      })
    }
  }

  render() {
    let {ifListShowCard, filterName, filterType, selectContent, openDrawer,originData} = this.state;

    return <div
      className={styles.outerBox}
    >
      <div
        className={styles.leftBox}
      >
        <div className={styles.leftTopBar}>
          <div className={styles.leftTitle}>自定义组件</div>
          <Space>
            <Segmented
              options={[
                {
                  value: 'list',
                  icon: <BarsOutlined />,
                },
                {
                  value: 'card',
                  icon: <AppstoreOutlined />,
                },
              ]}
              value={ifListShowCard?"card":"list"}
              onChange={(value)=>{
                this.setState({
                  ifListShowCard: value==="list"?false:true
                })
              }}
            />
            {
              this.powerConfig.New
                ?<div
                  className={styles.addOneBtn}
                  onClick={() => {
                    this.addOne();
                  }}
                >
                  <Tooltip placement={'top'} title={'新增自定义组件'}>
                    <PlusOutlined
                    />
                  </Tooltip>
                </div>
                :null
            }
          </Space>
        </div>
        <div className={styles.leftTopBar}>
          <Input
            szie={"small"}
            prefix={<SearchOutlined/>}
            style={{flex: 1, flexShrink: 0}}
            placeholder={"输入名称过滤"}
            value={filterName}
            onChange={(e) => {
              this.setState({
                filterName: e.target.value
              })
            }}
          />
          <Cascader
            szie={"small"}
            style={{flex: 1, flexShrink: 0}}
            placeholder={"选择类型过滤"}
            displayRender={(label, selectedOptions) => {
              return label.pop()
            }}
            showSearch={{
              filter: (inputValue, path) =>
                path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
            }}
            expandTrigger={"hover"}
            value={filterType}
            onChange={(value, selectedOptions) => {
              this.setState({
                filterType: value
              })
            }}
            options={
              ViewContentArray.map((group) => {
                return {
                  label: group.groupName,
                  value: group.groupName,
                  children: group.children?.map((chart) => {
                    return {
                      label: chart.title,
                      value: chart.type,
                    }
                  })
                }
              })
            }
          />
        </div>
        <div
          className={styles.listShow}
        >
          {this.listShow()}
          <div className={styles.chartItemBoxEmpty}/>
        </div>
      </div>
      {
        selectContent
          ? <div className={styles.rightBox}>
            <div className={styles.rightTopBar}>
              <div className={styles.rightTitle}>{selectContent.name}</div>
              <Space size={10}>
                {
                  this.powerConfig.Edit
                    ?<Button
                      size={"small"}
                      type={"primary"}
                      onClick={() => {
                        this.setState({
                          openDrawer: true
                        })
                      }}
                    >编辑图表</Button>
                    :null
                }
                {
                  this.powerConfig.Delete
                    ?<Tooltip
                      title={"删除"}
                    >
                      <Button
                        icon={<DeleteOutlined style={{fontSize: "16px"}}/>}
                        size={"small"}
                        type={"text"}
                        onClick={() => {
                          this.delete(selectContent)
                        }}
                      />
                    </Tooltip>
                    :null
                }
              </Space>
            </div>
            <CustomOneShow
              key={selectContent._id + selectContent.updateTime}
              selectContent={selectContent}
              onSave={(newEditChartItem) => {
                console.log(newEditChartItem)
                this.saveCustomComponent(newEditChartItem)
              }}
              originDataGetCB={(originData)=>{
                this.setState({
                  originData: originData
                })
              }}
            />
          </div>
          : <div
            className={styles.emptyMsg}
          >
            <EmptyView
              title={"请选择或新增自定义组件编辑"}
            />
          </div>
      }
      <Drawer
        visible={openDrawer}
        placement="right"
        destroyOnClose={true}
        width={'100%'}
        drawerStyle={{backgroundColor: '#282B3A'}}
        bodyStyle={{
          padding: '0',
        }}
        closable={false}
        onClose={() => {
          this.setState({
            openDrawer: false,
          });
        }}
      >
        {openDrawer ? (
          <DataBindAndPreview
            usage={this.props.usage}
            key={'db_' + selectContent?._id}
            ref={(ref) => (this.dataBindAndPreview = ref)}
            localSaveKey={selectContent?._id || "noIdForSel"}
            editChartItem={selectContent}
            onCancel={() => {
              this.setState({
                openDrawer: false,
              });
            }}
            onSave={(newEditChartItem) => {
              console.log("新的配置", newEditChartItem)
              this.saveCustomComponent(newEditChartItem);
            }}
            originData={originData}
          />
        ) : null}
      </Drawer>
    </div>
  }

  //删除
  delete(selectContent) {
    Modal.confirm({
      title: "提示",
      content: `是否删除自定义组件 ${selectContent.name} ？`,
      onOk: () => {
        HttpTool.post(APILXD.delCustomModule, (code, msg, json) => {
          this.setState({
            selectContent: null
          })
          this.getListData()
        }, (code, msg) => {
          message.error(msg)
        }, {
          moduleIds: [selectContent._id],
          businessType: UsageToCodeMap[this.props.usage]
        })
      }
    })
  }

  //保存
  saveCustomComponent(newEditChartItem) {
    let url = APILXD.editOrAddCustomModule;
    newEditChartItem.businessType = UsageToCodeMap[this.props.usage]

    if (!newEditChartItem.businessType) {
      message.warning("使用范围错误！")
      return;
    }

    HttpTool.post(url, (code, msg, json) => {
      this.getListData();

      if (json?._id) {
        this.setState({
          openDrawer: false,
          selectContent: json
        })
      } else {
        this.setState({
          openDrawer: false,
          selectContent: newEditChartItem
        })
      }

    }, (code, msg, json) => {
      message.error(msg)
    },newEditChartItem)
  }
}