import React, {Component} from 'react';
import styles from "./ImgList.less";
import CommonViewDiv from "../../../CommonViewDiv";
import {message} from "antd";

export default class ImgList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listData: []
        }
    }


    showValue(value){
        this.setState({
            listData: value
        })
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        let {listData} = this.state;

        if(!listData?.length){
            return null
        }

        if(!Array.isArray(listData)){
            return "传入的数据不是数组类型";
        }

        if(contentParams.typesetting === "floatType"){
            //流式 (也是flex布局实现)
            return <div
                className={styles.outerBox}
                style={{
                    gap: `${window.changeRemNum(contentParams.gapSet,"gapV","gapVUnit")} ${window.changeRemNum(contentParams.gapSet,"gapH","gapHUnit")}`
                }}
            >
                {listData.map((eachItem,index)=>{
                    let url;
                    let name;
                    let jumpUrlField;
                    if(typeof eachItem === "string"){
                        //直接传了地址
                        url = eachItem;
                    }else{
                        url = eachItem[contentParams.urlField];
                        name = eachItem[contentParams.nameField];
                        jumpUrlField = eachItem[contentParams.jumpUrlField];
                    }

                    if(url && url.indexOf("oss") !== -1){
                        url = url + "?x-oss-process=image/resize,w_250"
                    }

                    return <div
                        key={"ea_" +index}
                        className={styles.eachBox}
                        style={{
                            display: "flex",
                            gap: window.changeRemNum(contentParams.nameSet,"gapWithImg","gapUnit")
                        }}
                        onClick={()=>{
                            if(this.props.showType === "dev"){
                                message.info("录制模式不进行跳转");
                            }else{
                                if(jumpUrlField){
                                    window.open(jumpUrlField,"_black");
                                }else{
                                    message.info("无跳转地址");
                                }
                            }
                        }}
                    >
                        {
                            name && contentParams.nameSet?.position !== "insideBottom"
                                ?<div
                                    className={styles.nameBox}
                                    style={this.getNameStyleObj(contentParams)}
                                >
                                    {name}
                                </div>
                                :null
                        }
                        <div
                            className={styles.imgBox}
                            style={{
                                order: 5,
                                width: window.changeRemNum(contentParams.floatSet,"width","widthUnit"),
                                height: window.changeRemNum(contentParams.floatSet,"height","heightUnit"),
                                borderRadius: contentParams.borderSet?.radius + contentParams.borderSet?.radiusUnit,
                                border: `${window.changeRemNum(contentParams.borderSet,"width","widthUnit")} solid ${contentParams.borderSet?.color}`
                            }}
                        >
                            <img src={url}
                                 crossOrigin={"anonymous"}
                                 style={{
                                     objectFit: 'contain',
                                     width: "100%",
                                     height: "100%",
                                 }}
                            />
                            {
                                name && contentParams.nameSet?.position === "insideBottom"
                                  ?<div
                                    className={styles.nameBox}
                                    style={this.getNameStyleObj(contentParams)}
                                  >
                                      {name}
                                  </div>
                                  :null
                            }
                        </div>
                    </div>
                })}
                <div className={styles.emptyBox}></div>
            </div>
        }else{
            //flex布局
            let needNum = contentParams.flexSet.rowNum * contentParams.flexSet.colNum || 0;
            let width = Math.floor(10000/contentParams.flexSet.colNum)/100 + "%";
            let height = Math.floor(10000/contentParams.flexSet.rowNum)/100 + "%";

            return <div
                className={styles.outerBox}
            >
                {
                    listData.slice(0,needNum).map((eachItem,index)=>{
                        let url;
                        let name;
                        let jumpUrlField;
                        if(typeof eachItem === "string"){
                            //直接传了地址
                            url = eachItem;
                        }else{
                            url = eachItem[contentParams.urlField];
                            name = eachItem[contentParams.nameField];
                            jumpUrlField = eachItem[contentParams.jumpUrlField];
                        }

                        if(url && url.indexOf("oss") !== -1){
                            url = url + "?x-oss-process=image/resize,w_250"
                        }

                        return <div
                            key={"ea_" +index}
                            className={styles.eachBoxTwo}
                            style={{
                                display: "flex",
                                width: width,
                                height: height,
                                justifyContent: contentParams.flexSet?.justifyContent || "center",
                                gap: window.changeRemNum(contentParams.nameSet,"gapWithImg","gapUnit"),
                                padding: `${window.changeRemNum(contentParams.gapSet,"gapV","gapVUnit")} ${window.changeRemNum(contentParams.gapSet,"gapH","gapHUnit")}`

                            }}
                            onClick={()=>{
                                if(this.props.showType === "dev"){
                                    message.info("录制模式不进行跳转");
                                }else{
                                    if(jumpUrlField){
                                        window.open(jumpUrlField,"_black");
                                    }else{
                                        message.info("无跳转地址");
                                    }
                                }
                            }}
                        >
                            {
                                name && contentParams.nameSet?.position !== "insideBottom"
                                    ?<div
                                        className={styles.nameBox}
                                        style={this.getNameStyleObj(contentParams)}
                                    >
                                        {name}
                                    </div>
                                    :null
                            }
                            <div
                                className={styles.imgBoxTwo}
                                style={{
                                    order: 5,
                                    borderRadius: contentParams.borderSet?.radius + contentParams.borderSet?.radiusUnit,
                                    border: `${window.changeRemNum(contentParams.borderSet,"width","widthUnit")} solid ${contentParams.borderSet?.color}`
                                }}
                            >
                                <div className={styles.imgPos}>
                                    <img src={url}
                                         crossOrigin={"anonymous"}
                                         style={{
                                             objectFit: 'contain',
                                             width: "100%",
                                             height: "100%",
                                         }}
                                    />
                                </div>
                                {
                                    name && contentParams.nameSet?.position === "insideBottom"
                                        ?<div
                                            className={styles.nameBox}
                                            style={this.getNameStyleObj(contentParams)}
                                        >
                                            {name}
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    })
                }

                <div className={styles.emptyBox}></div>
            </div>
        }
    }

    getNameStyleObj(contentParams){
        let base = CommonViewDiv.getTextStyleObj(contentParams.fontSet);

        if(contentParams.typesetting === "floatType"){
            base.width = window.changeRemNum(contentParams.floatSet,"width","widthUnit");
        }

            base.display = "block";

        if(contentParams.nameSet.position === "insideBottom"){
            //需要绝对定位
            base.position = "absolute";
            base.left = 0;
            base.right = 0;
            base.bottom = window.changeRemNum(contentParams.nameSet,"gapWithImg","gapUnit");
        }else{
            base.order = contentParams.nameSet?.position === "bottom"?10:0;
        }

        return base
    }
}