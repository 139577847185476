import React from "react";
import {
    Form,
    Input,
    Select,
    Tag
} from "antd";
import SelectOptionMake from "../showStringSelect/SelectOptionMake";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"标签"}
            name={["label"]}
        >
            <Input
                maxLength={20}
            />
        </Item>
        <Item
            label={"普通选项"}
            name={["list"]}
        >
            <Select
                mode={"tags"}
                allowClear={true}
                placeholder={"请填写定义平铺选项"}
                tagRender={(props) => {
                    return <Tag
                        color={"cyan"}
                        closable={props.closable}
                        style={{marginRight: 3}}
                        onClose={props.onClose}
                    >
                        {props.label}
                    </Tag>
                }}
                maxTagTextLength={30}
            ></Select>
        </Item>
        <Item
            label={"映射选项"}
            name={["listSpecial"]}
        >
            <SelectOptionMake/>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        label: "请选择：",
        list: [],
        listSpecial: [],
    }
}

export default {
    getSetItems,
    getDefaultValues,
}