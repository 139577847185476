import React from "react";
import {
    Form,
    Divider,
    Input,
    Select,
    Slider,
    Radio,
    Switch,
    AutoComplete
} from "antd";
import UploadFile from "../../../../../../components/upLoad/UploadFile"
import CommonFormItems from "../../../CommonFormItems";
import ColorArray from "../../../formDiyItem/ColorArray";

const Item = Form.Item;

function getSetItems(extendData= {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"词语字段"}
            name={"labelField"}
            rules={[{required: false}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"数值字段"}
            name={"valueField"}
            rules={[{required: false}]}
            extra={"若打算使用随机数值，此项可不填(数值大小决定词汇字体大小)"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"颜色字段"}
            name={"colorField"}
            rules={[{required: false}]}
            extra={"若打算手动配置颜色，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            云图样式配置
        </Divider>
        <Item
            label={"范围轮廓"}
            name={"shape"}
            rules={[{required: true}]}
        >
            <Select>
                <Select.Option value={"circle"}>圆形</Select.Option>
                {/*<Select.Option value={"cardioid"}>心形</Select.Option>*/}
                <Select.Option value={"triangle"}>三角形</Select.Option>
                <Select.Option value={"triangle-forward"}>三角形2</Select.Option>
                <Select.Option value={"diamond"}>正方形</Select.Option>
                <Select.Option value={"pentagon"}>五边形</Select.Option>
                <Select.Option value={"star"}>星形</Select.Option>
                <Select.Option value={"diyShape"}>自定义图形</Select.Option>
            </Select>
        </Item>
        <Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.shape !== currentValues.shape}
        >
            {
                ({getFieldValue})=>{
                    let items;
                    let defaultImgUrl = getFieldValue("diyShape_imageUrl");
                    switch (getFieldValue("shape")){
                        case "diyShape": items = <>
                            <Item
                                name={["diyShape_imageUrl"]}
                                label={"上传图形"}
                                rules={[{required: true}]}
                                extra={"需要有明显色彩范围，黑白图效果最好"}
                                normalize={(value, prevValue, prevValues) => {
                                    value = value?.[0]?.url || null
                                    return value
                                }}
                            >
                                <UploadFile
                                    accept={"image/*"}
                                    size={1}
                                    bigLabel={true}
                                    data={{
                                        option: {
                                            size: 1,
                                            defaultValue: defaultImgUrl ? [{
                                                url: defaultImgUrl
                                            }] : null
                                        }
                                    }}
                                />
                            </Item>
                        </>
                    }

                    return items;
                }
            }
        </Item>
        <Item
            label={"词汇间距"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("gridSize",{
                min: 1,
                max: 50,
                step: 1,
                addonAfter: <span>rem</span>,
            })}
        </Item>
        <Item
            label={"字体大小限制"}
            name={"sizeRange"}
            rules={[{required: true}]}
        >
            <Slider
                range
                min={0}
                max={60}
                marks={{
                    5: '5rem',
                    60: '80rem',
                }}
            />
        </Item>
        <Item
            label={"旋转范围限制"}
            name={"rotationRange"}
            rules={[{required: true}]}
        >
            <Slider
                range
                min={-180}
                max={180}
                marks={{
                    [-180]: '-180度',
                    [180]: '180度  ',
                }}
            />
        </Item>
        <Item
            label={"旋转步长"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("rotationStep",{
                min: 1,
                max: 360,
                step: 1,
                addonAfter: <span>度</span>,
            })}
        </Item>
        <Item
            label={"允许超出范围"}
            name={"drawOutOfBound"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"颜色采用方式"}
            name={"colorSelType"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="random">随机获取</Radio>
                <Radio value="next">依次获取</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"手动配置颜色"}
            name={"colorArray"}
            rules={[{required: false}]}
            extra={"若不配置颜色，则会使用随机色"}
        >
            <ColorArray
                max={10}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        labelField: null,
        valueField: null,
        colorField: null,
        colorArray: ['#21eff5','#8a00ff','#c4e15e','#e744ed','#0084ff','#00ffcc','#40efa7','#72e054','#f0d360','#c6a3ff','#00eaff','#6748ea','#59e2a9'],
        colorSelType: "random", //random  next
        shape: "circle",
        drawOutOfBound: false,
        sizeRange: [12,40],
        rotationRange: [-90,90],
        rotationStep: 45,
        gridSize: 8,
        diyShape_imageUrl: null
    }
}

function getDemoCode(){
    return `
    以下示例中 word、value、color 均可在 数据对接设置 中修改替换成其它单词
    1.最简略的词云数据，无需对接字段，直接返回 文本数组，但是词汇的大小是随机的
    ["文案A","文案B","文案C",...]
    
    2.可返回对象数组，指定每个词汇的相对大小
    [
        {
            word: "文案A",
            value: 150,
        },
        {
            word: "文案B",
            value: 200,
        },
        ...
    ]
    
     3.每个词汇采用自定义颜色
    [
        {
            word: "文案A",
            color: "red",
        },
        {
            word: "文案B",
            color: "#ff00ff",
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}