import React from "react";
import {message,AutoComplete,Row,Col,Space,Button} from "antd";
import {DragOutlined,MinusCircleOutlined,SettingOutlined,PlusOutlined,LinkOutlined} from "@ant-design/icons";
import less from "../ShowEchart.less";
import classNames from "classnames";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

export default class LineDataSet extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            dataSetConfig: this.props.value || {
                labelField: null,
                lineArr: [],
            },


            listChange: 0,
            dragTreeNode: null,
            dropTreeNode: null,

        }
    }

    configChanged(){
        this.props.onChange?.(this.state.dataSetConfig);
    }

    render(){
        let {dataSetConfig,listChange} = this.state;

        let defineDragOpt = (treeNode) => {
            let opt = {};

            opt.draggable = true;
            opt.onDragStart = (e) => {
                log("开始拖拽");
                this.setState({
                    dragTreeNode: treeNode
                });
            };
            opt.onDragEnd = (e) => {
                log("结束");
                this.setState({
                    dragTreeNode: null,
                    dropTreeNode: null,
                });
            };

            opt.onDragOver = (e) => {
                e.preventDefault();
            };
            opt.onDragEnter = (e) => {
                log("进入");
                this.setState({
                    dropTreeNode: treeNode
                })
            };
            opt.onDragLeave = (e) => {
                //多一个判断，解决setState的异步更新策略导致的状态合并更新
                // if (this.state.dropTreeNode && this.state.dropTreeNode._id === treeNode._id) {
                //     this.setState({
                //         dropTreeNode: null
                //     })
                // }
            };
            opt.onDrop = (e) => {
                e.preventDefault();
                log("放下", this.state.dragTreeNode);
                log("放入", this.state.dropTreeNode);

                //重新排序
                let {dataSetConfig,listChange} = this.state;
                if (this.state.dragTreeNode.index === this.state.dropTreeNode.index) {
                    //没变
                } else {
                    let newList = [];
                    dataSetConfig.lineArr.forEach((item,index) => {
                        if (index === this.state.dropTreeNode.index) {
                            newList.push(this.state.dropTreeNode);
                            newList.push(this.state.dragTreeNode);
                        } else if (item.index === this.state.dragTreeNode.index) {
                            //不要了
                        } else {
                            newList.push(item);
                        }

                    });
                    dataSetConfig.lineArr = newList;
                }

                //释放
                this.setState({
                    dragTreeNode: null,
                    dropTreeNode: null,
                    dataSetConfig: dataSetConfig,
                    listChange: listChange + 1
                },()=>{
                    this.configChanged()
                })
            };

            return opt;
        };

        return <div>
            <Row>
                <Col span={6} offset={2}>标签属性：</Col>
                <Col span={10}>
                    <AutoComplete
                        size={"small"}
                        style={{width: "100%"}}
                        value={dataSetConfig?.labelField}
                        placeholder={"请选择或填写对接字段"}
                        options={this.props.options}
                        onChange={(v)=>{
                            dataSetConfig.labelField = v;
                            this.setState({},()=>{
                                this.configChanged()
                            })
                        }}
                    />
                </Col>
            </Row>
            {
                dataSetConfig?.lineArr?.map((eachLineSet,index)=>{
                    eachLineSet.index = index;
                    return <Row
                        {...defineDragOpt(eachLineSet)}
                        key={"la" + index + listChange}
                        className={classNames({
                            [less.tabLine]: true,
                            [less.dropTreeHover]: this.state.dropTreeNode && this.state.dropTreeNode.index === index
                        })}
                    >
                        <Col span={2}><DragOutlined /></Col>
                        <Col span={6}>
                            系列{index + 1}属性：</Col>
                        <Col span={10}>
                            <AutoComplete
                                size={"small"}
                                style={{width: "100%"}}
                                value={eachLineSet.field}
                                placeholder={"请选择或填写对接字段"}
                                options={this.props.options}
                                onChange={(v)=>{
                                    eachLineSet.field = v;
                                    this.setState({},()=>{
                                        this.configChanged()
                                    })
                                }}
                            />
                        </Col>
                        <Col span={5} offset={1}>
                            <Space>
                                <MinusCircleOutlined
                                    style={{
                                        color: "red"
                                    }}
                                    onClick={()=>{
                                        this.deleteLine(index)
                                    }}
                                />
                                {/*<SettingOutlined*/}
                                {/*    onClick={()=>{*/}
                                {/*        message.info("独立配置正在开发中")*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <ColorPicker
                                    value={eachLineSet.extraStyle?.color}
                                    hideRecommendBtn={true}
                                    onChange={(v)=>{
                                        if(!eachLineSet.extraStyle){
                                            eachLineSet.extraStyle = {};
                                        }
                                        eachLineSet.extraStyle.color = v;

                                        this.setState({
                                            dataSetConfig: dataSetConfig
                                        },()=>{
                                            this.configChanged()
                                        })
                                    }}
                                />
                            </Space>
                        </Col>
                    </Row>
                })
            }
            <Row>
                <Col span={6} offset={4}>
                    <Button
                        icon={<LinkOutlined />}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addAll()
                        }}
                    >
                        自动提取
                    </Button>
                </Col>
                <Col span={6} offset={4}>
                    <Button
                        icon={<PlusOutlined />}
                        style={{color: "limegreen"}}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addOneLineData()
                        }}
                    >
                        增加一个系列
                    </Button>
                </Col>
            </Row>
        </div>
    }


    addOneLineData(){
        let {dataSetConfig} = this.state;

        if(!dataSetConfig.lineArr){
            dataSetConfig.lineArr = [];
        }

        dataSetConfig.lineArr.push({
            field: null,
            extraStyle: null,
        })

        this.setState({})
    }

    deleteLine(index){
        let {dataSetConfig,listChange} = this.state;

        _.remove(dataSetConfig.lineArr,(ti,n)=>{
            return n === index
        })

        this.setState({listChange: listChange + 1},()=>{
            this.configChanged()
        })
    }

    addAll(){
        let {dataSetConfig} = this.state;
        if(!dataSetConfig.labelField){
            message.warning("请先配置维度");
            return;
        }

        if(!dataSetConfig.lineArr){
            dataSetConfig.lineArr = [];
        }

        let has = [];
        dataSetConfig?.lineArr?.forEach((lineSet)=>{
            has.push(lineSet.field);
        })
        this.props.options?.forEach((opt)=>{
            if(!has.includes(opt.value) && dataSetConfig.labelField !== opt.value){
                dataSetConfig.lineArr.push({
                    field: opt.value,
                    extraStyle: null,
                })
            }
        })

        this.setState({},()=>{
            this.configChanged()
        })
    }
}