import React from "react";
import {
    Form,
    Divider,
    Radio,
    InputNumber,
    Switch,
    Input,
    Select
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;
const Option = Select.Option;

function getSetItems(){
    return <>
        <Divider orientation="left">
            时区设置
        </Divider>
        <Item
            noStyle
            shouldUpdate
        >
            {({getFieldsValue,setFieldsValue})=>{
                return <>
                    <Item
                        label={"选择时区"}
                        name={["timeZoneSet","selectZone"]}
                        rules={[{required: true}]}
                    >
                        <Select
                            onChange={(v)=>{
                                let all = getFieldsValue();
                                all["timeZoneSet"]["zoneCityName"] = {
                                    0: "Atlantic/Azores",
                                    1: "巴黎时间",
                                    2: "雅典时间",
                                    3: "莫斯科时间",
                                    4: "阿布扎比时间",
                                    5: "新德里时间",
                                    6: "达卡时间",
                                    7: "曼谷时间",
                                    8: "北京时间",
                                    9: "东京时间",
                                    10: "墨尔本时间",
                                    11: "所罗门群岛时间",
                                    12: "惠灵顿时间",
                                    [-1]: "非洲大陆最西边",
                                    [-2]: "大西洋中部",
                                    [-3]: "巴西巴西利亚时间",
                                    [-4]: "圣地亚哥时间",
                                    [-5]: "纽约时间",
                                    [-6]: "芝加哥时间",
                                    [-7]: "菲尼克斯时间",
                                    [-8]: "加里福利亚时间",
                                    [-9]: "安克雷奇时间",
                                    [-10]: "檀香山时间",
                                    [-11]: "太平洋中部",
                                    [-12]: "太平洋中部",
                                }[v];
                                setFieldsValue(all)
                            }}
                        >
                            <Option value={0}>中央时区</Option>
                            <Option value={1}>东一区</Option>
                            <Option value={2}>东二区</Option>
                            <Option value={3}>东三区</Option>
                            <Option value={4}>东四区</Option>
                            <Option value={5}>东五区</Option>
                            <Option value={6}>东六区</Option>
                            <Option value={7}>东七区</Option>
                            <Option value={8}>东八区</Option>
                            <Option value={9}>东九区</Option>
                            <Option value={10}>东十区</Option>
                            <Option value={11}>东十一区</Option>
                            <Option value={12}>东十二区</Option>
                            <Option value={-12}>西十二区</Option>
                            <Option value={-11}>西十一区</Option>
                            <Option value={-10}>西十区</Option>
                            <Option value={-9}>西九区</Option>
                            <Option value={-8}>西八区</Option>
                            <Option value={-7}>西七区</Option>
                            <Option value={-6}>西六区</Option>
                            <Option value={-5}>西五区</Option>
                            <Option value={-4}>西四区</Option>
                            <Option value={-3}>西三区</Option>
                            <Option value={-2}>西二区</Option>
                            <Option value={-1}>西一区</Option>
                        </Select>
                    </Item>
                    <Item
                        label={"时区名称"}
                        name={["timeZoneSet","zoneCityName"]}
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder={"填写时区名称"}
                        />
                    </Item>
                </>
            }}
        </Item>
        <Item
            label={"显示时区文字"}
            name={["timeZoneSet","show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Divider orientation="left">
            日期展示格式
        </Divider>
        <Item
            noStyle
            shouldUpdate={true}
        >
            {({getFieldsValue,setFieldsValue})=>{
                return <>
                    <Item
                        label={"选择"}
                        name={["dateFormat","selectFormat"]}
                        rules={[{required: true}]}
                    >
                        <Select
                            onChange={(v)=>{
                                let all = getFieldsValue();
                                all["dateFormat"]["diyFormat"] = v;
                                setFieldsValue(all)
                            }}
                        >
                            <Option value={"YYYY年MM月DD日 HH:mm"}>****年**月**日 小时:分钟</Option>
                            <Option value={"MM/DD HH:mm"}>月/日 小时:分钟</Option>
                            <Option value={"HH:mm:ss"}>小时:分钟:秒</Option>
                            <Option value={"YYYY年MM月DD日 dddd"}>****年**月**日 星期几</Option>
                        </Select>
                    </Item>
                    <Item
                        label={"调整"}
                        name={["dateFormat","diyFormat"]}
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder={"自定义格式"}
                        />
                    </Item>
                </>
            }}
        </Item>
        <Divider orientation="left">
            时区字体设置
        </Divider>
        {CommonFormItems.getFontSet("zoneTxtSet", true)}
        <Divider orientation="left">
            日期字体设置
        </Divider>
        {CommonFormItems.getFontSet("textSet", true)}
    </>
}

function getDefaultValues(){
    return {
        timeZoneSet: {
            show: false,
            selectZone: 8,
            zoneCityName: "北京时间",
        },
        dateFormat: {
            selectFormat: "YYYY年MM月DD日 HH:mm",
            diyFormat: "YYYY年MM月DD日 HH:mm",
        },
        textSet: {
            fontFamily: "Date-Number",
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
        zoneTxtSet: {
            fontFamily: "default",
            color: "#ffffff",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: true, //加粗
            fontItalic: false, //倾斜
        },
    }
}
export default {
    getSetItems,
    getDefaultValues,
}