import React from "react";
import {
    Form,
    Button,
    message,
    Input,
    Radio,
    InputNumber,
    Select,
    Slider,
    Row,
    Col,
    Divider,
    AutoComplete,
    Switch,
    Checkbox
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import LineDataSet from "./line/LineDataSet";
import ColorArray from "../../../formDiyItem/ColorArray";
import ColorPicker from "../../../formDiyItem/ColorPicker";
import SetOneOrFour from "../../../formDiyItem/SetOneOrFour";
import DataDefinitionFile from "../../../dataDisposition/DataDefinitionFile";

const Item = Form.Item;

//Echart里一些重复的配置，放到这个文件
//如 text, label, tooltip, xAxis 等

//折线图数据集配置
//柱状图数据集配置
//每个系列单独的配置还没做，线与柱的基础数据绑定操作相同，暂用同一个组件LineDataSet
function getDatasetItems(extendData = {}) {
    let opts = [];
    if (extendData?.bindData) {
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Item
            noStyle={true}
            name={["dataSetConfig"]}
            rules={[{required: true}]}
        >
            <LineDataSet
                options={opts}
            />
        </Item>
    </>
}

//x轴配置
function getAxisItems(preField = "xAxis") {
    return <>
        <Item
            label={"显示轴线"}
            name={[preField, "show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"显示分割线"}
            name={[preField, "splitLine", "show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"文案角度"}
            rules={[{required: true}]}
        >
            {CommonFormItems.getSliderAndInputItemGroup([preField, "axisLabel", "rotate"], {
                min: -180,
                max: 180,
                step: 1
            })}
        </Item>
        <Divider
            orientation="right"
        >
            <Item
                style={{width: "160px"}}
                labelCol={{span: 14}}
                label={"更多坐标轴配置"}
                name={[preField, "seeMore"]}
                valuePropName={"checked"}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"已展开"}
                    unCheckedChildren={"已收起"}
                />
            </Item>
        </Divider>
        <Item
            noStyle={true}
            dependencies={[preField, "seeMore"]}
        >
            {({getFieldValue,getFieldsValue, setFieldValue}) => {
                let seeMore = getFieldValue([preField, "seeMore"]);

                return <Item
                    noStyle={true}
                    hidden={!seeMore}
                >
                    <Item
                        label={"坐标轴名称"}
                        name={[preField, "name"]}
                        rules={[{required: false}]}
                    >
                        <Input
                            maxLength={30}
                        />
                    </Item>
                    <Item
                        label={"坐标轴名称位置"}
                        name={[preField, "nameLocation"]}
                    >
                        <Radio.Group
                        >
                            <Radio value="start">首部</Radio>
                            <Radio value="center">中间</Radio>
                            <Radio value="end">尾部</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label={"名称与轴线间距"}
                        name={[preField, "nameGap"]}
                    >
                        {CommonFormItems.getSliderAndInputItemGroup([preField, "nameGap"], {
                            min: 0,
                            max: 30,
                            step: 1
                        })}
                    </Item>
                    <Item
                        label={"分割线颜色"}
                        name={[preField, "splitLine", "lineStyle", "color"]}
                        rules={[{required: false}]}
                    >
                        <ColorArray
                            max={10}
                            hasOpacity={true}
                        />
                    </Item>
                    <Item
                        label={"分割线类型"}
                        name={[preField, "splitLine", "lineStyle", "type"]}
                        rules={[{required: false}]}
                    >
                        <Radio.Group>
                            <Radio value={"solid"}>实线</Radio>
                            <Radio value={"dashed"}>虚线</Radio>
                            <Radio value={"dotted"}>点线</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label={"刻度标签前缀"}
                        name={[preField, "_axisBeforeTxt"]}
                        rules={[{required: false}]}
                    >
                        <Input
                            maxLength={20}
                            onChange={(e)=>{
                                let allV = getFieldsValue(true);
                                if(allV[preField]._axisBeforeTxt || allV[preField]._axisAfterTxt){
                                    let t = `${allV[preField]._axisBeforeTxt || ""}{value}${allV[preField]._axisAfterTxt || ""}`
                                    setFieldValue([preField,"axisLabel","formatter"],t)
                                }else{
                                    if(!allV[preField]?._timeLineFormatter){
                                        setFieldValue([preField,"axisLabel","formatter"],null)
                                    }else{
                                        setFieldValue([preField,"axisLabel","formatter"],allV[preField]?._timeLineFormatter)
                                    }
                                }
                            }}
                        />
                    </Item>
                    <Item
                        label={"刻度标签后缀"}
                        name={[preField, "_axisAfterTxt"]}
                        rules={[{required: false}]}
                    >
                        <Input
                            maxLength={20}
                            onChange={(e)=>{
                                let allV = getFieldsValue(true);
                                if(allV[preField]._axisBeforeTxt || allV[preField]._axisAfterTxt){
                                    let t = `${allV[preField]._axisBeforeTxt || ""}{value}${allV[preField]._axisAfterTxt || ""}`
                                    setFieldValue([preField,"axisLabel","formatter"],t)
                                }else{
                                    if(!allV[preField]?._timeLineFormatter){
                                        setFieldValue([preField,"axisLabel","formatter"],null)
                                    }else{
                                        setFieldValue([preField,"axisLabel","formatter"],allV[preField]?._timeLineFormatter)
                                    }
                                }
                            }}
                        />
                    </Item>
                    <Item
                        label={"时间轴刻度标签"}
                        name={[preField, "_timeLineFormatter"]}
                        rules={[{required: false}]}
                    >
                        <Select
                            onChange={(e)=>{
                                let allV = getFieldsValue(true);
                                if(!allV[preField]?._timeLineFormatter){
                                    setFieldValue([preField,"axisLabel","formatter"],null)
                                }else{
                                    setFieldValue([preField,"axisLabel","formatter"],allV[preField]?._timeLineFormatter)
                                }
                            }}
                        >
                            <Select.Option value={""}>自动</Select.Option>
                            <Select.Option value={"{yyyy}年{MMM}{d}日"}>年-月-日</Select.Option>
                            <Select.Option value={"{yyyy}年{MMM}"}>年-月</Select.Option>
                            <Select.Option value={"{MMM}{d}日"}>月-日</Select.Option>
                            <Select.Option value={"{MMM}{d}日 {HH}"}>月-日 时</Select.Option>
                            <Select.Option value={"{MMM}{d}日 {HH}:{mm}"}>月-日 时:分</Select.Option>
                            <Select.Option value={"{HH}:{mm}"}>时:分</Select.Option>
                            <Select.Option value={"{HH}:{mm}:{ss}"}>时:分:秒</Select.Option>
                            <Select.Option value={"{eeee}"}>星期</Select.Option>
                        </Select>
                    </Item>
                    <Item
                        hidden={true}
                        label={"刻度标签模板"}
                        name={[preField, "axisLabel", "formatter"]}
                    >
                        <Input
                            maxLength={20}
                        />
                    </Item>
                    {
                        preField === "xAxis"
                            ? <Item
                                label={"x轴位置"}
                                name={[preField, "position"]}
                            >
                                <Radio.Group
                                >
                                    <Radio value="top">顶部</Radio>
                                    <Radio value="bottom">底部</Radio>
                                </Radio.Group>
                            </Item>
                            : <Item
                                label={"y轴位置"}
                                name={[preField, "position"]}
                            >
                                <Radio.Group
                                >
                                    <Radio value="left">左侧</Radio>
                                    <Radio value="right">右侧</Radio>
                                </Radio.Group>
                            </Item>
                    }
                </Item>
            }}
        </Item>
    </>
}

//主题
function getThemeItems() {
    return <>
        <Item
            label={"画布背景色"}
            name={["theme", "backgroundColor"]}
            rules={[{required: false}]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"颜色组"}
            name={["theme", "color"]}
            rules={[{required: false}]}
        >
            <ColorArray
                max={20}
                hasOpacity={true}
                hasGradient={true}
            />
        </Item>
    </>
}

//通用配置
function getUsualItems() {
    return <>
        <Item
            label={"维度轴选择"}
            name={["usual", "_categoryWhere"]}
        >
            <Radio.Group
            >
                <Radio value="x">x轴</Radio>
                <Radio value="y">y轴</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"网格"}
        >
            <Item
                label={"上"}
                name={["usual", "grid", "top"]}
            >
                <AutoComplete
                    maxLength={10}
                    placeholder="请选择或填写"
                    options={[
                        {value: '10px'},
                        {value: '10%'},
                        {value: 'top'},
                        {value: 'middle'},
                        {value: 'bottom'},
                    ]}
                />
            </Item>
            <Item
                label={"下"}
                name={["usual", "grid", "bottom"]}
            >
                <AutoComplete
                    maxLength={10}
                    placeholder="请选择或填写"
                    options={[
                        {value: '10px'},
                        {value: '10%'},
                        {value: 'top'},
                        {value: 'middle'},
                        {value: 'bottom'},
                    ]}
                />
            </Item>
            <Item
                label={"左"}
                name={["usual", "grid", "left"]}
            >
                <AutoComplete
                    maxLength={10}
                    placeholder="请选择或填写"
                    options={[
                        {value: '10px'},
                        {value: '10%'},
                        {value: 'left'},
                        {value: 'center'},
                        {value: 'right'},
                    ]}
                />
            </Item>
            <Item
                label={"右"}
                name={["usual", "grid", "right"]}
            >
                <AutoComplete
                    maxLength={10}
                    placeholder="请选择或填写"
                    options={[
                        {value: '10px'},
                        {value: '10%'},
                        {value: 'left'},
                        {value: 'center'},
                        {value: 'right'},
                    ]}
                />
            </Item>
        </Item>
        <Item
            label={"刻度标签自适应"}
            name={["usual", "grid", "containLabel"]}
            valuePropName={"checked"}
            extra={"计算内边距时是否包含刻度标签所占空间"}
        >
            <Switch
                size={"small"}
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
    </>
}

//提示框信息
function getTooltipItems(diySet = {}) {
    return <>
        <Item
            label={"是否显示"}
            name={["tooltip", "show"]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        {
            diySet.extendItems
                ?diySet.extendItems
                :null
        }
        <Divider
            orientation="right"
        >
            <Item
                style={{width: "150px"}}
                labelCol={{span: 14}}
                label={"更多提示框配置"}
                name={["tooltip", "seeMore"]}
                valuePropName={"checked"}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"已展开"}
                    unCheckedChildren={"已收起"}
                />
            </Item>
        </Divider>
        <Item
            noStyle={true}
            dependencies={["tooltip", "seeMore"]}
        >
            {({getFieldValue}) => {
                let seeMore = getFieldValue(["tooltip", "seeMore"])
                return <Item
                    noStyle={true}
                    hidden={!seeMore}
                    // hidden={false}
                >
                    <Item
                        label={"触发类型"}
                        name={["tooltip", "trigger"]}
                    >
                        <Select>
                            <Select.Option value="item">数据项图形触发</Select.Option>
                            <Select.Option value="axis">坐标轴触发</Select.Option>
                            <Select.Option value="none">什么都不触发</Select.Option>
                        </Select>
                    </Item>
                    <Item
                        label={"触发的条件"}
                        name={["tooltip", "triggerOn"]}
                    >
                        <Select>
                            <Select.Option value="mousemove">鼠标移动时触发</Select.Option>
                            <Select.Option value="click">鼠标点击时触发</Select.Option>
                            <Select.Option value="mousemove|click">同时鼠标移动和点击时触发</Select.Option>
                        </Select>
                    </Item>
                    <Item
                        label={"文字颜色"}
                        name={["tooltip", "textStyle", "color"]}
                    >
                        <ColorPicker
                            // hideOpacity={true}
                        />
                    </Item>
                    <Item
                        label={"背景颜色"}
                        name={["tooltip", "backgroundColor"]}
                    >
                        <ColorPicker
                            // hideOpacity={true}
                        />
                    </Item>
                    <Item
                        label={"边框颜色"}
                        name={["tooltip", "borderColor"]}
                    >
                        <ColorPicker/>
                    </Item>
                    <Item
                        label={"边框宽度"}
                    >
                        {CommonFormItems.getSliderAndInputItemGroup(["tooltip", "borderWidth"], {
                            min: 0,
                            max: 10
                        })}
                    </Item>
                    <Item
                        label={"内边距"}
                        name={["tooltip", "padding"]}
                    >
                        <SetOneOrFour/>
                    </Item>
                </Item>
            }}
        </Item>
    </>
}

//图例
function getLegendItems() {
    return <>
        <Item
            label={"是否显示"}
            name={["legend", "show"]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"位置"}
            name={["legend", "_position"]}
        >
            <Select>
                <Select.Option value="top">上</Select.Option>
                <Select.Option value="bottom">下</Select.Option>
                <Select.Option value="left">左</Select.Option>
                <Select.Option value="right">右</Select.Option>
                <Select.Option value="topLeft">左上</Select.Option>
                <Select.Option value="topRight">右上</Select.Option>
                <Select.Option value="bottomLeft">左下</Select.Option>
                <Select.Option value="bottomRight">右下</Select.Option>
                <Select.Option value="center">完全居中</Select.Option>
            </Select>
        </Item>
        <Item
            label={"图形"}
            name={["legend", "icon"]}
        >
            <Select>
                <Select.Option value="circle">圆</Select.Option>
                <Select.Option value="rect">矩形</Select.Option>
                <Select.Option value="roundRect">圆角矩形</Select.Option>
                <Select.Option value="triangle">三角</Select.Option>
                <Select.Option value="diamond">菱形</Select.Option>
                <Select.Option value="arrow">箭头</Select.Option>
                <Select.Option value="none">透明</Select.Option>
            </Select>
        </Item>
        <Divider
            orientation="right"
        >
            <Item
                style={{width: "150px"}}
                labelCol={{span: 13}}
                label={"更多图例配置"}
                name={["legend", "seeMore"]}
                valuePropName={"checked"}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"已展开"}
                    unCheckedChildren={"已收起"}
                />
            </Item>
        </Divider>
        <Item
            noStyle={true}
            dependencies={["legend", "seeMore"]}
        >
            {({getFieldValue}) => {
                let seeMore = getFieldValue(["legend", "seeMore"])
                return <Item
                    noStyle={true}
                    hidden={!seeMore}
                >
                    <Item
                        label={"图例类型"}
                        name={["legend", "type"]}
                    >
                        <Radio.Group>
                            <Radio value="plain">普通图例</Radio>
                            <Radio value="scroll">可滚动翻页</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label={"布局朝向"}
                        name={["legend", "orient"]}
                    >
                        <Radio.Group>
                            <Radio value="horizontal">水平方向</Radio>
                            <Radio value="vertical">垂直方向</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label={"对齐方式"}
                        name={["legend", "align"]}
                    >
                        <Radio.Group>
                            <Radio value="auto">自动</Radio>
                            <Radio value="left">首部</Radio>
                            <Radio value="right">尾部</Radio>
                        </Radio.Group>
                    </Item>
                    <Item
                        label={"范围"}
                    >
                        <Item
                            label={"宽"}
                            name={["legend", "width"]}
                        >
                            <AutoComplete
                                maxLength={10}
                                placeholder="请选择或填写"
                                options={[
                                    {value: '80%'},
                                    {value: '200px'},
                                    {value: 'auto'},
                                ]}
                            />
                        </Item>
                        <Item
                            label={"高"}
                            name={["legend", "height"]}
                        >
                            <AutoComplete
                                maxLength={10}
                                placeholder="请选择或填写"
                                options={[
                                    {value: '80%'},
                                    {value: '200px'},
                                    {value: 'auto'},
                                ]}
                            />
                        </Item>
                    </Item>
                    <Item
                        label={"每项间隔"}
                    >
                        {CommonFormItems.getSliderAndInputItemGroup(["legend", "itemGap"], {
                            min: 0,
                            max: 30
                        })}
                    </Item>
                </Item>
            }}
        </Item>
    </>
}

//数据堆叠
function getStackSetItems() {
    return <>
        <Item
            label={"采用堆叠模式"}
            name={["stackSet", "show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Divider
            orientation="right"
        >
            <Item
                style={{width: "150px"}}
                labelCol={{span: 13}}
                label={"更多堆叠配置"}
                name={["stackSet", "seeMore"]}
                valuePropName={"checked"}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"已展开"}
                    unCheckedChildren={"已收起"}
                />
            </Item>
        </Divider>
        <Item
            noStyle={true}
            dependencies={["stackSet", "seeMore"]}
        >
            {({getFieldValue}) => {
                let seeMore = getFieldValue(["stackSet", "seeMore"])
                return <Item
                    noStyle={true}
                    hidden={!seeMore}
                >
                    <Item
                        label={"堆叠属性"}
                        name={["stackSet", "stack"]}
                    >
                        <Input
                            placeholder={"填写自定义属性"}
                            maxLength={30}
                        />
                    </Item>
                    <Item
                        label={"堆叠方式"}
                        name={["stackSet", "stackStrategy"]}
                    >
                        <Select>
                            <Select.Option value="samesign">堆叠正负相同的值</Select.Option>
                            <Select.Option value="all">堆叠所有的值</Select.Option>
                            <Select.Option value="positive">只堆积正值</Select.Option>
                            <Select.Option value="negative">只堆叠负值</Select.Option>
                        </Select>
                    </Item>
                </Item>
            }}
        </Item>
    </>
}

//数据标签
function getDataLabelItems(diySet = {},preField="label") {
    return <>
        <Item
            noStyle={true}
            dependencies={[preField, "_setType"]}
        >
            {({getFieldValue}) => {
                let setType = getFieldValue([preField, "_setType"]);

                return <>
                    <Item
                        noStyle={true}
                        hidden={setType !== "normal"}
                    >
                        <Item
                            label={"显示"}
                            name={[preField, "show"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                        <Item
                            label={"显示方案"}
                            name={[preField, "_showPlan"]}
                            rules={[{required: true}]}
                        >
                            <Select>
                                {
                                    diySet.labelShowPlanOptions
                                        ? diySet.labelShowPlanOptions
                                        : <>
                                            <Select.Option value="normal">普通</Select.Option>
                                            <Select.Option value="pop">气泡</Select.Option>
                                            <Select.Option value="popDark">气泡(深色)</Select.Option>
                                        </>
                                }
                            </Select>
                        </Item>
                        <Item
                            label={"颜色"}
                            name={[preField, "color"]}
                            extra={"不设置，则自动继承线条颜色"}
                            rules={[{required: false}]}
                        >
                            <ColorPicker/>
                        </Item>
                        <Item
                            label={"位置"}
                            name={[preField, "position"]}
                            rules={[{required: true}]}
                        >
                            <Select>
                                {
                                    diySet.labelPositionOptions
                                        ? diySet.labelPositionOptions
                                        : <>
                                            <Select.Option value="top">上</Select.Option>
                                            <Select.Option value="bottom">下</Select.Option>
                                            <Select.Option value="left">左</Select.Option>
                                            <Select.Option value="right">右</Select.Option>
                                        </>
                                }
                            </Select>
                        </Item>
                        <Item
                            label={"前缀"}
                            name={[preField, "_beforeTxt"]}
                            rules={[{required: false}]}
                        >
                            <Input
                                maxLength={20}
                            />
                        </Item>
                        <Item
                            label={"后缀"}
                            name={[preField, "_afterTxt"]}
                            rules={[{required: false}]}
                        >
                            <Input
                                maxLength={20}
                            />
                        </Item>
                        {
                            diySet.extendItems
                                ?diySet.extendItems
                                :null
                        }
                    </Item>
                    <Item
                        noStyle={true}
                        hidden={setType !== "emphasis"}
                    >
                        <Item
                            label={"改变哪些属性"}
                            name={[preField, "_emphasisSel"]}
                        >
                            <Select
                                mode={"tags"}
                            >
                                <Select.Option value={"show"}>显示</Select.Option>
                                <Select.Option value={"color"}>颜色</Select.Option>
                            </Select>
                        </Item>
                        <Item
                            noStyle={true}
                            dependencies={[preField, "_emphasisSel"]}
                        >
                            {({getFieldValue}) => {
                                let selFields = getFieldValue([preField, "_emphasisSel"]);

                                return selFields?.map((selField) => {
                                    switch (selField) {
                                        case "show":
                                            return <Item
                                                key={"show"}
                                                label={"显示"}
                                                name={[preField, "_emphasis", "show"]}
                                                rules={[{required: true}]}
                                                valuePropName={"checked"}
                                            >
                                                <Switch
                                                    checkedChildren={"是"}
                                                    unCheckedChildren={"否"}
                                                />
                                            </Item>;
                                        case "color":
                                            return <Item
                                                key={"color"}
                                                label={"颜色"}
                                                extra={"不设置，则自动继承线条颜色"}
                                                name={[preField, "_emphasis", "color"]}
                                                rules={[{required: false}]}
                                            >
                                                <ColorPicker/>
                                            </Item>;
                                    }
                                })
                            }}
                        </Item>
                    </Item>
                </>
            }}
        </Item>
    </>
}

//点击事件配置
function getChatItemClickItems(extendData = {}){
    let currentSceneMsg = extendData.currentSceneMsg;

    let parameterBoxArr = [];
    if(currentSceneMsg?.contentArray){
        currentSceneMsg.contentArray?.forEach((eachContent)=>{
            if(eachContent.contentMsg.contentMode === "parameter"){
                if(DataDefinitionFile.ifParameterAllowSetValue(eachContent.contentMsg.dataConfig?.contentType)){
                    //可接收参数
                    parameterBoxArr.push({
                        uid: eachContent.uid
                    })
                }
            }
        })
    }

    return <>
        <Item
          label={"启用点击取值"}
          name={["clickActionSet", "use"]}
          rules={[{required: true}]}
          valuePropName={"checked"}
        >
            <Switch
              checkedChildren={"是"}
              unCheckedChildren={"否"}
            />
        </Item>
        <Item
          noStyle={true}
          dependencies={["clickActionSet", "use"]}
        >
            {({getFieldValue}) => {
                let seeOption = getFieldValue(["clickActionSet", "use"])
                return <Item
                  noStyle={true}
                  hidden={!seeOption}
                >
                    <Item
                      label={"获取的值类型"}
                      name={["clickActionSet", "valueType"]}
                    >
                        <Select>
                            <Select.Option value="name">维度值</Select.Option>
                            <Select.Option value="seriesName">系列名称</Select.Option>
                        </Select>
                    </Item>
                    <Item
                      label={"传给哪些容器"}
                      name={["clickActionSet", "toWhere"]}
                    >
                        <Checkbox.Group>
                            {
                                parameterBoxArr?.length
                                    ?parameterBoxArr.map((eachBox)=>{
                                        return <Checkbox value={eachBox.uid}>容器-{eachBox.uid.slice(0,4)}</Checkbox>
                                  })
                                    : "没有可以接收数据的参数容器"
                            }

                        </Checkbox.Group>
                    </Item>
                </Item>
            }}
        </Item>
    </>
}

export default {
    getDatasetItems,
    getAxisItems,
    getThemeItems,
    getUsualItems,
    getTooltipItems,
    getLegendItems,
    getStackSetItems,
    getDataLabelItems,
    getChatItemClickItems
}