import React,{useRef} from "react";
import {Tabs} from "antd";
import styles from "./DashboardModelList.less";
import TemplateList from "../analysisManage/TemplateList"
import DarkStyleWrap from "../../../DarkStyleWrap";

export default (props) => {
  //权限
  const powerConfigRef = useRef(window.___.initPower(props.post.functions))

  return (
    <DarkStyleWrap
    >
      <div
        className={styles.mainPage}
      >
        <Tabs
          className={styles.tabsBox}
          tabBarStyle={{padding: "0 40px",margin: 0}}
          items={[
            {
              key: 'company',
              label: '公司',
              children: <div
                className={styles.tabPaneBox}
              >
                <TemplateList
                  usage={"company"}
                  powerConfig={powerConfigRef.current}
                />
              </div>
            },
            {
              key: 'space',
              label: '空间',
              children: <div
                className={styles.tabPaneBox}
              >
                <TemplateList
                  usage={"space"}
                  powerConfig={powerConfigRef.current}
                />
              </div>
            },
            {
              key: 'unit',
              label: '执行单元',
              children: <div
                className={styles.tabPaneBox}
              >
                <TemplateList
                  usage={"unit"}
                  powerConfig={powerConfigRef.current}
                />
              </div>
            },
            {
              key: 'device',
              label: '数字人',
              children: <div
                className={styles.tabPaneBox}
              >
                <TemplateList
                  usage={"device"}
                  powerConfig={powerConfigRef.current}
                />
              </div>
            }
          ]}
        />
      </div>
    </DarkStyleWrap>
  )
}