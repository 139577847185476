import React from "react";
import {customtheme} from "./darkTheme";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import styles from "./common.less"

//包裹一层，来设置暗色主题
export default function (props){
  return <ConfigProvider
    theme={customtheme}
    locale={zhCN}
  >
    <React.Fragment>
      <div className={props.noAbsolute ? '' : styles.maintheme}>
        {props.children}
      </div>
    </React.Fragment>
  </ConfigProvider>
}