import React, {Component} from 'react';
import styles from "./TimeDuration.less";
import CommonViewDiv from "../../../CommonViewDiv";

export default class TimeDuration extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            time: ""
        }
    }

    showValue(value){
        this.setState({
            time: value
        })
    }

    componentDidMount(){
        this.loop = setInterval(()=>{
            this.setState({})
        },1000)
    }

    componentWillUnmount(){
        this.loop && clearInterval(this.loop);
    }

    render(){
        let time = this.state.time;
        let err;
        if(typeof time === "object"){
            err = "[格式错误]"
        }else if(typeof Number(time) !== "number"){
            err = "[格式错误]"
        }

        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};

        let timeViewDivStr = "";

        //原始样式对象
        let centerTextSet = contentParams.centerTextSet;
        let beforeType = contentParams.beforeType;
        let beforeTextSet = contentParams.beforeTextSet;
        let beforeIconSet = contentParams.beforeIconSet;

        if(contentParams.timeType === "cutDown"){
            //倒计时
            let endTime = Number(time) || contentParams.defaultTime;
            if(!endTime){
                timeViewDivStr = "--:--";
            }else{
                let duaTime = endTime - Date.now().valueOf();

                if(duaTime<0){
                    //已经超时

                    //样式对象替换掉
                    centerTextSet = contentParams.overTimeTextSet;
                    beforeType = contentParams.beforeTypeOverTime;
                    beforeTextSet = contentParams.beforeTextOverTimeSet;
                    beforeIconSet = contentParams.beforeIconOverTimeSet;

                    if(contentParams.overTimeTextSet?.text){
                        //替换成超时文案
                        timeViewDivStr = contentParams.overTimeTextSet?.text;
                    }else{
                        //无视超时，继续计算超过时间
                        timeViewDivStr = this.getDurationTimeStr(duaTime,contentParams);
                    }
                }else{
                    //未超时
                    timeViewDivStr = this.getDurationTimeStr(duaTime,contentParams);
                }
            }
        }else{
            //正计时
            let startTime = Number(time) || contentParams.defaultTime;

            if(!startTime){
                timeViewDivStr = "--:--";
            }else{
                let duaTime = Date.now().valueOf() - startTime;
                if(duaTime < 0){
                    timeViewDivStr = "未到达目标时间点";
                }else{
                    timeViewDivStr = this.getDurationTimeStr(duaTime,contentParams);
                }
            }
        }

        return <div
            className={styles.outerBox}
            style={{
                flexDirection: contentParams.flexDirection || "row",
                alignItems: `${contentParams.alignItems}`,
                gap: window.changeRemNum(contentParams.gap)
            }}
        >
            {
                beforeType === "text"
                    ?CommonViewDiv.getText(beforeTextSet,beforeTextSet?.text)
                    :null
            }
            {
                beforeType === "icon"
                    ?CommonViewDiv.getIcon(beforeIconSet)
                    :null
            }
            <div>
                {
                    err
                        ?<span style={{color:"red"}}>格式错误</span>
                        :<span
                            style={{
                                display: "inline-block",
                                color: centerTextSet?.color,
                                fontSize: window.changeRemNum(centerTextSet,"fontSize","sizeUnit"),

                                fontWeight: centerTextSet?.fontBold ? "bold" : "normal",
                                fontStyle: centerTextSet?.fontItalic ? "italic" : "normal",
                                fontFamily: (centerTextSet?.fontFamily === "default")
                                    ?"unset"
                                    :(centerTextSet.fontFamily||"Date-Number")
                            }}
                        >
                    {timeViewDivStr}
                 </span>
                }
            </div>
        </div>
    }

    /**获取持续时间字符串
     * @param durTime
     * @param contentParams
     <Select.Option value={1}>根据时间判断</Select.Option>
     <Select.Option value={2}>分:秒</Select.Option>
     <Select.Option value={3}>时:分</Select.Option>
     <Select.Option value={4}>时:分:秒</Select.Option>
     <Select.Option value={5}>天:时</Select.Option>
     <Select.Option value={6}>天:时:分</Select.Option>
     */
    getDurationTimeStr(durTime,contentParams){
        let str = "";
        let times = Math.abs(durTime);

        let  unitStyleObj={
            display: "inline-block",
            color: contentParams.unitTextSet?.color,
            fontSize: window.changeRemNum(contentParams.unitTextSet,"fontSize","sizeUnit"),

            fontWeight: contentParams.unitTextSet?.fontBold ? "bold" : "normal",
            fontStyle: contentParams.unitTextSet?.fontItalic ? "italic" : "normal",
            fontFamily: (contentParams.unitTextSet.fontFamily && contentParams.unitTextSet.fontFamily !== "default")?contentParams.unitTextSet.fontFamily:"unset",

            padding: `0px ${window.changeRemNum(contentParams.unitGap)}`
        }

        let day = 0,
            hour = 0,
            minute = 0,
            second = 0;

        let formatType = contentParams.timeFormat;

        if(formatType === 1){
            //根据时间自动判断
            if(times<60000){
                //小于一分钟
                formatType = 99;
            }else if(times<60000 * 60){
                //小于一小时
                formatType = 2;
            }else if(times<60000 * 60){
                //小于一天
                formatType = 3;
            }else{
                formatType = 6;
            }
        }

        switch (formatType){
            case 2:
                minute = Math.floor(times / (60 * 1000));
                second = Math.floor(times / 1000) - (minute * 60);
                str = <>
                    {minute}
                    <span
                        style={unitStyleObj}
                    >分钟</span>
                    {second}
                    <span
                        style={unitStyleObj}
                    >秒</span>
                </>;
                break;
            case 3:
                hour = Math.floor(times / (60 * 6 * 10000));
                minute = Math.floor(times / (60 * 1000)) - (hour * 60);
                str = <>
                    {hour}
                    <span
                        style={unitStyleObj}
                    >小时</span>
                    {minute}
                    <span
                        style={unitStyleObj}
                    >分钟</span>
                </>;
                break;
            case 4:
                hour = Math.floor(times / (60 * 6 * 10000));
                minute = Math.floor(times / (60 * 1000)) - (hour * 60);
                second = Math.floor(times / 1000) - (hour * 60 * 60) - (minute * 60);

                str = <>
                    {hour}
                    <span
                        style={unitStyleObj}
                    >小时</span>
                    {minute}
                    <span
                        style={unitStyleObj}
                    >分钟</span>
                    {second}
                    <span
                        style={unitStyleObj}
                    >秒</span>
                </>;
                break;
            case 5:
                day = Math.floor(times / (24 * 60 * 6 * 10000));
                hour = Math.floor(times / (60 * 6 * 10000)) - (day * 24);
                str = <>
                    {day}
                    <span
                        style={unitStyleObj}
                    >天</span>
                    {hour}
                    <span
                        style={unitStyleObj}
                    >小时</span>
                </>;
                break;
            case 6:
                day = Math.floor(times / (24 * 60 * 6 * 10000));
                hour = Math.floor(times / (60 * 6 * 10000)) - (day * 24);
                minute = Math.floor(times / (60 * 1000)) - (day * 24 * 60) - (hour * 60);
                str = <>
                    {day}
                    <span
                        style={unitStyleObj}
                    >天</span>
                    {hour}
                    <span
                        style={unitStyleObj}
                    >小时</span>
                    {minute}
                    <span
                        style={unitStyleObj}
                    >分钟</span>
                </>;
                break;
            case 7:
                day = Math.floor(times / (24 * 60 * 6 * 10000));
                hour = Math.floor(times / (60 * 6 * 10000)) - (day * 24);
                minute = Math.floor(times / (60 * 1000)) - (day * 24 * 60) - (hour * 60);
                second = Math.floor(times / 1000)%60;

                str = <>
                    {day}
                    <span
                        style={unitStyleObj}
                    >天</span>
                    {hour}
                    <span
                        style={unitStyleObj}
                    >小时</span>
                    {minute}
                    <span
                        style={unitStyleObj}
                    >分钟</span>
                    {second}
                    <span
                        style={unitStyleObj}
                    >秒</span>
                </>;
                break;

            case 99:
                second = Math.floor(times / 1000);

                str = <>
                    {second}
                    <span
                        style={unitStyleObj}
                    >秒</span>
                </>;
                break;
        }

        return str;
    }
}