import React from "react";
import {
    Form,
    Divider,
    Radio,
    Switch,
    Input,
    AutoComplete,
    InputNumber
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import ColorPicker from "../../../formDiyItem/ColorPicker";

const Item = Form.Item;

function getSetItems(extendData = {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }

    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"总数字段名"}
            name={"totalField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"第一段字段名"}
            name={"firstField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"第二段字段名"}
            name={"secondField"}
            rules={[{required: false}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            进度条样式设置
        </Divider>
        <Item
            label={"进度条两端"}
            name={"strokeLinecap"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="round">圆角</Radio>
                <Radio value="butt">方角</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"进度条底色"}
            name={"trailColor"}
            rules={[{required: true}]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"第一段颜色"}
            name={"firstColor"}
            rules={[{required: true}]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"第二段颜色"}
            name={"secondColor"}
            rules={[{required: true}]}
        >
            <ColorPicker/>
        </Item>
        <Divider orientation="left">
            文案样式
        </Divider>
        <Item
            label={"展示类型"}
            name={["msgSet", "type"]}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="number">实际数字</Radio>
                <Radio value="percent">百分比</Radio>
                <Radio value="numAndPct">百分比+(数字)</Radio>
                <Radio value="setTxt">填写文案</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"字段名换行"}
            name={["msgSet", "wrap"]}
            extra={"字段名单行显示"}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"处理大数字"}
            name={["msgSet", "bigNumber"]}
            rules={[{required: true}]}
            extra={"当数量超过1万时，以W为单位展示"}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"每项间距"}
            rules={[{required: true}]}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["msgSet", "gap"],{
                min: 0,
                max: 50,
                addonAfter: "rem"
            })}
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues) => prevValues.msgSet.type !== currentValues.msgSet.type}

        >
            {({getFieldValue})=>{
                let v;
                switch (getFieldValue(["msgSet","type"])){
                    case "numAndPct":
                    case "percent":v = <>
                        <Item
                            label={"保留"}
                            name={["msgSet", "precision"]}
                            rules={[{required: true}]}
                        >
                            <InputNumber
                                min={0}
                                max={4}
                                step={1}
                                precision={0}
                                addonAfter={"位小数"}
                            />
                        </Item>
                        <Item
                            label={"展示第一段"}
                            name={["msgSet", "showFirst"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                        <Item
                            label={"展示第二段"}
                            name={["msgSet", "showSecond"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                    </>
                            break;
                    case "number": v = <>
                        <Item
                            label={"展示总量"}
                            name={["msgSet", "showTotal"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                        <Item
                            label={"展示第一段"}
                            name={["msgSet", "showFirst"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                        <Item
                            label={"展示第二段"}
                            name={["msgSet", "showSecond"]}
                            rules={[{required: true}]}
                            valuePropName={"checked"}
                        >
                            <Switch
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                    </>
                        break;
                    case "setTxt": v = <>
                        <Item
                            label={"填写内容"}
                            name={["msgSet", "textContent"]}
                        >
                            <Input
                                maxLength={50}
                            />
                        </Item>
                    </>
                        break;
                }
                return v;
            }}
        </Item>
        {CommonFormItems.getFontSet("msgFont",true)}
        <Divider orientation="left">
            扩展设置
        </Divider>
        <Item
            label={"进度条类型"}
            name={"type"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="line">直线</Radio>
                <Radio value="circle">圆形</Radio>
                <Radio value="dashboard">仪表板</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle={true}
            dependencies={["type"]}
        >
            {({getFieldValue})=>{
                let v;
                switch (getFieldValue(["type"])){
                    case "line": v = <>
                        <Item
                            label={"进度条线的宽度"}
                        >
                            {CommonFormItems.getSliderAndInputItemGroup(["lineSet","strokeWidth"],{
                                min: 2,
                                max: 50,
                                addonAfter: "px"
                            })}
                        </Item>
                    </>
                        break;
                    case "circle": v = <>
                        <Item
                            label={"进度条线的宽度"}
                            extra={"与直线型不同，这里宽度的单位是百分比"}
                        >
                            {CommonFormItems.getSliderAndInputItemGroup(["circleSet","strokeWidth"],{
                                min: 1,
                                max: 50,
                                addonAfter: "%"
                            })}
                        </Item>
                    </>
                        break;
                    case "dashboard": v = <>
                        <Item
                            label={"缺口角度"}
                        >
                            {CommonFormItems.getSliderAndInputItemGroup(["dashboardSet","gapDegree"],{
                                min: 0,
                                max: 295,
                            })}
                        </Item>
                        <Item
                            label={"缺口位置"}
                            name={["dashboardSet","gapPosition"]}
                        >
                            <Radio.Group>
                                <Radio value="top">上</Radio>
                                <Radio value="bottom">下</Radio>
                                <Radio value="left">左</Radio>
                                <Radio value="right">右</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            label={"进度条线的宽度"}
                            extra={"与直线型不同，这里宽度的单位是百分比"}
                        >
                            {CommonFormItems.getSliderAndInputItemGroup(["dashboardSet","strokeWidth"],{
                                min: 1,
                                max: 50,
                                addonAfter: "%"
                            })}
                        </Item>
                    </>
                        break;
                }
                return v;
            }}
        </Item>
    </>
}

function getDefaultValues(){
    return {
        totalField: null,
        firstField: null,
        secondField: null,
        firstColor: "#05ec05ff",
        secondColor: "#ff0000ff",
        trailColor: "#5c5c5caa",
        strokeLinecap: "round",
        type: "circle",
        lineSet: {
            strokeWidth: 10,
        },
        circleSet: {
            strokeWidth: 6,
        },
        dashboardSet: {
            gapDegree: 75,
            strokeWidth: 6,
            gapPosition: "bottom"
        },
        msgSet: {
            wrap: false,
            gap: 10,
            showTotal: true,
            showFirst: true,
            showSecond: true,
            type: "number", //number  percent   setTxt
            textContent: null,
            precision: 2,   //精确位数
            bigNumber: false,
        },
        msgFont: {
            fontFamily: "default",
            color: "",
            fontSize: 16,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        }
    }
}

//推荐数据格式
function getDemoCode(){
    return `
    以下示例中 total、success、error 均可在 数据对接设置 中修改替换成其它单词
    {
        total: 2000,
        success: 1200,
        error: 500,
    }`
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}