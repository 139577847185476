import React from "react";
import { AutoComplete,Input } from "antd";

export default class StringShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.contentMsg?.dataConfig?.parameterValue
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.contentMsg?.dataConfig?.parameterValue !== nextProps.contentMsg?.dataConfig?.parameterValue) {
            console.log("新的值222",nextProps.contentMsg?.dataConfig?.parameterValue)
            this.setState({
                value: nextProps.contentMsg?.dataConfig?.parameterValue
            })
        }
    }

    setValue(newValue){
        console.log("新的值",newValue)
        this.setState({
            value: newValue
        },()=>{
            this.props.valueChanged?.(newValue)
        })
    }

    render(){
        let {contentParams} = this.props.contentMsg.dataConfig;
        let {value} = this.state;
        if(!contentParams){
            return null
        }

        let options = []
        if(contentParams.list?.length){
            contentParams.list.forEach((str)=>{
                options.push({
                    label: str,
                    value: str
                })
            })
        }
        if(contentParams.listSpecial?.length){
            contentParams.listSpecial.forEach((item)=>{
                if(item.label){
                    options.push({
                        label: item.label,
                        value: item.value
                    })
                }
            })
        }

        return <AutoComplete
          options={options}
          onSelect={(v)=>{
              this.setState({
                  value: v
              },()=>{
                  this.props.valueChanged?.(v)
              })
          }}
          value={value}
        >
            <Input
              allowClear={true}
              {...contentParams.inputOption}

              onChange={(e)=>{
                  this.setState({
                      value: e.target.value
                  })
              }}
              onBlur={(e)=>{
                  this.props.valueChanged?.(value)
              }}
              onDoubleClick={(e)=>{
                  let inputDom = e.target;
                  if(inputDom){
                      inputDom.focus();
                      let contentLen = inputDom.value.length;
                      inputDom.setSelectionRange(0, contentLen);
                  }
              }}
              onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                      this.props.valueChanged?.(value)
                  }
              }}
            />
        </AutoComplete>
    }
}