import React from "react";
import styles from "./DeviceStaticsChart.less";
import APILXD from "../../../http/APILXD";
import {Select, Tooltip, message,Button,Modal} from "antd";
// import * as echarts from "echarts";
import TimeHelp from "../../../tool/TimeHelp";
import axios from "axios";
// import MapShowComponent from "./MapShowComponent";
import MapContainer from "./MapContainer";
import HisAddView from "./component/HisAddView";
import HisOnOffLineView from "./component/HisOnOffLineView";
import HttpTool from "../../../tool/HttpTool";
import CookieHelp from "../../../tool/CookieHelp";
import {EyeOutlined,EyeInvisibleOutlined,QuestionCircleOutlined} from "@ant-design/icons";

export default class DeviceStaticsChart extends React.Component{
    constructor(props) {
        super(props);

        let userInfo = CookieHelp.getUserInfo();
        this.role = userInfo && userInfo.info && userInfo.info.role || 2;

        this.state = {
            config: null,
            selType: this.role === 1?2:1,//厂商看包含tv，超级管理员看可工作
            selRomIds: [],
            deviceTotal: null,
            allRomIds: [],
            closeBoxMap: {},
            boxOneMsg: null,    //每月在线数量平均值
            boxTwoMsg: null,        //每月损耗数据
            boxThreeMsg: null,
            boxFourMsg: null,
            boxFiveMsg: null,
            deviceCountrysData: null,   //每个国家设备数量
            isOnline: 1,
            isMonthOnline: 1,
        }


        this.userRoms = userInfo && userInfo.info && userInfo.info.romIds || [];
    }

    componentDidMount() {
        this.setBaseChartOption()

        this.getAllGlobalConfigs();
        if(this.role === 1){
            this.getAllHeartBeatRomIds()
        }
    }

    //配置基础图表项
    setBaseChartOption(){
        this.optionForBoxOne = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "50px",
                right: "50px",
                top: "40px",
                bottom: "10px"
            },
            xAxis: {
                name: "月份",
                type: 'category',
                data: []
            },
            yAxis: {
                name: "达标设备数（台）",
                type: 'value',
                minInterval: 1,
            },
            series: [
                {
                    name: '达标设备数',
                    type: 'line',
                    lineStyle: {
                      color: "dodgerblue"
                    },
                    itemStyle: {
                        color: "dodgerblue"
                    },
                    data: []
                },
            ]
        }
        this.optionForBoxTwo = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {
                    let data = params[0];
                    return (data.axisValue) + '<br>设备总量: '+data.data.total +' 台<br>损耗量: ' + data.data.loss + ' 台<br>损耗率: '+ data.data.value + ' %';
                }
            },
            grid: {
                containLabel: true,
                left: "50px",
                right: "50px",
                top: "40px",
                bottom: "0px"
            },
            xAxis: {
                name: "日期",
                type: 'category',
                axisLabel: {
                    rotate: -45,
                },
                data: []
            },
            yAxis: {
                name: "损耗比率（%）",
                type: 'value',
                minInterval: 0.01,
            },
            series: [
                {
                    name: '损耗比率（%）',
                    type: 'line',
                    lineStyle: {
                        color: "red"
                    },
                    itemStyle: {
                        color: "red"
                    },
                    data: []
                },
            ]
        }

        this.optionForBoxThree = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "0px"
            },
            xAxis: {
                name: "日期",
                type: 'category',
                axisLabel: {
                    rotate: -45,
                },
                nameTextStyle: {
                    fontSize: 0
                },
                data: []
            },
            yAxis: {
                name: "新增数（台）",
                type: 'value',
                minInterval: 1,
                nameTextStyle: {
                    padding: [0,0,0,20]
                }
            },
            series: [
                {
                    name: '新增数',
                    type: 'line',
                    lineStyle: {
                        color: "limegreen"
                    },
                    itemStyle: {
                        color: "limegreen"
                    },
                    data: []
                },
            ]
        }

        this.optionForBoxFour = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "20px"
            },
            xAxis: {
                name: "未上线天数",
                type: 'category',
                nameLocation: "center",
                nameGap: 25,
                data: []
            },
            yAxis: {
                name: "数量（台）",
                type: 'value',
                minInterval: 1,
                nameTextStyle: {
                    padding: [0,0,0,20]
                }
            },
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    color: "red",
                    data: []
                },
            ]
        }

        this.optionForBoxFive = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            legend: {
                data: ['离线数量', '在线数量'],
                left: "center"
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "0px"
            },
            xAxis: {
                name: "日期",
                type: 'category',
                axisLabel: {
                    rotate: -45,
                },
                nameTextStyle: {
                    fontSize: 0
                },
                data: []
            },
            yAxis: {
                name: "数量（台）",
                type: 'value',
                minInterval: 1,
                nameTextStyle: {
                    padding: [0,0,0,20]
                }
            },
            series: [
                {
                    name: '离线数量',
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    color: "darkorange",
                    data: []
                },
                {
                    name: '在线数量',
                    type: 'line',
                    stack: 'Total',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    color: "dodgerblue",
                    data: []
                },
            ]
        }

        this.optionForDeviceCountrysData = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "20px"
            },
            xAxis: {
                name: "",
                type: 'category',
                nameLocation: "center",
                nameGap: 25,
                axisLabel: {
                    rotate: -45,
                },
                data: []
            },
            yAxis: {
                name: "数量（台）",
                type: 'value',
                minInterval: 1,
                nameTextStyle: {
                    padding: [0,0,0,20]
                }
            },
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    color: "dodgerblue",
                    data: []
                },
            ],
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                }
            ]
        }

        this.optionForDeviceSizeData = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
            },
            grid: {
                containLabel: true,
                left: "20px",
                right: "20px",
                top: "40px",
                bottom: "20px"
            },
            xAxis: {
                name: "",
                type: 'category',
                nameLocation: "center",
                nameGap: 25,
                axisLabel: {
                    rotate: -45,
                },
                data: []
            },
            yAxis: {
                name: "数量（台）",
                type: 'value',
                minInterval: 1,
                nameTextStyle: {
                    padding: [0,0,0,20]
                }
            },
            series: [
                {
                    name: '数量',
                    type: 'bar',
                    color: "#fff531",
                    data: []
                },
            ],
            dataZoom: [
                {
                    type: "slider",
                    show: true,
                }
            ]
        }

        setTimeout(()=>{
            this.boxOneChart = echarts.init(this.boxOne);
            this.boxTwoChart = echarts.init(this.boxTwo);
            this.boxThreeChart = echarts.init(this.boxThree);
            this.boxFourChart = echarts.init(this.boxFour);
            this.boxFiveChart = echarts.init(this.boxFive);
            this.boxDeviceCountryChart = echarts.init(this.boxDeviceCountrysData);
            this.boxDeviceSizeChart = echarts.init(this.boxDeviceSizeData);
            this.refetchData()
        })
    }

    refetchData(){
        this.getDeviceTotal();
        this.getDeviceMonthStandard();
        this.getDeviceDayLoss();
        this.getLatestThirtyAddDevice();
        this.getOfflineThirtyDevice()
        this.getOnOfflineDeviceRate();
        this.getDeviceCountrysData()
        this.getDeviceLocation()
        this.getDeviceSize()
    }

    getDeviceCountrysData(){
        let {selRomIds,selType,isOnline} = this.state;
        HttpTool.post(APILXD.getDeviceAreaTotalMsg,(code,msg,json)=>{

            this.renderBoxDeviceCountrysData(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            runStatus: selType,
            isOnline: isOnline,
        })
    }

    getAllHeartBeatRomIds(){
        HttpTool.post(APILXD.getHeartBeatRomIds,(code,msg,json)=>{
            this.setState({
                allRomIds: json
            })
        },(code,msg)=>{
            message.error(msg);
        },{})
    }

    getDeviceTotal(){
        let {selRomIds} = this.state;
        HttpTool.post(APILXD.getDeviceTotal,(code,msg,json)=>{
            this.setState({
                deviceTotal: json
            })
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null
        })
    }

    //获取系统配置
    getAllGlobalConfigs() {
        let param = {};
        let successCB = (code, msg, json, option) => {
            this.setState({
                config: json,
            });
        };
        let failureCB = (code, msg) => {
            message.error(msg);
        };

        HttpTool.post(APILXD.getAllGlobalConfigs, successCB, failureCB, param);
    }

    //获取设备每月在线达标数
    getDeviceMonthStandard(){
        let {selRomIds,selType} = this.state;
        HttpTool.post(APILXD.getDeviceMonthStandard,(code,msg,json)=>{

            this.renderBoxOneChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            runStatus: selType
        })
    }

    //获取设备每日损耗情况
    getDeviceDayLoss(){
        let {selRomIds} = this.state;
        HttpTool.post(APILXD.getDeviceDayLoss,(code,msg,json)=>{

            this.renderBoxTwoChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
        })
    }

    //获取最近30天新增设备
    getLatestThirtyAddDevice(){
        let {selRomIds} = this.state;
        HttpTool.post(APILXD.getLatestThirtyAddDevice,(code,msg,json)=>{

            this.renderBoxThreeChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
        })
    }

    getOfflineThirtyDevice(){
        let {selRomIds,selType} = this.state;
        HttpTool.post(APILXD.getOfflineThirtyDevice,(code,msg,json)=>{

            this.renderBoxFourChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            runStatus: selType
        })
    }

    getOnOfflineDeviceRate(){
        let {selRomIds,selType} = this.state;
        HttpTool.post(APILXD.getOnOfflineDeviceRate,(code,msg,json)=>{

            this.renderBoxFiveChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            runStatus: selType
        })
    }

    getDeviceSize(){
        let {selRomIds,isMonthOnline} = this.state;
        HttpTool.post(APILXD.getDeviceFreeSize,(code,msg,json)=>{
            this.renderBoxSizeChart(json)
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            isMonthOnline: isMonthOnline
        })
    }

    getDeviceLocation(){
        let {selRomIds,selType,isOnline} = this.state;
        HttpTool.post(APILXD.getDeviceLocation,(code,msg,json)=>{
           if(!json.key){
               //无数据
               this.mapShowCompent && this.mapShowCompent.renderDataToMap({
                   devices: [],
                   zoom: 1,
               })
           }else{
               this.downDataFile(json.host , json.key)
           }
        },(code,msg)=>{
            message.error(msg);
        },{
            romIds: selRomIds.length?selRomIds:null,
            runStatus: selType,
            isOnline: isOnline,
        })
    }

    //从阿里云下载数据文件
    downDataFile(ossHost,fileUrl){
        let {selType} = this.state;
        this.time = Date.now();
        axios({
            method: 'get',
            url: ossHost + "/" + fileUrl,
            headers: {
                "Content-Type": "text/plain",
            },
        }).then(({status, data}) => {
            if (status === 200) {
                let dataUnzip = window.unzip(data);
                let dataStrArr = dataUnzip.split(/[\r\n]/);
                let dataArr = [];
                dataStrArr.forEach((str)=>{
                    if(str){
                        let temp = str.split("|");

                        //temp[4]   1:离线;2:看电视;3:空闲
                        // 包含tv中
                        //
                        // 1：离线，   2，,3 都是 在线
                        //
                        //
                        // 以可工作为准
                        //
                        // 1，2 离线，    3：在线
                        let status = selType === 1?(temp[4]==1?1:2):(temp[4]==3?2:1)

                        dataArr.push({
                            _id: temp[0] || null,
                            lng: (temp[1])?temp[1]:null,
                            lat: (temp[2])?temp[2]:null,
                            name: temp[3] || null,
                            status: status,
                            gender: temp[5] || null,
                        })
                    }
                })
                // for(let a of dataArr){
                //     console.log("longlatStr",a.lat,a.lng ,a._id)
                // }
                dataArr =  _.orderBy(dataArr, ['lng','lat'], ['desc','desc']);
                // for(let a of dataArr){
                //     console.log("longlatStr",a.lat,a.lng,a._id)
                // }

                let zoom = 1;

                this.mapShowCompent && this.mapShowCompent.renderDataToMap({
                    devices: dataArr,
                    zoom: zoom,
                },ossHost)
            } else {
                this.setState({
                    loading: false,
                })
                message.error("加载数据出错");
            }
        }).catch((err) => {
            if(err.toString() === "Cannot read properties of undefined (reading 'setOffset')"){
                //切换太快导致echart渲染出错
            }else{
                message.error('处理出错');
                console.error(err);
            }
            this.setState({
                loading: false,
            })
        });
    }

    renderBoxSizeChart(data){
        let option = _.cloneDeep(this.optionForDeviceSizeData);

        let xData = [];
        let vData = [];

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            xData.push(item.fsgrade);
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;

        this.boxDeviceSizeChart && this.boxDeviceSizeChart.setOption(option)
    }

    renderBoxOneChart(data){
        let option = _.cloneDeep(this.optionForBoxOne);

        let xData = [];
        let vData = [];

        let total = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"yyyy-MM"));
            total+=item.total;
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;

        let average = data.length?Math.round(total/data.length):0;
        this.setState({
            boxOneMsg: {
                average
            }
        })
        this.boxOneChart && this.boxOneChart.setOption(option)
    }

    renderBoxTwoChart(data){
        let option = _.cloneDeep(this.optionForBoxTwo);

        let xData = [];
        let vData = [];


        data.forEach((item)=>{
            vData.push({
                value: item.total?(item.loss * 100/item.total).toFixed(2):0,
                loss: item.loss,
                total: item.total,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"yyyy-MM-dd"));
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;

        let lastDayData = data[data.length - 1];
        if(lastDayData && lastDayData.total){
            let boxTwoTotalLoss = (lastDayData.loss * 100/lastDayData.total).toFixed(2);
            let boxTwoAverageLoss = (boxTwoTotalLoss/data.length).toFixed(2);

            this.setState({
                boxTwoMsg: {
                    total: lastDayData.total,
                    loss: lastDayData.loss,
                    boxTwoTotalLoss: boxTwoTotalLoss,
                    boxTwoAverageLoss: boxTwoAverageLoss,
                },
            })
        }

        this.boxTwoChart && this.boxTwoChart.setOption(option)
    }

    renderBoxThreeChart(data){
        let option = _.cloneDeep(this.optionForBoxThree);

        let xData = [];
        let vData = [];

        let total = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"MM-dd"));
            total+=item.total;
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;

        this.setState({
            boxThreeMsg: {
                total
            }
        })
        this.boxThreeChart && this.boxThreeChart.setOption(option)
    }

    renderBoxFourChart(data){
        let option = _.cloneDeep(this.optionForBoxFour);

        let xData = [];
        let vData = [];

        let total = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            xData.push(item.days);
            total+=item.total;
        })

        option.series[0].data = vData;
        option.xAxis.data = xData;
        option.xAxis.name = this.state.selType === 1?"连续未上线天数":"连续无法工作天数";

        this.setState({
            boxFourMsg: {
                total
            }
        })
        this.boxFourChart && this.boxFourChart.setOption(option)
    }

    renderBoxFiveChart(data){
        let option = _.cloneDeep(this.optionForBoxFive);

        let xData = [];
        let vData = [];
        let vData2 = [];

        let totalOffline = 0;
        let totalOnline = 0;

        data.forEach((item)=>{
            vData.push({
                value: item.offline,
            });
            vData2.push({
                value: item.online,
            });
            xData.push(TimeHelp.getYMDFormat(item.time,"MM-dd"));
            totalOffline+=item.offline;
            totalOnline+=item.online;
        })

        option.series[0].data = vData;
        option.series[0].name = this.state.selType === 1?"离线数量":"不可工作数量";
        option.series[1].data = vData2;
        option.series[1].name = this.state.selType === 1?"在线数量":"可工作数量";
        option.xAxis.data = xData;
        option.legend.data = this.state.selType === 1?["离线数量","在线数量"]:["不可工作数量","可工作数量"];

        this.setState({
            boxFiveMsg: {
                offlineAverage: (totalOffline * 100/(totalOffline + totalOnline)).toFixed(2),
                onlineAverage: (totalOnline * 100/(totalOffline + totalOnline)).toFixed(2),
            }
        })
        this.boxFiveChart && this.boxFiveChart.setOption(option)
    }

    renderBoxDeviceCountrysData(data){
        let {selRomIds,selType,isOnline} = this.state;
        let option = _.cloneDeep(this.optionForDeviceCountrysData);

        let xData = [];
        let vData = [];

        data.forEach((item)=>{
            vData.push({
                value: item.total,
            });
            if(item.name === "香港" || item.name === "澳门" || item.name === "台湾"){
                item.name = "中国" + item.name
            }
            xData.push(item.name);
        })

        option.series[0].data = vData;
        option.series[0].color = isOnline === 1?"dodgerblue":(isOnline === 2?"limegreen":"red");
        option.xAxis.data = xData;

        this.boxDeviceCountryChart && this.boxDeviceCountryChart.setOption(option)
    }


    render(){
        let {config,selType,allRomIds,selRomIds,deviceTotal,closeBoxMap,boxOneMsg,boxTwoMsg,boxThreeMsg,boxFourMsg,boxFiveMsg} = this.state;
        let couldSelRom = this.role === 1?allRomIds:this.userRoms;
        return <div className={styles.mainPage}>
            <div className={styles.header}>
                <div className={styles.headTitle}>设备在线分析</div>
                <div>
                    <div className={styles.headItem}>在线状态依据：
                        <Select
                            style={{width: "120px"}}
                            value={selType}
                            onChange={(value)=>{
                                this.setState({
                                    selType: value
                                },()=>{
                                    this.refetchData()
                                })
                            }}
                        >
                            <Select.Option value={1}>包含TV中</Select.Option>
                            <Select.Option value={2}>以可工作为准</Select.Option>
                        </Select>
                    </div>
                    <div className={styles.headItem}>系统：
                        <Select
                            allowClear={true}
                            placeholder={"选择系统过滤"}
                            style={{width: "200px"}}
                            mode={"multiple"}
                            value={selRomIds}
                            onChange={(value)=>{
                                this.setState({
                                    selRomIds: value
                                },()=>{
                                    this.refetchData()
                                })
                            }}
                        >
                            {
                                couldSelRom.map((o)=>{
                                    return <Select.Option
                                        key={o}
                                        value={o}
                                    >
                                        {o}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.deviceTotalBox}>
                    <div className={styles.totalItemBox}>
                        <div className={styles.tibTitle}>所有设备数量</div>
                        <div className={styles.tibValue}>{deviceTotal&&window.formatPrice(deviceTotal.total)}</div>
                    </div>
                    <div className={styles.totalItemBox}>
                        <div className={styles.tibTitle}>当前在线</div>
                        <div className={styles.tibValue}>
                            <span style={{color: "limegreen"}}>{deviceTotal&&window.formatPrice(deviceTotal.online)}</span>
                            <div className={styles.onlineExtraBox}>
                                <div className={styles.oebItem}>
                                    <div>可用</div>
                                    <div>{deviceTotal&&window.formatPrice(deviceTotal.free)}</div>
                                </div>
                                <div className={styles.oebItem}>
                                    <div>TV中</div>
                                    <div>{deviceTotal&&window.formatPrice(deviceTotal.tv)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.totalItemBox}>
                        <div className={styles.tibTitle}>当前离线</div>
                        <div className={styles.tibValue}>
                            <span style={{color: "red"}}>{deviceTotal&&window.formatPrice(deviceTotal.offline)}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.chartOuterBox}>
                    <div className={styles.chartHeadBox}>
                        <div className={styles.chTitle}>
                            <Tooltip
                                placement={"top"}
                                title={selType === 1?"设备每月在线数量":"设备每月工作数量"}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            {selType === 1?"设备每月在线数量":"设备每月工作数量"}
                        </div>
                        <div
                            onClick={()=>{
                                closeBoxMap["boxOne"] = !closeBoxMap["boxOne"];
                                this.setState({
                                    closeBoxMap
                                })
                            }}
                        >
                            {
                                closeBoxMap["boxOne"]
                                  ?<EyeOutlined className={styles.eyeIcon}/>
                                  :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                            }
                        </div>
                        <div className={styles.chDesc}>每月在线时长达 {config && config.monthOnlineHour} 小时</div>
                    </div>
                    <div
                        className={styles.chartBodyBox}
                        style={closeBoxMap["boxOne"]?{height: 0}:{}}
                    >
                        <div
                            ref={ref=>this.boxOne = ref}
                            className={styles.chartContainer}
                        >
                            {
                                boxOneMsg!==null
                                    ?<div className={styles.boxOneExtra}>每月平均：{boxOneMsg.average}</div>
                                    :null
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.chartOuterBox}>
                    <div className={styles.chartHeadBox}>
                        <div className={styles.chTitle}>
                            <Tooltip
                                placement={"top"}
                                title={"设备损耗情况"}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            设备损耗情况</div>
                        <div
                            onClick={()=>{
                                closeBoxMap["boxTwo"] = !closeBoxMap["boxTwo"];
                                this.setState({
                                    closeBoxMap
                                })
                            }}
                        >
                            {
                                closeBoxMap["boxTwo"]
                                  ?<EyeOutlined className={styles.eyeIcon}/>
                                  :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                            }
                        </div>
                    </div>
                    <div
                        className={styles.chartBodyBox}
                        style={closeBoxMap["boxTwo"]?{height: 0}:{}}
                    >
                        <div
                            ref={ref=>this.boxTwo = ref}
                            className={styles.chartContainer}
                        >
                            {
                                boxTwoMsg!==null
                                    ?<div className={styles.boxTwoExtra}>
                                        <div>全部损耗率：{boxTwoMsg.boxTwoTotalLoss} %</div>
                                        <div>平均损耗率：{boxTwoMsg.boxTwoAverageLoss} %</div>
                                    </div>
                                    :null
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.chartGroupBox}>
                    <div className={styles.chartOuterBox}
                        style={{flex: 1}}
                    >
                        <div className={styles.chartHeadBox}>
                            <div className={styles.chTitle}>
                                <Tooltip
                                    placement={"top"}
                                    title={"最近30天新增设备"}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                                最近30天新增设备</div>
                            <Button
                                size={"small"}
                                type={"primary"}
                                ghost={true}
                                onClick={()=>{
                                    this.openHisAddModal()
                                }}
                            >
                                历史
                            </Button>
                            <div
                                onClick={()=>{
                                    closeBoxMap["boxThreeGroup"] = !closeBoxMap["boxThreeGroup"];
                                    this.setState({
                                        closeBoxMap
                                    })
                                }}
                            >
                                {
                                    closeBoxMap["boxThreeGroup"]
                                      ?<EyeOutlined className={styles.eyeIcon}/>
                                      :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                                }
                            </div>
                        </div>
                        <div
                            className={styles.chartBodyBox}
                            style={closeBoxMap["boxThreeGroup"]?{height: 0}:{}}
                        >
                            <div
                                ref={ref=>this.boxThree = ref}
                                className={styles.chartContainer}
                            >
                                {
                                    boxThreeMsg!==null
                                        ?<div className={styles.boxThreeExtra}>
                                            <div>总数量：{boxThreeMsg.total}</div>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.chartOuterBox}
                         style={{flex: 1}}
                    >
                        <div className={styles.chartHeadBox}>
                            <div className={styles.chTitle}>
                                <Tooltip
                                    placement={"top"}
                                    title={selType === 1?"超过30天未上线设备":"超过30天无法工作设备"}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                                {selType === 1?"超过30天未上线设备":"超过30天无法工作设备"}</div>
                            <div
                                onClick={()=>{
                                    closeBoxMap["boxThreeGroup"] = !closeBoxMap["boxThreeGroup"];
                                    this.setState({
                                        closeBoxMap
                                    })
                                }}
                            >
                                {
                                    closeBoxMap["boxThreeGroup"]
                                      ?<EyeOutlined className={styles.eyeIcon}/>
                                      :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                                }
                            </div>
                        </div>
                        <div
                            className={styles.chartBodyBox}
                            style={closeBoxMap["boxThreeGroup"]?{height: 0}:{}}
                        >
                            <div
                                ref={ref=>this.boxFour = ref}
                                className={styles.chartContainer}
                            >
                                {
                                    boxFourMsg!==null
                                        ?<div className={styles.boxTwoExtra}>
                                            <div>总数量：{boxFourMsg.total}</div>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.chartOuterBox}
                         style={{flex: 1}}
                    >
                        <div className={styles.chartHeadBox}>
                            <div className={styles.chTitle}>
                                <Tooltip
                                    placement={"top"}
                                    title={selType === 1?"最近30天设备在线比":"最近30天设备可工作比"}
                                >
                                    <QuestionCircleOutlined />
                                </Tooltip>
                                {selType === 1?"最近30天设备在线比":"最近30天设备可工作比"}
                            </div>
                            <Button
                                size={"small"}
                                type={"primary"}
                                ghost={true}
                                onClick={()=>{
                                    this.openHisOnOfflineModal()
                                }}
                            >
                                历史
                            </Button>
                            <div
                                onClick={()=>{
                                    closeBoxMap["boxThreeGroup"] = !closeBoxMap["boxThreeGroup"];
                                    this.setState({
                                        closeBoxMap
                                    })
                                }}
                            >
                                {
                                    closeBoxMap["boxThreeGroup"]
                                      ?<EyeOutlined className={styles.eyeIcon}/>
                                      :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                                }
                            </div>
                        </div>
                        <div
                            className={styles.chartBodyBox}
                            style={closeBoxMap["boxThreeGroup"]?{height: 0}:{}}
                        >
                            <div
                                ref={ref=>this.boxFive = ref}
                                className={styles.chartContainer}
                            >
                                {
                                    boxFiveMsg!==null
                                        ?<div className={styles.boxFiveExtra}>
                                            <div
                                                style={{color: "dodgerblue"}}
                                            >
                                                {selType === 1?"平均在线率":"平均可工作率"}
                                                ：{boxFiveMsg.onlineAverage} %</div>
                                            <div
                                                style={{color: "darkorange"}}
                                            >
                                                {selType === 1?"平均离线率":"平均无法工作率"}
                                                ：{boxFiveMsg.offlineAverage} %</div>
                                        </div>
                                        :null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.chartOuterBox}
                    style={{flex: 1}}
                >
                    <div className={styles.chartHeadBox}>
                        <div className={styles.chTitle}>
                            <Tooltip
                              placement={"top"}
                              title={"设备剩余空间统计"}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            设备剩余空间统计
                        </div>
                        <div
                          onClick={()=>{
                              closeBoxMap["boxSizeGroup"] = !closeBoxMap["boxSizeGroup"];
                              this.setState({
                                  closeBoxMap
                              })
                          }}
                        >
                            {
                                closeBoxMap["boxSizeGroup"]
                                  ?<EyeOutlined className={styles.eyeIcon}/>
                                  :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                            }
                        </div>
                        <Select
                          value={this.state.isMonthOnline}
                          size={"small"}
                          style={{width: "160px"}}
                          onChange={(value)=>{
                              this.setState({
                                  isMonthOnline: value
                              },()=>{
                                  this.getDeviceSize()
                              })
                          }}
                        >
                            <Select.Option value={1}>
                                最近30天在线
                            </Select.Option>
                            <Select.Option value={2}>全部</Select.Option>
                        </Select>
                    </div>
                    <div
                      className={styles.chartBodyBox}
                      style={closeBoxMap["boxSizeGroup"]?{height: 0}:{}}
                    >
                        <div
                          ref={ref=>this.boxDeviceSizeData = ref}
                          className={styles.chartContainer}
                        >
                        </div>
                    </div>
                </div>
                <div className={styles.chartOuterBox}
                     style={{flex: 1}}
                >
                    <div className={styles.chartHeadBox}>
                        <div className={styles.chTitle}>
                            <Tooltip
                                placement={"top"}
                                title={"设备分布区域数量统计"}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            设备分布区域数量统计
                        </div>
                        <div
                            onClick={()=>{
                                closeBoxMap["boxFiveGroup"] = !closeBoxMap["boxFiveGroup"];
                                this.setState({
                                    closeBoxMap
                                })
                            }}
                        >
                            {
                                closeBoxMap["boxFiveGroup"]
                                  ?<EyeOutlined className={styles.eyeIcon}/>
                                  :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                            }
                        </div>
                        <Select
                            value={this.state.isOnline}
                            size={"small"}
                            style={{width: "160px"}}
                            onChange={(value)=>{
                                this.setState({
                                    isOnline: value
                                },()=>{
                                    this.getDeviceCountrysData()
                                    this.getDeviceLocation()
                                })
                            }}
                        >
                            <Select.Option value={1}>全部</Select.Option>
                            <Select.Option value={2}>
                                {selType === 1?"在线":"可工作"}
                            </Select.Option>
                            <Select.Option value={3}>
                                {selType === 1?"离线":"不可工作"}
                            </Select.Option>
                        </Select>
                    </div>
                    <div
                        className={styles.chartBodyBox}
                        style={closeBoxMap["boxFiveGroup"]?{height: 0}:{}}
                    >
                        <div
                            ref={ref=>this.boxDeviceCountrysData = ref}
                            className={styles.chartContainer}
                        >
                        </div>
                    </div>
                </div>
                <div className={styles.chartOuterBox}
                     style={{flex: 1}}
                >
                    <div className={styles.chartHeadBox}>
                        <div className={styles.chTitle}>
                            <Tooltip
                                placement={"top"}
                                title={"当前设备分布"}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            当前设备分布
                        </div>
                        <div
                            onClick={()=>{
                                closeBoxMap["boxThreeSix"] = !closeBoxMap["boxThreeSix"];
                                this.setState({
                                    closeBoxMap
                                })
                            }}
                        >
                            {
                                closeBoxMap["boxThreeSix"]
                                  ?<EyeOutlined className={styles.eyeIcon}/>
                                  :<EyeInvisibleOutlined className={styles.eyeIcon}/>
                            }
                        </div>
                        <Select
                            value={this.state.isOnline}
                            size={"small"}
                            style={{width: "160px"}}
                            onChange={(value)=>{
                                this.setState({
                                    isOnline: value
                                },()=>{
                                    this.getDeviceCountrysData()
                                    this.getDeviceLocation()
                                })
                            }}
                        >
                            <Select.Option value={1}>全部</Select.Option>
                            <Select.Option value={2}>
                                {selType === 1?"在线":"可工作"}
                            </Select.Option>
                            <Select.Option value={3}>
                                {selType === 1?"离线":"不可工作"}
                            </Select.Option>
                        </Select>
                    </div>
                    <div
                        className={styles.chartBodyBox}
                        style={closeBoxMap["boxThreeSix"]?{height: 0}:{height: 600}}
                    >
                        <div
                            ref={ref=>this.boxSix = ref}
                            className={styles.chartContainer}
                        >
                            <MapContainer
                                ref={ref=>this.mapShowCompent = ref}
                                style={this.props.mapComponentStyle || {padding: "10px 10px 0"}}
                                hideBottomMsg={this.props.hideBottomMsg}
                                defaultPosition={this.props.defaultPosition}
                                diyMapStyle={this.props.diyMapStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    openHisAddModal(){
        Modal.info({
            title: "历史新增设备",
            width: "90%",
            content: <div style={{height: "60vh"}}>
                <HisAddView
                    selRomIds={this.state.selRomIds}
                />
            </div>
        })
    }

    openHisOnOfflineModal(){
        Modal.info({
            title: this.state.selType === 1?"最近30天设备在线比":"最近30天设备可工作比",
            width: "90%",
            content: <div style={{height: "60vh"}}>
                <HisOnOffLineView
                    selRomIds={this.state.selRomIds}
                    selType={this.state.selType}
                />
            </div>
        })
    }
}