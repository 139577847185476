import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";

export default class RadarShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {dataConfig} = this.props.contentMsg;

        let option = {}

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;

        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position){
            case "top": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "center",
            });break;
            case "bottom": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "center",
            });break;
            case "left": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "5%",
            });break;
            case "right": option.legend =  Object.assign(option.legend,{
                top: "center",
                right: "5%",
            });break;
            case "topLeft": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "5%",
            });break;
            case "topRight": option.legend =  Object.assign(option.legend,{
                top: "5%",
                right: "5%",
            });break;
            case "bottomLeft": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "5%",
            });break;
            case "bottomRight": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                right: "5%",
            });break;
            case "center": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "center",
            });break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        //雷达图指标轴
        option.radar = {
            center: [dataConfig?.contentParams?.radarSpecial?._centerSetX || "50%",dataConfig?.contentParams?.radarSpecial?._centerSetY || "50%"],
            radius: [(dataConfig?.contentParams?.radarSpecial?._radiusInner || 0) + "%",(dataConfig?.contentParams?.radarSpecial?._radiusOuter || 75) + "%"],
            nameGap: dataConfig?.contentParams?.axisName?._nameGap,
            axisName: dataConfig?.contentParams?.axisName,
            splitNumber: dataConfig?.contentParams?.axisSplit?._splitNumber,
            shape: dataConfig?.contentParams?.radarSpecial?._radarType,
            axisLine: {
                show: dataConfig?.contentParams?.axisSplit?.axisLine?.show,
                symbol: ["none",dataConfig?.contentParams?.axisSplit?.axisLine?.symbol],
                symbolSize: [dataConfig?.contentParams?.axisSplit?.axisLine?._symbolSize,dataConfig?.contentParams?.axisSplit?.axisLine?._symbolSize],
                lineStyle: {
                    color: dataConfig?.contentParams?.axisSplit?._allColor
                }
            },
            axisTick: dataConfig?.contentParams?.axisSplit?.axisTick,
            axisLabel: dataConfig?.contentParams?.axisSplit?.axisLabel,
            splitLine: {
                show: dataConfig?.contentParams?.splitStyle?.splitLine?.show,
                lineStyle: {
                    color: dataConfig?.contentParams?.splitStyle?.splitLine?._color,
                    width: dataConfig?.contentParams?.splitStyle?.splitLine?._width,
                    type: dataConfig?.contentParams?.splitStyle?.splitLine?._type,
                }
            },
            splitArea: {
                show: dataConfig?.contentParams?.splitStyle?.splitArea?.show,
                areaStyle: {
                    color: dataConfig?.contentParams?.splitStyle?.splitArea?._color,
                }
            }
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "radar",
                symbol: dataConfig?.contentParams?.dataItemStyle?.symbol,
                symbolSize: dataConfig?.contentParams?.dataItemStyle?.symbolSize,
            };

            //连线
            seriesItem.lineStyle = {
                width: dataConfig?.contentParams?.dataItemStyle?.lineStyle?.width,
                type: dataConfig?.contentParams?.dataItemStyle?.lineStyle?.type,
                opacity: dataConfig?.contentParams?.dataItemStyle?.lineStyle?._opacity/100,
            }
            //面积
            seriesItem.areaStyle = {
                opacity: dataConfig?.contentParams?.dataItemStyle?.areaStyle?._opacity/100,
            }

            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                    position: dataConfig?.contentParams?.label.position,
                }

                switch (dataConfig?.contentParams?.label._showPlan){
                    case "normal":break;
                    case "pop":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                }

                //需要用到field的特殊配置先修改好
                if(dataConfig?.contentParams?.label?.show){
                    if(dataConfig?.contentParams?.label._beforeTxt || dataConfig?.contentParams?.label._afterTxt){
                        //特殊格式
                        seriesItem.label.formatter = `${dataConfig?.contentParams?.label._beforeTxt}{c}${dataConfig?.contentParams?.label._afterTxt}`
                    }
                }
            }


            //高亮模式
            seriesItem.emphasis = {};
            //高亮下的连线和面积
            if(dataConfig?.contentParams?.dataItemStyle?._emphasisSel?.length){
                if(dataConfig?.contentParams?.dataItemStyle?._emphasisSel?.includes("areaOpacity")){
                    //先继承基础值
                    seriesItem.emphasis.areaStyle = _.cloneDeep(seriesItem.areaStyle);
                    seriesItem.emphasis.areaStyle.opacity = dataConfig?.contentParams?.dataItemStyle?._emphasis?.areaOpacity/100;
                }
                if(dataConfig?.contentParams?.dataItemStyle?._emphasisSel?.includes("lineOpacity")){
                    //先继承基础值
                    seriesItem.emphasis.lineStyle = _.cloneDeep(seriesItem.lineStyle);
                    seriesItem.emphasis.lineStyle.opacity = dataConfig?.contentParams?.dataItemStyle?._emphasis?.lineOpacity/100;
                }
            }
            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }

            //根据数据和配置填充其它属性
            seriesItem.data = [];
            option.radar.indicator = [];

            if(dataConfig?.contentParams?.dataSetConfig?.indicatorField && dataConfig?.contentParams?.dataSetConfig?.valueField){
                if(data === null || data === undefined){
                    //空数据
                }else if(Array.isArray(data) && data.length){
                    //数组类型，指标轴和数据放在一起
                    //判断出是哪种结构
                    if(Array.isArray(data[0][dataConfig?.contentParams?.dataSetConfig?.valueField])){
                        //多系列
                        let seriesItemObjArr = [];
                        //建立系列数组
                        data[0][dataConfig?.contentParams?.dataSetConfig?.valueField].forEach((eachSeriesItem,index)=>{
                            let seriesObj = {
                                value:[],
                                name: "系列" + (index + 1)
                            }
                            if(eachSeriesItem.hasOwnProperty(dataConfig?.contentParams?.dataSetConfig?.seriesField)){
                                //有系列名配置
                                seriesObj.name = eachSeriesItem[dataConfig?.contentParams?.dataSetConfig?.seriesField]
                            }
                            seriesItemObjArr.push(seriesObj);
                        })

                        //建立指标轴和填充数据项目
                        let hasMax = true;
                        let maxNum = 0;
                        data.forEach((eachDataItem,index)=>{
                            let indicatorItem = {
                                name: eachDataItem[dataConfig?.contentParams?.dataSetConfig?.indicatorField],
                                min: 0
                            }

                            if(indicatorItem.name === undefined){
                                indicatorItem.name = "未匹配到指标"
                            }

                            if(dataConfig?.contentParams?.dataSetConfig?.maxField && !isNaN(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                                //最大值的设定方式是 指定 数字
                                indicatorItem.max = Number(dataConfig?.contentParams?.dataSetConfig?.maxField)
                            }else if(eachDataItem.hasOwnProperty(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                                //指定了字段
                                indicatorItem.max = eachDataItem[dataConfig?.contentParams?.dataSetConfig?.maxField]
                            }else {
                                hasMax = false;
                            }
                            option.radar.indicator.push(indicatorItem);
                            eachDataItem[dataConfig?.contentParams?.dataSetConfig?.valueField].forEach((eachSeriesItem,serValueIndex)=>{
                                //给每个系列对应位置填值
                                let v = typeof eachSeriesItem === "number"?eachSeriesItem:eachSeriesItem[dataConfig?.contentParams?.dataSetConfig?.valueField]
                                seriesItemObjArr[serValueIndex].value.push(v)
                                maxNum = Math.max(maxNum,v);
                            })
                        })

                        if(!hasMax){
                            //缺少最大值
                            option.radar.indicator.forEach((ei)=>{
                                if(!ei.hasOwnProperty("max")){
                                    ei.max = maxNum;
                                }
                            })
                        }
                        seriesItem.data = seriesItemObjArr;
                    }else if(typeof data[0][dataConfig?.contentParams?.dataSetConfig?.valueField] === "number"){
                        //单系列
                        let seriesItemValueArr = {
                            name: "系列1",
                            value: []
                        };
                        let hasMax = true;
                        let maxNum = 0;
                        data.forEach((eachDataItem,index)=>{
                            let indicatorItem = {
                                name: eachDataItem[dataConfig?.contentParams?.dataSetConfig?.indicatorField],
                                min: 0
                            }
                            if(indicatorItem.name === undefined){
                                indicatorItem.name = "未匹配到指标"
                            }
                            if(dataConfig?.contentParams?.dataSetConfig?.maxField && !isNaN(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                                //最大值的设定方式是 指定 数字
                                indicatorItem.max = Number(dataConfig?.contentParams?.dataSetConfig?.maxField)
                            }else if(eachDataItem.hasOwnProperty(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                                //指定了字段
                                indicatorItem.max = eachDataItem[dataConfig?.contentParams?.dataSetConfig?.maxField]
                            }else {
                                hasMax = false;
                            }
                            seriesItemValueArr.value.push(eachDataItem[dataConfig?.contentParams?.dataSetConfig?.valueField])
                            maxNum = Math.max(maxNum,eachDataItem[dataConfig?.contentParams?.dataSetConfig?.valueField]);
                            option.radar.indicator.push(indicatorItem);
                        })

                        if(!hasMax){
                            //缺少最大值
                            option.radar.indicator.forEach((ei)=>{
                                if(!ei.hasOwnProperty("max")){
                                    ei.max = maxNum;
                                }
                            })
                        }
                        seriesItem.data.push(seriesItemValueArr);
                    }
                }else if(Object.prototype.toString.call(data) === '[object Object]'){
                    //对象，指标和数据分开存放
                    //先提取指标轴
                    let hasMax = true;
                    let maxNum = 0;
                    data[dataConfig?.contentParams?.dataSetConfig?.indicatorField]?.map((eachIndicator)=>{
                        let indicatorItem = {
                            min: 0
                        };
                        if(typeof eachIndicator === "string"){
                            indicatorItem.name = eachIndicator;
                        }else if(typeof eachIndicator === "object"){
                            indicatorItem.name = eachIndicator[dataConfig?.contentParams?.dataSetConfig?.indicatorField];
                            if(indicatorItem.name === undefined){
                                indicatorItem.name = "未匹配到指标"
                            }
                        }
                        if(dataConfig?.contentParams?.dataSetConfig?.maxField && !isNaN(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                            //最大值的设定方式是 指定 数字
                            indicatorItem.max = Number(dataConfig?.contentParams?.dataSetConfig?.maxField)
                        }else if(eachIndicator.hasOwnProperty(dataConfig?.contentParams?.dataSetConfig?.maxField)){
                            //指定了字段
                            indicatorItem.max = eachIndicator[dataConfig?.contentParams?.dataSetConfig?.maxField]
                        }else{
                            hasMax=false;
                        }
                        option.radar.indicator.push(indicatorItem);
                    })

                    //系列数据的提取
                    //判断出是哪种结构
                    if(data[dataConfig?.contentParams?.dataSetConfig?.valueField]?.length){
                        let firstValue = data[dataConfig?.contentParams?.dataSetConfig?.valueField][0];

                        if(typeof firstValue === "number"){
                            //单系列
                            seriesItem.data.push({
                                name: "系列1",
                                value: data[dataConfig?.contentParams?.dataSetConfig?.valueField]
                            });
                            maxNum = Math.max(maxNum,...data[dataConfig?.contentParams?.dataSetConfig?.valueField]);
                        }else if(Array.isArray(firstValue)){
                            //多系列，无系列名
                            let seriesItemObjArr = [];
                            data[dataConfig?.contentParams?.dataSetConfig?.valueField].forEach((eachSerItem,index)=>{
                                maxNum = Math.max(maxNum,...eachSerItem);
                                seriesItemObjArr.push({
                                    name: "系列" + (index + 1),
                                    value: eachSerItem
                                })
                            })
                            seriesItem.data = seriesItemObjArr;
                        }else{
                            //多系列，有系列名
                            let seriesItemObjArr = [];
                            data[dataConfig?.contentParams?.dataSetConfig?.valueField].forEach((eachSerItem,index)=>{
                                maxNum = Math.max(maxNum,...eachSerItem[dataConfig?.contentParams?.dataSetConfig?.valueField]);
                                seriesItemObjArr.push({
                                    name: eachSerItem[dataConfig?.contentParams?.dataSetConfig?.seriesField] || "系列" + (index + 1),
                                    value: eachSerItem[dataConfig?.contentParams?.dataSetConfig?.valueField]
                                })
                            })
                            seriesItem.data = seriesItemObjArr;
                        }
                    }

                    if(!hasMax){
                        //缺少最大值
                        option.radar.indicator.forEach((ei)=>{
                            if(!ei.hasOwnProperty("max")){
                                ei.max = maxNum;
                            }
                        })
                    }
                }else{
                    this.setState({
                        error: `数据格式不符合规范`
                    })
                    return;
                }
            }

            option.series = [seriesItem];


        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}