import React from "react";
import {
    Form,
    Divider,
    Input,
    Select,
    AutoComplete,
    Radio,
    Switch,
    Collapse,
    InputNumber
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";
import PieDataSet from "./PieDataSet";

const Item = Form.Item;

/**
 * 饼图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"限制数据量"}
                name={["limitNum"]}
                rules={[{required: true}]}
            >
                <InputNumber
                    min={10}
                    max={1000000}
                    step={1}
                />
            </Item>
            <Item
                noStyle={true}
                name={["dataSetConfig"]}
                rules={[{required: true}]}
            >
                <PieDataSet
                    bindData={extendData?.bindData}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="pieSpecial"
            header="饼图专用"
        >
            <Item
                label={"图形类型"}
                name={["pieSpecial","_pieType"]}
                extra={<div style={{opacity:0.5}}>
                    <div>
                        圆心模式：扇区圆心角展现数据的百分比，半径展现数据的大小。
                    </div>
                    <div>
                        面积模式：所有扇区圆心角相同，仅通过半径展现数据大小。
                    </div>
                </div>}
            >
                <Select>
                    <Select.Option
                        value="normal"
                    >
                        普通饼图
                    </Select.Option>
                    <Select.Option
                        value="radius"
                    >
                        南丁格尔图-圆心模式
                    </Select.Option>
                    <Select.Option
                        value="area"
                    >
                        南丁格尔图-面积模式
                    </Select.Option>
                </Select>
            </Item>
            <Item
                label={"内圈半径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["pieSpecial","_radiusInner"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"外圈半径"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["pieSpecial","_radiusOuter"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"圆心位置"}
            >
                <Item
                    label={"水平"}
                    name={["pieSpecial","_centerSetX"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
                <Item
                    label={"垂直"}
                    name={["pieSpecial","_centerSetY"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            { value: '100px' },
                            { value: '50%' },
                        ]}
                    />
                </Item>
            </Item>
            <Divider
                orientation="right"
            >
                <Item
                    style={{width: "150px"}}
                    labelCol={{span: 14}}
                    label={"更多配置"}
                    name={["pieSpecial","seeMore"]}
                    valuePropName={"checked"}
                >
                    <Switch
                        size={"small"}
                        checkedChildren={"已展开"}
                        unCheckedChildren={"已收起"}
                    />
                </Item>
            </Divider>
            <Item
                noStyle={true}
                dependencies={["pieSpecial","seeMore"]}
            >
                {({getFieldValue})=> {
                    let seeMore = getFieldValue(["pieSpecial", "seeMore"]);

                    return <Item
                        noStyle={true}
                        hidden={!seeMore}
                    >
                        <Item
                            label={"排列方式"}
                            name={["pieSpecial","_clockwise"]}
                        >
                            <Radio.Group
                            >
                                <Radio value="clockwise">顺时针</Radio>
                                <Radio value="counterclockwise">逆时针</Radio>
                            </Radio.Group>
                        </Item>
                        <Item
                            label={"需要展示所有数据的标签"}
                            name={["pieSpecial","_showAllLabel"]}
                            valuePropName={"checked"}
                            labelCol={{span: 12}}
                            wrapperCol={{span: 10}}
                            extra={"选否，会自动隐藏占比不足1%的数据的标签"}
                        >
                            <Switch
                                size={"small"}
                                checkedChildren={"是"}
                                unCheckedChildren={"否"}
                            />
                        </Item>
                        <Item
                            label={"视觉引导线"}
                            name={["pieSpecial","_labelLine"]}
                            extra={"扇区与标签的连线类型"}
                        >
                            <Radio.Group
                            >
                                <Radio value="hide">隐藏</Radio>
                                <Radio value="line">折线</Radio>
                                <Radio value="curve">曲线</Radio>
                            </Radio.Group>
                        </Item>
                    </Item>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="totalMsg"
            header="总量信息面板"
        >
            <Item
                label={"是否展示"}
                valuePropName={"checked"}
                name={["totalMsg","show"]}
            >
                <Switch
                    size={"small"}
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"文案位置"}
            >
                <Item
                    label={"水平"}
                    name={["totalMsg", "_centerSetX"]}
                >
                    {CommonFormItems.getSliderAndInputItemGroup(["totalMsg","_centerSetX"],{
                        min: 0,
                        max: 100,
                        step: 1,
                        addonAfter: "%"
                    })}
                </Item>
                <Item
                    label={"垂直"}
                    name={["totalMsg","_centerSetY"]}
                >
                    {CommonFormItems.getSliderAndInputItemGroup(["totalMsg","_centerSetY"],{
                        min: 0,
                        max: 100,
                        step: 1,
                        addonAfter: "%"
                    })}
                </Item>
            </Item>
            <Divider orientation="left">
                总量数字设置
            </Divider>
            {CommonFormItems.getFontSet(["totalMsg","centerTextSet"],true)}
            <Item
                label={"左间隙"}
            >
                {CommonFormItems.distanceSet(["totalMsg","marginLeft", "num"], ["totalMsg","marginLeft", "unit"], ["px", "rem"])}
            </Item>
            <Item
                label={"右间隙"}
            >
                {CommonFormItems.distanceSet(["totalMsg","marginRight", "num"], ["totalMsg","marginRight", "unit"], ["px", "rem"])}
            </Item>
            <Divider orientation="left">
                前缀文案设置
            </Divider>
            <Item
                label={"文案"}
                name={["totalMsg","beforeTextSet","text"]}
            >
                <Input
                    maxLength={"50"}
                />
            </Item>
            {CommonFormItems.getFontSet(["totalMsg","beforeTextSet"],true)}
            <Divider orientation="left">
                后缀文案设置
            </Divider>
            <Item
                label={"文案"}
                name={["totalMsg","afterTextSet","text"]}
            >
                <Input
                    maxLength={"50"}
                />
            </Item>
            {CommonFormItems.getFontSet(["totalMsg","afterTextSet"],true)}
            <Divider orientation="left">
                其它设置
            </Divider>
            <Item
                label={"对齐方式"}
                name={["totalMsg","alignItems"]}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value="flex-start">顶部对齐</Radio>
                    <Radio value="center">居中对齐</Radio>
                    <Radio value="baseline">基线对齐</Radio>
                    <Radio value="flex-end">底部对齐</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={"大数字处理"}
                name={["totalMsg","tranBigNum"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Item>
            <Item
                noStyle={true}
                shouldUpdate={(prevValues, currentValues)=>{return prevValues.totalMsg?.tranBigNum !== currentValues.totalMsg?.tranBigNum}}

            >
                {({getFieldValue})=>{
                    if(getFieldValue(["totalMsg","tranBigNum"])){
                        return <Item
                            label={"保留小数位数"}
                            name={["totalMsg","bigNumPoint"]}
                            rules={[{required: true}]}
                        >
                            <InputNumber
                                min={0}
                                max={4}
                                step={1}
                            />
                        </Item>
                    }
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="itemStyle"
            header="扇区样式"
        >
            <Item
                label={"内侧圆角"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","_borderRadiusInner"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"外侧圆角"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","_borderRadiusOuter"],{
                    min: 0,
                    max: 100,
                    step: 1,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"描边线宽"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderWidth"], {
                    min: 0,
                    max: 10
                })}
            </Item>
            <Item
                label={"描边线颜色"}
                name={["itemStyle","borderColor"]}
            >
                <ColorPicker/>
            </Item>
            <Item
                label={"描边线类型"}
                name={["itemStyle","borderType"]}
            >
                <Radio.Group
                >
                    <Radio value="solid">实线</Radio>
                    <Radio value="dashed">虚线</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={"阴影大小"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","shadowBlur"], {
                    min: 0,
                    max: 20
                })}
            </Item>
            <Item
                label={"阴影颜色"}
                name={["itemStyle","shadowColor"]}
            >
                <ColorPicker/>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="outside">扇区外侧</Select.Option>
                    <Select.Option value="inside">扇区内侧</Select.Option>
                </>,
                extendItems: <>
                    <Item
                        label={"显示数值"}
                        valuePropName={"checked"}
                        name={["label","_showRealNum"]}
                    >
                        <Switch
                            size={"small"}
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                    <Item
                        label={"显示百分比"}
                        valuePropName={"checked"}
                        name={["label","_showPercentage"]}
                    >
                        <Switch
                            size={"small"}
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems({
                extendItems: <>
                    <Item
                        label={"显示百分比"}
                        valuePropName={"checked"}
                        name={["tooltip","_showPercentage"]}
                    >
                        <Switch
                            size={"small"}
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"legend"}
            header={"图例"}
        >
            {EchartComItem.getLegendItems()}
        </Collapse.Panel>
        <Collapse.Panel
          forceRender={true}
          key="chatItemClick"
          header="点击图形取值"
        >
            {EchartComItem.getChatItemClickItems(extendData)}
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        limitNum: 500,
        //数据集
        dataSetConfig: {
            labelField: null,
            valueField: null,
            pieSetArr: null,
        },
        dataset: {},
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        //布局
        usual: {

        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            },
            _showPercentage: false
        },
        //图例
        legend: {
            seeMore: false,
            show: false,
            type: "plain",  //plain  scroll
            icon: "roundRect",
            _position: "top",
            width: "auto",
            height: "auto",
            orient: "horizontal",
            align: "auto",
            itemGap: 10,
        },
        //饼图特殊
        pieSpecial:{
            seeMore: false,
            _pieType: "normal",
            _clockwise: "clockwise",
            _showAllLabel: false,
            _labelLine: "line",
            _radiusInner: 0,
            _radiusOuter: 75,
            _centerSetX: "50%",
            _centerSetY: "50%",

        },
        //标签
        label: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "outside",
            _beforeTxt: "",
            _afterTxt: "",
            _showRealNum: false,
            _showPercentage: false,

            _emphasisSel: [],
            _emphasis: {},
        },
        //扇区样式
        itemStyle: {
            borderWidth: 0,
            borderColor: "#ffffffff",
            borderType: "solid",
            shadowBlur: 0,
            shadowColor: "#000000e5",
            _borderRadiusInner: 0,
            _borderRadiusOuter: 0
        },
        //总量信息
        totalMsg: {
            show: false,
            _centerSetX: 50,
            _centerSetY: 50,
            centerTextSet: {
                fontFamily: "default",
                text: null,
                color: "#ffffff",
                fontSize: 18,
                sizeUnit: "rem", //px rem
                fontBold: true, //加粗
                fontItalic: false, //倾斜
            },
            beforeTextSet: {
                fontFamily: "default",
                text: null,
                color: "#ffffff",
                fontSize: 14,
                sizeUnit: "rem", //px rem
                fontBold: false, //加粗
                fontItalic: false, //倾斜
            },
            afterTextSet: {
                fontFamily: "default",
                text: null,
                color: "#ffffff",
                fontSize: 14,
                sizeUnit: "rem", //px rem
                fontBold: false, //加粗
                fontItalic: false, //倾斜
            },
            marginLeft: {
                num: 4,
                unit: "rem"
            },
            marginRight: {
                num: 4,
                unit: "rem"
            },
            tranBigNum: true,
            bigNumPoint: 2,
            alignItems: "center",
        },
        //点击事件配置
        clickActionSet: {
            use: false,
            toWhere: [],
            valueType: "name"
        }
    }
}

function getDemoCode(){
    return `
    1.饼状图，只能存在2个维度，第一个维度代表名称，第二个维度代表数值，如：季度，销售额
        1.1 返回数组中每个数据项中存在维度名，如：
            [
                {
                    季度:"第一季度",
                    销售总量:2600,
                },
                {
                    季度:"第二季度",
                    销售总量:3400,
                },
                 {
                    季度:"第三季度",
                    销售总量:5600,
                },
            ]
       1.2 返回一个对象，里面根据维度名称组装数组数据，各数组数据按顺序一一对应
            {
                季度:["第一季度","第二季度","第三季度"],
                销售总量:[2600,3400,5600]
            }
    
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}