import React, {useEffect} from 'react';

export default function Demo001(props){

    useEffect(()=>{
        //后台、平台端、空间端，会传入不同的前缀
        console.log("请求前缀:", props.contentMsg?.proConfig?.apiPrefix)

        //告诉组件，展示在了什么未知
        console.log("展示在哪里:", props.contentMsg?.proConfig?.usageParams?.usage)

        //空间、执行单元、数字人，会传入代表各自的id，字段都是businessID
        console.log("id信息:", props.contentMsg?.proConfig?.usageParams?.businessId)
    },[])

    return <div>测试系统组件</div>
}