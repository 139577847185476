import React, {Component} from 'react';
import styles from "./ShowWorkAnalyse.less";
// import WorkAnalyse from "@/pages/containers/workSet/workAnalyse/WorkAnalyse";

export default class ShowWorkAnalyse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: null,
            data: null,
        }
    }

    componentDidMount() {

    }

    showValue(data){
        if(Object.prototype.toString.call(data) !== '[object Object]'){
            this.setState({
                errorMsg: "数据结构不正确",
            })
        }

        this.setState({
            errorMsg: null,
            data: data
        })
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams} = contentMsg.dataConfig;
        let {data,errorMsg} = this.state;



        if(!contentParams?.dataSetConfig?.keyField || !contentParams?.dataSetConfig?.typeField){
            return <div className={styles.warnMsg}>请先配置关联项</div>
        }

        if(errorMsg){
            return <div className={styles.warnMsg}>{errorMsg}</div>;
        }

        if(!data){
            return <div className={styles.warnMsg}>无数据</div>;
        }

        if(!data[contentParams.dataSetConfig.keyField] || !data[contentParams.dataSetConfig.typeField]){
            return <div className={styles.warnMsg}>数据中缺少关联内容</div>
        }

        return <div
          className={styles.outer + " swiper-no-swiping"}
        >
            分析组件暂未接入
            {/*<WorkAnalyse*/}
            {/*  from={"dashboard"}*/}
            {/*  key={data[contentParams.dataSetConfig.keyField]}*/}
            {/*  baseTopSearchParams={{*/}
            {/*      taskAccountKey: data[contentParams.dataSetConfig.keyField],*/}
            {/*      taskAccountType: data[contentParams.dataSetConfig.typeField]*/}
            {/*  }}*/}
            {/*/>*/}
        </div>
    }
}