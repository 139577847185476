import React from "react";
import {Switch,Space} from "antd";

export default class SwitchShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {}
    }

    render(){
        let contentMsg = this.props.contentMsg;
        let {contentParams,parameterValue} = contentMsg.dataConfig;

        if(!contentParams){
            return null
        }

        return <Space>
            {
                contentParams.label
                    ?<span>{contentParams.label}</span>
                    :null
            }
            <Switch
                {...contentParams.inputOption}
                defaultChecked={parameterValue}
                onChange={(checked)=>{
                    this.props.valueChanged?.(checked,parameterValue)
                }}
            />
        </Space>
    }
}