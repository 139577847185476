import React from "react";
import {
  Form,
  Divider,
} from "antd";

const Item = Form.Item;

function getSetItems(extendData = {}){
  return <>
    <Divider orientation="left">
      无配置项
    </Divider>
  </>
}

function getDefaultValues(){
  return {}
}

export default {
  getSetItems,
  getDefaultValues,
}