import React from "react";
import {
    Form,
    Divider,
    Input,
    Select,
    InputNumber,
    Switch,
    Radio,
    Button,
    AutoComplete
} from "antd";
import ColorArray from "../../../formDiyItem/ColorArray";
import CommonFormItems from "../../../CommonFormItems";
import ColorPicker from "../../../formDiyItem/ColorPicker";

const Item = Form.Item;

function getSetItems(extendData = {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"单波名称字段"}
            name={"nameField"}
            rules={[{required: false}]}
            extra={"若不填，则数据必须是数字或数字数组"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"单波数值字段"}
            name={"valueField"}
            rules={[{required: false}]}
            extra={"若不填，则数据必须是数字或数字数组"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"单波总量字段"}
            name={"totalField"}
            rules={[{required: false}]}
            extra={"若不填，则以 填满轮廓所需数值 为准"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"填满轮廓所需数值"}
            name={"fillValue"}
            rules={[{required: true}]}
            extra={"当设置总量字段且数据中返回了总量信息，则此配置失效"}
        >
            <InputNumber
                min={0.1}
                max={100000000}
                step={0.1}
                controls={false}
            />
        </Item>
        <Divider orientation="left">
            水波样式配置
        </Divider>
        <Item
            label={"波浪高度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("amplitude",{
                min: 0,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"波浪宽度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("waveLength",{
                min: 0,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"开启动画"}
            name={"waveAnimation"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["direction"]}
            label={"波动方向"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="left">向左</Radio>
                <Radio value="right">向右</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"波浪颜色梯度"}
            name={"colorArray"}
            rules={[{required: true}]}
        >
            <ColorArray
                max={10}
            />
        </Item>
        <Item
            label={"波浪不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["waveStyle","opacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"波浪阴影"}
        >
            {CommonFormItems.getShadowSet(["waveStyle","shadowSet"])}
        </Item>
        <Divider orientation="left">
            轮廓样式配置
        </Divider>
        <Item
            label={"轮廓形状"}
            name={"shape"}
            rules={[{required: true}]}
        >
            <Select>
                <Select.Option value={"circle"}>圆形</Select.Option>
                <Select.Option value={"rect"}>矩形</Select.Option>
                <Select.Option value={"roundRect"}>圆角矩形</Select.Option>
                <Select.Option value={"triangle"}>三角形</Select.Option>
                <Select.Option value={"diamond"}>菱形</Select.Option>
                <Select.Option value={"pin"}>坐标</Select.Option>
                <Select.Option value={"arrow"}>箭头</Select.Option>
                <Select.Option value={"mapleLeaf"}>枫叶</Select.Option>
                <Select.Option value={"tree"}>树</Select.Option>
                <Select.Option value={"rocket"}>火箭</Select.Option>
                <Select.Option value={"cetacean"}>鲸鱼</Select.Option>
                <Select.Option value={"rabbit"}>兔子</Select.Option>
                <Select.Option value={"snail"}>蜗牛</Select.Option>
                <Select.Option value={"container"}>铺满容器内容</Select.Option>
            </Select>
        </Item>
        <Item
            label={"轮廓占容器比例"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("radius",{
                min: 20,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"轮廓底色"}
            name={["backgroundSet","color"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"内边距"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["outline","borderDistance"],{
                min: 0,
                max: 50,
                step: 1
            })}
        </Item>
        <Item
            label={"轮廓描边"}
            name={["outline","show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"显示"}
                unCheckedChildren={"隐藏"}
            />
        </Item>
        <Item
            label={"轮廓颜色"}
            name={["outline","lineStyle","borderColor"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"轮廓粗细"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["outline","lineStyle","borderWidth"],{
                min: 0,
                max: 20,
                step: 1
            })}
        </Item>
        <Item
            label={"轮廓阴影"}
        >
            {CommonFormItems.getShadowSet(["outline","lineStyle","shadowSet"])}
        </Item>
        <Divider orientation="left">
            内部文本样式设置
        </Divider>
        <Item
            label={"显示文案"}
            name={["label","show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["label","showType"]}
            label={"数据值"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value={1}>百分比</Radio>
                <Radio value={2}>具体数值</Radio>
                <Radio value={3}>隐藏</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues) => prevValues.label.showType !== currentValues.label.showType}
        >
            {({getFieldValue})=>{
                let showType = getFieldValue(["label","showType"]);
                return <>
                    <Item
                        label={"精度"}
                        name={["label","precision"]}
                        rules={[{required: true}]}
                        hidden={showType !== 1}
                    >
                        <InputNumber
                            min={0}
                            max={4}
                            step={1}
                        />
                    </Item>
                    <Item
                        label={"处理大数字"}
                        name={["label","bigNumber"]}
                        rules={[{required: true}]}
                        extra={"当数量超过1万时，以W为单位展示"}
                        hidden={showType !== 2}
                        valuePropName={"checked"}
                    >
                        <Switch
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </>
            }}
        </Item>
        <Item
            label={"前缀文案"}
            extra={"若不填，则不显示"}
            name={["label","beforeText"]}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        <Item
            label={"后缀文案"}
            extra={"若不填，则不显示"}
            name={["label","afterText"]}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        <Item
            label={"文字上浮色"}
            extra={"当文字没有被水波没过时的颜色"}
            name={["label","color"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"文字下沉色"}
            extra={"当文字被水波没过时的颜色"}
            name={["label","insideColor"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"字体类型"}
            name={["label","fontFamily"]}
        >
            {CommonFormItems.getFontFamilySelect()}
        </Item>
        <Item
            label={"字体大小"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["label","fontSize"],{
                min: 10,
                max: 150,
                step: 1,
                addonAfter: "rem"
            })}
        </Item>
        <Item
            label={"加粗"}
            name={["label","fontWeightBold"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["label","position"]}
            label={"位置"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="inside">内部</Radio>
                <Radio value="top">靠上</Radio>
                <Radio value="bottom">靠下</Radio>
                <Radio value="left">靠左</Radio>
                <Radio value="right">靠右</Radio>
            </Radio.Group>
        </Item>
        <Item
            name={["label","align"]}
            label={"水平对齐"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="left">左对齐</Radio>
                <Radio value="center">居中</Radio>
                <Radio value="right">右对齐</Radio>
            </Radio.Group>
        </Item>
        <Item
            name={["label","baseline"]}
            label={"垂直对齐"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="top">顶部对齐</Radio>
                <Radio value="middle">居中</Radio>
                <Radio value="bottom">底部对齐</Radio>
            </Radio.Group>
        </Item>
    </>
}

function getDefaultValues(){
    return {
        //数据对接
        nameField: null,
        valueField: null,
        totalField: null,
        fillValue: 100, //多少算满
        colorArray: ['#294D99', '#156ACF', '#1598ED', '#45BDFF'], //颜色梯度

        //水波样式
        waveLength: 50,  //水波宽度
        amplitude: 5,   //波浪高度  0则水平
        waveAnimation: true,    //是否动画
        direction: "right", //波浪移动方向
        waveStyle: {    //波浪配置
            opacity: 95,  //透明度
            shadowSet: {
                blur: 50, //阴影大小
                color: '#000000',  //阴影颜色
                opacity: 20,  //阴影不透明度
            }
        },

        //轮廓样式
        shape: "circle",    //图形
        radius: 90, //大小百分比
        outline: {  //外层轮廓线样式
            show: true,     //是否展示
            borderDistance: 8,  //内边距
            lineStyle: {
                borderColor: '#294D99',
                borderWidth: 8,
                shadowSet: {
                    blur: 50, //阴影大小
                    color: '#000000',  //阴影颜色
                    opacity: 20,  //阴影不透明度
                }
            }
        },
        backgroundSet: {    //背景配置
            // borderWidth: 0, //边宽
            // borderColor: "#294D99", //边框颜色
            color: "#E3F7FF"    //背景色
        },


        //文字样式
        label: {    //内部文字颜色
            show: true,
            fontFamily: "default",
            showType: 1,
            bigNumber: false,
            beforeText: null,
            afterText: null,
            color: '#294D99',       //背景内文字颜色
            insideColor: '#ffffff',    //波浪内文字颜色
            fontSize: 50,   //rem
            fontWeightBold:  true,//加粗
            align: 'center',//left', 'center', or 'right'
            baseline: 'middle',//'top', 'middle', or 'bottom'
            position: 'inside',//'inside', 'left', 'right', 'top', 'bottom'
            precision: 0,
        },
    }
}

function getDemoCode(){
    return `
    以下示例中 title、value、total 均可在 数据对接设置 中修改替换成其它单词
    1.最简略的水波图数据，当 填满轮廓所需数值 手动填写数值时，视图数据只需返回一个数字，如： 
    60
    
    2.也可以返回一个对象，如：
    {
        title: "波浪",
        value: 60,
    }
    
    
    3.展示 3 个不同高度的波浪，可以返回 数字数组，如：
    [30,50,80]
    
    
    4.展示三个水波，且给每个波浪定义名称，在鼠标悬浮时展示
    [
        {
            title: "波浪A",
            value: 30,
        },
        {
            title: "波浪B",
            value: 50,
        },
        {
            title: "波浪C",
            value: 80,
        },
        ...
    ]
   
    5.每个波采用独立的百分比，对接 填满轮廓所需数值 字段，下例设置字段为total：
    [
        {
            title: "波浪A",
            value: 30,
            total: 100,
        },
        {
            title: "波浪B",
            value: 50,
            total: 80,
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}