import React from "react";
import {Select} from "antd";

export default class StringSelectShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.contentMsg?.dataConfig?.parameterValue
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.contentMsg?.dataConfig?.parameterValue !== nextProps.contentMsg?.dataConfig?.parameterValue) {
            this.setState({
                value: nextProps.contentMsg?.dataConfig?.parameterValue
            })
        }
    }

    setValue(newValue){
        this.setState({
            value: newValue
        },()=>{
            this.props.valueChanged?.(newValue)
        })
    }

    render(){
        let {contentParams} = this.props.contentMsg.dataConfig;
        let {value} = this.state;
        if(!contentParams){
            return null
        }
        let optionsSpecial = []
        if(contentParams.listSpecial?.length){
            optionsSpecial = contentParams.listSpecial.filter((item)=>{
                return !!item.label
            })
        }

        return <div
            style={{width: "100%",
                display: "flex",
                alignItems: "center",
            }}
            className={"swiper-no-swiping"}
        >
            {
                contentParams.label
                    ?<span>{contentParams.label}</span>
                    :null
            }
            <div
                style={{flex: 1}}
            >
                <Select
                    style={{width: "100%"}}
                    allowClear={true}
                    {...contentParams.selectOption}
                    value={value}
                    showSearch={true}
                    filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onChange={(v)=>{
                        this.setState({
                            value: v
                        },()=>{
                            this.props.valueChanged?.(v)
                        })
                    }}
                >
                    {contentParams.list?.map((str,index)=>{
                        return <Select.Option
                            key={"str_" + str + index}
                            value={str}
                        >
                            {str}
                        </Select.Option>
                    })}
                    {optionsSpecial.map((item,index)=>{
                        return <Select.Option
                            key={"str_" + item.value + index}
                            value={item.value}
                        >
                            {item.label}
                        </Select.Option>
                    })}
                </Select>
            </div>
        </div>
    }
}