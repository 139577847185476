import React from "react";
import { Result } from "antd";
import commonEmpty from "./icons/commonEmpty.png";
import unitEmpty from "./icons/unitEmpty.png";
import mySpaceEmpty from "./icons/mySpaceEmpty.png";


export default function (props) {
  let iconPath = {
    common: commonEmpty,
    unit: unitEmpty,
    mySpace: mySpaceEmpty
  }[props.type || "common"]

  return <Result
    title={'暂无数据'}
    icon={<img
      alt={props.title || "暂无数据"}
      src={iconPath}
      style={{
        marginBottom: "-30px",
        width: '200px'
      }}
    />}
    {...props}
  />
}