import React from "react";
import styles from "./CustomRegister.less";
import ViewAndCode from "./ViewAndCode";
import DefaultThemeConfig from "../../DefaultThemeConfig";
import {Space,Button,Form,message,Empty} from "antd";
import DataGetHelp from "../../../dataSet/components/dataSet/DataGetHelp";
import ChartOptionSetView from "./ChartOptionSetView";

export default class CustomOneShow extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      selectContent: props.selectContent,
      originData: null,
      openOptionSet: false,
      styleUpdate: 0
    }

    this.DataGetHelp = new DataGetHelp();
  }


  componentDidMount(){
    this.loadBindData()
  }

  render(){
    let {selectContent,originData,openOptionSet,styleUpdate} = this.state;

    return <div className={styles.rightCenter}>
      {this.getParamsSetViewForSelectNode(selectContent)}
      <div className={styles.viewBox}>
        <div className={styles.viewBoxBody}>
          <ViewAndCode
            ref={(ref) => {
              this.viewAndCode = ref
            }}
            key={"vc_" + selectContent?.contentMsg?.dataConfig.contentType}
            dataUpdate={selectContent?._id}
            styleUpdate={styleUpdate}
            contentMsg={selectContent?.contentMsg}
            themeConfig={DefaultThemeConfig}
            scencStyleConfig={{}}
            defaultData={originData}
          />
        </div>
        <div className={styles.viewBoxFooter}>
          {
            openOptionSet
              ?<Button
                size={"small"}
                type={"primary"}
                style={{backgroundColor: "limegreen", borderColor: "limegreen"}}
                onClick={()=>{
                  this.props.onSave?.(selectContent)
                  this.setState({
                    openOptionSet: false
                  })
                }}
              >保存属性配置</Button>
              :null
          }
          <Button
            size={"small"}
            type={"primary"}
            onClick={()=>{
              this.setState({
                openOptionSet: !openOptionSet
              })
            }}
          >{openOptionSet?"关闭属性配置":"打开属性配置"}</Button>
        </div>
      </div>
      {
        openOptionSet
          ?<div
            className={styles.chartOptionBox}
          >
            <ChartOptionSetView
              usage={selectContent.contentMsg.dataConfig.mockParams?.usage}
              defaultCategoryId={selectContent.categoryId}
              defaultDataConfig={selectContent.contentMsg.dataConfig}
              bindData={null}
              onCategoryChange={(categoryId)=>{
                selectContent.categoryId = categoryId
              }}
              onContentParamsChange={(dataConfig)=>{
                selectContent.contentMsg.dataConfig = dataConfig
                this.setState({
                  styleUpdate: this.state.styleUpdate + 1
                })
              }}
            />
          </div>
          :null
      }
    </div>
  }

  //数据参数配置
  getParamsSetViewForSelectNode(selectNodeDetail) {
    let childInputArgParamsMap = {};
    let config = selectNodeDetail.contentMsg.dataConfig.dataRequestConfig;

    //是元数据查询类型，且需要配置输入参数
    childInputArgParamsMap[selectNodeDetail._id] = {
      dataType: 6,
      title: selectNodeDetail.name,
      inputArg: _.cloneDeep(config.configs.inputArg),
    };

    let keys = Object.keys(childInputArgParamsMap);

    if (!keys.length) {
      return <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={"没有参数需要配置"}
      />
    }

    this.layoutMap = {};

    return (
      <div className={styles.paramsBox}>
        <div className={styles.paramsContentBody}>
          <Empty
            style={{marginTop: "150px"}}
            description={"无参数定义"}
          />
        </div>
        <div className={styles.paramsContentFooter}>
          <Space>
            {/*<Button*/}
            {/*  size={"small"}*/}
            {/*  ghost={true}*/}
            {/*  type={"primary"}*/}
            {/*  danger={true}*/}
            {/*  onClick={()=>{*/}
            {/*    */}
            {/*  }}*/}
            {/*>重置参数</Button>*/}
            <Button
              size={'small'}
              type={'primary'}
              onClick={() => {
                this.loadBindData();
              }}
            >
              发起请求
            </Button>
          </Space>
        </div>
      </div>
    );
  }

  async loadBindData(){
    let {selectContent} = this.state;

    let configs = selectContent.contentMsg.dataConfig.dataRequestConfig.configs;

    let err;

    let resultData = await this.DataGetHelp.getDataByBindConfig({
      dataType: 6,  //服务端创建的自定义组件特有类型
      configs: configs,
      title: selectContent.name
    }).catch((e) => {
      err = e;
    });

    if(err){
      message.error(err);
      return;
    }

    this.setState({
      originData: resultData.originData
    },()=>{
      this.viewAndCode?.showValue(resultData.originData)
      this.props.originDataGetCB?.(resultData.originData)
    })
  }

  //将layout收集到的参数，赋值到原始的参数定义上
  //采用新版本的form收集值，替换老版本的Layout组件配置，不用再特殊处理值结果了 --2023.01.12
  getChangedValueByLayout(inputsOrigin, newParams) {
    let inputs = _.cloneDeep(inputsOrigin);
    Object.keys(newParams).forEach((field) => {
      if (inputs[field]) {
        if (!inputs[field].value) {
          inputs[field].value = {
            source: 'work',
          };
        }
        inputs[field].value.value = newParams[field];
      }
    });

    return inputs;
  }
}