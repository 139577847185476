import React from "react";
import {
    Form,
    Radio,
    Input,
    Switch,
    InputNumber
} from "antd";

const Item = Form.Item;

function getSetItems(extendData= {}){

    return <>
        <Item
            label={"最小值"}
            name={["sliderOption","min"]}
            rules={[{required: true}]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"最小值标签"}
            name={["minLabel"]}
        >
            <Input maxLength={10}/>
        </Item>
        <Item
            label={"最大值"}
            name={["sliderOption","max"]}
            rules={[{required: true}]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"最大值标签"}
            name={["maxLabel"]}
        >
            <Input maxLength={10}/>
        </Item>
        <Item
            label={"步长"}
            name={["sliderOption","step"]}
        >
            <InputNumber/>
        </Item>
        <Item
            label={"总是显示提示条"}
            name={["sliderOption","tooltipVisible"]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"提示条标签"}
            name={["tooltipLabel"]}
        >
            <Input maxLength={10}/>
        </Item>
        <Item
            label={"提示条位置"}
            name={["sliderOption","tooltipPlacement"]}
        >
            <Radio.Group>
                <Radio value={"top"}>上</Radio>
                <Radio value={"bottom"}>下</Radio>
                <Radio value={"left"}>左</Radio>
                <Radio value={"right"}>右</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"垂直展示"}
            name={["sliderOption","vertical"]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"反转显示"}
            name={["sliderOption","reverse"]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
    </>
}

function getDefaultValues(){
    return {
        minLabel: "最小0",
        maxLabel: "最大100",
        tooltipLabel: "当前值：",
        sliderOption: {
            min: 0,
            max: 100,
            step: 1,
            precision: 2,
            vertical: false,
            tooltipVisible: false,
            tooltipPlacement: "top",
            reverse: false,
        }
    }
}

export default {
    getSetItems,
    getDefaultValues,
}