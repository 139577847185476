import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";

export default class BarShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });

        if(this.props.contentMsg?.dataConfig?.contentParams?.clickActionSet?.use){
            this.myChart.on('click',  (params)=> {
                let txt = params[this.props.contentMsg?.dataConfig?.contentParams?.clickActionSet?.valueType];
                this.props?.broadcastData(txt)
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {
            grid: {
                left: '10',
                right: '10',
                bottom: '10',
                top: '10',
                containLabel: true
            },
            xAxis: Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.xAxis),
            yAxis: Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.yAxis),
        }

        let axisType = "category";
        if(this.props.contentMsg?.dataConfig?.contentParams?.barSpecial?._timeAxis){
            //改成时间轴
            axisType = "time";
        }

        //是否x,y翻转
        if(this.props.contentMsg?.dataConfig?.contentParams?.usual?._categoryWhere === "y"){
            option.xAxis = Object.assign(option.xAxis,{type: "value"})
            option.yAxis = Object.assign(option.yAxis,{type: axisType})
        }else{
            option.xAxis = Object.assign(option.xAxis,{type: axisType})
            option.yAxis = Object.assign(option.yAxis,{type: "value"})
        }

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;


        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position){
            case "top": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "center",
            });break;
            case "bottom": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "center",
            });break;
            case "left": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "5%",
            });break;
            case "right": option.legend =  Object.assign(option.legend,{
                top: "center",
                right: "5%",
            });break;
            case "topLeft": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "5%",
            });break;
            case "topRight": option.legend =  Object.assign(option.legend,{
                top: "5%",
                right: "5%",
            });break;
            case "bottomLeft": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "5%",
            });break;
            case "bottomRight": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                right: "5%",
            });break;
            case "center": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "center",
            });break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        if(option.tooltip?._showPercentage){
            let color = option.tooltip?.textStyle?.color || "inherit";
            let ifTimeAxis = this.props.contentMsg?.dataConfig?.contentParams?.barSpecial?._timeAxis;

            option.tooltip.formatter=(params)=>{
                // console.log(params)
                if(Array.isArray(params)){
                    let total = 0;
                    let firstData = params[0]?.data;

                    if(Array.isArray(firstData)){
                        //数组一定不是时间轴模式，不用判断ifTimeAxis
                        firstData.forEach((each,i)=>{
                            if(i !==0 ){
                                total += each
                            }
                        })
                    }else{
                        if(ifTimeAxis){
                            params[0]?.dimensionNames.forEach((each,i)=>{
                                //时间轴，时间与系列交替
                                if(i%2 !==0 ){
                                    total += (firstData[each] || 0)
                                }
                            })
                        }else{
                            params[0]?.dimensionNames.forEach((each,i)=>{
                                //类目轴，去掉第一个维度项
                                if(i !==0 ){
                                    total += (firstData[each] || 0)
                                }
                            })
                        }
                    }

                    return `<div>
                        <div style="color: ${color}">${params[0]?.name}</div>
                        ${params.map((eachSeriesItem)=>{
                        let eachSeriesItemValue;
                        if(Array.isArray(eachSeriesItem.data)){
                            eachSeriesItemValue = eachSeriesItem.value[eachSeriesItem.seriesIndex + 1]
                        }else{
                            if(ifTimeAxis){
                                eachSeriesItemValue = eachSeriesItem.value[eachSeriesItem.dimensionNames[eachSeriesItem.seriesIndex*2 + 1]]
                            }else{
                                eachSeriesItemValue = eachSeriesItem.value[eachSeriesItem.dimensionNames[eachSeriesItem.seriesIndex + 1]]
                            }
                        }


                        return `<div  style="color: ${color}">
                                ${eachSeriesItem.marker}
                                <span style="display: inline-block;min-width: 120px">${eachSeriesItem.seriesName}</span>
                                <span style="display: inline-block;font-weight: bold">${eachSeriesItemValue || 0}</span>
                                 <span style="display: inline-block;"> (${((eachSeriesItemValue || 0)*100/total).toFixed(1)}%)</span>
                            </div>`
                    }).join("")}
                    </div>`
                }else{
                    let total = 0;
                    if(Array.isArray(params?.data)){
                        params?.data.forEach((each,i)=>{
                            if(i !==0 ){
                                total += each
                            }
                        })
                    }else{
                        if(ifTimeAxis){
                            params?.dimensionNames.forEach((each,i)=>{
                                //时间轴，时间与系列交替
                                if(i%2 !==0 ){
                                    total += (params?.data[each] || 0)
                                }
                            })
                        }else{
                            params?.dimensionNames.forEach((each,i)=>{
                                if(i !==0 ){
                                    total += (params?.data[each] || 0)
                                }
                            })
                        }
                    }

                    let eachSeriesItemValue;
                    if(Array.isArray(params.data)){
                        eachSeriesItemValue = params.value[params.seriesIndex + 1]
                    }else{
                        if(ifTimeAxis){
                            eachSeriesItemValue = params.value[params.dimensionNames[params.seriesIndex*2 + 1]]
                        }else{
                            eachSeriesItemValue = params.value[params.dimensionNames[params.seriesIndex + 1]]
                        }
                    }

                    return `<div>
                        <div style="color: ${color}">${params?.name}</div>
                        <div style="color: ${color}">
                                ${params.marker}
                                <span style="display: inline-block;min-width: 120px">${params.seriesName}</span>
                                <span style="display: inline-block;font-weight: bold">${eachSeriesItemValue || 0}</span>
                                 <span style="display: inline-block;"> (${((eachSeriesItemValue || 0) * 100 / total).toFixed(1)}%)</span>
                            </div>
                    </div>`
                }
            }
        }

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }


        //网格布局
        option.grid = this.props.contentMsg?.dataConfig?.contentParams?.usual?.grid;

        //缩放
        if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useX || this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useY){
            option.dataZoom = []

            if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useX){
                let baseSet = {};

                if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.mode === "percent"){
                    baseSet = {
                        start: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.start,
                        end: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.end,
                        throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                    }
                }else if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.mode === "value"){
                    baseSet = {
                        startValue: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.startValue - 1,
                        endValue: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.endValue - 1,
                        reverse: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._xMoreSet?.reverse,
                        throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                    }
                }

                option.dataZoom.push(Object.assign({
                    type: 'slider',
                    xAxisIndex: [0],
                    height: 20,
                },baseSet))

                option.dataZoom.push(Object.assign({
                    type: 'inside',
                    xAxisIndex: [0],
                },baseSet))
            }
            if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useY){
                let baseSet = {};

                if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.mode === "percent"){
                    baseSet = {
                        start: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.start,
                        end: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.end,
                        throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                    }
                }else if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.mode === "value"){
                    baseSet = {
                        startValue: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.startValue - 1,
                        endValue: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.endValue - 1,
                        reverse: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._yMoreSet?.reverse,
                        throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                    }
                }

                option.dataZoom.push(Object.assign({
                    type: 'slider',
                    yAxisIndex: [0],
                    width: 20,
                },baseSet))

                option.dataZoom.push(Object.assign({
                    type: 'inside',
                    yAxisIndex: [0],
                },baseSet))
            }
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    //柱状图由于配置了 开启大数据优化，不再限制数据量
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)


        let {styleConfig,dataConfig} = this.props.contentMsg;


        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "bar",
                large: true,
            };

            //缩放特殊配置
            if(option.dataZoom){
                let total ;
                if(Array.isArray(data)){
                    total = data.length;
                }else{
                    total = data?.[dataConfig?.contentParams?.dataSetConfig?.labelField]?.length || 0;
                }

                option.dataZoom?.forEach((eachZoomSet)=>{
                    if(eachZoomSet.reverse){
                        //需要从数据项末尾开始往前算
                        let setStartV = eachZoomSet.startValue;
                        let setEndV = eachZoomSet.endValue;
                        eachZoomSet.endValue = Math.max(total - 1 - setStartV,0);
                        eachZoomSet.startValue = Math.max(total - 1 - setEndV,0);
                    }

                    if(dataConfig?.contentParams?.barSpecial?._timeAxis && eachZoomSet.hasOwnProperty("startValue")){
                        //时间轴模式，不支持项数的配置，根据数据转换成百分比
                        eachZoomSet.start = (eachZoomSet.startValue * 100/(total - 1)).toFixed(2);
                        eachZoomSet.end = (eachZoomSet.endValue * 100/(total - 1)).toFixed(2);

                        delete eachZoomSet.startValue;
                        delete eachZoomSet.endValue;
                    }
                })
            }


            //柱状图特殊配置
            if(dataConfig?.contentParams?.barSpecial?.showBackground){
                seriesItem.showBackground = true;
                seriesItem.backgroundStyle = dataConfig?.contentParams?.barSpecial.backgroundStyle;
            }

            //不同系列柱间距
            seriesItem.barGap = dataConfig?.contentParams?.barSpecial._barGap + "%";


            //数据堆叠
            if(dataConfig?.contentParams?.stackSet?.show){
                seriesItem = Object.assign(seriesItem,dataConfig?.contentParams?.stackSet)
            }

            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                    position: dataConfig?.contentParams?.label.position,
                }

                switch (dataConfig?.contentParams?.label._showPlan){
                    case "normal":break;
                    case "pop":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding= 5;
                        seriesItem.label.backgroundColor= "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius= 100;
                        break;
                }
            }

            //高亮配置
            seriesItem.emphasis = {}
            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }

            //根据数据和配置填充其它属性
            option.dataset = {}
            option.series = [];

            let newDataForTimeAxis = [];

            if(dataConfig?.contentParams?.dataSetConfig?.labelField){
                let dim = [];

                if(dataConfig?.contentParams?.barSpecial?._timeAxis){
                    //时间轴
                    let needFillTimeAxis = true;
                    if(Array.isArray(data)){
                        newDataForTimeAxis = _.cloneDeep(data);
                    }else{
                        //对象模式，直接组装出所需格式
                        needFillTimeAxis = false;
                        if(data[dataConfig.contentParams.dataSetConfig.labelField]?.length){
                            data[dataConfig.contentParams.dataSetConfig.labelField].forEach((timeValue,timeIndex)=>{
                                let dataItem = {}
                                dataConfig.contentParams.dataSetConfig.lineArr.forEach((lineSet,lineIndex)=>{
                                    let timeMark = "系列" + (lineIndex + 1);

                                    dataItem[timeMark] = timeValue;
                                    dataItem[lineSet.field] = data[lineSet.field]?.[timeIndex];
                                })
                                newDataForTimeAxis.push(dataItem)
                            })
                        }
                    }

                    dataConfig.contentParams.dataSetConfig.lineArr.forEach((lineSet,lineIndex)=>{
                        let timeMark = "系列" + (lineIndex + 1);
                        if(needFillTimeAxis){
                            //补上独立的时间
                            newDataForTimeAxis.forEach((eachItem)=>{
                                eachItem[timeMark] = eachItem[dataConfig.contentParams.dataSetConfig.labelField]
                            })
                        }
                        dim.push({
                            name: timeMark,
                            displayName: "",
                            type: "time"
                        });
                        dim.push({
                            name: lineSet.field,
                            type: "number"
                        });
                        let newSeriesItem = _.cloneDeep(seriesItem);

                        if(lineSet.extraStyle?.color){
                            newSeriesItem.itemStyle = {
                                color: lineSet.extraStyle?.color
                            }
                        }

                        //需要用到field的特殊配置先修改好
                        if(dataConfig?.contentParams?.label?.show){
                            if(dataConfig?.contentParams?.label._beforeTxt || dataConfig?.contentParams?.label._afterTxt){
                                //特殊格式
                                newSeriesItem.label.formatter = `${dataConfig?.contentParams?.label._beforeTxt} {@${lineSet.field}} ${dataConfig?.contentParams?.label._afterTxt}`
                            }
                        }
                        option.series.push(newSeriesItem)
                    })
                }else{
                    //类目轴
                    dim.push(dataConfig.contentParams.dataSetConfig.labelField);
                    dataConfig.contentParams.dataSetConfig.lineArr.forEach((lineSet)=>{
                        dim.push(lineSet.field);
                        let newSeriesItem = _.cloneDeep(seriesItem);

                        if(lineSet.extraStyle?.color){
                            newSeriesItem.itemStyle = {
                                color: lineSet.extraStyle?.color
                            }
                        }

                        //需要用到field的特殊配置先修改好
                        if(dataConfig?.contentParams?.label?.show){
                            if(dataConfig?.contentParams?.label._beforeTxt || dataConfig?.contentParams?.label._afterTxt){
                                //特殊格式
                                newSeriesItem.label.formatter = `${dataConfig?.contentParams?.label._beforeTxt} {@${lineSet.field}} ${dataConfig?.contentParams?.label._afterTxt}`
                            }
                        }
                        option.series.push(newSeriesItem)
                    })
                }

                if(dataConfig?.contentParams?.barSpecial?._timeAxis){
                    //时间轴
                    option.dataset.source = newDataForTimeAxis;
                }else{
                    //类目轴
                    option.dataset.source = data;
                }



                if(dataConfig?.contentParams?.barSpecial?._minSet?.type === "auto"){
                    //数值轴需要自动配置最小值
                    let min = Infinity;
                    dataConfig.contentParams.dataSetConfig.lineArr.forEach((lineSet)=>{
                        if(Array.isArray(data)){
                            data.forEach((eachItem)=>{
                                //0 不做考虑
                                if(eachItem[lineSet.field]){
                                    min = Math.min(min,eachItem[lineSet.field]);
                                }
                            })
                        }else if(Array.isArray(data[lineSet.field])){
                            //0 不做考虑
                            min = Math.min(min,...data[lineSet.field].filter((eachNum)=>{
                                return eachNum !== 0
                            }));
                        }
                    })

                    if(min !== Infinity){
                        min = min * dataConfig?.contentParams?.barSpecial?._minSet?.autoNum/100;

                        let autoStep = dataConfig?.contentParams?.barSpecial?._minSet?.autoStep || 1;
                        min = min - min%autoStep;

                        let pointIndex = String(autoStep).indexOf('.') + 1;
                        let precision = pointIndex > 0?(String(autoStep).length - pointIndex):0;

                        min = min.toFixed(precision)

                        if(option.yAxis.type === "value"){
                            option.yAxis.min = min;
                        }else{
                            option.xAxis.min = min;
                        }
                    }

                }else if(dataConfig?.contentParams?.barSpecial?._minSet?.type === "set"){
                    //手动设置
                    if(option.yAxis.type === "value"){
                        option.yAxis.min = dataConfig?.contentParams?.barSpecial?._minSet?.setNum;
                    }else{
                        option.xAxis.min = dataConfig?.contentParams?.barSpecial?._minSet?.setNum;
                    }
                }

                option.dataset.dimensions = dim;
            }
        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}