import React from 'react'
import { Route,Routes } from "react-router-dom";
import App from './main/App.js';
import DashboardSet from "./main/dashboardLayoutSet/index";

let routes = (<Routes>
    <Route path="/dashboardSet" element={<DashboardSet />}/>
    <Route path="/" element={<App />}/>
</Routes>);

export default routes;

