import {message} from "antd";

class Help {
    constructor() {

    }

    getQueryVariable(variable) {
        let url = decodeURIComponent(window.location.href);
        let index = url.lastIndexOf('?');
        let query = url.slice(index + 1);
        let vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return undefined;
    }

    getParams() {
        let url = decodeURIComponent(window.location.href);
        let index = url.lastIndexOf('?');
        let query = url.slice(index + 1);
        let vars = query.split("&");
        let obj = {};
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            obj[pair[0]] = pair[1]
        }
        return obj;
    }

    push(that, path, data) {
        if (!that) {
            console.log("that is null");
            return;
        }
        if (!path) {
            console.log("path is null");
            return;
        }
        let url = path;
        if (data) {
            url += "?";
            for (let key in data) {
                url = url + key + "=" + data[key] + "&"
            }

            url = url.substring(0, url.length - 1)
        }

        that.props.history.push(url);
    }

    //根据给定的字符串函数，返回执行结果
    formatDataShow(value, valueType, formatString) {
        if(!formatString){
            //格式话方法不存在，采用降级处理
            let valueView = "" + value;

            if(value === null || value===undefined){
                switch (valueType) {
                    case "Boolean":
                        valueView = "false";
                        break;
                    case "Number":
                        valueView = "0";
                        break;
                    case "String":
                        valueView = `""`;
                        break;
                }
            }

            return valueView
        }


        let format = null;
        if (formatString) {
            try {
                format = eval(`(function(){return ${formatString}})()`);
                if (typeof format !== "function") {
                    //不是一个方法
                    format = null;
                }
            } catch (e) {
                format = null;
                console.log("format错误", e);
                console.log(formatString);
            }
        }

        return format ? format(value, valueType): value;
    }

    copyText(str) {
        let oInput = document.createElement('input');
        oInput.value = str;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand("Copy");
        oInput.style.display = 'none';
        document.body.removeChild(oInput);

        if (str && str.length > 100) {
            str = str.substring(0, 100);
        }
        message.success("复制成功:" + str)
    }

    dataURLtoBlob(dataurl){
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], {type: mime});
    }
}

export default new Help();