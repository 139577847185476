import React from "react";
import styles from "./DataDisposition.less";
import {Divider,Skeleton,message} from "antd";
import AllContentMap from "../contentView/AllContentDefinition";

export default class CustomComponentSel extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount(){

  }

  render(){
    return <div
    >
      {this.props.option?.filter((eachGroup)=>{
        return !!eachGroup.child?.length
      })?.map((eachGroup,index)=>{
        return <div
          key={"index_" + index}
        >
          <Divider orientation="left">
            <div className={styles.groupName}>{eachGroup.name}</div>
          </Divider>
          <div className={styles.eachGroupBox}>
            {
              eachGroup.child?.map((eachView)=>{
                let coverUrl;
                if (eachView.contentMsg?.dataConfig?.coverUrl) {
                  coverUrl = eachView.contentMsg?.dataConfig?.coverUrl;
                } else if (eachView.contentMsg?.dataConfig?.contentType) {
                  coverUrl = AllContentMap[eachView.contentMsg.dataConfig.contentType]?.cover
                }

                return <div
                  key={eachView._id}
                  className={styles.viewItem}
                  onClick={()=>{
                    eachView.groupName = eachGroup.name;
                    this.props.onChange?.(eachView)
                  }}
                >
                  {
                    coverUrl
                      ?<div className={styles.viewLogo}
                            style={{backgroundImage: `url(${coverUrl})`}}
                      ></div>
                      :<div className={styles.viewLogo}>{eachView.name}</div>
                  }
                  {eachView.name}
                </div>
              })
            }
          </div>
        </div>
      })}
    </div>
  }
}