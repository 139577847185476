import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";

export default class ScatterShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        let ifUseCartesian2d = this.props.contentMsg?.dataConfig?.contentParams?.scatterSpecial?._coordinateSystem === "cartesian2d";

        if(ifUseCartesian2d){
            //直角坐标系
            option.xAxis = Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.xAxis);
            option.yAxis = Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.yAxis);
        }else{
            //极坐标系  径向轴 radiusAxis 作为主轴，代表分类数目， 角度轴 angleAxis 作为辅轴，代表 数量，(也可作为 类目)
            option.angleAxis = Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.yAxis);
            option.radiusAxis = Object.assign({},this.props.contentMsg?.dataConfig?.contentParams?.xAxis);
        }

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;

        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position){
            case "top": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "center",
            });break;
            case "bottom": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "center",
            });break;
            case "left": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "5%",
            });break;
            case "right": option.legend =  Object.assign(option.legend,{
                top: "center",
                right: "5%",
            });break;
            case "topLeft": option.legend =  Object.assign(option.legend,{
                top: "5%",
                left: "5%",
            });break;
            case "topRight": option.legend =  Object.assign(option.legend,{
                top: "5%",
                right: "5%",
            });break;
            case "bottomLeft": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                left: "5%",
            });break;
            case "bottomRight": option.legend =  Object.assign(option.legend,{
                bottom: "5%",
                right: "5%",
            });break;
            case "center": option.legend =  Object.assign(option.legend,{
                top: "center",
                left: "center",
            });break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;
        option.tooltip.formatter= '{a} {b} {c}'

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        if(ifUseCartesian2d){
            //网格布局
            option.grid = this.props.contentMsg?.dataConfig?.contentParams?.usual?.grid;
        }else{
            //极坐标布局
            option.polar = {
                center: [this.props.contentMsg?.dataConfig?.contentParams?.usualForPolar?._centerSetX || "50%",this.props.contentMsg?.dataConfig?.contentParams?.usualForPolar?._centerSetY || "50%"],
                radius: [(this.props.contentMsg?.dataConfig?.contentParams?.usualForPolar?._radiusInner || 0) + "%",(this.props.contentMsg?.dataConfig?.contentParams?.usualForPolar?._radiusOuter || 75) + "%"],
            }
        }

        //缩放
        if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useX || this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useY){
            option.dataZoom = []

            if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useX){
                let zoomXConfig = {
                    throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                }
                if(ifUseCartesian2d){
                    zoomXConfig.xAxisIndex = [0]
                    option.dataZoom.push(Object.assign({
                        type: 'slider',
                        height: 20,
                    },zoomXConfig))
                }else{
                    zoomXConfig.angleAxisIndex = [0]
                }

                option.dataZoom.push(Object.assign({
                    type: 'inside',
                },zoomXConfig))
            }
            if(this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?.useY){
                let zoomYConfig = {
                    throttle: this.props.contentMsg?.dataConfig?.contentParams?.dataZoom?._instantly?0:100
                }
                if(ifUseCartesian2d){
                    zoomYConfig.yAxisIndex = [0]
                    option.dataZoom.push(Object.assign({
                        type: 'slider',
                        width: 20,
                    },zoomYConfig))
                }else{
                    zoomYConfig.radiusAxisIndex = [0]
                }
                option.dataZoom.push(Object.assign({
                    type: 'inside',
                },zoomYConfig))
            }
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);

        if(Object.prototype.toString.call(data) !== '[object Object]'){
            this.setState({
                error: "数据结构不符合要求"
            })
            return;
        }

        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        let option = this.getChartOption();

        let ifUseCartesian2d = dataConfig?.contentParams?.scatterSpecial?._coordinateSystem === "cartesian2d";

        if(dataConfig?.contentParams){
            //散点图特殊配置
            let seriesItem = {
                type: "scatter",
                coordinateSystem: dataConfig?.contentParams?.scatterSpecial?._coordinateSystem,
                symbol: dataConfig?.contentParams?.scatterSpecial?._symbolSet.symbol,
                symbolSize: dataConfig?.contentParams?.scatterSpecial?._symbolSet.symbolSize,
                large: true,
            };

            if(ifUseCartesian2d){
                //直角坐标系
                if(dataConfig?.contentParams?.dataSetConfig?.mainAxisField){
                    option.xAxis.type = "category";
                    option.xAxis.data = data[dataConfig?.contentParams?.dataSetConfig?.mainAxisField]
                }
                if(dataConfig?.contentParams?.dataSetConfig?.auxiliaryAxisField){
                    option.yAxis.type = "category";
                    option.yAxis.data = data[dataConfig?.contentParams?.dataSetConfig?.auxiliaryAxisField]
                }
                // 是否x,y翻转
                if(this.props.contentMsg?.dataConfig?.contentParams?.usual?._categoryWhere === "y"){
                    let tempSave = {
                        type: option.xAxis.type,
                        data: option.xAxis.data,
                    }

                    option.xAxis.type = option.yAxis.type;
                    option.xAxis.data = option.yAxis.data;
                    option.yAxis.type = tempSave.type;
                    option.yAxis.data = tempSave.data;
                }
            }else{
                //极坐标系
                if(dataConfig?.contentParams?.dataSetConfig?.mainAxisField){
                    option.radiusAxis.type = "category";
                    option.radiusAxis.data = data[dataConfig?.contentParams?.dataSetConfig?.mainAxisField]
                }
                if(dataConfig?.contentParams?.dataSetConfig?.auxiliaryAxisField){
                    option.angleAxis.type = "category";
                    option.angleAxis.data = data[dataConfig?.contentParams?.dataSetConfig?.auxiliaryAxisField]
                }
            }

            //根据数据和配置填充其它属性
            option.series = [];
            dataConfig?.contentParams?.dataSetConfig?.pointSeriesArr?.forEach((eachPointArrConfig)=>{
                if(Array.isArray(data[eachPointArrConfig.field]) && Array.isArray(data[eachPointArrConfig.field][0])){
                    //数据有效
                    let newSeriesItem = _.cloneDeep(seriesItem);
                    newSeriesItem.name = eachPointArrConfig.field;
                    if(data[eachPointArrConfig.field][0].length === 3){
                        //带大小的数据
                        let min = 100000000;
                        let max = 0;
                        data[eachPointArrConfig.field].forEach((eachPoint)=>{
                            min = Math.min(min,(eachPoint[2] || 0));
                            max = Math.max(max,(eachPoint[2] || 0));
                        })
                        let dua = (max - min) || max || 1;
                        newSeriesItem.symbolSize = function (val){
                            return ((val[2] || 0)/dua) * dataConfig?.contentParams?.scatterSpecial?._symbolSet.symbolSize
                        }
                    }

                    newSeriesItem.data = data[eachPointArrConfig.field]
                    if(ifUseCartesian2d && this.props.contentMsg?.dataConfig?.contentParams?.usual?._categoryWhere === "y"){
                        //翻转了轴
                        newSeriesItem.data?.forEach((eachPoint)=>{
                            let temp = eachPoint[0];
                            eachPoint[0] = eachPoint[1];
                            eachPoint[1] = temp;
                        })
                    }
                    option.series.push(newSeriesItem)
                }
            })
        }

        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}