import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";
import TreeDataHandler from "../../../../../../../tool/treeDataHandler";

export default class TreemapShow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            error: null
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme,{
            locale: "ZH"
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.myChart && this.myChart.dispose()
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if(this.props.contentMsg?.dataConfig?.contentParams?.theme?.color){
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor)=>{
                if(typeof eachColor === 'string' || !eachColor){
                    return eachColor
                }else{
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if(eachColor.direction==="horizontal"){
                        obj.x2 = 1;
                    }else{
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    showValue(data){
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig,dataConfig} = this.props.contentMsg;

        if(!Array.isArray(data)){
            this.setState({
                error: "数据格式不匹配"
            })
            return;
        }

        let option = this.getChartOption();

        if(dataConfig?.contentParams){
            let seriesItem = {
                type: "treemap",
                leafDepth: dataConfig.contentParams?.treemapSpecial?.leafDepth,
                drillDownIcon: dataConfig.contentParams?.treemapSpecial?.drillDownIcon,
            };

            //布局
            seriesItem = Object.assign(seriesItem,dataConfig.contentParams?.usual?.grid)

            //面包屑
            seriesItem.breadcrumb = dataConfig.contentParams?.breadcrumb;
            switch (dataConfig.contentParams?.breadcrumb?._position){
                case "top": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "2%",
                    left: "center",
                });break;
                case "bottom": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    bottom: "2%",
                    left: "center",
                });break;
                case "left": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "center",
                    left: "2%",
                });break;
                case "right": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "center",
                    right: "2%",
                });break;
                case "topLeft": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "2%",
                    left: "2%",
                });break;
                case "topRight": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "2%",
                    right: "2%",
                });break;
                case "bottomLeft": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    bottom: "2%",
                    left: "2%",
                });break;
                case "bottomRight": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    bottom: "2%",
                    right: "2%",
                });break;
                case "center": option.legend =  Object.assign(seriesItem.breadcrumb,{
                    top: "center",
                    left: "center",
                });break;
            }

            //标签
            if(dataConfig?.contentParams?.label){
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                    position: dataConfig?.contentParams?.label.position,
                }

                if(dataConfig?.contentParams?.label?._beforeTxt || dataConfig?.contentParams?.label?._afterTxt){
                    seriesItem.label.formatter = `${dataConfig?.contentParams?.label?._beforeTxt || ""}{b} {c}${dataConfig?.contentParams?.label?._afterTxt || ""}`
                }

                // switch (dataConfig?.contentParams?.label._showPlan){
                //     case "normal":break;
                //     case "pop":
                //         seriesItem.label.padding= 5;
                //         break;
                //     case "popDark":
                //         seriesItem.label.padding= 5;
                //         break;
                // }
            }

            //父标签
            if(dataConfig?.contentParams?.upperLabel){
                seriesItem.upperLabel = {
                    show: dataConfig?.contentParams?.upperLabel.show,
                    color: dataConfig?.contentParams?.upperLabel.color || "inherit",
                    position: dataConfig?.contentParams?.upperLabel.position,
                    backgroundColor: dataConfig?.contentParams?.upperLabel.backgroundColor,
                }

                if(dataConfig?.contentParams?.upperLabel?._beforeTxt || dataConfig?.contentParams?.upperLabel?._afterTxt){
                    seriesItem.upperLabel.formatter = `${dataConfig?.contentParams?.upperLabel?._beforeTxt || ""}{b} {c}${dataConfig?.contentParams?.upperLabel?._afterTxt || ""}`
                }

                // switch (dataConfig?.contentParams?.upperLabel._showPlan){
                //     case "normal":break;
                //     case "pop":
                //         seriesItem.upperLabel.padding= 5;
                //         break;
                //     case "popDark":
                //         seriesItem.upperLabel.padding= 5;
                //         break;
                // }
            }

            //面积样式
            seriesItem.itemStyle = dataConfig?.contentParams?.itemStyle;

            //高亮配置
            seriesItem.emphasis = {}
            //高亮下的面积
            if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.itemStyle = _.cloneDeep(seriesItem.itemStyle);

                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("borderColorSaturation")){
                    seriesItem.emphasis.itemStyle.borderColorSaturation = dataConfig?.contentParams?.itemStyle?._emphasis?.borderColorSaturation;
                }
                if(dataConfig?.contentParams?.itemStyle?._emphasisSel?.includes("shadowColor")){
                    //需要设置颜色，但颜色不一定配置了
                    if(dataConfig?.contentParams?.itemStyle?._emphasis?.shadowColor){
                        seriesItem.emphasis.itemStyle.shadowColor = dataConfig?.contentParams?.itemStyle?._emphasis?.shadowColor;
                    }else{
                        delete seriesItem.emphasis.itemStyle.shadowColor
                    }
                }
            }

            //高亮下的标签
            if(dataConfig?.contentParams?.label?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.label?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.label?._emphasis?.show){
                        seriesItem.emphasis.label.show = true;
                    }else{
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }

            //高亮下的父级标签
            if(dataConfig?.contentParams?.upperLabel?._emphasisSel?.length){
                //先继承基础值
                seriesItem.emphasis.upperLabel = _.cloneDeep(seriesItem.upperLabel);

                if(dataConfig?.contentParams?.upperLabel?._emphasisSel?.includes("color")){
                    seriesItem.emphasis.upperLabel.color = dataConfig?.contentParams?.upperLabel?._emphasis?.color || "inherit";
                }

                if(dataConfig?.contentParams?.upperLabel?._emphasisSel?.includes("show")){
                    if(dataConfig?.contentParams?.upperLabel?._emphasis?.show){
                        seriesItem.emphasis.upperLabel.show = true;
                    }else{
                        seriesItem.emphasis.upperLabel.show = false;
                    }
                }
            }

            //根据数据和配置填充其它属性
            let newData = [];
            if(dataConfig?.contentParams?.dataSetConfig?.labelField && dataConfig?.contentParams?.dataSetConfig?.childrenField){
                let matchRule = {
                    name: dataConfig?.contentParams?.dataSetConfig?.labelField,
                    children: dataConfig?.contentParams?.dataSetConfig?.childrenField
                }
                if(dataConfig?.contentParams?.dataSetConfig?.valueField){
                    matchRule["value"] = dataConfig?.contentParams?.dataSetConfig?.valueField
                }
                newData = TreeDataHandler.changeField(data,matchRule,"new",dataConfig?.contentParams?.dataSetConfig?.childrenField)
            }

            seriesItem.data = newData
            option.series = [seriesItem];
        }


        //自定义转换数据和配置项
        if(dataConfig.formatOptionCode){
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = func(option,data);
            }catch (e){
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        this.setState({
            error: null
        })
    }

    render(){
        let {error} = this.state;
        return <div className={styles.chartBox}>
            <div
                ref={(ref) => {
                    this.chartDom = ref
                }}
                style={{
                    // height: window.innerHeight - 200
                }}
                className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                error
                    ?<div className={styles.errorMsg}>{error}</div>
                    :null
            }
        </div>
    }
}