import React from "react";
import {
    Form,
    Radio,
    InputNumber,
    Select,
    Row,
    Col,
    Divider,
    Checkbox,
    Switch,
    Collapse
} from "antd";
import UploadFile from "../../../../components/upLoad/UploadFile"
import styles from "./DashboardConfigSet.less";
import {ClearOutlined} from "@ant-design/icons";
import CommonFormItems from "../CommonFormItems";
import SVGBorderTypeSel from "./SVGBorderTypeSel";
import ColorPicker from "../formDiyItem/ColorPicker";

const Item = Form.Item;
const Option = Select.Option;


//这个文件给 仪表板，场景，容器  三个配置使用
//有判断 属性值 是否继承自 父级  的逻辑。

//可复用的配置抽象
class BaseSetItem {
    constructor(props) {
        //用来判断默认值是自己的还是继承父级的
        this.selfConfig = props?.selfConfig || null;
        this.resetField = props?.resetField;
    }

    upDateSelfConfig(selfConfig) {
        this.selfConfig = selfConfig;
    }

    //根据传入字段数组，从父级配置中获取缺失的内容
    //只处理第一级
    mergeFinallyConfig(selfConfig, parentConfig, needDealKeys) {
        //合并一下作为表单的默认值
        let formDefaultValues = _.cloneDeep(selfConfig);
        needDealKeys.forEach((key) => {
            if (!formDefaultValues[key]) {
                //之前没配置过，完全替换
                formDefaultValues[key] = parentConfig[key];
            } else {
                //之前配置过，对单个键做判断，只处理第一层子集
                Object.keys(parentConfig[key]).forEach((childKey) => {
                    if (formDefaultValues[key][childKey] === undefined) {
                        //替换成父级的
                        formDefaultValues[key][childKey] = parentConfig[key][childKey]
                    }
                })
            }
        })

        return formDefaultValues;
    }

    //收集配置文件中的文件地址
    collectFileUrls(themeConfig) {
        let fileUrls = [];
        if (!themeConfig) {
            return fileUrls;
        }
        let dealAction = (data) => {
            let prototypeStr = Object.prototype.toString.call(data);
            if (prototypeStr === '[object Object]') {
                //对象，提取键
                Object.keys(data).forEach((eachKey) => {
                    if (eachKey.indexOf("upload_") === 0 && typeof data[eachKey] === "string") {
                        //是文件地址
                        fileUrls.push(data[eachKey])
                    } else {
                        dealAction(data[eachKey])
                    }
                })
            } else if (prototypeStr === '[object Array]') {
                //数组
                data.forEach((eachItem) => {
                    dealAction(eachItem)
                })
            } else {
                //其它，不处理
            }
        }

        dealAction(themeConfig);

        return fileUrls;
    }





    //处理label，判断值是否来源于自己，加重置按钮和透明度
    //this.selfConfig 不存在则不需要判断
    getLabel(formName, field, title, hasResetBtn = true) {
        return <span
            className={
                this.selfConfig && this.selfConfig?.[formName]?.[field] === undefined
                    ? styles.notMyValueLabel
                    : null
            }
        >
                    {
                        hasResetBtn && this.selfConfig?.[formName]?.[field] !== undefined
                            ? <ClearOutlined
                                className={styles.resetBtn}
                                onClick={() => {
                                    this.resetField?.(formName, field);
                                }}
                            />
                            : null

                    }
            {title}
                </span>
    }

    //多个背景配置用的参数一样，抽出来了
    getBackgroundSet(formName, hideOptionsArr) {
        // console.log("this.selfConfig",this.selfConfig)
        return <>
            {/*<Item*/}
            {/*    label={this.getLabel(formName,"padding","内边距")}*/}
            {/*>*/}
            {/*    {CommonFormItems.getSliderAndInputItemGroup("padding")}*/}
            {/*</Item>*/}
            <Item
                label={this.getLabel(formName, "use", "启用背景")}
                name="use"
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"开"}
                    unCheckedChildren={"关"}
                />
            </Item>
            <Item
                label={this.getLabel(formName, "radiusSet", "背景圆角")}
            >
                {CommonFormItems.distanceSet(["radiusSet", "num"],["radiusSet", "unit"],["px","rem","%"])}
            </Item>
            <Item
                label={this.getLabel(formName, "overflowHidden", "隐藏超出部分")}
                name="overflowHidden"
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={this.getLabel(formName, "opacity", "不透明度")}
            >
                {CommonFormItems.getSliderAndInputItemGroup("opacity", {
                    min: 0,
                    max: 100
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "brightness", "亮度")}
            >
                {CommonFormItems.getSliderAndInputItemGroup("brightness", {
                    min: 0,
                    max: 200
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "blur", "模糊效果")}
            >
                {CommonFormItems.getSliderAndInputItemGroup("blur", {
                    min: 0,
                    max: 20
                })}
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "modeSet", "具体设置")}
            </Divider>
            <Item
                label={this.getLabel(formName, "modeSet", "背景类型", false)}
                name={["modeSet", "mode"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Radio.Group>
                    {hideOptionsArr?.includes("color") ? null : <Radio value="color">纯色</Radio>}
                    {hideOptionsArr?.includes("border") ? null : <Radio value="border">边框</Radio>}
                    {hideOptionsArr?.includes("eachBorder") ? null : <Radio value="eachBorder">差异边框</Radio>}
                    {hideOptionsArr?.includes("svgBorder") ? null : <Radio value="svgBorder">图形边框</Radio>}
                    {hideOptionsArr?.includes("image") ? null : <Radio value="image">图片</Radio>}
                    {hideOptionsArr?.includes("video") ? null : <Radio value="video">视频</Radio>}
                </Radio.Group>
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.modeSet.mode !== currentValues.modeSet.mode}
            >
                {({getFieldValue}) => {
                    let showItem;
                    let defaultImgUrl = getFieldValue(["modeSet", "upload_imageUrl"]);
                    let defaultVioUrl = getFieldValue(["modeSet", "upload_videoUrl"]);

                    switch (getFieldValue(["modeSet", "mode"])) {
                        case "color":
                            showItem = <Item
                                label={this.getLabel(formName, "modeSet", "背景颜色", false)}
                            >
                                {CommonFormItems.getColorSetItemInner(["modeSet", "color"])}
                            </Item>
                            break;
                        case "border":
                            showItem = <>
                                <Item
                                    label={this.getLabel(formName, "modeSet", "边框颜色", false)}
                                >
                                    {CommonFormItems.getColorSetItemInner(["modeSet", "color"])}
                                </Item>
                                <Form.Item
                                    label={this.getLabel(formName, "modeSet", "边框类型", false)}
                                    name={["modeSet", "borderBgSet", "type"]}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="solid">实线</Radio>
                                        <Radio value="dashed">虚线</Radio>
                                        <Radio value="dotted">点线</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label={this.getLabel(formName, "modeSet", "边框粗细", false)}
                                    name={["modeSet", "borderBgSet", "width"]}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        step={1}
                                        precision={0}
                                        addonAfter={<span>px</span>}
                                    />
                                </Form.Item>
                            </>
                            break;
                        case "eachBorder":
                            showItem = <>
                                <Item
                                    label={"上边框"}
                                >
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "颜色", false)}
                                    >
                                        {CommonFormItems.getColorSetItemInner(["modeSet", "borderTop","color"])}
                                    </Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "类型", false)}
                                        name={["modeSet", "borderTop", "borderBgSet", "type"]}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "粗细", false)}
                                        name={["modeSet", "borderTop", "borderBgSet", "width"]}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"下边框"}
                                >
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "颜色", false)}
                                    >
                                        {CommonFormItems.getColorSetItemInner(["modeSet", "borderBottom", "color"])}
                                    </Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "类型", false)}
                                        name={["modeSet", "borderBottom", "borderBgSet", "type"]}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "粗细", false)}
                                        name={["modeSet", "borderBottom", "borderBgSet", "width"]}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"左边框"}
                                >
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "颜色", false)}
                                    >
                                        {CommonFormItems.getColorSetItemInner(["modeSet", "borderLeft", "color"])}
                                    </Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "类型", false)}
                                        name={["modeSet", "borderLeft", "borderBgSet", "type"]}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "粗细", false)}
                                        name={["modeSet", "borderLeft", "borderBgSet", "width"]}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"右边框"}
                                >
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "颜色", false)}
                                    >
                                        {CommonFormItems.getColorSetItemInner(["modeSet", "borderRight", "color"])}
                                    </Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "类型", false)}
                                        name={["modeSet", "borderRight", "borderBgSet", "type"]}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={this.getLabel(formName, "modeSet", "粗细", false)}
                                        name={["modeSet", "borderRight", "borderBgSet", "width"]}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                            </>
                            break;
                        case "image":
                            showItem = <>
                                <Form.Item
                                    name={["modeSet", "backgroundImgSize"]}
                                    label={this.getLabel(formName, "modeSet", "图片大小", false)}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="cover">剪裁铺满</Radio>
                                        <Radio value="contain">包含</Radio>
                                        <Radio value="100% 100%">拉伸填充</Radio>
                                        <Radio value="diy">自定义比例</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => prevValues.modeSet.backgroundImgSize !== currentValues.modeSet.backgroundImgSize}
                                >
                                    {({getFieldValue}) => {
                                        let showItem;
                                        if (getFieldValue(["modeSet", "backgroundImgSize"]) === "diy") {
                                            showItem = <>
                                                <Item
                                                    label={this.getLabel(formName, "modeSet", "宽", false)}
                                                >
                                                    {CommonFormItems.distanceSet(["modeSet", "backgroundImgSizeDiy", "width"], ["modeSet", "backgroundImgSizeDiy", "widthUnit"])}
                                                </Item>
                                                <Item
                                                    label={this.getLabel(formName, "modeSet", "高", false)}
                                                >
                                                    {CommonFormItems.distanceSet(["modeSet", "backgroundImgSizeDiy", "height"], ["modeSet", "backgroundImgSizeDiy", "heightUnit"])}
                                                </Item>
                                            </>
                                        } else {
                                            showItem = <>

                                            </>
                                        }
                                        return showItem
                                    }}
                                </Item>
                                <Form.Item
                                    name={["modeSet", "backgroundImgRepeat"]}
                                    label={this.getLabel(formName, ["modeSet", "backgroundImgRepeat"], "缺省设置", false)}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="repeat">自动填充缺省部分</Radio>
                                        <Radio value="no-repeat">不填充缺省</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label={this.getLabel(formName, "modeSet", "图片位置", false)}
                                >
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "距左", false)}
                                    >
                                        {CommonFormItems.distanceSet(["modeSet", "bgImgPositionSet", "left"], ["modeSet", "bgImgPositionSet", "leftUnit"])}
                                    </Item>
                                    <Item
                                        label={this.getLabel(formName, "modeSet", "距顶", false)}
                                    >
                                        {CommonFormItems.distanceSet(["modeSet", "bgImgPositionSet", "top"], ["modeSet", "bgImgPositionSet", "topUnit"])}
                                    </Item>
                                </Form.Item>
                                <Form.Item
                                    name={["modeSet", "upload_imageUrl"]}
                                    label={this.getLabel(formName, "modeSet", "上传背景图", false)}
                                    rules={[{required: true}]}
                                    normalize={(value, prevValue, prevValues) => {
                                        value = value?.[0]?.url || null
                                        return value
                                    }}
                                >
                                    <UploadFile
                                        accept={"image/*"}
                                        size={1}
                                        bigLabel={true}
                                        data={{
                                            option: {
                                                size: 1,
                                                defaultValue: defaultImgUrl ? [{
                                                    url: defaultImgUrl
                                                }] : null
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </>
                            break;
                        case "video":
                            showItem = <>
                                <Form.Item
                                    name={["modeSet", "upload_videoUrl"]}
                                    label={this.getLabel(formName, "modeSet", "上传视频", false)}
                                    rules={[{required: true}]}
                                    normalize={(value, prevValue, prevValues) => {
                                        value = value?.[0]?.url || null
                                        return value
                                    }}
                                >
                                    <UploadFile
                                        type={2}
                                        accept={"video/*"}
                                        size={1}
                                        bigLabel={true}
                                        data={{
                                            option: {
                                                size: 1,
                                                defaultValue: defaultVioUrl ? [{
                                                    url: defaultVioUrl
                                                }] : null
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={["modeSet", "bgVdoPositionSet", "objectFit"]}
                                    label={this.getLabel(formName, "modeSet", "填充方式", false)}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="cover">剪裁铺满</Radio>
                                        <Radio value="contain">包含</Radio>
                                        <Radio value="fill">拉伸填充</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name={["modeSet", "bgVdoPositionSet", "scale"]}
                                    label={this.getLabel(formName, "modeSet", "缩放比例", false)}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        min={0.01}
                                        max={100}
                                        step={1}
                                        precision={2}
                                        addonAfter={<span>倍</span>}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name={["modeSet", "bgVdoPositionSet", "scaleOption"]}
                                    label={this.getLabel(formName, "modeSet", "缩放中心", false)}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="center">视频中心</Radio>
                                        <Radio value="top left">视频左上角</Radio>
                                        <Radio value="top right">视频右上角</Radio>
                                        <Radio value="bottom left">视频左下角</Radio>
                                        <Radio value="bottom right">视频右下角</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Item
                                    label={"视频设置偏移"}
                                >
                                    <Item
                                        label={this.getLabel(formName, "positionSet", "x轴偏移", false)}
                                    >
                                        {CommonFormItems.distanceSet(["modeSet", "bgVdoPositionSet", "absoluteSet", "translateX"], ["modeSet", "bgVdoPositionSet", "absoluteSet", "translateXUnit"])}
                                    </Item>
                                    <Item
                                        label={this.getLabel(formName, "positionSet", "y轴偏移", false)}
                                    >
                                        {CommonFormItems.distanceSet(["modeSet", "bgVdoPositionSet", "absoluteSet", "translateY"], ["modeSet", "bgVdoPositionSet", "absoluteSet", "translateYUnit"])}
                                    </Item>
                                </Item>
                            </>;break;
                        case "svgBorder": showItem = <>
                            <Item
                                label={this.getLabel(formName, "modeSet", "边框颜色", false)}
                            >
                                {CommonFormItems.getColorSetItemInner(["modeSet", "color"])}
                            </Item>
                            <Form.Item
                                label={this.getLabel(formName, "modeSet", "边框类型", false)}
                                name={["modeSet", "borderSVGType"]}
                            >
                                <SVGBorderTypeSel
                                    color={getFieldValue(["modeSet", "color"])}
                                />
                            </Form.Item>
                        </>
                    }
                    return showItem
                }}
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.modeSet.mode !== currentValues.modeSet.mode}
            >
                {({getFieldValue}) => {
                    let showItem;
                    if (getFieldValue(["modeSet", "mode"]) !== "color") {
                        showItem = <>
                            <Divider orientation="left">
                                {this.getLabel(formName, "baseLaySet", "基底设置")}
                            </Divider>
                            <Item
                                label={this.getLabel(formName, "baseLaySet", "基底颜色", false)}
                            >
                                {CommonFormItems.getColorSetItemInner(["baseLaySet", "color"])}
                            </Item>
                            <Item
                                label={this.getLabel(formName, "baseLaySet", "基底不透明度")}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["baseLaySet", "opacity"], {
                                    min: 0,
                                    max: 100
                                })}
                            </Item>
                        </>
                    } else {
                        showItem = <>

                        </>
                    }
                    return showItem
                }}
            </Item>
        </>
    }

    //基础配置
    getCommonSetForm(formName) {
        return <>
            <Divider orientation="left">
                {this.getLabel(formName, "refreshSet", "数据对接相关", true)}
            </Divider>
            <Item
                label={this.getLabel(formName, "refreshSet", "刷新频率")}
                extra={"容器绑定数据的刷新频率"}
            >
                <Row>
                    <Col span={12}>
                        <Item
                            noStyle
                            name={["refreshSet", "time"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            <InputNumber
                                min={1}
                                max={1000}
                                step={1}
                                precision={0}
                            />
                        </Item>
                    </Col>
                    <Col span={12}>
                        <Item
                            noStyle
                            name={["refreshSet", "unit"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            <Select placeholder="选择时间单位"
                                    style={{
                                        width: "60px",
                                        marginLeft: '8px',
                                    }}
                            >
                                <Option value={1}>秒</Option>
                                <Option value={60}>分钟</Option>
                                <Option value={360}>小时</Option>
                            </Select>
                        </Item>
                    </Col>
                </Row>
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "colorMode", "整体风格", true)}
            </Divider>
            <Item
                name="colorMode"
                label={this.getLabel(formName, "colorMode", "主题色")}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="dark">深色</Radio>
                    <Radio value="light">浅色</Radio>
                </Radio.Group>
            </Item>
            {this.getPaddingSet(formName, "容器内边距")}
        </>
    }

    //内容样式
    getContentSet(formName) {
        return <>
            <Item
                label={this.getLabel(formName, "opacity", "不透明度")}
                name="opacity"
            >
                {CommonFormItems.getSliderAndInputItemGroup("opacity", {
                    min: 0,
                    max: 100
                })}
            </Item>
            {this.getPaddingSet(formName, "内容内边距")}
            <Item
                label={this.getLabel(formName, "justifyContent", "水平方向")}
                name={"justifyContent"}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value="flex-start">靠左</Radio>
                    <Radio value="center">居中</Radio>
                    <Radio value="flex-end">靠右</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={this.getLabel(formName, "alignItems", "垂直方向")}
                name={"alignItems"}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value="flex-start">靠上</Radio>
                    <Radio value="center">居中</Radio>
                    <Radio value="flex-end">靠下</Radio>
                </Radio.Group>
            </Item>
            {/*<Divider orientation="left">*/}
            {/*    {this.getLabel(formName, "cardStyle", "卡片配色")}*/}
            {/*</Divider>*/}
            {/*<Item*/}
            {/*    label={this.getLabel(formName, "cardStyle", "名称颜色", false)}*/}
            {/*>*/}
            {/*    {CommonFormItems.getColorSetItemInner(["cardStyle", "titleColor"])}*/}
            {/*</Item>*/}
            {/*<Item*/}
            {/*    label={this.getLabel(formName, "cardStyle", "值颜色", false)}*/}
            {/*>*/}
            {/*    {CommonFormItems.getColorSetItemInner(["cardStyle", "valueColor"])}*/}
            {/*</Item>*/}
        </>
    }

    //padding设置
    getPaddingSet(formName, mainTitle = "内边距") {
        return <>
            <Item
                label={this.getLabel(formName, "padding", mainTitle)}
            >
                <Item
                    label={this.getLabel(formName, "padding", "上", false)}
                >
                    {CommonFormItems.distanceSet(["padding", "top"], ["padding", "topUnit"], ["px", "rem"])}
                </Item>
                <Item
                    label={this.getLabel(formName, "padding", "下", false)}
                >
                    {CommonFormItems.distanceSet(["padding", "bottom"], ["padding", "bottomUnit"], ["px", "rem"])}
                </Item>
                <Item
                    label={this.getLabel(formName, "padding", "左", false)}
                >
                    {CommonFormItems.distanceSet(["padding", "left"], ["padding", "leftUnit"], ["px", "rem"])}
                </Item>
                <Item
                    label={this.getLabel(formName, "padding", "右", false)}
                >
                    {CommonFormItems.distanceSet(["padding", "right"], ["padding", "rightUnit"], ["px", "rem"])}
                </Item>
            </Item>
        </>
    }

    //容器标题
    getContentTitle(formName) {
        return <>
            <Item
                label={this.getLabel(formName, "show", "显示")}
                name="show"
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"开"}
                    unCheckedChildren={"关"}
                />
            </Item>
            {this.getPaddingSet(formName, "标题内边距")}
            <Item
                label={this.getLabel(formName, "color", "标题颜色", false)}
            >
                {CommonFormItems.getColorSetItemInner("color")}
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "fontSet", "标题字体")}
            </Divider>
            <Item
                label={this.getLabel(formName, "fontSet", "字体类型", false)}
                name={["fontSet", "fontFamily"]}
                rules={[{required: true}]}
            >
                {CommonFormItems.getFontFamilySelect()}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "基准单位", false)}
                name={["fontSet", "sizeUnit"]}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value="px">px</Radio>
                    <Radio value="rem">rem</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字体大小", false)}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["fontSet", "fontSize"], {
                    min: 1,
                    max: 32
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字间距", false)}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["fontSet", "wordSpacing"], {
                    min: 0,
                    max: 32
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "行高", false)}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["fontSet", "lineHeight"], {
                    min: 0,
                    max: 32
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字体样式", false)}
            >
                <Item
                    noStyle
                    name={["fontSet", "fontBold"]}
                    rules={[{required: true}]}
                    valuePropName={"checked"}
                >
                    <Checkbox>加粗</Checkbox>
                </Item>
                <Item
                    noStyle
                    name={["fontSet", "fontItalic"]}
                    rules={[{required: true}]}
                    valuePropName={"checked"}
                >
                    <Checkbox>倾斜</Checkbox>
                </Item>
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "positionSet", "标题位置")}
            </Divider>
            <Item
                label={this.getLabel(formName, "positionSet", "书写方向", false)}
                name={["positionSet", "writingMode"]}
                rules={[{required: true}]}
            >
                <Radio.Group
                    buttonStyle="solid"
                    size={"small"}
                >
                    <Radio.Button value="lr-tb">从左到右</Radio.Button>
                    <Radio.Button value="tb-rl">从上到下</Radio.Button>
                </Radio.Group>
            </Item>
            <Item
                label={this.getLabel(formName, "positionSet", "布局方式", false)}
                name={["positionSet", "layoutMode"]}
                rules={[{required: true}]}
            >
                <Radio.Group
                    buttonStyle="solid"
                    size={"small"}
                >
                    <Radio.Button value="flex">挤占</Radio.Button>
                    <Radio.Button value="absolute">覆盖</Radio.Button>
                </Radio.Group>
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.positionSet.layoutMode !== currentValues.positionSet.layoutMode}
            >
                {({getFieldValue}) => {
                    let showItem;
                    if (getFieldValue(["positionSet", "layoutMode"]) === "flex") {
                        showItem = <>
                            <Item
                                label={this.getLabel(formName, "positionSet", "占据方位", false)}
                                name={["positionSet", "flexSet", "flexWhere"]}
                                rules={[{required: true}]}
                            >
                                <Radio.Group>
                                    <Radio value="top">顶部</Radio>
                                    <Radio value="bottom">底部</Radio>
                                    <Radio value="left">左侧</Radio>
                                    <Radio value="right">右侧</Radio>
                                </Radio.Group>
                            </Item>
                            <Item
                                label={this.getLabel(formName, "positionSet", "对齐方式", false)}
                                name={["positionSet", "flexSet", "flexAlignItems"]}
                                rules={[{required: true}]}
                            >
                                <Radio.Group

                                >
                                    <Radio value="flex-start">头部</Radio>
                                    <Radio value="center">中间</Radio>
                                    <Radio value="flex-end">尾部</Radio>
                                </Radio.Group>
                            </Item>
                        </>
                    } else {
                        showItem = <>
                            <Item
                                label={"覆盖方位设置"}
                            >
                                <Item
                                    label={this.getLabel(formName, "positionSet", "距离顶部", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "top"], ["positionSet", "absoluteSet", "topUnit"])}
                                </Item>
                                <Item
                                    label={this.getLabel(formName, "positionSet", "距离左侧", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "left"], ["positionSet", "absoluteSet", "leftUnit"])}
                                </Item>
                                <Item
                                    label={this.getLabel(formName, "positionSet", "距离右侧", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "right"], ["positionSet", "absoluteSet", "rightUnit"])}
                                </Item>
                                <Item
                                    label={this.getLabel(formName, "positionSet", "距离底部", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "bottom"], ["positionSet", "absoluteSet", "bottomUnit"])}
                                </Item>
                                <Item
                                    label={this.getLabel(formName, "positionSet", "x轴偏移", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "translateX"], ["positionSet", "absoluteSet", "translateXUnit"])}
                                </Item>
                                <Item
                                    label={this.getLabel(formName, "positionSet", "y轴偏移", false)}
                                >
                                    {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "translateY"], ["positionSet", "absoluteSet", "translateYUnit"])}
                                </Item>
                            </Item>
                        </>
                    }
                    return showItem
                }}
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "iconSet", "图标设置")}
            </Divider>
            <Item
                label={this.getLabel(formName, "iconSet", "是否需要", false)}
                name={["iconSet", "use"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Item>
            <Item
                label={"大小设置"}
            >
                <Item
                    label={this.getLabel(formName, "iconSet", "宽", false)}
                >
                    {CommonFormItems.distanceSet(["iconSet", "iconW"], ["iconSet", "iconWUnit"], ["px", "rem"])}
                </Item>
                <Item
                    label={this.getLabel(formName, "positionSet", "高", false)}
                >
                    {CommonFormItems.distanceSet(["iconSet", "iconH"], ["iconSet", "iconHUnit"], ["px", "rem"])}
                </Item>
            </Item>
            <Item
                label={this.getLabel(formName, "iconSet", "与文字的间隙", false)}
                name={["iconSet", "spaceWidthTxt"]}
            >
                {CommonFormItems.distanceSet(["iconSet", "spaceWidthTxt"], ["iconSet", "spaceWidthTxtUnit"], ["px", "rem"])}
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.iconSet?.use !== currentValues.iconSet?.use}
            >
                {({getFieldValue}) => {
                    let defaultImgUrl = getFieldValue(["iconSet", "upload_imageUrl"]);

                    return <Form.Item
                        name={["iconSet", "upload_imageUrl"]}
                        label={this.getLabel(formName, "iconSet", "上传图标", false)}
                        rules={[{required: true}]}
                        normalize={(value, prevValue, prevValues) => {
                            value = value?.[0]?.url || null
                            return value
                        }}
                    >
                        <UploadFile
                            accept={"image/*"}
                            size={1}
                            data={{
                                option: {
                                    size: 1,
                                    defaultValue: defaultImgUrl ? [{
                                        url: defaultImgUrl
                                    }] : null
                                }
                            }}
                        />
                    </Form.Item>
                }}
            </Item>
        </>
    }

    //内容字体
    getContentFontSet(formName) {
        return <>
            <Item
                label={this.getLabel(formName, "color", "字体颜色", false)}
            >
                {CommonFormItems.getColorSetItemInner("color")}
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "fontSet", "字体细节")}
            </Divider>
            <Item
                label={this.getLabel(formName, "fontSet", "字体类型", false)}
                name={["fontSet", "fontFamily"]}
                rules={[{required: true}]}
            >
                {CommonFormItems.getFontFamilySelect()}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字体大小", false)}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["fontSet", "fontSize"], {
                    min: 12,
                    max: 32
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字间距", false)}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["fontSet", "wordSpacing"], {
                    min: 0,
                    max: 32
                })}
            </Item>
            <Item
                label={this.getLabel(formName, "fontSet", "字体样式", false)}
            >
                <Item
                    noStyle
                    name={["fontSet", "fontBold"]}
                    rules={[{required: true}]}
                    valuePropName={"checked"}
                >
                    <Checkbox>加粗</Checkbox>
                </Item>
                <Item
                    noStyle
                    name={["fontSet", "fontItalic"]}
                    rules={[{required: true}]}
                    valuePropName={"checked"}
                >
                    <Checkbox>倾斜</Checkbox>
                </Item>
            </Item>
        </>
    }

//切换相关的配置
    getChangeModeSet(formName) {
        return <>
            <Item
                label={this.getLabel(formName, "effects", "切换效果", false)}
                name="effects"
            >
                <Select placeholder="选择切换效果"
                >
                    <Option value={"slide"}>滑入</Option>
                    <Option value={"fade"}>渐变</Option>
                    <Option value={"flip"}>翻转</Option>
                    <Option value={"cards"}>卡片</Option>
                    <Option value={"coverflow"}>3d流</Option>
                    <Option value={"cube"}>方块</Option>
                    {/*<Option value={"creative"}>自定义特效</Option>*/}
                </Select>
            </Item>
            <Divider orientation="left">
                {this.getLabel(formName, "changeSet", "触发配置")}
            </Divider>
            <Item
                label={this.getLabel(formName, "changeSet", "触发时机", false)}
                name={["changeSet", "when"]}
            >
                <Select placeholder="选择触发时机"
                >
                    <Option value={"time"}>定时切换</Option>
                    <Option value={"none"}>无需自动切换</Option>
                </Select>
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.changeSet.when !== currentValues.changeSet.when}
            >
                {({getFieldValue}) => {
                    let showItem;
                    if (getFieldValue(["changeSet", "when"]) === "time") {
                        showItem = <>
                            <Item
                                label={this.getLabel(formName, "changeSet", "切换间隔", false)}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Item
                                            noStyle
                                            name={["changeSet", "delayTime"]}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            style={{
                                                display: 'inline-block',
                                                width: "45%"
                                            }}
                                        >
                                            <InputNumber
                                                min={1}
                                                max={1000}
                                                step={1}
                                                precision={0}
                                            />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item
                                            noStyle
                                            name={["changeSet", "delayTimeUnit"]}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            style={{
                                                display: 'inline-block',
                                            }}
                                        >
                                            <Select placeholder="选择时间单位"
                                                    style={{
                                                        width: "60px",
                                                        marginLeft: '8px',
                                                    }}
                                            >
                                                <Option value={1}>秒</Option>
                                                <Option value={60}>分钟</Option>
                                            </Select>
                                        </Item>
                                    </Col>
                                </Row>
                            </Item>
                        </>
                    } else {
                        showItem = <>

                        </>
                    }
                    return showItem
                }}
            </Item>
        </>
    }

    //标题背景和图标
    getTitlePlusSet(formName) {
        return <>
            <Item
                label={this.getLabel(formName, "longSet", "背景长度", false)}
            >
                {CommonFormItems.distanceSet(["longSet", "num"], ["longSet", "unit"])}
            </Item>
            {this.getBackgroundSet(formName, ["video"])}

        </>
    }

    //场景切换配置
    getSceneSwiperOptionSet(formName){
        return <>
            <div style={{
                color: "darkorange"
            }}>
                注意：场景切换相关的配置修改后需要保存并在仪表板展示页面查看效果，本页面无切换动画。
            </div>
            <Divider orientation="left">
                切换时机配置
            </Divider>
            <Item
                label={"触发时机"}
                name={["changeSet", "when"]}
            >
                <Select placeholder="选择触发时机"
                >
                    <Option value={"time"}>定时切换</Option>
                    <Option value={"none"}>无需自动切换</Option>
                </Select>
            </Item>
            <Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.changeSet.when !== currentValues.changeSet.when}
            >
                {({getFieldValue}) => {
                    let showItem;
                    if (getFieldValue(["changeSet", "when"]) === "time") {
                        showItem = <>
                            <Item
                                label={"切换间隔"}
                            >
                                <Row>
                                    <Col span={12}>
                                        <Item
                                            noStyle
                                            name={["changeSet", "delayTime"]}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            style={{
                                                display: 'inline-block',
                                                width: "45%"
                                            }}
                                        >
                                            <InputNumber
                                                min={1}
                                                max={1000}
                                                step={1}
                                                precision={0}
                                            />
                                        </Item>
                                    </Col>
                                    <Col span={12}>
                                        <Item
                                            noStyle
                                            name={["changeSet", "delayTimeUnit"]}
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            style={{
                                                display: 'inline-block',
                                            }}
                                        >
                                            <Select placeholder="选择时间单位"
                                                    style={{
                                                        width: "60px",
                                                        marginLeft: '8px',
                                                    }}
                                            >
                                                <Option value={1}>秒</Option>
                                                <Option value={60}>分钟</Option>
                                            </Select>
                                        </Item>
                                    </Col>
                                </Row>
                            </Item>
                            <Item
                                name={["changeSet", "disableOnInteraction"]}
                                label={"自动停止"}
                                extra={"用户操作后是否停止自动切换"}
                                rules={[{required: true}]}
                                valuePropName={"checked"}
                            >
                                <Switch
                                    checkedChildren={"是"}
                                    unCheckedChildren={"否"}
                                />
                            </Item>
                        </>
                    } else {
                        showItem = <>

                        </>
                    }
                    return showItem
                }}
            </Item>
            <Divider orientation="left">
                切换动画配置
            </Divider>
            <Item
                label={"切换效果"}
                name="effects"
                extra={"注:3d类型的切换可能会影响场景内部的样式以及循环功能，导致未知问题。"}
            >
                <Select placeholder="选择切换效果"
                >
                    <Option value={"slide"}>滑入</Option>
                    <Option value={"fade"}>淡入淡出</Option>
                    <Option value={"cube"}>方块</Option>
                    <Option value={"cards"}>卡片</Option>
                    <Option value={"coverflow"}>3d流</Option>
                    <Option value={"flip"}>3d翻转(适用2个场景)</Option>
                    {/*<Option value={"creative"}>自定义特效</Option>*/}
                </Select>
            </Item>
            <Form.Item
                label={"切换方向"}
                name={["direction"]}
                rules={[{required: true}]}
                extra={"垂直方向的切换暂未实现"}
            >
                <Radio.Group>
                    <Radio value="horizontal">水平切换</Radio>
                    <Radio value="vertical" disabled={true}>垂直切换</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={"切换速度"}
                name={["speed"]}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value={500}>快</Radio>
                    <Radio value={1000}>中</Radio>
                    <Radio value={2000}>慢</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={"循环模式"}
                name={["loopMode"]}
                rules={[{required: true}]}
                extra={"无限循环模式暂时无法使用"}
            >
                <Radio.Group>
                    <Radio value={"rewind"}>倒带</Radio>
                    <Radio value={"loop"} disabled={true}>无限循环</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label={"鼠标拖拽"}
                name={["allowTouchMove"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Form.Item>
            <Form.Item
                label={"方向键切换"}
                name={["keyboard"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"启用"}
                    unCheckedChildren={"禁用"}
                />
            </Form.Item>
            <Divider orientation="left">
                分页器控件
            </Divider>
            <Form.Item
                label={"是否启用"}
                name={["paginationSet","show"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Form.Item>
            <Item
                label={"距离底部"}
            >
                {CommonFormItems.distanceSet(["paginationSet","bottom","num"], ["paginationSet","bottom","unit"],["px","%"])}
            </Item>
            <Form.Item
                label={"表现形式"}
                name={["paginationSet","type"]}
                rules={[{required: true}]}
            >
                <Radio.Group>
                    <Radio value={"bullets"}>圆点</Radio>
                    <Radio value={"fraction"}>分页</Radio>
                </Radio.Group>
            </Form.Item>
            <Item
                noStyle={true}
                shouldUpdate={(prevValues, currentValues) => prevValues.paginationSet.type !== currentValues.paginationSet.type}
            >
                {({getFieldValue}) => {
                    let showItem;

                    switch (getFieldValue(["paginationSet", "type"])) {
                        case "bullets":
                            showItem = <>
                                <Item
                                    label={"圆点颜色"}
                                >
                                    {CommonFormItems.getColorSetItemInner(["paginationSet","color"])}
                                </Item>
                                <Item
                                    label={"大小"}
                                >
                                    {CommonFormItems.distanceSet(["paginationSet","size","num"], ["paginationSet","size","unit"],["px","rem"])}
                                </Item>
                            </>;break;
                            break;
                        case "fraction":
                            showItem = <>
                                {CommonFormItems.getFontSet(["paginationSet","fontSet"],true)}
                            </>;break;
                            break;
                    }

                    return showItem;
                }}
            </Item>
            <Divider orientation="left">
                翻页按钮控件
            </Divider>
            <Form.Item
                label={"是否启用"}
                name={["navigationSet","show"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Form.Item>
            <Item
                label={"按钮颜色"}
            >
                {CommonFormItems.getColorSetItemInner(["navigationSet","color"])}
            </Item>
            <Item
                label={"大小"}
            >
                {CommonFormItems.distanceSet(["navigationSet","size","num"], ["navigationSet","size","unit"],["px","rem"])}
            </Item>
            <Divider orientation="left">
                滚动条控件
            </Divider>
            <Form.Item
                label={"是否启用"}
                name={["scrollbarSet","show"]}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Switch
                    checkedChildren={"是"}
                    unCheckedChildren={"否"}
                />
            </Form.Item>
            <Item
                label={"滚动条颜色"}
            >
                {CommonFormItems.getColorSetItemInner(["scrollbarSet","color"])}
            </Item>
            <Item
                label={"高度"}
            >
                {CommonFormItems.distanceSet(["scrollbarSet","size","num"], ["scrollbarSet","size","unit"],["px","rem"])}
            </Item>
        </>
    }

    //导航配置
    getNavigationBarSet(formName){
        return <>
            <Collapse
                accordion={true}
            >
                <Collapse.Panel
                    forceRender={true}
                    key="showSet"
                    header="总配置"
                >
                    <Form.Item
                        label={"启用导航"}
                        name={["show"]}
                        rules={[{required: true}]}
                        valuePropName={"checked"}
                    >
                        <Switch
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Form.Item>
                </Collapse.Panel>
                <Collapse.Panel
                    forceRender={true}
                    key="positionSet"
                    header="导航位置与大小"
                >
                    <Item
                        label={"布局方式"}
                        name={["positionSet", "layoutMode"]}
                        rules={[{required: true}]}
                    >
                        <Radio.Group
                            buttonStyle="solid"
                            size={"small"}
                        >
                            <Radio.Button value="flex">挤占</Radio.Button>
                            <Radio.Button value="absolute">覆盖</Radio.Button>
                        </Radio.Group>
                    </Item>
                    <Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.positionSet.layoutMode !== currentValues.positionSet.layoutMode}
                    >
                        {({getFieldValue}) => {
                            let showItem;
                            if (getFieldValue(["positionSet", "layoutMode"]) === "flex") {
                                showItem = <>
                                    <Item
                                        label={"占据方位"}
                                        name={["positionSet", "flexSet", "flexWhere"]}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="top">顶部</Radio>
                                            <Radio value="bottom">底部</Radio>
                                        </Radio.Group>
                                    </Item>
                                    <Item
                                        label={"水平方向"}
                                        name={[ "flexAlignItems"]}
                                        rules={[{required: true}]}
                                        extra={"注意：这里是导航栏的外层方位。当导航栏宽度为100%，此配置无效。"}
                                    >
                                        <Radio.Group
                                        >
                                            <Radio value="flex-start">靠左</Radio>
                                            <Radio value="center">居中</Radio>
                                            <Radio value="flex-end">靠右</Radio>
                                        </Radio.Group>
                                    </Item>
                                    <Item
                                        label={"导航宽"}
                                    >
                                        {CommonFormItems.distanceSet(["navWidth"], ["navWidthUnit"],["px","rem","%"])}
                                    </Item>
                                </>
                            } else {
                                showItem = <>
                                    <Item
                                        label={"覆盖方位设置"}
                                    >
                                        <Item
                                            label={"距离顶部"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "top"], ["positionSet", "absoluteSet", "topUnit"])}
                                        </Item>
                                        <Item
                                            label={"距离左侧"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "left"], ["positionSet", "absoluteSet", "leftUnit"])}
                                        </Item>
                                        <Item
                                            label={"距离右侧"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "right"], ["positionSet", "absoluteSet", "rightUnit"])}
                                        </Item>
                                        <Item
                                            label={"距离底部"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "bottom"], ["positionSet", "absoluteSet", "bottomUnit"])}
                                        </Item>
                                        <Item
                                            label={"x轴偏移"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "translateX"], ["positionSet", "absoluteSet", "translateXUnit"])}
                                        </Item>
                                        <Item
                                            label={"y轴偏移"}
                                        >
                                            {CommonFormItems.distanceSet(["positionSet", "absoluteSet", "translateY"], ["positionSet", "absoluteSet", "translateYUnit"])}
                                        </Item>
                                    </Item>
                                </>
                            }
                            return showItem
                        }}
                    </Item>
                    <Item
                        label={"导航高"}
                    >
                        {CommonFormItems.distanceSet(["navHeight"], ["navHeightUnit"],["px","rem"])}
                    </Item>
                    {this.getPaddingSet(formName, "导航内边距")}
                </Collapse.Panel>
                <Collapse.Panel
                    forceRender={true}
                    key="detailSet"
                    header="栏目划分"
                >
                    <Form.Item
                        label={"PC每栏展示"}
                        name={["slidesPerView"]}
                        rules={[{required: true}]}
                    >
                        <InputNumber
                            min={1}
                            max={20}
                            addonAfter={"个项目"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"手机每栏展示"}
                        name={["slidesPerViewMobile"]}
                        rules={[{required: true}]}
                    >
                        <InputNumber
                            min={1}
                            max={10}
                            addonAfter={"个项目"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={"每项间距"}
                        name={["spaceBetween"]}
                        rules={[{required: true}]}
                    >
                        {CommonFormItems.getSliderAndInputItemGroup(["spaceBetween"], {
                            min: 0,
                            max: 100,
                            step: 1,
                        })}
                    </Form.Item>
                    <Item
                        label={"栏目居中排列"}
                        name="centerInsufficientSlides"
                        rules={[{required: true}]}
                        valuePropName={"checked"}
                    >
                        <Switch
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </Collapse.Panel>
                <Collapse.Panel
                    forceRender={true}
                    key="normalItemStyleSet"
                    header="栏目文案"
                    extra={
                        <Item
                            noStyle={true}
                            name={["normalItemStyleSet","_setType"]}
                        >
                            <Radio.Group
                                size={"small"}
                            >
                                <Radio.Button value="normal">未选中</Radio.Button>
                                <Radio.Button value="emphasis">选中时</Radio.Button>
                            </Radio.Group>
                        </Item>
                    }
                >
                    <Item
                        noStyle={true}
                        dependencies={["normalItemStyleSet","_setType"]}
                    >
                        {({getFieldValue})=> {
                            let setType = getFieldValue(["normalItemStyleSet", "_setType"]);

                            return <>
                                <Item
                                    noStyle={true}
                                    hidden={setType !== "normal"}
                                >
                                    <Item
                                        label={"是否显示"}
                                        name={["normalItemStyleSet","show"]}
                                        rules={[{required: true}]}
                                        valuePropName={"checked"}
                                    >
                                        <Switch
                                            checkedChildren={"是"}
                                            unCheckedChildren={"否"}
                                        />
                                    </Item>
                                    {CommonFormItems.getFontSet(["normalItemStyleSet","fontSet"],true)}
                                </Item>
                                <Item
                                    noStyle={true}
                                    hidden={setType !== "emphasis"}
                                >
                                    <Item
                                        label={"改变哪些属性"}
                                        name={["normalItemStyleSet","_emphasisSel"]}
                                    >
                                        <Select
                                            mode={"tags"}
                                        >
                                            <Select.Option value={"show"}>显示与隐藏</Select.Option>
                                            <Select.Option value={"color"}>字体颜色</Select.Option>
                                            <Select.Option value={"fontSize"}>字体大小</Select.Option>
                                            <Select.Option value={"fontStyle"}>字体样式</Select.Option>
                                        </Select>
                                    </Item>
                                    <Item
                                        noStyle={true}
                                        shouldUpdate={(prevValues, currentValues) => prevValues.normalItemStyleSet._emphasisSel !== currentValues.normalItemStyleSet._emphasisSel}
                                    >
                                        {({getFieldValue})=> {
                                            let selFields = getFieldValue(["normalItemStyleSet", "_emphasisSel"]);

                                            return selFields?.map((selField)=>{
                                                switch (selField){
                                                    case "show": return <Item
                                                        label={"是否显示"}
                                                        name={["normalItemStyleSet","_emphasis","show"]}
                                                        rules={[{required: true}]}
                                                        valuePropName={"checked"}
                                                    >
                                                        <Switch
                                                            checkedChildren={"是"}
                                                            unCheckedChildren={"否"}
                                                        />
                                                    </Item>
                                                    case "color": return <Item
                                                        label={"字体颜色"}
                                                        name={["normalItemStyleSet","_emphasis","color"]}
                                                    >
                                                        <ColorPicker/>
                                                    </Item>
                                                    case "fontSize": return <Item
                                                        label={"字体大小"}
                                                    >
                                                        {CommonFormItems.distanceSet(["normalItemStyleSet","_emphasis","fontSize"],["normalItemStyleSet","_emphasis","sizeUnit"],["px","rem"])}
                                                    </Item>
                                                    case "fontStyle": return <Item
                                                        label={"字体样式"}
                                                    >
                                                        <Item
                                                            noStyle
                                                            name={["normalItemStyleSet","_emphasis","fontBold"]}
                                                            rules={[{required: true}]}
                                                            valuePropName={"checked"}
                                                        >
                                                            <Checkbox>加粗</Checkbox>
                                                        </Item>
                                                        <Item
                                                            noStyle
                                                            name={["normalItemStyleSet","_emphasis","fontItalic"]}
                                                            rules={[{required: true}]}
                                                            valuePropName={"checked"}
                                                        >
                                                            <Checkbox>倾斜</Checkbox>
                                                        </Item>
                                                    </Item>
                                                }
                                            })
                                        }}
                                    </Item>
                                </Item>
                            </>
                        }}
                    </Item>
                </Collapse.Panel>
                <Collapse.Panel
                    forceRender={true}
                    key="normalItemBgSet"
                    header="栏目背景"
                    extra={
                        <Item
                            noStyle={true}
                            name={["normalItemBgSet","_setType"]}
                        >
                            <Radio.Group
                                size={"small"}
                            >
                                <Radio.Button value="normal">未选中</Radio.Button>
                                <Radio.Button value="emphasis">选中时</Radio.Button>
                            </Radio.Group>
                        </Item>
                    }
                >
                    <Item
                        noStyle={true}
                        dependencies={["normalItemBgSet","_setType"]}
                    >
                        {({getFieldValue})=> {
                            let setType = getFieldValue(["normalItemBgSet", "_setType"]);

                            return <>
                                <Item
                                    noStyle={true}
                                    hidden={setType !== "normal"}
                                >
                                    <Item
                                        label={"启用背景"}
                                        name={["normalItemBgSet","use"]}
                                        rules={[{required: true}]}
                                        valuePropName={"checked"}
                                    >
                                        <Switch
                                            checkedChildren={"开"}
                                            unCheckedChildren={"关"}
                                        />
                                    </Item>
                                    <Item
                                        label={"背景圆角"}
                                    >
                                        {CommonFormItems.distanceSet(["normalItemBgSet","radiusSet", "num"],["normalItemBgSet","radiusSet", "unit"],["px","rem","%"])}
                                    </Item>
                                    <Item
                                        label={"不透明度"}
                                    >
                                        {CommonFormItems.getSliderAndInputItemGroup(["normalItemBgSet","opacity"], {
                                            min: 0,
                                            max: 100
                                        })}
                                    </Item>
                                    <Item
                                        label={"亮度"}
                                    >
                                        {CommonFormItems.getSliderAndInputItemGroup(["normalItemBgSet","brightness"], {
                                            min: 0,
                                            max: 200
                                        })}
                                    </Item>
                                    {this.getNavItemBgSet(formName,["normalItemBgSet"])}
                                </Item>
                                <Item
                                    noStyle={true}
                                    hidden={setType !== "emphasis"}
                                >
                                    <Item
                                        label={"改变哪些属性"}
                                        name={["normalItemBgSet","_emphasisSel"]}
                                    >
                                        <Select
                                            mode={"tags"}
                                        >
                                            <Select.Option value={"use"}>启用与禁用</Select.Option>
                                            <Select.Option value={"radiusSet"}>圆角</Select.Option>
                                            <Select.Option value={"opacity"}>不透明度</Select.Option>
                                            <Select.Option value={"brightness"}>亮度</Select.Option>
                                            <Select.Option value={"modeSet"}>背景类型</Select.Option>
                                        </Select>
                                    </Item>
                                    <Item
                                        noStyle={true}
                                        shouldUpdate={(prevValues, currentValues) => prevValues.normalItemBgSet._emphasisSel !== currentValues.normalItemBgSet._emphasisSel}
                                    >
                                        {({getFieldValue})=> {
                                            let selFields = getFieldValue(["normalItemBgSet", "_emphasisSel"]);

                                            return selFields?.map((selField)=>{
                                                switch (selField){
                                                    case "use": return <Item
                                                        label={"启用背景"}
                                                        name={["normalItemBgSet","_emphasis","use"]}
                                                        rules={[{required: true}]}
                                                        valuePropName={"checked"}
                                                    >
                                                        <Switch
                                                            checkedChildren={"开"}
                                                            unCheckedChildren={"关"}
                                                        />
                                                    </Item>
                                                    case "radiusSet": return <Item
                                                        label={"背景圆角"}
                                                    >
                                                        {CommonFormItems.distanceSet(["normalItemBgSet","_emphasis","radiusSet", "num"],["normalItemBgSet","_emphasis","radiusSet", "unit"],["px","rem","%"])}
                                                    </Item>
                                                    case "opacity": return <Item
                                                        label={"不透明度"}
                                                    >
                                                        {CommonFormItems.getSliderAndInputItemGroup(["normalItemBgSet","_emphasis","opacity"], {
                                                            min: 0,
                                                            max: 100
                                                        })}
                                                    </Item>
                                                    case "brightness": return <Item
                                                        label={"亮度"}
                                                    >
                                                        {CommonFormItems.getSliderAndInputItemGroup(["normalItemBgSet","_emphasis","brightness"], {
                                                            min: 0,
                                                            max: 200
                                                        })}
                                                    </Item>
                                                    case "modeSet": return this.getNavItemBgSet(formName,["normalItemBgSet","_emphasis"])
                                                }
                                            })
                                        }}
                                    </Item>
                                </Item>
                            </>
                        }}
                    </Item>
                </Collapse.Panel>
            </Collapse>
        </>
    }

    //每个导航标题的背景样式 (省去了一些通用背景配置的样式)
    getNavItemBgSet(formName,parentKeyArr){
        return <>
            <Item
                label={"背景类型"}
                name={parentKeyArr.concat(["modeSet", "mode"])}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="color">纯色</Radio>
                    <Radio value="border">边框</Radio>
                    <Radio value="eachBorder">差异边框</Radio>
                    <Radio value="image">图片</Radio>
                    <Radio value="injectImage">场景缩略图</Radio>
                </Radio.Group>
            </Item>
            <Item
                noStyle
                dependencies={parentKeyArr.concat(["modeSet","mode"])}
                //  prevValues.modeSet.mode 因为要拼接 parentKeyArr 不好写，直接使用 dependencies
                // shouldUpdate={(prevValues, currentValues) => prevValues.modeSet.mode !== currentValues.modeSet.mode}
            >
                {({getFieldValue}) => {
                    let showItem;
                    let defaultImgUrl = getFieldValue(parentKeyArr.concat(["modeSet", "upload_imageUrl"]));

                    let selWhich = getFieldValue(parentKeyArr.concat(["modeSet", "mode"]));
                    switch (selWhich) {
                        case "color":
                            showItem = <Item
                                label={"背景颜色"}
                            >
                                {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "color"]))}
                            </Item>
                            break;
                        case "border":
                            showItem = <>
                                <Item
                                    label={"边框颜色"}
                                >
                                    {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "color"]))}
                                </Item>
                                <Form.Item
                                    label={"边框类型"}
                                    name={parentKeyArr.concat(["modeSet", "borderBgSet", "type"])}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="solid">实线</Radio>
                                        <Radio value="dashed">虚线</Radio>
                                        <Radio value="dotted">点线</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label={"边框粗细"}
                                    name={parentKeyArr.concat(["modeSet", "borderBgSet", "width"])}
                                    rules={[{required: true}]}
                                >
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        step={1}
                                        precision={0}
                                        addonAfter={<span>px</span>}
                                    />
                                </Form.Item>
                            </>
                            break;
                        case "eachBorder":
                            showItem = <>
                                <Item
                                    label={"上边框"}
                                >
                                    <Item
                                        label={"颜色"}
                                    >
                                        {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "borderTop","color"]))}
                                    </Item>
                                    <Form.Item
                                        label={"类型"}
                                        name={parentKeyArr.concat(["modeSet", "borderTop", "borderBgSet", "type"])}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={"粗细"}
                                        name={parentKeyArr.concat(["modeSet", "borderTop", "borderBgSet", "width"])}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"下边框"}
                                >
                                    <Item
                                        label={"颜色"}
                                    >
                                        {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "borderBottom", "color"]))}
                                    </Item>
                                    <Form.Item
                                        label={"类型"}
                                        name={parentKeyArr.concat(["modeSet", "borderBottom", "borderBgSet", "type"])}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={"粗细"}
                                        name={parentKeyArr.concat(["modeSet", "borderBottom", "borderBgSet", "width"])}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"左边框"}
                                >
                                    <Item
                                        label={"颜色"}
                                    >
                                        {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "borderLeft", "color"]))}
                                    </Item>
                                    <Form.Item
                                        label={"类型"}
                                        name={parentKeyArr.concat(["modeSet", "borderLeft", "borderBgSet", "type"])}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={"粗细"}
                                        name={parentKeyArr.concat(["modeSet", "borderLeft", "borderBgSet", "width"])}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                                <Item
                                    label={"右边框"}
                                >
                                    <Item
                                        label={"颜色"}
                                    >
                                        {CommonFormItems.getColorSetItemInner(parentKeyArr.concat(["modeSet", "borderRight", "color"]))}
                                    </Item>
                                    <Form.Item
                                        label={"类型"}
                                        name={parentKeyArr.concat(["modeSet", "borderRight", "borderBgSet", "type"])}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group>
                                            <Radio value="solid">实线</Radio>
                                            <Radio value="dashed">虚线</Radio>
                                            <Radio value="dotted">点线</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label={"粗细"}
                                        name={parentKeyArr.concat(["modeSet", "borderRight", "borderBgSet", "width"])}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            step={1}
                                            precision={0}
                                            addonAfter={<span>px</span>}
                                        />
                                    </Form.Item>
                                </Item>
                            </>
                            break;
                        case "injectImage":
                        case "image":
                            showItem = <>
                                <Form.Item
                                    name={parentKeyArr.concat(["modeSet", "backgroundImgSize"])}
                                    label={"图片大小"}
                                    rules={[{required: true}]}
                                >
                                    <Radio.Group>
                                        <Radio value="cover">剪裁铺满</Radio>
                                        <Radio value="contain">包含</Radio>
                                        <Radio value="100% 100%">拉伸填充</Radio>
                                        <Radio value="diy">自定义比例</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Item
                                    noStyle
                                    dependencies={parentKeyArr.concat(["modeSet","backgroundImgSize"])}
                                >
                                    {({getFieldValue}) => {
                                        let showItem;
                                        if (getFieldValue(parentKeyArr.concat(["modeSet", "backgroundImgSize"])) === "diy") {
                                            showItem = <>
                                                <Item
                                                    label={"宽"}
                                                >
                                                    {CommonFormItems.distanceSet(parentKeyArr.concat(["modeSet", "backgroundImgSizeDiy", "width"]), parentKeyArr.concat(["modeSet", "backgroundImgSizeDiy", "widthUnit"]))}
                                                </Item>
                                                <Item
                                                    label={"高"}
                                                >
                                                    {CommonFormItems.distanceSet(parentKeyArr.concat(["modeSet", "backgroundImgSizeDiy", "height"]), parentKeyArr.concat(["modeSet", "backgroundImgSizeDiy", "heightUnit"]))}
                                                </Item>
                                            </>
                                        } else {
                                            showItem = <>

                                            </>
                                        }
                                        return showItem
                                    }}
                                </Item>
                                <Form.Item
                                    label={"图片位置"}
                                >
                                    <Item
                                        label={"距左"}
                                    >
                                        {CommonFormItems.distanceSet(parentKeyArr.concat(["modeSet", "bgImgPositionSet", "left"]), parentKeyArr.concat(["modeSet", "bgImgPositionSet", "leftUnit"]))}
                                    </Item>
                                    <Item
                                        label={"距顶"}
                                    >
                                        {CommonFormItems.distanceSet(parentKeyArr.concat(["modeSet", "bgImgPositionSet", "top"]), parentKeyArr.concat(["modeSet", "bgImgPositionSet", "topUnit"]))}
                                    </Item>
                                </Form.Item>
                                {
                                    selWhich === "image"
                                        ?<Form.Item
                                            name={parentKeyArr.concat(["modeSet", "upload_imageUrl"])}
                                            label={"上传背景图"}
                                            rules={[{required: true}]}
                                            normalize={(value, prevValue, prevValues) => {
                                                value = value?.[0]?.url || null
                                                return value
                                            }}
                                        >
                                            <UploadFile
                                                accept={"image/*"}
                                                size={1}
                                                bigLabel={true}
                                                data={{
                                                    option: {
                                                        size: 1,
                                                        defaultValue: defaultImgUrl ? [{
                                                            url: defaultImgUrl
                                                        }] : null
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        :null
                                }
                            </>
                            break;
                    }
                    return showItem
                }}
            </Item>
        </>
    }
}




export default BaseSetItem;