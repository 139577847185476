import React from "react";
import {
    Form,
    Divider,
    Select,
    AutoComplete,
    Radio,
    Switch,
    Collapse,
    InputNumber
} from "antd";
import EchartComItem from "../EchartComItem";
import CommonFormItems from "../../../../CommonFormItems";
import ColorPicker from "../../../../formDiyItem/ColorPicker";

const Item = Form.Item;

/**
 * 漏斗图的配置
 * @return {JSX.Element}
 */
function getSetItems(extendData = {}) {
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }


    return <Collapse
        accordion={true}
    >
        <Collapse.Panel
            forceRender={true}
            key="dataSetConfig"
            header="数据对接设置"
        >
            <Item
                label={"名称字段"}
                name={["dataSetConfig","labelField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"数值字段"}
                name={["dataSetConfig","valueField"]}
                rules={[{required: true}]}
            >
                <AutoComplete
                    size={"small"}
                    style={{width: "100%"}}
                    placeholder={"请选择或填写对接字段"}
                    options={opts}
                />
            </Item>
            <Item
                label={"限制数据量"}
                name={["limitNum"]}
                rules={[{required: true}]}
            >
                <InputNumber
                    min={10}
                    max={1000000}
                    step={1}
                />
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="funnelSpecial"
            header="漏斗图专用"
        >
            <Item
                label={"数据排序"}
                name={["funnelSpecial","sort"]}
            >
                <Radio.Group>
                    <Radio value={"ascending"}>按照数值升序</Radio>
                    <Radio value={"descending"}>按照数值降序</Radio>
                    <Radio value={"none"}>不排序</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={"指定数据最小值"}
                name={["funnelSpecial","min"]}
            >
                <InputNumber
                    step={1}
                />
            </Item>
            <Item
                label={"指定数据最大值"}
                name={["funnelSpecial","max"]}
                extra={"可不设置最大值，将自动从数据中提取"}
            >
                <InputNumber
                    step={1}
                />
            </Item>
            <Item
                label={"最小值映射的漏斗宽度"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["funnelSpecial","minSize"],{
                    min: 0,
                    max: 100,
                    addonAfter: "%"
                })}
            </Item>
            <Item
                label={"最大值映射的漏斗宽度"}
            >
                {CommonFormItems.getSliderAndInputItemGroup(["funnelSpecial","maxSize"],{
                    min: 0,
                    max: 100,
                    addonAfter: "%"
                })}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="itemStyle"
            header="面积样式"
            extra={
                <Item
                    noStyle={true}
                    name={["itemStyle","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            <Item
                noStyle={true}
                dependencies={["itemStyle", "_setType"]}
            >
                {({getFieldValue}) => {
                    let setType = getFieldValue(["itemStyle", "_setType"]);

                    return <>
                        <Item
                            noStyle={true}
                            hidden={setType !== "normal"}
                        >
                            <Item
                                label={"描边线宽"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","borderWidth"], {
                                    min: 0,
                                    max: 50
                                })}
                            </Item>
                            <Item
                                label={"描边线颜色"}
                                name={["itemStyle","borderColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                            <Item
                                label={"描边线类型"}
                                name={["itemStyle","borderType"]}
                            >
                                <Radio.Group>
                                    <Radio value="solid">实线</Radio>
                                    <Radio value="dashed">虚线</Radio>
                                    <Radio value="dotted">点线</Radio>
                                </Radio.Group>
                            </Item>
                            <Item
                                label={"各层间距"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["funnelSpecial","gap"], {
                                    min: 0,
                                    max: 50
                                })}
                            </Item>
                            <Item
                                label={"阴影大小"}
                            >
                                {CommonFormItems.getSliderAndInputItemGroup(["itemStyle","shadowBlur"], {
                                    min: 0,
                                    max: 20
                                })}
                            </Item>
                            <Item
                                label={"阴影颜色"}
                                name={["itemStyle","shadowColor"]}
                            >
                                <ColorPicker/>
                            </Item>
                        </Item>
                        <Item
                            noStyle={true}
                            hidden={setType !== "emphasis"}
                        >
                            <Item
                                label={"改变哪些属性"}
                                name={["itemStyle", "_emphasisSel"]}
                            >
                                <Select
                                    mode={"tags"}
                                >
                                    <Select.Option value={"borderColor"}>描边线颜色</Select.Option>
                                    <Select.Option value={"borderType"}>描边线类型</Select.Option>
                                </Select>
                            </Item>
                            <Item
                                noStyle={true}
                                dependencies={["itemStyle", "_emphasisSel"]}
                            >
                                {({getFieldValue}) => {
                                    let selFields = getFieldValue(["itemStyle", "_emphasisSel"]);

                                    return selFields?.map((selField) => {
                                        switch (selField) {
                                            case "borderType":
                                                return <Item
                                                    label={"描边线类型"}
                                                    name={["itemStyle", "_emphasis", "borderType"]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value="solid">实线</Radio>
                                                        <Radio value="dashed">虚线</Radio>
                                                        <Radio value="dotted">点线</Radio>
                                                    </Radio.Group>
                                                </Item>
                                            case "borderColor":
                                                return <Item
                                                    key={"描边线颜色"}
                                                    label={"阴影颜色"}
                                                    name={["itemStyle", "_emphasis", "borderColor"]}
                                                    rules={[{required: false}]}
                                                >
                                                    <ColorPicker/>
                                                </Item>;
                                        }
                                    })
                                }}
                            </Item>
                        </Item>
                    </>
                }}
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="theme"
            header="主题"
        >
            {EchartComItem.getThemeItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="usual"
            header="布局"
        >
            <Item
                label={"网格"}
            >
                <Item
                    label={"上"}
                    name={["usual", "grid", "top"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"下"}
                    name={["usual", "grid", "bottom"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'top'},
                            {value: 'middle'},
                            {value: 'bottom'},
                        ]}
                    />
                </Item>
                <Item
                    label={"左"}
                    name={["usual", "grid", "left"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
                <Item
                    label={"右"}
                    name={["usual", "grid", "right"]}
                >
                    <AutoComplete
                        maxLength={10}
                        placeholder="请选择或填写"
                        options={[
                            {value: '10px'},
                            {value: '10%'},
                            {value: 'left'},
                            {value: 'center'},
                            {value: 'right'},
                        ]}
                    />
                </Item>
            </Item>
            <Item
                label={"漏斗朝向"}
                name={["usual","_orient"]}
            >
                <Radio.Group>
                    <Radio value={"vertical"}>垂直</Radio>
                    <Radio value={"horizontal"}>水平</Radio>
                </Radio.Group>
            </Item>
            <Item
                label={"对齐方式"}
                name={["usual","_funnelAlign"]}
            >
                <Radio.Group>
                    <Radio value={"left"}>靠左</Radio>
                    <Radio value={"center"}>居中</Radio>
                    <Radio value={"right"}>靠右</Radio>
                </Radio.Group>
            </Item>
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key="dataLabel"
            header="数据标签"
            extra={
                <Item
                    noStyle={true}
                    name={["label","_setType"]}
                >
                    <Radio.Group
                        size={"small"}
                    >
                        <Radio.Button value="normal">平时</Radio.Button>
                        <Radio.Button value="emphasis">高亮时</Radio.Button>
                    </Radio.Group>
                </Item>
            }
        >
            {EchartComItem.getDataLabelItems({
                labelPositionOptions: <>
                    <Select.Option value="inside">内部居中</Select.Option>
                    <Select.Option value="insideRight">内部靠右</Select.Option>
                    <Select.Option value="insideLeft">内部靠左</Select.Option>
                    <Select.Option value="leftTop">左上</Select.Option>
                    <Select.Option value="rightTop">右上</Select.Option>
                    <Select.Option value="leftBottom">左下</Select.Option>
                    <Select.Option value="rightBottom">右下</Select.Option>
                    <Select.Option value="left">外部左侧</Select.Option>
                    <Select.Option value="right">外部右侧</Select.Option>
                    <Select.Option value="top">外部上方</Select.Option>
                    <Select.Option value="bottom">外部下方</Select.Option>
                </>
            })}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"tooltip"}
            header={"鼠标提示面板"}
        >
            {EchartComItem.getTooltipItems()}
        </Collapse.Panel>
        <Collapse.Panel
            forceRender={true}
            key={"legend"}
            header={"图例"}
        >
            {EchartComItem.getLegendItems()}
        </Collapse.Panel>
    </Collapse>
}

function getDefaultValues() {
    return {
        //数据量限制
        limitNum: 500,
        //数据集
        dataSetConfig: {
            labelField: null,
            valueField: null,
        },
        //主题
        theme: {
            backgroundColor: "#00000000",
            color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        },
        usual: {
            grid: {
                left: "10px",
                right: "10px",
                top: "10px",
                bottom: "10px"
            },
            _orient: "vertical",
            _funnelAlign: "center",
        },
        //鼠标提示面板
        tooltip: {
            seeMore: false,
            show: true,
            trigger: "item",
            triggerOn: "mousemove|click",
            backgroundColor: "#ffffffb3",
            borderColor: "#333333ff",
            borderWidth: 0,
            padding: 5,
            textStyle: {
                color: null
            }
        },
        //标签
        label: {
            _setType: "normal",
            show: true,
            _showPlan: "normal",//normal  pop
            color: "#ffffffff",
            position: "inside",
            _beforeTxt: "",
            _afterTxt: "",

            _emphasisSel: [],
            _emphasis: {},
        },
        //面积区样式
        itemStyle: {
            _setType: "normal",
            borderWidth: 0,
            borderType: "solid",
            borderColor: "#000000ff",
            shadowBlur: 0,
            shadowColor: "#000000e5",

            _emphasisSel: [],
            _emphasis: {},
        },
        //漏斗图特殊
        funnelSpecial:{
            min: 0,
            max: null,
            minSize: 0,
            maxSize: 100,
            gap: 0,
            sort: 'ascending',
        },
        //图例
        legend: {
            seeMore: false,
            show: false,
            type: "plain",  //plain  scroll
            icon: "roundRect",
            _position: "top",
            width: "auto",
            height: "auto",
            orient: "horizontal",
            align: "auto",
            itemGap: 10,
        },
    }
}

function getDemoCode(){
    return `
    注：数据示例中的 name, value 字段可以自定义。
    1. 返回一个数组，每一项是漏斗每一层的数据
        [
            {name: "第一层",value: 350},
            {name: "第二层",value: 250},
            {name: "第三层",value: 80},
            {name: "第四层",value: 180},
            {name: "第五层",value: 300},
        ]
    2. 返回一个对象，分别说明 层名称 和 层数据
        {
            name: ["第一层","第二层","第三层","第四层","第五层"],
            value: [350,250,80,180,300],
        }
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}