import React from "react";
import {
    Form,
    Input,
    Checkbox,
    Row,
    Col,
    InputNumber,
    Select,
    Slider
} from "antd";
import UploadFile from "../../../components/upLoad/UploadFile"
import ColorPicker from "./formDiyItem/ColorPicker";

const Item = Form.Item;
const Option = Select.Option;

//输入框 + 单位选择
function distanceSet(valueField, unitField, unitArray = ["px", "%"]) {
    return <Row>
        <Col span={12}>
            <Item
                noStyle
                name={valueField}
                style={{
                    display: 'inline-block',
                }}
            >
                <InputNumber
                    controls={false}
                    min={-1000}
                    max={1000}
                    step={1}
                    precision={0}
                />
            </Item>
        </Col>
        <Col span={12}>
            <Item
                noStyle
                name={unitField}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: 'inline-block',
                }}
            >
                <Select placeholder="选择单位"
                        style={{
                            width: "60px",
                            marginLeft: '8px',
                        }}
                >
                    {
                        unitArray.map((str) => {
                            return <Option
                                value={str}
                                key={str}
                            >{str}</Option>
                        })
                    }
                </Select>
            </Item>
        </Col>
    </Row>
}

//滑动和输入框通用
function getSliderAndInputItemGroup(field, options) {
    return <Row>
        <Col span={options?.addonAfter?16:18}>
            <Item
                noStyle
                name={field}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <Slider
                    min={0}
                    max={50}
                    step={1}
                    {...options}
                />
            </Item>
        </Col>
        <Col span={options?.addonAfter?8:6}>
            <Item
                noStyle
                name={field}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <InputNumber
                    style={{
                        width: options?.addonAfter?"70px":"60px"
                    }}
                    controls={false}
                    min={0}
                    max={50}
                    step={1}
                    precision={0}
                    {...options}
                />
            </Item>
        </Col>
    </Row>
}

//字体配置 （大小，粗细，颜色）
function getFontSet(parentKeyName,hideInput=false,hideColor=false){
    return <>
        {
            hideInput
                ?null
                :<Item
                    label={"文本"}
                    name={[].concat(parentKeyName).concat("text")}
                    rules={[{required: true}]}
                >
                    <Input
                        maxLength={50}
                    />
                </Item>
        }
        {
            hideColor
                ?null
                :<Item
                    label={"字体颜色"}
                    name={[].concat(parentKeyName).concat("color")}
                >
                    <ColorPicker/>
                </Item>
        }
        <Item
            label={"字体类型"}
            name={[].concat(parentKeyName).concat("fontFamily")}
        >
            {getFontFamilySelect()}
        </Item>
        <Item
            label={"字体大小"}
        >
            {distanceSet([].concat(parentKeyName).concat("fontSize"),[].concat(parentKeyName).concat("sizeUnit"),["px","rem"])}
        </Item>
        <Item
            label={"字体样式"}
        >
            <Item
                noStyle
                name={[].concat(parentKeyName).concat("fontBold")}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Checkbox>加粗</Checkbox>
            </Item>
            <Item
                noStyle
                name={[].concat(parentKeyName).concat("fontItalic")}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Checkbox>倾斜</Checkbox>
            </Item>
        </Item>
    </>
}

//图标配置(大小，地址)
function getIconSet(parentKeyName,getFieldValue){
    let defaultImgUrl = getFieldValue([parentKeyName, "upload_imageUrl"]);

    return <>
        <Item
            label={"大小设置"}
        >
            <Item
                label={"宽"}
            >
                {distanceSet([parentKeyName, "iconW"], [parentKeyName, "iconWUnit"], ["px", "rem"])}
            </Item>
            <Item
                label={"高"}
            >
                {distanceSet([parentKeyName, "iconH"], [parentKeyName, "iconHUnit"], ["px", "rem"])}
            </Item>
        </Item>
        <Item
            name={[parentKeyName, "upload_imageUrl"]}
            label={"上传图标"}
            rules={[{required: true}]}
            normalize={(value, prevValue, prevValues) => {
                value = value?.[0]?.url || null
                return value
            }}
        >
            <UploadFile
                toCN={true}
                accept={"image/*"}
                size={1}
                data={{
                    option: {
                        size: 1,
                        defaultValue: defaultImgUrl ? [{
                            url: defaultImgUrl
                        }] : null
                    }
                }}
            />
        </Item>
    </>
}

function getFontFamilySelect(){
    return <Select>
        <Select.Option value={"default"}>跟随网站系统</Select.Option>
        <Select.Option value={"STSong,SimSun"}>宋体</Select.Option>
        <Select.Option value={"STFangsong,FangSong"}>仿宋</Select.Option>
        <Select.Option value={"STHeiti,SimHei"}>黑体</Select.Option>
        <Select.Option value={"STKaiti,KaiTi"}>楷体</Select.Option>
        <Select.Option value={"cursive"}>手写字体</Select.Option>
        <Select.Option value={"Date-Number"}>段码数字</Select.Option>
        <Select.Option value={"Beautiful-Number"}>特化数字</Select.Option>
    </Select>
}

//阴影配置
function getShadowSet(parentKeyNameArr){
    return <>
        <Item
            label={"大小"}
        >
            {getSliderAndInputItemGroup(parentKeyNameArr.concat("blur"),{
                min: 0,
                max: 100,
                step: 1
            })}
        </Item>
        <Item
            label={"颜色"}
        >
            {getColorSetItemInner(parentKeyNameArr.concat("color"))}
        </Item>
        <Item
            label={"不透明度"}
        >
            {getSliderAndInputItemGroup(parentKeyNameArr.concat("opacity"),{
                min: 0,
                max: 100,
                step: 1
            })}
        </Item>
    </>
}

//颜色配置
function getColorSetItemInner(field,extraOption={hideOpacity:true}){
    return  <Form.Item
        name={field}
    >
        <ColorPicker
            {...extraOption}
        />
    </Form.Item>
}

//准备键转换下拉内容
function getAutoCompleteOptions(keyStrList){
    if(!keyStrList?.length){
        return [];
    }

    return keyStrList.map((keyStr)=>{
        let lastKey = keyStr.split(".").pop();
        return {value: lastKey, label: keyStr, key: keyStr}
    })
}

//提取下拉key
function getOriginKeyListByData(data){
    if(!data || typeof data !== "object"){
        return [];
    }

    let keyMap = new Map();

    let dealAction = (data,parentKey)=>{
        let prototypeStr = Object.prototype.toString.call(data);
        if(prototypeStr === '[object Object]'){
            //对象，提取键
            Object.keys(data).forEach((eachKey)=>{
                let currKey = parentKey + (parentKey?".":"") + eachKey;
                if(!keyMap.has(currKey)){
                    keyMap.set(currKey,true)
                }
                dealAction(data[eachKey],currKey)
            })
        }else if(prototypeStr === '[object Array]'){
            //数组
            data.forEach((eachItem)=>{
                dealAction(eachItem,parentKey)
            })
        }else{
            //其它，不处理
        }
    }

    dealAction(data,"");
    // console.log(keyMap);
    return Array.from(keyMap.keys());
}

//上两步的结合
function getAutoCompleteOptionsByBindData(data){
    return getAutoCompleteOptions(getOriginKeyListByData(data))
}

export default {
    distanceSet,
    getSliderAndInputItemGroup,
    getFontSet,
    getIconSet,
    getFontFamilySelect,
    getShadowSet,
    getColorSetItemInner,
    getOriginKeyListByData,
    getAutoCompleteOptions,
    getAutoCompleteOptionsByBindData
}